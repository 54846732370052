import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useService, useStores } from 'Hooks';
import { Col, Layout, Row } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Delete, Email, Phone, ServiceRequest as ServiceRequestIcon, Website } from 'Components/icons';
import './index.less';
import { LocationServiceService } from 'Services/LocationServiceService';
import { LocationServiceDto } from 'Api/Features/LocationServices/Dtos/LocationServiceDto';
import SimpleList, { SimpleListItemProps } from 'Components/simple-list/simple-list';
import EditLocationServiceModal from '../edit-service-modal';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import { htmlContainsText } from 'Utils';
import SimpleListImagesComponent from 'Components/simple-list-images-component';
import ImageCarouselModal from 'Components/image-carousel-modal';
import { truncate } from 'Utils/TextUtils';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const LocationServiceDetails: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    const { globalLoadingStore, confirmationModalStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const { id, serviceId } = useParams<{ id: string; serviceId: string }>();
    const [data, setData] = useState<LocationServiceDto>();
    const locationServiceService = useService(LocationServiceService);

    const [contactInformation, setContactInformation] = useState<SimpleListItemProps[]>([]);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [imageCarouselModalState, setImageCarouselModalState] = useState<{
        visible: boolean;
        startingIndex: number;
    }>({ visible: false, startingIndex: 0 });

    const deleteService = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`LocationServices.location_service_delete_confirm_title`),
                message: t(`LocationServices.location_service_delete_confirm_message`),
                positiveText: t(`LocationServices.location_service_delete_confirm_positive`),
                negativeText: t(`LocationServices.location_service_delete_confirm_negative`),
            }))
        )
            return;

        globalLoadingStore.addLoading();
        await locationServiceService.deletelocationServices(serviceId);
        globalLoadingStore.removeLoading();
        history.push(`/locations/${id}/management/locationServices`);
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'locationServices',
            nameKey: 'Location.location_services',
        },
        {
            path: data?.id || '',
            name: data?.name || '',
        },
    ];

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('LocationServices.location_service_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('LocationServices.location_service_delete'),
            action: (): Promise<void> => deleteService(),
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const response: LocationServiceDto | null = await locationServiceService.getLocationService(
                serviceId
            );

            if (response) {
                setData(response);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [locationServiceService, serviceId, globalLoadingStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        if (data) {
            const contactInformation: SimpleListItemProps[] = [];
            if (data.phoneNumber) {
                contactInformation.push({
                    avatar: <Phone fill={theme['primary-color']} />,
                    title: data.phoneNumber,
                });
            }
            if (data.email) {
                contactInformation.push({
                    avatar: <Email fill={theme['primary-color']} />,
                    title: data.email,
                });
            }
            if (data.website) {
                contactInformation.push({
                    avatar: <Website fill={theme['primary-color']} />,
                    title: truncate(data.website, 70)
                });
            }
            setContactInformation(contactInformation);

            const description: SimpleListItemProps[] = [];
            if (data.description) {
                description.push({
                    title: data.description,
                });
            }
            setDescription(description);
        }
    }, [data]);

    return (
        <div className="LocationServiceDetails">
            <LocationHeader
                title={data?.name || ''}
                defaultImg={<ServiceRequestIcon fill={theme['primary-color']} />}
                imgSrc={data?.mainImageUrl}
                loading={loading}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />
            <Content>
                <Row gutter={40}>
                    {contactInformation?.length > 0 && (
                        <Col span={12}>
                            <SimpleList
                                title={t('Contact.contact_information')}
                                data={contactInformation}
                            />
                        </Col>
                    )}

                    <Col span={12}>
                        {description?.length > 0 && htmlContainsText(data?.description ?? null) && (
                            <SimpleList title={t('description')} data={description} />
                        )}

                        {data?.images && data.images.length > 0 && (
                            <SimpleListImagesComponent
                                title={t('additionnal_images')}
                                images={data.images
                                    .filter(
                                        (image) =>
                                            image?.url !== null &&
                                            image?.url !== undefined &&
                                            image.id !== null &&
                                            image.id !== undefined
                                    )
                                    .map((image) => ({
                                        imageUrl: image?.url!,
                                        id: image?.id!,
                                    }))}
                                onImageClick={(id: string): void => {
                                    const index = data.images?.findIndex(
                                        (image) => image?.id === id
                                    );
                                    if (index !== undefined && index !== -1)
                                        setImageCarouselModalState({
                                            visible: true,
                                            startingIndex: index,
                                        });
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <EditLocationServiceModal
                    visible={editModalOpen}
                    onComplete={(success: boolean) => {
                        setEditModalOpen(false);
                        if (success) fetch();
                    }}
                    locationId={id}
                    locationService={data}
                />
            )}

            {imageCarouselModalState.visible && (
                <ImageCarouselModal
                    onClose={(): void =>
                        setImageCarouselModalState({ visible: false, startingIndex: 0 })
                    }
                    images={
                        data?.images?.filter((image) => image !== null).map((image) => image!) ?? []
                    }
                    startingIndex={imageCarouselModalState.startingIndex}
                />
            )}
        </div>
    );
});

export default LocationServiceDetails;
