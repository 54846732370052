import React, { FunctionComponent, useEffect, useState, useCallback, useContext } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Delete, FoodHot as FoodCategoriesIcon } from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import FoodCategoryModal from '../food-category-modal';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';
import { htmlContainsText } from 'Utils';
import { FoodMerchantContext } from '../../index';

const { Content } = Layout;

const FoodCategoriesDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { merchantId, categoryId } = useParams<{ merchantId: string; categoryId: string }>();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodCategoryService = useService(FoodCategoryService);
    const [data, setData] = useState<FoodCategoryDto | null>(null);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const deleteFoodCategory = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Category.delete_category_confirm_title`),
                message: t(`Category.delete_category_confirm_message`),
                positiveText: t(`Category.delete_category_confirm_positive`),
                negativeText: t(`Category.delete_category_confirm_negative`),
            }))
        )
            return;

        await foodCategoryService.deleteFoodCategory(categoryId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Food.edit_food_category'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Food.delete_food_category'),
            action: (): Promise<void> => deleteFoodCategory(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const response: FoodCategoryDto | null = await foodCategoryService.getFoodCategory(
                    categoryId
                );
                if (response) {
                    setData(response);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [editModalOpen, categoryId, foodCategoryService, globalLoadingStore, toastStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'merchants',
                nameKey: 'Food.food_and_beverages_merchant',
            },
            {
                path: foodMerchant?.id || '',
                name: foodMerchant?.name || '',
            },
            {
                path: 'categories',
                name: t('Food.merchant_categories_breadcrumb', {param1: foodMerchant?.name}),
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = [];
        if (data?.description) {
            description.push({
                title: data?.description,
            });
        }
        return description;
    }, [data]);

    useEffect(() => {
        if (data) {
            setDescription(getDescription);
        }
    }, [data, getDescription]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="FoodCategoriesDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={<></>}
                        imgSrc={data?.imageUrl}
                        defaultImg={<FoodCategoriesIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={<ActionMenu options={actions} type="primary" trigger="click" />}
                    />
                    <Content>
                        <Row gutter={40}>
                            {description && htmlContainsText(data.description || '') && (
                                <Col span={12}>
                                    <SimpleList
                                        className="description"
                                        title={t('description')}
                                        data={description}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Content>
                    {editModalOpen && (
                        <FoodCategoryModal
                            visible={editModalOpen}
                            onComplete={onEditComplete}
                            foodCategory={data}
                            merchantId={merchantId}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default FoodCategoriesDetail;
