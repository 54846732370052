import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    TimePicker,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Amenity as AmenityIcon, Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateAmenitySchema } from 'Schemas';
import { useFormValidation, useService, useStores } from 'Hooks';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './create-amenity.less';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AmenityService } from 'Services/AmenityService';
import moment, { Moment } from 'moment';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import { AmenityCategoryDto } from 'Api/Features/AmenityCategories/Dtos/AmenityCategoryDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { MOMENT_TIME_FORMAT } from 'Models/Constants';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ImagePicker } from 'Components/image-picker';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { moneyToFloat } from 'Utils/CurrencyUtils';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { theme } from 'variant';
import Icon from 'Components/icons/Icon';
import { AmenityBookingDurations, AmenityBookingDurationsTimeSpan } from 'Models/Amenities/AmenityBookingDurations';
import { convertDurationToTimeSpanString, convertTimeSpanStringToMinutes } from 'Utils/TimeUtils';
import CheckboxDropdown from 'Components/checkbox-dropdown/checkbox-dropdown';
import { AmenityBookingWindow } from 'Models/Amenities/AmenityBookingWindow';

const TimePickerAutoaccept = (props: PickerProps<Moment>) => {
    const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
        const value = moment(elem.target.value, props.format);
        if (value && value.isValid() && props.onChange) {
            props.onChange(value, elem.target.value);
        }
    };
    return <TimePicker {...props} onBlur={onBlur} />;
};

const { Title } = Typography;
const { Option } = Select;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface AmenityModel extends CreateAmenityRequestDto {
    allDay: boolean;
}

interface AmenityModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationId?: string;
    amenity?: AmenityDto;
}

const AmenityModal: FunctionComponent<AmenityModalProps> = ({
    visible,
    onComplete,
    locationId,
    amenity,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const amenityService = useService(AmenityService);
    const amenityCategoryService = useService(AmenityCategoryService);
    const [form] = Form.useForm();
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [amenityCategories, setAmenityCategories] = useState<Array<AmenityCategoryDto | null>>(
        []
    );
    const [allDay, setAllDay] = useState(false);
    const [openingTime, setOpeningTime] = useState<Moment | undefined>();
    const [closingTime, setClosingTime] = useState<Moment | undefined>();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        CreateAmenitySchema,
        form
    );
    const [townHallChecked, setTownHallChecked] = useState<boolean | undefined>(
        amenity?.isTownhallExclusive
    );
    const [coworkingChecked, setCoworkingChecked] = useState<boolean | undefined>(
        amenity?.isCoworking
    );

    const [hasSameDayLimit, setHasSameDayLimit] = useState<boolean>(false);
    const [hasContiguousLimit, setHasContiguousLimit] = useState<boolean>(false);
    const [maximumBookingDurationValue, setMaximumBookingDurationValue] = useState<
        string | undefined
    >();
    const [selectedBookingWindow, setSelectedBookingWindow] = useState<number>(
        amenity?.bookingWindowMaxDays ?? AmenityBookingWindow.OneYear
    );

    useEffect(() => {
        const fetchAmenityCategories = async (): Promise<void> => {
            const [items] = await amenityCategoryService.getAmenityCategories({
                includeEmpty: true,
            });
            setAmenityCategories(items);
        };

        fetchAmenityCategories();
    }, [amenityCategoryService]);

    useEffect(() => {
        setMaximumBookingDurationValue(
            amenity &&
                amenity.maximumBookingDuration &&
                AmenityBookingDurationsTimeSpan.indexOf(amenity.maximumBookingDuration) !== -1
                ? AmenityBookingDurations[
                      AmenityBookingDurationsTimeSpan.indexOf(amenity.maximumBookingDuration)
                  ].toString()
                : undefined
        );
    }, [amenity]);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (amenity) {
            form.setFieldsValue({
                name: amenity.name,
                description: amenity.description ?? undefined,
                categoryId: amenity.category?.id,
                pricePerBlock: amenity.pricePerBlock,
                taxAmountPerBlock: amenity.taxAmountPerBlock,
                isTownhallExclusive: amenity.isTownhallExclusive,
                isCoworking: amenity.isCoworking,
                isApprovalRequired: amenity.isApprovalRequired,
                creditsPerBlock: amenity.creditsPerBlock ?? 0,
                maximumContiguousBookingDuration: amenity.maximumContiguousBookingDuration
                    ? convertTimeSpanStringToMinutes(amenity.maximumContiguousBookingDuration)
                    : undefined,
                sameDayMaximumTotalBookingDuration: amenity.sameDayMaximumTotalBookingDuration
                    ? convertTimeSpanStringToMinutes(amenity.sameDayMaximumTotalBookingDuration)
                    : undefined,
                bookingWindowMaxDays: amenity.bookingWindowMaxDays ?? AmenityBookingWindow.OneYear
            });
            
            setOpeningTime(
                amenity.openingTime !== null
                    ? moment(amenity.openingTime, MOMENT_TIME_FORMAT)
                    : undefined
            );
            setClosingTime(
                amenity.closingTime !== null
                    ? moment(amenity.closingTime, MOMENT_TIME_FORMAT)
                    : undefined
            );

            if (amenity?.openingTime === null && amenity?.closingTime === null) {
                setAllDay(true);
            }
            
            setHasSameDayLimit(amenity.sameDayMaximumTotalBookingDuration !== null);
            setHasContiguousLimit(amenity.maximumContiguousBookingDuration !== null);
        }
    }, [amenity, form]);

    const on24hChange = (event: CheckboxChangeEvent): void => {
        setAllDay(event.target.checked);
        if (event.target.checked) {
            setOpeningTime(undefined);
            setClosingTime(undefined);
            form.setFieldsValue({
                openingTime: undefined,
                closingTime: undefined,
            });
        }
    };

    const onOpeningTimeChange = (value: Moment | null): void => {
        if (value !== null) setOpeningTime(value);
        else setOpeningTime(undefined);
    };

    const onClosingTimeChange = (value: Moment | null): void => {
        if (value !== null) setClosingTime(value);
        else setClosingTime(undefined);
    };

    const onAmenityAccessChange = (event: CheckboxChangeEvent): void => {
        const townHallChecked = event.target.id === 'isTownhallExclusive' && event.target.checked;
        const coworkingChecked = event.target.id === 'isCoworking' && event.target.checked;
        setTownHallChecked(townHallChecked);
        setCoworkingChecked(coworkingChecked);
        form.setFieldsValue({
            isTownhallExclusive: townHallChecked,
            isCoworking: coworkingChecked,
        });
    };

    const getMainImage = (): UpdateFileRequestDto | null => {
        return mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;
    };

    const errorsHandler = (error: any): void => {
        const responseErrors = error.response?.data?.errors;
        if (responseErrors && responseErrors['image.UploadBase64'] !== undefined) {
            const errors = new Map<string, string[]>();
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
            setErrors(errors);
        } else if (!error.treated) {
            toastStore.displayError(error);
        }
    };

    const createAmenity = async (values: Store): Promise<void> => {
        const data: AmenityModel = {
            name: values.name,
            description: values.description,
            categoryId: values.categoryId,
            locationId: locationId,
            pricePerBlock: moneyToFloat(values.pricePerBlock),
            taxAmountPerBlock: moneyToFloat(values.taxAmountPerBlock),
            isApprovalRequired: values.isApprovalRequired,
            openingTime: openingTime === undefined ? null : openingTime.format('HH:mm:00'),
            closingTime: closingTime === undefined ? null : closingTime.format('HH:mm:00'),
            allDay: allDay,
            isTownhallExclusive: values.isTownhallExclusive,
            isCoworking: values.isCoworking,
            image: getMainImage(),
            creditsPerBlock: values.creditsPerBlock !== '' ? values.creditsPerBlock : undefined,
            maximumBookingDuration: maximumBookingDurationValue,
            sameDayMaximumTotalBookingDuration: hasSameDayLimit ? values.sameDayMaximumTotalBookingDuration : undefined,
            maximumContiguousBookingDuration: hasContiguousLimit ? values.maximumContiguousBookingDuration : undefined,
            bookingWindowMaxDays: selectedBookingWindow
        };

        if (!(await validateForm(data, false))) return;

        data.maximumBookingDuration = maximumBookingDurationValue
            ? convertDurationToTimeSpanString(
                  moment.duration(maximumBookingDurationValue, 'minutes')
              )
            : null;
        data.sameDayMaximumTotalBookingDuration = hasSameDayLimit
            ? convertDurationToTimeSpanString(
                  moment.duration(values.sameDayMaximumTotalBookingDuration, 'minutes')
              )
            : null;
        data.maximumContiguousBookingDuration = hasContiguousLimit
            ? convertDurationToTimeSpanString(
                  moment.duration(values.maximumContiguousBookingDuration, 'minutes')
              )
            : null;

        try {
            globalLoadingStore.addLoading();
            await amenityService.createAmenity(data);
            toastStore.toast({
                type: 'success',
                messageKey: `Amenities.amenity_created`,
            });
            dismiss(true);
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editAmenity = async (values: Store): Promise<void> => {
        const oldValues = amenity as AmenityDto;
        const data: AmenityModel = {
            name: values.name || oldValues.name,
            description: values.description || (oldValues.description ?? undefined),
            categoryId: values.categoryId || oldValues.category?.id,
            locationId: locationId,
            pricePerBlock: moneyToFloat(values.pricePerBlock),
            taxAmountPerBlock: moneyToFloat(values.taxAmountPerBlock),
            isApprovalRequired: values.isApprovalRequired,
            openingTime: openingTime === undefined ? null : openingTime.format('HH:mm:00'),
            closingTime: closingTime === undefined ? null : closingTime.format('HH:mm:00'),
            allDay: allDay,
            isTownhallExclusive: values.isTownhallExclusive,
            isCoworking: values.isCoworking,
            image: getMainImage(),
            creditsPerBlock: values.creditsPerBlock !== '' ? values.creditsPerBlock : undefined,
            maximumBookingDuration: maximumBookingDurationValue,
            sameDayMaximumTotalBookingDuration: hasSameDayLimit ? values.sameDayMaximumTotalBookingDuration : undefined,
            maximumContiguousBookingDuration: hasContiguousLimit ? values.maximumContiguousBookingDuration : undefined,
            bookingWindowMaxDays: selectedBookingWindow
        };
        
        if (!(await validateForm(data, false))) return;

        data.maximumBookingDuration = maximumBookingDurationValue
            ? convertDurationToTimeSpanString(
                  moment.duration(maximumBookingDurationValue, 'minutes')
              )
            : null;
        data.sameDayMaximumTotalBookingDuration = hasSameDayLimit
            ? convertDurationToTimeSpanString(
                  moment.duration(values.sameDayMaximumTotalBookingDuration, 'minutes')
              )
            : null;
        data.maximumContiguousBookingDuration = hasContiguousLimit
            ? convertDurationToTimeSpanString(
                  moment.duration(values.maximumContiguousBookingDuration, 'minutes')
              )
            : null;

        try {
            globalLoadingStore.addLoading();
            await amenityService.updateAmenity(data, amenity!.id as string);
            toastStore.toast({
                type: 'success',
                messageKey: `Amenities.amenity_updated`,
            });
            dismiss(true);
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (amenity !== undefined) {
            await editAmenity(values);
        } else {
            await createAmenity(values);
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `Amenities.modal_confirm_positive${amenity !== undefined ? '_edit' : ''}`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                amenity !== undefined
                    ? t('Amenities.modal_edit_title')
                    : t('Amenities.modal_create_title')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="CreateAmenity">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('Amenities.modal_name')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ name: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="categoryId"
                                label={t('Amenities.modal_amenity_category')}
                                required={true}
                            >
                                <Select>
                                    {amenityCategories.map((x) => (
                                        <Option key={x?.id} value={x?.id || ''}>
                                            {x?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="pricePerBlock"
                                label={t('Amenities.price_per_fifteen')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    value={amenity?.pricePerBlock || undefined}
                                    thousandSeparator={true}
                                    placeholder="$0.00"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { value } = values;
                                        form.setFieldsValue({ pricePerBlock: value });
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="taxAmountPerBlock"
                                label={t('Amenities.modal_tax_amount')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    value={amenity?.taxAmountPerBlock || undefined}
                                    thousandSeparator={true}
                                    placeholder="$0.00"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { value } = values;
                                        form.setFieldsValue({ taxAmountPerBlock: value });
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="creditsPerBlock"
                                label={t('Amenities.coworking_price')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    value={amenity?.pricePerBlock || 0}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { floatValue } = values;
                                        form.setFieldsValue({ creditsPerBlock: floatValue });
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('Amenities.modal_description')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                onChange={(value: string): void =>
                                    form.setFieldsValue({ description: value })
                                }
                                value={form.getFieldValue('description') || null}
                            />
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Amenities.amenity_settings')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="openingTime"
                                label={t('Amenities.opening_time')}
                                required={!allDay}
                            >
                                <TimePickerAutoaccept
                                    disabled={allDay}
                                    className="w-100"
                                    size="large"
                                    format={MOMENT_TIME_FORMAT}
                                    minuteStep={15}
                                    onChange={(val): void => onOpeningTimeChange(val)}
                                    defaultValue={openingTime ? openingTime : undefined}
                                    value={openingTime ? openingTime : null}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="closingTime"
                                label={t('Amenities.closing_time')}
                                required={!allDay}
                            >
                                <TimePickerAutoaccept
                                    disabled={allDay}
                                    className="w-100"
                                    size="large"
                                    format={MOMENT_TIME_FORMAT}
                                    minuteStep={15}
                                    onChange={(val): void => onClosingTimeChange(val)}
                                    defaultValue={closingTime ? closingTime : undefined}
                                    value={closingTime ? closingTime : null}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="maximumBookingDuration"
                                label={t('Amenities.max_booking_duration')}
                            >
                                <CheckboxDropdown
                                    options={AmenityBookingDurations.map((x) => ({
                                        value: x.toString(),
                                        label: x.toString(),
                                    }))}
                                    onDropdownValueChange={(value): void =>
                                        setMaximumBookingDurationValue(value)
                                    }
                                    defaultCheckboxChecked={
                                        amenity?.maximumBookingDuration !== null
                                    }
                                    dropdownValue={maximumBookingDurationValue}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="sameDayMaximumTotalBookingDuration"
                                label={t('Amenities.same_day_max_booking')}
                            >
                                <Input
                                    addonBefore={
                                        <Checkbox
                                            onChange={(e): void => {
                                                setHasSameDayLimit(e.target.checked);
                                                form.setFieldsValue({
                                                    sameDayMaximumTotalBookingDuration: e.target
                                                        .checked
                                                        ? '30'
                                                        : undefined,
                                                });
                                            }}
                                            checked={hasSameDayLimit}
                                        ></Checkbox>
                                    }
                                    disabled={!hasSameDayLimit}
                                    type={'number'}
                                ></Input>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="maximumContiguousBookingDuration"
                                label={t('Amenities.max_contigous_booking')}
                            >
                                <Input
                                    addonBefore={
                                        <Checkbox
                                            onChange={(e): void => {
                                                setHasContiguousLimit(e.target.checked);
                                                form.setFieldsValue({
                                                    maximumContiguousBookingDuration: e.target
                                                        .checked
                                                        ? '30'
                                                        : undefined,
                                                });
                                            }}
                                            checked={hasContiguousLimit}
                                        ></Checkbox>
                                    }
                                    type={'number'}
                                    disabled={!hasContiguousLimit}
                                ></Input>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="bookingWindowMaxDays"
                                label={t('Amenities.booking_window_limit')}
                                required
                            >
                                <Select defaultValue={selectedBookingWindow} value={selectedBookingWindow} onChange={(value): void => setSelectedBookingWindow(value)}>
                                    {Object.values(AmenityBookingWindow).map((value) => (
                                        <Option key={value} value={value}>
                                            {t(`Amenities.AmenityBookingWindow_${value}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12} className="h-40">
                            <ValidatedFormItem errors={errors} name="open24h">
                                <Checkbox onChange={on24hChange} defaultChecked={allDay}>
                                    {t('Amenities.settings_open_24h')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="isApprovalRequired">
                                <Checkbox
                                    defaultChecked={form.getFieldValue('isApprovalRequired')}
                                    onChange={(event: CheckboxChangeEvent): void =>
                                        form.setFieldsValue({
                                            isApprovalRequired: event.target.checked,
                                        })
                                    }
                                >
                                    {t('Amenities.settings_confirmation_needed')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Amenities.exclusivity_type_optional')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="h-40">
                            <ValidatedFormItem errors={errors} name="isTownhallExclusive">
                                <Checkbox
                                    defaultChecked={amenity?.isTownhallExclusive}
                                    onChange={onAmenityAccessChange}
                                    checked={townHallChecked}
                                >
                                    <span>{t('Amenities.this_amenity_is')}</span>
                                    <Icon iconName="Townhall" fill={theme['primary-color']} />
                                    <span className="type-text">{t('townhall')}</span>
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="isCoworking">
                                <Checkbox
                                    defaultChecked={amenity?.isCoworking}
                                    onChange={onAmenityAccessChange}
                                    checked={coworkingChecked}
                                >
                                    <span>{t('Amenities.this_amenity_is')}</span>
                                    <Icon iconName="Coworking" fill={theme['primary-color']} />
                                    <span className="type-text">{t('coworking')}</span>
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter} className="imageRow">
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        amenity?.imageUrl
                                            ? [
                                                  {
                                                      url: amenity?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default AmenityModal;
