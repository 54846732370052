import React, { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { Delete } from 'Components/icons';
import { FoodItemSpecification } from 'Models/FoodItem/FoodItemSpecification';
import { FormItemProps } from 'antd/es/form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface FoodItemSpecificationProps {
    id: string;
    gutter: [Gutter, Gutter];
    specification: FoodItemSpecification;
    onRemoveSpecification: (specificationId: string) => void;
    onSpecificationNameChange: (specificationId: string, value: string) => void;
    onSpecificationPriceChange: (
        specificationId: string,
        value: string | number | undefined
    ) => void;
    onSpecificationTaxAmountChange: (
        specificationId: string,
        value: string | number | undefined
    ) => void;
    errors: Map<string, string[]>;
    errorKey: string;
    showDelete?: boolean;
}

const FoodItemSpecificationRow: FunctionComponent<FoodItemSpecificationProps> = ({
    id,
    gutter,
    specification,
    onRemoveSpecification,
    onSpecificationNameChange,
    onSpecificationPriceChange,
    onSpecificationTaxAmountChange,
    errors,
    errorKey,
    showDelete,
}) => {
    const { t } = useTranslation();

    const generateErrors = (key: string | undefined): FormItemProps => {
        const fieldErrors = errors.get(key || '');
        const errorProps: FormItemProps = {};
        if (fieldErrors) {
            errorProps.hasFeedback = true;
            errorProps.help = fieldErrors.map((errorKey) => t(errorKey)).join('\n');
            errorProps.validateStatus = 'error';
        }
        return errorProps;
    };

    const keys = Array.from(errors.keys()).filter((k) => k.includes(errorKey));
    const nameErrorKey = keys.find((k) => k.includes('name'));
    const nameErrors = generateErrors(nameErrorKey);
    const priceErrorKey = keys.find((k) => k.includes('price'));
    const priceErrors = generateErrors(priceErrorKey);
    const taxAmountErrorKey = keys.find((k) => k.includes('tax'));
    const taxAmountErrors = generateErrors(taxAmountErrorKey);

    return (
        <>
            <Row gutter={gutter} key={specification.id} id={specification.id}>
                <Col span={9}>
                    <Form.Item
                        label={t('Food.food_item_specification_label')}
                        colon={false}
                        {...nameErrors}
                    >
                        <Input
                            value={specification.name || undefined}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                onSpecificationNameChange(specification.id, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={t('Table.column_price')} colon={false} {...priceErrors}>
                        <NumberFormat
                            className="ant-input"
                            value={specification.price || undefined}
                            allowNegative={false}
                            thousandSeparator={true}
                            placeholder="$0.00"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'$'}
                            onValueChange={(values: NumberFormatValues): void => {
                                const { value } = values;
                                onSpecificationPriceChange(specification.id, value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={t('tax_amount')} colon={false} {...taxAmountErrors}>
                        <NumberFormat
                            className="ant-input"
                            allowNegative={false}
                            value={specification.taxAmount || undefined}
                            thousandSeparator={true}
                            placeholder="$0.00"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'$'}
                            onValueChange={(values: NumberFormatValues): void => {
                                const { value } = values;
                                onSpecificationTaxAmountChange(specification.id, value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    {showDelete && (
                        <Form.Item label="&nbsp;" colon={false}>
                            <Button
                                type="default"
                                icon={<Delete />}
                                onClick={(): void => onRemoveSpecification(specification.id)}
                                htmlType="button"
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default FoodItemSpecificationRow;
