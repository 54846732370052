import { UserHidDetailsDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsDto';
import { UserHidDetailsInvitationDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsInvitationDto';
import { UserHidDetailsCredentialContainerDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsCredentialContainerDto';

export class UserHidDetails implements UserHidDetailsDto {
    constructor(dto: UserHidDetailsDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    externalId?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    email?: string | null;
    status?: string | null;
    invitations?: (UserHidDetailsInvitationDto | null)[] | null;
    credentialContainers?: (UserHidDetailsCredentialContainerDto | null)[] | null;
    hidConsoleUrl?: string | null;
}
