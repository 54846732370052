import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { useSearchDebounce, useService } from 'Hooks';
import { BookingService } from 'Services/BookingService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import { ColumnType, Key, SorterResult, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Amenity as AmenityIcon, Booking as BookingIcon, User as UserIcon, Recurring } from 'Components/icons';
import { TableFilters } from 'Components/table-filters';
import { PAGE_SIZE } from 'Models/Constants';
import './index.less';
import { Booking } from 'Models/Bookings/Booking';
import { TdWithImage } from 'Components/td-with-image';
import { GetBookingsSortColumnDto } from 'Api/Features/Bookings/Dtos/GetBookingsSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import StatusBadge from 'Components/status-badge/status-badge';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Booking.booking_approval_status',
        items: Object.keys(BookingApprovalStatusDto)
            .filter((key) => key !== BookingApprovalStatusDto.NotRequired)
            .map((key) => {
                return {
                    key: key,
                    displayNameKey: 'StatusBadge.badge_status_' + key,
                    checked: true,
                };
            }),
    },
];

const LocationBookingApproval: FunctionComponent = () => {
    //#region Hooks
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const bookingService = useService(BookingService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'booking-approval',
            nameKey: 'Booking.approval_requests',
        },
    ];

    //#endregion

    //#region Table Content

    const getTableColumnsSortHandler = () => {
        return (amenA: Booking, amenB: Booking): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<Booking>,
        columnKey: GetBookingsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<Booking>): ColumnType<Booking>[] => {
        return [
            {
                key: GetBookingsSortColumnDto.AmenityName,
                title: t('Amenities.amenity_label'),
                render: (booking: Booking): ReactNode | null => {
                    return (
                        <TdWithImage
                            defaultImg={<AmenityIcon />}
                            imgSrc={booking.amenity?.imageUrl}
                        >
                            {booking.amenity?.name}
                        </TdWithImage>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetBookingsSortColumnDto.AmenityName
                ),
            },
            {
                key: GetBookingsSortColumnDto.UserName,
                title: t('User.user'),
                render: (booking: Booking): ReactNode | null => {
                    return (
                        <TdWithImage defaultImg={<UserIcon />} imgSrc={booking.organizer?.imageUrl}>
                            {booking.organizerName}
                        </TdWithImage>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetBookingsSortColumnDto.UserName),
            },
            {
                key: GetBookingsSortColumnDto.CompanyName,
                title: t('Company.company'),
                render: (booking: Booking): ReactNode | null => {
                    return (
                        <TdWithImage defaultImg={<UserIcon />} imgSrc={booking.company?.imageUrl}>
                            {booking.company?.name}
                        </TdWithImage>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetBookingsSortColumnDto.CompanyName
                ),
            },
            {
                key: GetBookingsSortColumnDto.Date,
                title: t('Booking.booking_date_and_time'),
                render: (booking: Booking): ReactNode => {
                    return (
                        <div className="booking-date-container">
                            <div>{booking.dateAndTime}</div>
                            {booking.bookingRecurrence !== null && booking.bookingRecurrence !== undefined && (
                                <div className="recurring-container">
                                    <Recurring fill={theme['primary-color']} />
                                    <span className="text">{t('recurring')}</span>
                                </div>
                            )}
                        </div>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetBookingsSortColumnDto.Date),
                width: 403
            },
            {
                title: t('Booking.booking_approval_status'),
                render: (booking: Booking): ReactNode | null => (
                    booking.approvalStatus && (
                        <StatusBadge status={ booking.statusBadgeStatus } />
                    )
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<Booking> = {
        columnKey: GetBookingsSortColumnDto.AmenityName as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<Booking>
    ): [GetBookingsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetBookingsSortColumnDto = tableSort.columnKey as GetBookingsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<Booking>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<Booking> | SorterResult<Booking>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
        });
    };

    //#endregion

    const getCheckedStatus = (): BookingApprovalStatusDto[] => {
        return filterStoreRef.current.checkedItemsByFilterKey(
            'status'
        ) as BookingApprovalStatusDto[];
    };

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetBookingsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
        }) => {
            setLoading(true);

            try {
                // call api
                const [items, totalItems] = await bookingService.getBookings({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    locationIds: [id],
                    searchTerm: params.searchTerm,
                    approvalStatuses: getCheckedStatus(),
                    collapseRecurrence: true
                });

                setBookings(items);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [bookingService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                approvalStatuses: getCheckedStatus(),
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    const onRowClick = (bookingId: string | null): void => {
        if (bookingId) {
            history.push(`/locations/${id}/booking-approval/${bookingId}`);
        }
    };
    //#endregion

    return (
        <div className="BookingApprovals">
            <LocationHeader
                title={t('Booking.approval_requests')}
                subTitle={t('Booking.approval_requests_subtitle')}
                defaultImg={<BookingIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Booking): string => record.id || ''}
                    dataSource={bookings}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: Booking) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
        </div>
    );
};

export default LocationBookingApproval;
