import React, { FunctionComponent, useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import { Close, ServiceRequest as ServiceRequestIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { EditLocationServiceSchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { LocationServiceService } from 'Services/LocationServiceService';
import { LocationServiceDto } from 'Api/Features/LocationServices/Dtos/LocationServiceDto';
import './edit-service-modal.less';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { CreateLocationServiceRequestDto } from 'Api/Features/LocationServices/Dtos/CreateLocationServiceRequestDto';
import { WEBSITE_PLACEHOLDER } from 'Models/Constants';

const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface EditServiceModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationService?: LocationServiceDto;
    locationId: string;
}

const EditLocationServiceModal: FunctionComponent<EditServiceModalProps> = ({
    visible,
    onComplete,
    locationService,
    locationId
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const locationServiceService = useService(LocationServiceService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        EditLocationServiceSchema, form
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [otherImagesDetails, setOtherImagesDetails] = useState<ImageDetails[]>();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (locationService) {
            form.setFieldsValue({
                name: locationService.name,
                website: locationService.website,
                email: locationService.email,
                phoneNumber: locationService.phoneNumber,
                description: locationService.description ?? undefined,
            });
        }
    }, [locationService, form]);

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ServiceRequestIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t('leave_confirm_positive'),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ServiceRequestIcon />,
                title: t(`LocationServices.location_service_saved_success`),
                message: t(`LocationServices.location_service_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        
        const mainImage = mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const model = {
            description: formValues.description,
            email: formValues.email && formValues.email !== '' ? formValues.email : undefined,
            locationId: locationId,
            name: formValues.name,
            phoneNumber: formValues.phoneNumber,
            website: formValues.website,
            mainImage: mainImage,
            images: otherImagesDetails
                ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                .map(
                    (image) =>
                        ({
                            delete: image.isDeleted,
                            uploadBase64: image.base64,
                            id: image.id,
                        } as UpdateFileRequestDto)
                ),
        } as CreateLocationServiceRequestDto;

        if(!(await validateForm(model, false))) return;

        try {
            globalLoadingStore.addLoading();

            if (locationService) {
                await locationServiceService.updateLocationService(locationService.id!, model);
            } else {
                await locationServiceService.createLocationService(model);
            }

            success();
        } catch (e) {
            if (e.response?.data?.errorCode === 'E015003') {
                toastStore.toast({
                    type: 'error',
                    message: t('Errors.error_setting_image')
                })
            }
            else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                locationService !== undefined
                    ? t('LocationServices.location_service_edit_title')
                    : t('LocationServices.location_service_create_title')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="EditLocationServiceModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                label={t('Amenities.main_image')}
                                name="mainImage"
                            >
                                <ImagePicker
                                    images={
                                        locationService?.mainImageUrl
                                            ? [
                                                  {
                                                      url: locationService?.mainImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                label={t('additionnal_images')}
                                name="images"
                            >
                                <ImagePicker
                                    allowMultiple
                                    images={locationService?.images?.map(
                                        (image) =>
                                            ({ url: image?.url, uid: image?.id } as UploadFile)
                                    )}
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setOtherImagesDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="website"
                                label={t('User.website')}
                            >
                                <Input placeholder={WEBSITE_PLACEHOLDER} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="email" label={t('User.email')}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="phoneNumber"
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('description')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={
                                    locationService?.description ||
                                    form.getFieldValue('description') ||
                                    null
                                }
                                onChange={handleDescriptionChange}
                            />
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default EditLocationServiceModal;
