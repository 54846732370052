import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { Col, Layout, Row } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Message as MessageIcon } from 'Components/icons';
import { Location } from 'Models/Location/Location';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { CompanyMessage } from 'Models/Messages/CompanyMessage';
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from 'Models/Constants';
import './index.less';

const { Content } = Layout;

const LocationMessageDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    const { globalLoadingStore, navigationStore } = useStores();
    const [loading, setLoading] = useState(true);

    const { id, messageId } = useParams();
    const locationService = useService(LocationService);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [message, setMessage] = useState<CompanyMessage | undefined>(undefined);
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'announcements',
            name: t('Location.location_announcements'),
        },
        {
            path: messageId,
            nameKey: 'Message.announcement_details',
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const locationData: LocationDto | null = await locationService.getById(id);

            if (locationData) {
                const location: Location = new Location(locationData);
                setLocation(location);
                navigationStore.setSubmenu(location);

                const messageData = await locationService.getLocationMessageById(
                    location.id!,
                    messageId
                );
                if (messageData) {
                    setMessage(new CompanyMessage(messageData));
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [locationService, id, messageId, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <>
            <LocationHeader
                title={t('Location.location_announcements')}
                subTitle={t('Location.location_announcements_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                loading={loading}
                routes={breadcrumbs}
            />
            <Layout>
                <Content className="messageDetailContent">
                    <Row gutter={16}>
                        <Col span={24}>
                            <h1 className="messageTitle">{message?.title || ''}</h1>
                            <h2 className="messageFrom">
                                {t('Message.message_details_from')}&nbsp;{message?.fromName || '-'}
                            </h2>
                            <h2 className="messageTo">
                                {t('Message.message_details_to')}&nbsp;
                                {location?.name || ''}
                            </h2>
                            <h2 className="messageDate">
                                {t('Message.message_details_date')}&nbsp;
                                {moment(message?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}
                            </h2>
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: message?.body || '' }} />
                            <hr className="closingLine" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
});

export default LocationMessageDetail;
