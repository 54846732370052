import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { autorun } from 'mobx';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { images, theme } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { CircleCheckmark, Location as LocationIcon } from 'Components/icons';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { DATETIME_DISPLAY_FORMAT, PAGE_SIZE } from 'Models/Constants';
import { CheckInService } from 'Services/CheckInService';
import { CheckInDto } from 'Api/Features/CheckIns/Dtos/CheckInDto';
import { GetCheckInsSortColumnDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsSortColumnDto';
import { GetCheckInsRequestDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsRequestDto';
import moment from 'moment';
import './index.less';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [];

const CheckIns: FunctionComponent = () => {
    //#region Hooks
    const { t } = useTranslation();
    const { locationStore, navigationStore, userStore } = useStores();
    const checkInService = useService(CheckInService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [checkIns, setCheckIns] = useState<CheckInDto[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (checkInA: CheckInDto, checkInB: CheckInDto): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<CheckInDto>,
        columnKey: GetCheckInsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getCampusTimed = (checkIn: CheckInDto): string => {
        if (checkIn.location) {
            const location = locationStore.locations.find(
                (location) => location.id === checkIn.location?.id
            );
            if (location)
                return moment
                    .tz(checkIn.checkInTime, location?.timeZone!)
                    .format(DATETIME_DISPLAY_FORMAT);
            else return '';
        }
        return '';
    };

    const getTableColumns = (currentSort: SorterResult<CheckInDto>): ColumnType<CheckInDto>[] => {
        return [
            {
                key: GetCheckInsSortColumnDto.Date,
                title: t('date'),
                render: (checkIn: CheckInDto): string => getCampusTimed(checkIn),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCheckInsSortColumnDto.Date),
            },
            {
                key: GetCheckInsSortColumnDto.FirstName,
                title: t('User.first_name'),
                render: (checkIn: CheckInDto): string => checkIn.firstName ?? '',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCheckInsSortColumnDto.FirstName),
            },
            {
                key: GetCheckInsSortColumnDto.LastName,
                title: t('User.last_name'),
                render: (checkIn: CheckInDto): string => checkIn.lastName ?? '',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCheckInsSortColumnDto.LastName),
            },
            {
                key: GetCheckInsSortColumnDto.Email,
                title: t('User.email'),
                render: (checkIn: CheckInDto): string => checkIn.email ?? '',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCheckInsSortColumnDto.Email),
            },
            {
                key: 'phoneNumber',
                title: t('phone_number'),
                render: (checkIn: CheckInDto): string =>
                    checkIn.phoneNumber === '' ? 'N/A' : checkIn?.phoneNumber ?? 'N/A',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
            },
            {
                key: GetCheckInsSortColumnDto.LocationName,
                title: t('Location.location'),
                render: (checkIn: CheckInDto): ReactNode | null => {
                    return checkIn.location ? (
                        <TdWithImage
                            defaultImg={<LocationIcon />}
                            imgSrc={checkIn.location.mainImageUrl}
                        >
                            {checkIn.location.name}
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetCheckInsSortColumnDto.LocationName
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<CheckInDto> = {
        columnKey: GetCheckInsSortColumnDto.Date as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<CheckInDto>
    ): [GetCheckInsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetCheckInsSortColumnDto = tableSort.columnKey as GetCheckInsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<CheckInDto>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<CheckInDto> | SorterResult<CheckInDto>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            locationIds: filterStore.currentLocationIds,
            from: filterStoreRef.current.startDate,
            to: filterStoreRef.current.endDate
        });
    };
    //#endregion

    //#region Fetch & Effects
    useEffect(() => {
        navigationStore.clearSubmenu();
    }, [navigationStore]);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetCheckInsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
            locationIds: string[];
            from: string;
            to: string;
        }) => {
            setLoading(true);
            try {
                // call api
                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    from: filterStoreRef.current.startDate === "" ? null : filterStoreRef.current.startDate,
                    to: filterStoreRef.current.endDate === "" ? null : filterStoreRef.current.endDate
                } as GetCheckInsRequestDto;
                
                if (params.locationIds.length > 0) {
                    request.locationIds = params.locationIds;
                } else if (userStore.isBuildingManager) {
                    request.locationIds = userStore.buildingManagerLocationIds;
                }

                const [checkIns, totalItems] = await checkInService.getCheckIns(request);
                setCheckIns(checkIns);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [checkInService, userStore]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
                locationIds: filterStore.currentLocationIds,
                from: filterStoreRef.current.startDate,
                to: filterStoreRef.current.endDate
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    return (
        <div className="CheckIns">
            <ListSectionHeader
                title={t('CheckIn.check_in_title')}
                subTitle={t('CheckIn.check_in_subtitle')}
                defaultImg={<CircleCheckmark fill={theme['primary-color']} />}
                backgroundImageUrl={listSectionHeader}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeLocations
                    availableLocations={locationStore.locations
                        .filter(
                            (location) => 
                                userStore.isAdmin ||
                                (userStore.isBuildingManager &&
                                    userStore.buildingManagerLocationIds.some(
                                        (buildingId) => location.id === buildingId
                                    ))
                        )
                        .map((location) => location as LightLocationInfo)}
                    includeSearch
                    includeDates
                />
                <Table
                    className="table-striped-rows table-action-rows no-hover"
                    bordered
                    columns={columns}
                    rowKey={(checkIn: CheckInDto): string => checkIn.id ?? ''}
                    dataSource={checkIns}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>
        </div>
    );
};

export default CheckIns;
