// GENERATED FILE - DO NOT MODIFY
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateLocationServiceRequestDto } from 'Api/Features/LocationServices/Dtos/CreateLocationServiceRequestDto';
import { GetLocationServicesRequestDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesRequestDto';
import { GetLocationServicesResponseDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesResponseDto';
import { LocationServiceDto } from 'Api/Features/LocationServices/Dtos/LocationServiceDto';
import { UpdateLocationServiceImagesRequest } from 'Api/Features/LocationServices/Dtos/UpdateLocationServiceImagesRequest';
import { UpdateLocationServiceRequestDto } from 'Api/Features/LocationServices/Dtos/UpdateLocationServiceRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LocationServiceProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLocationService(locationServiceId: string): Promise<LocationServiceDto | null> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}",
            { locationServiceId: locationServiceId },
            null
        );

        const data: LocationServiceDto | null = await this.httpClient.get<LocationServiceDto | null>(uri);
        return data;
    }

    public async getLocationServices(request: GetLocationServicesRequestDto | null): Promise<GetLocationServicesResponseDto | null> {
        const uri = this.buildUri(
            "/location-services",
            null,
            { ...request || undefined }
        );

        const data: GetLocationServicesResponseDto | null = await this.httpClient.get<GetLocationServicesResponseDto | null>(uri);
        return data;
    }

    public async createLocationService(request: CreateLocationServiceRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/location-services",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateLocationServiceRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateLocationService(locationServiceId: string, request: UpdateLocationServiceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}",
            { locationServiceId: locationServiceId },
            null
        );

        await this.httpClient.patch<UpdateLocationServiceRequestDto | null, void>(uri, request);
    }

    public async deletelocationServices(locationServiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}",
            { locationServiceId: locationServiceId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateLocationServiceMainImage(locationServiceId: string, request: UpdateFileRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}/main-image",
            { locationServiceId: locationServiceId },
            null
        );

        await this.httpClient.put<UpdateFileRequestDto | null, void>(uri, request);
    }

    public async deleteLocationServiceMainImage(locationServiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}/main-image",
            { locationServiceId: locationServiceId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateLocationServiceImages(locationServiceId: string, request: UpdateLocationServiceImagesRequest | null): Promise<void> {
        const uri = this.buildUri(
            "/location-services/{locationServiceId}/images",
            { locationServiceId: locationServiceId },
            null
        );

        await this.httpClient.patch<UpdateLocationServiceImagesRequest | null, void>(uri, request);
    }
}