import React, { FunctionComponent, useEffect, useState, useCallback, useContext } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Delete,
    Promotion as FoodPromotionsIcon,
    Calendar as CalendarIcon,
    FoodSandwich as FoodItemsIcon,
    FoodHot as FoodCategoriesIcon,
} from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { FoodPromotion } from 'Models/FoodPromotion/FoodPromotion';
import { FoodPromotionService } from 'Services/FoodPromotionService';
import FoodPromotionModal from '../food-promotion-modal';
import { FoodMerchantContext } from '../../index';
import StatusBadge from 'Components/status-badge/status-badge';
import moment from 'moment';
import { DATE_API_FORMAT } from 'Models/Constants';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const FoodPromotionsDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, promotionId } = useParams<{ id: string; promotionId: string }>();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const foodPromotionService = useService(FoodPromotionService);
    const [data, setData] = useState<FoodPromotion | null>(null);
    const [duration, setDuration] = useState<SimpleListItemProps[]>([]);
    const [itemsAndCategories, setItemsAndCategories] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const deleteFoodPromotion = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Food.delete_promotion_confirm_title`),
                message: t(`Food.delete_promotion_confirm_message`),
                positiveText: t(`Food.delete_promotion_confirm_positive`),
                negativeText: t(`Food.delete_promotion_confirm_negative`),
            }))
        )
            return;

        await foodPromotionService.deleteFoodPromotion(promotionId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Food.promotion_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Food.promotion_delete'),
            action: (): Promise<void> => deleteFoodPromotion(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const response: FoodPromotion | null = await foodPromotionService.getFoodPromotion(
                    promotionId
                );
                if (response) {
                    setData(response);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [editModalOpen, promotionId, foodPromotionService, globalLoadingStore, toastStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'merchants',
                nameKey: 'Food.food_and_beverages_merchant',
            },
            {
                path: foodMerchant?.id || '',
                name: foodMerchant?.name || '',
            },
            {
                path: 'promotions',
                name: t('Food.merchant_promotions_breadcrumb', { param1: foodMerchant?.name }),
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const getItemsAndCategories = useCallback((): SimpleListItemProps[] => {
        const itemsAndCategories: SimpleListItemProps[] = [];

        if (data?.itemsAndCategories.length) {
            data?.itemsAndCategories.map((element) => {
                itemsAndCategories.push({
                    avatar: (
                        <ImageWithPlaceholder
                            width={54}
                            height={54}
                            imgSrc={element.imageUrl}
                            defaultImg={
                                element.description ? <FoodItemsIcon /> : <FoodCategoriesIcon />
                            }
                        />
                    ),
                    title: element.name,
                    description: element.description,
                });
            });
        }

        return itemsAndCategories;
    }, [data]);

    const getDuration = useCallback((): SimpleListItemProps[] => {
        const duration: SimpleListItemProps[] = [];
        if (data?.startDate) {
            duration.push({
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                title: moment(data?.startDate).format(DATE_API_FORMAT),
                description: t('start_date'),
            });
        }
        if (data?.endDate) {
            duration.push({
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                title: moment(data?.endDate).format(DATE_API_FORMAT),
                description: t('end_date'),
            });
        }
        return duration;
    }, [data, t]);

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = []
        if(data?.description)
            description.push({ title: data.description });
        return description;
    }, [data])

    useEffect(() => {
        if (data) {
            setItemsAndCategories(getItemsAndCategories);
            setDuration(getDuration);
        }
    }, [data, getItemsAndCategories, getDuration]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="FoodPromotionsDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={
                            <>
                                <StatusBadge status={data?.statusBadgeStatus} />
                                <div className="header-details">
                                    <div>
                                        <div>{t('Food.promotions_detail_fixed_reduction')}</div>
                                        <strong>{data?.fixedOrReduction}</strong>
                                    </div>
                                </div>
                            </>
                        }
                        imgSrc={data?.bannerImageUrl}
                        defaultImg={<FoodPromotionsIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={
                            (userStore.isAdmin ||
                                new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                                <ActionMenu options={actions} type="primary" trigger="click" />
                            )
                        }
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                {itemsAndCategories?.length > 0 && (
                                    <SimpleList
                                        className="itemsAndCategories"
                                        title={t('Food.promotions_modal_items_categories')}
                                        data={itemsAndCategories}
                                    />
                                )}

                                {duration.length > 0 && (
                                    <SimpleList
                                        className="duration"
                                        title={t('Food.promotions_modal_duration')}
                                        data={duration}
                                    />
                                )}
                                {data?.description && (
                                    <SimpleList
                                        title={t('Food.promotion_description')}
                                        data={getDescription()}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {data?.bannerImageUrl && (
                                    <SimpleList
                                        className="duration"
                                        title={t('Images.images_banner')}
                                    >
                                        <div
                                            className="promotion-banner"
                                            style={{
                                                backgroundImage: `url(${data?.bannerImageUrl})`,
                                            }}
                                        />
                                    </SimpleList>
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {editModalOpen && (
                        <FoodPromotionModal
                            visible={editModalOpen}
                            onComplete={onEditComplete}
                            foodPromotion={data}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default FoodPromotionsDetail;
