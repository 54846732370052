import React, {
    FunctionComponent,
    useCallback, useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { FoodEntree as FoodOrderIcon } from 'Components/icons';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useTranslation } from 'react-i18next';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { Button, Layout, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TableFilters } from 'Components/table-filters';
import { autorun } from 'mobx';
import { FoodOrderDestinationService } from 'Services/FoodOrderDestinationService';
import FilterStore from 'Stores/FilterStore';
import { PAGE_SIZE } from 'Models/Constants';
import { FoodOrderDestination } from 'Models/FoodOrderDestination/FoodOrderDestination';
import FoodOrderDestinationModal from './order-destination-modal';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { GetFoodOrderDestinationsSortColumnDto } from 'Api/Features/FoodOrderDestinations/Dtos/GetFoodOrderDestinationsSortColumnDto';
import { FoodMerchantContext } from '../index';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const FoodOrderDestinations: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, merchantId } = useParams();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const filterStoreRef = useRef(new FilterStore());
    const foodOrderDestinationService = useService(FoodOrderDestinationService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<FoodOrderDestination[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { userStore } = useStores();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'merchants',
            nameKey: 'Food.food_and_beverages_merchant',
        },
        {
            path: foodMerchant?.id || '',
            name: foodMerchant?.name || '',
        },
        {
            path: 'order-destinations',
            nameKey: 'Food.order_destinations'
        }
    ];

    const getTableColumnsSortHandler = () => {
        return (orderA: FoodOrderDestination, orderB: FoodOrderDestination): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<FoodOrderDestination>,
        columnKey: GetFoodOrderDestinationsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<FoodOrderDestination>
    ): ColumnType<FoodOrderDestination>[] => {
        return [
            {
                key: GetFoodOrderDestinationsSortColumnDto.Name,
                title: t('name'),
                dataIndex: 'name',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetFoodOrderDestinationsSortColumnDto.Name
                ),
            },
            {
                key: GetFoodOrderDestinationsSortColumnDto.Email,
                title: t('User.email'),
                dataIndex: 'email',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetFoodOrderDestinationsSortColumnDto.Email
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<FoodOrderDestination> = {
        columnKey: GetFoodOrderDestinationsSortColumnDto.Name as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<FoodOrderDestination>
    ): [GetFoodOrderDestinationsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetFoodOrderDestinationsSortColumnDto = tableSort.columnKey as GetFoodOrderDestinationsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<FoodOrderDestination>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetFoodOrderDestinationsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
        }) => {
            setLoading(true);
            try {
                const [
                    items,
                    totalItems,
                ] = await foodOrderDestinationService.getFoodOrderDestinations({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    merchantIds: [merchantId]
                });

                setData(items);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [foodOrderDestinationService, merchantId]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<FoodOrderDestination> | SorterResult<FoodOrderDestination>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);

        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort] );

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                fetch({
                    pagination: pagination,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                });
            }
        },
        [fetch, pagination, defaultColumnSort, defaultDirectionSort]
    );

    const onRowClick = (orderDestinationId: string | null): void => {
        if (orderDestinationId) {
            history.push(
                `/locations/${id}/management/merchants/${merchantId}/order-destinations/${orderDestinationId}`
            );
        }
    };

    return (
        <div className="ConferenceRooms">
            <LocationHeader
                title={t('Food.order_destinations')}
                subTitle={t('Food.order_destinations_subtitle', { param1: foodMerchant?.name })}
                defaultImg={<FoodOrderIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('Food.order_destinations_create')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: FoodOrderDestination): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: FoodOrderDestination) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <FoodOrderDestinationModal
                    visible={createModalOpen}
                    onComplete={onCreationComplete}
                    merchantId={merchantId}
                />
            )}
        </div>
    );
};

export default FoodOrderDestinations;
