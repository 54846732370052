// GENERATED FILE - DO NOT MODIFY
import { CreateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/CreateFoodItemRequestDto';
import { FoodItemDto } from 'Api/Features/FoodItems/Dtos/FoodItemDto';
import { GetFoodItemsOrderResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsOrderResponseDto';
import { GetFoodItemsRequestDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsRequestDto';
import { GetFoodItemsResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsResponseDto';
import { GetFoodMerchantFoodItemsRequestDto } from 'Api/Features/FoodItems/Dtos/GetFoodMerchantFoodItemsRequestDto';
import { GetFoodMerchantFoodItemsResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodMerchantFoodItemsResponseDto';
import { UpdateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/UpdateFoodItemRequestDto';
import { UpdateFoodItemsOrderingRequestDto } from 'Api/Features/FoodItems/Dtos/UpdateFoodItemsOrderingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FoodItemProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFoodItem(foodItemId: string): Promise<FoodItemDto | null> {
        const uri = this.buildUri(
            "/food-items/{foodItemId}",
            { foodItemId: foodItemId },
            null
        );

        const data: FoodItemDto | null = await this.httpClient.get<FoodItemDto | null>(uri);
        return data;
    }

    public async getFoodItems(request: GetFoodItemsRequestDto | null): Promise<GetFoodItemsResponseDto | null> {
        const uri = this.buildUri(
            "/food-items",
            null,
            { ...request || undefined }
        );

        const data: GetFoodItemsResponseDto | null = await this.httpClient.get<GetFoodItemsResponseDto | null>(uri);
        return data;
    }

    public async getFoodMerchantFoodItems(foodMerchantId: string, request: GetFoodMerchantFoodItemsRequestDto | null): Promise<GetFoodMerchantFoodItemsResponseDto | null> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}/food-items",
            { foodMerchantId: foodMerchantId },
            { ...request || undefined }
        );

        const data: GetFoodMerchantFoodItemsResponseDto | null = await this.httpClient.get<GetFoodMerchantFoodItemsResponseDto | null>(uri);
        return data;
    }

    public async createFoodItem(request: CreateFoodItemRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/food-items",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateFoodItemRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateFoodItem(foodItemId: string, request: UpdateFoodItemRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-items/{foodItemId}",
            { foodItemId: foodItemId },
            null
        );

        await this.httpClient.patch<UpdateFoodItemRequestDto | null, void>(uri, request);
    }

    public async deleteFoodItem(foodItemId: string): Promise<void> {
        const uri = this.buildUri(
            "/food-items/{foodItemId}",
            { foodItemId: foodItemId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getFoodItemsOrdering(foodMerchantId: string): Promise<GetFoodItemsOrderResponseDto | null> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}/food-items/order",
            { foodMerchantId: foodMerchantId },
            null
        );

        const data: GetFoodItemsOrderResponseDto | null = await this.httpClient.get<GetFoodItemsOrderResponseDto | null>(uri);
        return data;
    }

    public async updateFoodItemsOrdering(foodMerchantId: string, request: UpdateFoodItemsOrderingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}/food-items/order",
            { foodMerchantId: foodMerchantId },
            null
        );

        await this.httpClient.put<UpdateFoodItemsOrderingRequestDto | null, void>(uri, request);
    }
}