import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu, Tooltip } from 'antd';
import './user-navigation.less';
import { ClickParam } from 'antd/es/menu';
import { UserContext } from 'Routes/console/authenticated/users/id';
import { theme } from 'variant';
import { PresetColorType } from 'antd/lib/_util/colors';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';

interface MenuItem {
    key: string;
    label: string;
    disabled: boolean;
}

const UserNavigation: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const context = useContext(UserContext)

    const menuItems = (): MenuItem[] => {
        const items = [
            {
                key: `/users/${id}/profile`,
                label: t('User.user_profile'),
                disabled: false
            },
            {
                key: `/users/${id}/invoices`,
                label: t('User.user_invoices'),
                disabled: false
            },
            {
                key: `/users/${id}/messages`,
                label: t('User.user_messages'),
                disabled: context?.company?.type === CompanyTypeDto.Passport
            },
            {
                key: `/users/${id}/building-access`,
                label: t('User.building_access'),
                disabled: false
            },
            {
                key: `/users/${id}/documents`,
                label: t('User.user_documents'),
                disabled: false
            }
        ];

        return items;
    };

    const onMenuItemClicked = (param: ClickParam): void => {
        if(history.location.pathname !== param.key) {
            history.push(param.key);
        }
    };

    const getSelectedKeys = (): string[] => {
        const path = history.location.pathname;
        return menuItems()
            .filter((x) => {
                return path.includes(x.key);
            })
            .map((x) => {
                return x.key;
            });
    };

    const toolTipContent = (): ReactNode => {
        return (
            <div>
                <div className="tooltip-content">{t('Company.this_feature_not_available_for_a')}</div>
                <div className="tooltip-content">{t('User.passport_user')}</div>
            </div>
        );
    };

    return (
        <div className="UserNavigation no-select">
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={getSelectedKeys()}
                onClick={onMenuItemClicked}
            >
                {menuItems().map((x: MenuItem) => {
                    if (x.disabled) {
                        return (
                            <Menu.Item key={x.key} disabled>
                                <Tooltip
                                    color={theme['white'] as PresetColorType}
                                    title={toolTipContent}
                                >
                                    <div>{x.label}</div>
                                </Tooltip>
                            </Menu.Item>
                        );
                    } else return <Menu.Item key={x.key}>{x.label}</Menu.Item>;
                })}
            </Menu>
        </div>
    );
});

export default UserNavigation;
