import React, { FunctionComponent } from 'react';
import { Modal } from 'antd';
import { Close } from 'Components/icons';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.less';
import { theme } from 'variant';


export interface ImageCarouselModal {
    images: StoredFileDto[];
    startingIndex: number;
    width?: number | null;
    onClose: () => void;
}

const ImageCarouselModal: FunctionComponent<ImageCarouselModal> = ({
    images,
    startingIndex,
    width,
    onClose
}) => {

    return (
        <Modal
            visible
            className="ImageCarouselModal"
            footer={null}
            centered
            width={width ? width : 1195}
            closeIcon={<Close fill={theme['white']} />}
            onCancel={onClose}
        >
            <Carousel
                dynamicHeight={false}
                interval={0}
                showThumbs={false}
                selectedItem={startingIndex}
                showStatus={false}
            >
                {images.map((image) => (
                    <div className="image-wrapper" key={image.id ?? ''}>
                        <img width={'auto'} height={'100%'} src={image.url ?? ''} alt="" />
                    </div>
                ))}
            </Carousel>
        </Modal>
    );
};

export default ImageCarouselModal;
