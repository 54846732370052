import { FoodPromotionDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionDto';
import { FoodPromotionStatusDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionStatusDto';
import { FoodCategory } from 'Models/FoodCategory/FoodCategory';
import { FoodMerchant } from 'Models/FoodMerchant/FoodMerchant';
import { FoodItem } from 'Models/FoodItem/FoodItem';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { moneyFormat } from '../../Utils';

interface ItemsCategories {
    name: string | null | undefined;
    imageUrl: string | null;
    description: string | undefined;
}

export class FoodPromotion implements FoodPromotionDto {
    constructor(dto: FoodPromotionDto) {
        Object.assign(this, dto);
    }
    id = '';
    name: string | null = null;
    startDate = '';
    endDate = '';
    price: number | null = null;
    taxAmount: number | null = null;
    reductionPercentage: number | null = null;
    status: FoodPromotionStatusDto = FoodPromotionStatusDto.Unknown;
    bannerImageUrl: string | null = null;
    categories: (FoodCategory | null)[] | null = null;
    items: (FoodItem | null)[] | null = null;
    merchant: FoodMerchant | null = null;
    description: string | null = null;

    get itemsAndCategories(): ItemsCategories[] {
        const categories: string[] = [];
        const itemsAndCategories: ItemsCategories[] = [];
        if(this.categories?.length) {
            this.categories.forEach((category) => {
                if(category?.id) {
                    categories.push(category.id);
                    itemsAndCategories.push({
                        name: category?.name,
                        imageUrl: category?.imageUrl
                    } as ItemsCategories)
                }
            })
        }
        if(this.items?.length) {
            this.items.forEach((item) => {
                if(!categories.includes(item?.category?.id || '')) {
                    itemsAndCategories.push({
                        name: item?.name,
                        imageUrl: item?.imageUrl,
                        description: item?.category?.name
                    } as ItemsCategories)
                }
            })
        }
        return itemsAndCategories
    }

    get itemsAndCategoriesToString(): string {
        return this.itemsAndCategories.map((element) => element.name).join(', ');
    }

    get fixedOrReduction(): string {
        if(this.price) {
            return moneyFormat(this.price);
        }
        if(this.reductionPercentage) {
            return this.reductionPercentage + ' %';
        }
        return '';
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if(this.status === FoodPromotionStatusDto.Ongoing) {
            return StatusBadgeStatus.Ongoing;
        }
        else if(this.status === FoodPromotionStatusDto.Upcoming) {
            return StatusBadgeStatus.Upcoming;
        }
        else if(this.status === FoodPromotionStatusDto.Expired) {
            return StatusBadgeStatus.Expired;
        }
        return StatusBadgeStatus.Unknown;
    }
}
