import React, { FunctionComponent, useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { FoodSalad as FoodMerchantsIcon } from 'Components/icons';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateFoodMerchantSchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';

import './food-merchant-modal.less';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { FoodMerchantDto } from 'Api/Features/FoodMerchants/Dtos/FoodMerchantDto';
import { FoodMerchantService } from 'Services/FoodMerchantService';
import { CreateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/CreateFoodMerchantRequestDto';
import { UpdateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/UpdateFoodMerchantRequestDto';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { DEBOUNCE_DELAY, PAGE_SIZE } from 'Models/Constants';
import { BbotService } from 'Services/BbotService';
import { BbotRestaurantDto } from 'Api/Features/Bbot/Dtos/BbotRestaurantDto';
import debounce from 'lodash.debounce';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { images } from 'variant';

const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface FoodMerchantModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationId?: string;
    foodMerchant?: FoodMerchantDto;
}

enum MerchantType {
    MRP,
    BBOT
}

const FoodMerchantModal: FunctionComponent<FoodMerchantModalProps> = ({
    visible,
    onComplete,
    locationId,
    foodMerchant,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodMerchantService = useService(FoodMerchantService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        CreateFoodMerchantSchema,
        form
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [merchantTypeRadioValue, setMerchantTypeRadioValue] = useState<MerchantType>(
        foodMerchant?.bbotRestaurant ? MerchantType.BBOT : MerchantType.MRP
    );
    const bbotService = useService(BbotService);

    const [bbotMerchantSearchResults, setBbotMerchantSearchResults] = useState<BbotRestaurantDto[]>(
        []
    );
    const [bbotMerchantSearchTerm, setBbotMerchantSearchTerm] = useState('');
    const [bbotMerchantMaxResults, setBbotMerchantMaxResults] = useState(false);
    const [bbotMerchantCurrentPage, setBbotMerchantCurrentPage] = useState(0);
    const [bbotMerchantOptions, setBbotMerchantOptions] = useState<SelectCustomOption[]>([]);
    const [selectedBbotMerchantId, setSelectedBbotMerchantId] = useState<string[] | undefined>(
        foodMerchant?.bbotRestaurant?.id ? [foodMerchant?.bbotRestaurant?.id] : undefined
    );
    const [selectedBbotMerchantOptions, setSelectedBbotMerchantOptions] = useState<
        SelectCustomOption[]
    >([]);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (foodMerchant) {
            form.setFieldsValue({
                name: foodMerchant.name,
                description: foodMerchant.description ?? undefined,
            });
        }
    }, [foodMerchant, form]);

    const getMainImage = (): UpdateFileRequestDto | null => {
        return mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;
    };

    const searchBbotMerchants = async (
        page: number,
        searchTerm: string
    ): Promise<BbotRestaurantDto[]> => {
        globalLoadingStore.addLoading();
        const args = {
            pageSize: PAGE_SIZE,
            page: page,
            searchTerm: searchTerm,
        };

        const [results, totalItemCount] = await bbotService.getBbotRestaurants(args);

        if (results.length + PAGE_SIZE * page >= totalItemCount) {
            setBbotMerchantMaxResults(true);
        }
        globalLoadingStore.removeLoading();
        return results;
    };

    const debounceBbotMerchantSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchBbotMerchants(page, searchTerm).then((results) => {
                setBbotMerchantSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, DEBOUNCE_DELAY)
    );

    const resetBbotMerchantSearch = (): void => {
        setBbotMerchantCurrentPage(0);
        setBbotMerchantSearchResults([]);
        setBbotMerchantSearchTerm('');
        setBbotMerchantMaxResults(false);
    };

    const handleBbotMerchantKeywordsChange = useCallback((value: string): void => {
        resetBbotMerchantSearch();
        setBbotMerchantSearchTerm(value);
    }, []);

    const handleBbotMerchantenuScrollToBottom = (): void => {
        if (!bbotMerchantMaxResults) {
            setBbotMerchantCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        if(merchantTypeRadioValue === MerchantType.BBOT) {
            debounceBbotMerchantSearch.current(bbotMerchantCurrentPage, bbotMerchantSearchTerm);
        }
    }, [bbotMerchantCurrentPage, bbotMerchantSearchTerm, merchantTypeRadioValue]);

    useEffect(() => {
        const searchResults = bbotMerchantSearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: x?.nameForAdmins,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(
            searchResults,
            selectedBbotMerchantOptions
        );
        setBbotMerchantOptions(merged);
    }, [bbotMerchantSearchResults, selectedBbotMerchantOptions]);

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodMerchantsIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(
                    `Food.merchant_leave_confirm_positive${
                        foodMerchant !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodMerchantsIcon />,
                title: t(`Food.merchant_saved_success`),
                message: t(`Food.merchant_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const errorsHandler = (error: any): void => {
        const errors = new Map<string, string[]>();
        if (error.response?.data?.errors?.['icon.UploadBase64'] !== undefined) {
            errors.set('icon', [error.response?.data.errors['icon.UploadBase64'][0].description]);
        }
        if (error.response?.data?.errors?.['image.UploadBase64'] !== undefined) {
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
        }
        if (error.response?.data?.errors?.['description'] !== undefined) {
            errors.set('description', [error.response?.data.errors['description'][0].description]);
        }
        setErrors(errors);
        if (!errors.size) {
            toastStore.displayError(error);
        }
    };

    const createFoodMerchant = async (values: Store): Promise<void> => {
        const data = {
            name: values.name,
            description: values.description,
            locationId: locationId,
            image: getMainImage(),
            isBbot: merchantTypeRadioValue === MerchantType.BBOT,
            bbotRestaurantId: values.bbotRestaurantId?.value,
        } as CreateFoodMerchantRequestDto;

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodMerchantService.createFoodMerchant(data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editFoodMerchant = async (values: Store): Promise<void> => {
        const oldValues: FoodMerchantDto = foodMerchant!;
        const data: UpdateFoodMerchantRequestDto = {
            name: values.name || oldValues.name,
            description: values.description,
            image: getMainImage(),
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodMerchantService.updateFoodMerchant(data, foodMerchant!.id as string);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (foodMerchant !== undefined) {
            await editFoodMerchant(values);
        } else {
            await createFoodMerchant(values);
        }
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                foodMerchant !== undefined
                    ? t('Food.merchant_edit_title')
                    : t('Food.merchant_create_title')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="FoodMerchantModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24}>
                            {foodMerchant === undefined && (
                                <div className="label">{t('Food.choose_merchant_type')}</div>
                            )}

                            <div className="merchant-type-container">
                                <Radio.Group
                                    value={merchantTypeRadioValue}
                                    defaultValue={MerchantType.MRP}
                                    onChange={(event): void =>
                                        setMerchantTypeRadioValue(event.target.value)
                                    }
                                    disabled={foodMerchant !== undefined}
                                >
                                    <Space direction="vertical">
                                        <div className="radio-row mrp">
                                            <img
                                                src={images.pineappleBlack}
                                                alt={t('brand_name')}
                                                width={15}
                                                height={30}
                                            />
                                            <div className="line" />
                                            <Radio value={MerchantType.MRP}>
                                                {t('mrp_heyday')}
                                            </Radio>
                                        </div>
                                        <div className="radio-row bbot">
                                            <img
                                                src={images.bbotLogo}
                                                alt={t('Food.bbot')}
                                                width={22}
                                                height={22}
                                            />
                                            <div className="line" />
                                            <Radio value={MerchantType.BBOT}>
                                                {t('Food.bbot_merchant')}
                                            </Radio>
                                        </div>
                                    </Space>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>

                    {merchantTypeRadioValue === MerchantType.MRP ? (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="name"
                                    label={t('name')}
                                    required={true}
                                >
                                    <Input
                                        onChange={(e): void =>
                                            form.setFieldsValue({ name: e.target.value })
                                        }
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="bbotRestaurantId"
                                    label={foodMerchant === undefined ? t('Food.associate_bbot') : t('Food.associated_bbot')}
                                    required
                                >
                                    <SelectCustom
                                        disabled={foodMerchant !== undefined}
                                        options={bbotMerchantOptions}
                                        strongLabel={true}
                                        placeholder={t('SelectCustom.placeholder_bbot')}
                                        onChange={(option: SelectCustomOption): void => {
                                            setSelectedBbotMerchantId([option.value]);
                                            setSelectedBbotMerchantOptions([option]);
                                        }}
                                        onKeywordsChange={handleBbotMerchantKeywordsChange}
                                        onMenuScrollToBottom={handleBbotMerchantenuScrollToBottom}
                                        hideSelectedOptions={false}
                                        selected={selectedBbotMerchantId}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('description')}
                                className="description"
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <div>
                                <p className="help">{t('Food.merchant_main_image_help')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        foodMerchant?.imageUrl
                                            ? [
                                                  {
                                                      url: foodMerchant?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default FoodMerchantModal;
