import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { FoodItemProxy } from 'Api/Features/FoodItems/FoodItemProxy';
import { GetFoodItemsResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsResponseDto';
import { GetFoodItemsRequestDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsRequestDto';
import { CreateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/CreateFoodItemRequestDto';
import { UpdateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/UpdateFoodItemRequestDto';
import { FoodItemDto } from 'Api/Features/FoodItems/Dtos/FoodItemDto';
import { FoodItem } from 'Models/FoodItem/FoodItem';
import { GetFoodItemsOrderResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsOrderResponseDto';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';

@inject(FoodItemProxy)
export class FoodItemService extends ApiService {
    constructor(private readonly foodItemProxy: FoodItemProxy) {
        super();
    }

    public async getFoodItem(id: string): Promise<FoodItem | null> {
        const response: FoodItemDto | null = await this.foodItemProxy.getFoodItem(id);
        return response ? new FoodItem(response) : null;
    }

    public async getFoodItems(
        request?: GetFoodItemsRequestDto
    ): Promise<[FoodItem[], number]> {
        const response: GetFoodItemsResponseDto | null = await this.foodItemProxy.getFoodItems(
            request || null
        );
        return [
            response?.items?.map((dto: FoodItemDto | null) => new FoodItem(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createFoodItem(request: CreateFoodItemRequestDto): Promise<void> {
        await this.foodItemProxy.createFoodItem(request);
    }

    public async updateFoodItem(
        request: UpdateFoodItemRequestDto,
        id: string
    ): Promise<void> {
        await this.foodItemProxy.updateFoodItem(id, request);
    }

    public async deleteFoodItem(id: string): Promise<void> {
        await this.foodItemProxy.deleteFoodItem(id);
    }

    public async getFoodItemsOrder(
        id: string
    ): Promise<GetFoodItemsOrderResponseDto | null> {
        return await this.foodItemProxy.getFoodItemsOrdering(id);
    }

    public async updateFoodItemsOrder(
        id: string,
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.foodItemProxy.updateFoodItemsOrdering(id, { method: method, ids: ids });
    }
}
