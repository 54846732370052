// GENERATED FILE - DO NOT MODIFY

export enum NotificationTypeDto {
    GeneralUserMessage = "GeneralUserMessage",
    ForgotPassword = "ForgotPassword",
    CompanyInvitation = "CompanyInvitation",
    FoodOrderCreation = "FoodOrderCreation",
    Announcement = "Announcement",
    PaymentSuccessful = "PaymentSuccessful",
    BookingApproval = "BookingApproval",
    BookingRefusal = "BookingRefusal",
    InvoiceRefund = "InvoiceRefund",
    PackageDelivery = "PackageDelivery",
    AmenityBookingCreation = "AmenityBookingCreation",
    AmenityBookingCancellation = "AmenityBookingCancellation",
    AmenityBookingApprovalRequired = "AmenityBookingApprovalRequired",
    ServiceRequestCreation = "ServiceRequestCreation",
    DatawatchBuildingAccessUpdate = "DatawatchBuildingAccessUpdate",
    CheckInCreation = "CheckInCreation",
    CheckInGuestConfirmationNotification = "CheckInGuestConfirmationNotification",
}