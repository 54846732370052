import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './not-found.less';
import { images } from 'variant';
import { useTranslation } from 'react-i18next';

const NotFoundPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="notfound-container">
            <img className="logo" src={images.logoWhite} alt={t('brand_name')} />
            <h1 className="not-found">{t('Errors.page_not_found')}</h1>
            <Button type="primary" className="back-to-site">
                <Link to="/">{t('Navigation.back_to_site')}</Link>
            </Button>
        </div>
    );
};

export default NotFoundPage;
