import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { theme } from 'variant';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from 'Models/Constants';
import { Button, Layout, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import FilterStore from 'Stores/FilterStore';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { FoodPromotionService } from 'Services/FoodPromotionService';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Promotion as FoodPromotionsIcon } from 'Components/icons';
import LocationHeader from 'Components/location-header/location-header';
import { TdWithImage } from 'Components/td-with-image';
import { TableFilters } from 'Components/table-filters';
import { FoodPromotion } from 'Models/FoodPromotion/FoodPromotion';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import FoodPromotionModal from './food-promotion-modal';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { GetFoodPromotionsSortColumnDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsSortColumnDto';
import { FoodMerchantContext } from '../index';
import { GetFoodPromotionsRequestDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsRequestDto';
import StatusBadge from 'Components/status-badge/status-badge';
import { FoodPromotionStatusDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionStatusDto';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Food.promotions_status',
        items: [
            {
                key: 'ongoing',
                displayNameKey: 'Ongoing',
                checked: true,
            },
            {
                key: 'upcoming',
                displayNameKey: 'Upcoming',
                checked: true,
            },
            {
                key: 'expired',
                displayNameKey: 'Expired',
                checked: true,
            },
        ],
    },
];

const FoodPromotions: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, merchantId } = useParams();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const foodPromotionService = useService(FoodPromotionService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<FoodPromotion[]>([]);
    const { userStore } = useStores();

    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const pageTitle = t('Food.merchant_promotions_pagetitle', { param1: foodMerchant?.name });

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'merchants',
            nameKey: 'Food.food_and_beverages_merchant',
        },
        {
            path: foodMerchant?.id || '',
            name: foodMerchant?.name || '',
        },
        {
            path: 'items',
            nameKey: 'Food.promotions_label',
        },
    ];

    const getTableColumnsSortHandler = () => {
        return (itemA: FoodPromotion, itemB: FoodPromotion): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<FoodPromotion>,
        columnKey: GetFoodPromotionsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<FoodPromotion>): ColumnType<FoodPromotion>[] => {
        return [
            {
                key: GetFoodPromotionsSortColumnDto.Name,
                title: t('Food.promotions_name'),
                render: (foodPromotion: FoodPromotion): ReactNode => (
                    <TdWithImage
                        defaultImg={<FoodPromotionsIcon fill={theme['white']} />}
                        imgSrc={foodPromotion?.bannerImageUrl}
                    >
                        {foodPromotion?.name}
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetFoodPromotionsSortColumnDto.Name),
            },
            {
                title: t('Food.promotions_table_items_categories'),
                render: (foodPromotion: FoodPromotion): ReactNode => <>{foodPromotion.itemsAndCategoriesToString}</>,
            },
            {

                title: t('Food.promotions_table_fixed_reduction'),
                render: (foodPromotion: FoodPromotion): ReactNode => <>{foodPromotion.fixedOrReduction}</>,
            },
            {
                title: t('Food.promotions_status'),
                render: (foodPromotion: FoodPromotion): ReactNode | null => (
                    <StatusBadge status={foodPromotion.statusBadgeStatus}/>
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<FoodPromotion> = {
        columnKey: GetFoodPromotionsSortColumnDto.Status as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<FoodPromotion>
    ): [GetFoodPromotionsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetFoodPromotionsSortColumnDto = tableSort.columnKey as GetFoodPromotionsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<FoodPromotion>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetFoodPromotionsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            statuses: string[];
        }) => {
            setLoading(true);
            try {
                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    statuses: params.statuses as FoodPromotionStatusDto[],
                    merchantIds: [merchantId]
                } as GetFoodPromotionsRequestDto;

                const [items, totalItems] = await foodPromotionService.getFoodPromotions(request);

                setData(items);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [foodPromotionService, merchantId]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<FoodPromotion> | SorterResult<FoodPromotion>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            statuses: filterStore.checkedItemsByFilterKey('status'),
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                statuses: filterStore.checkedItemsByFilterKey('status'),
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    const onRowClick = (itemId: string | null): void => {
        if (itemId) {
            history.push(`/locations/${id}/management/merchants/${merchantId}/promotions/${itemId}`);
        }
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                fetch({
                    pagination: pagination,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    statuses: filterStore.checkedItemsByFilterKey('status'),
                });
            }
        },
        [fetch, pagination, defaultColumnSort, defaultDirectionSort]
    );

    return (
        <div className="FoodPromotions">
            <LocationHeader
                title={pageTitle}
                subTitle={t('Food.merchant_promotions_subtitle')}
                defaultImg={<FoodPromotionsIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('Food.merchant_promotions_create')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: FoodPromotion): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: FoodPromotion) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <FoodPromotionModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
};

export default FoodPromotions;
