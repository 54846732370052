// GENERATED FILE - DO NOT MODIFY
import { CreateUserHidAccessRequestDto } from 'Api/Features/UserHidInfo/Dtos/CreateUserHidAccessRequestDto';
import { GetUserHidInvitationsResponseDto } from 'Api/Features/UserHidInfo/Dtos/GetUserHidInvitationsResponseDto';
import { UserHidDetailsDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UserHidInfoProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUserHidDetails(userId: string): Promise<UserHidDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/hid-details",
            { userId: userId },
            null
        );

        const data: UserHidDetailsDto | null = await this.httpClient.get<UserHidDetailsDto | null>(uri);
        return data;
    }

    public async createUserHidAccess(userId: string, request: CreateUserHidAccessRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/hid-details",
            { userId: userId },
            null
        );

        await this.httpClient.post<CreateUserHidAccessRequestDto | null, void>(uri, request);
    }

    public async getUserHidInvitations(userId: string): Promise<GetUserHidInvitationsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/hid-invitations",
            { userId: userId },
            null
        );

        const data: GetUserHidInvitationsResponseDto | null = await this.httpClient.get<GetUserHidInvitationsResponseDto | null>(uri);
        return data;
    }
}