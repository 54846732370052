import React, { FunctionComponent } from 'react';
import { Modal, Form, Row, Col, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Close, User as UserIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { CreateServiceRequestSchema } from 'Schemas';
import { Location } from 'Models/Location/Location';
import { CreateServiceRequestRequestDto } from 'Api/Features/ServiceRequests/Dtos/CreateServiceRequestRequestDto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './create-request.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface RequestModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    location?: Location;
    isEditing?: boolean;
}

const CreateLocationServiceRequest: FunctionComponent<RequestModalProps> = ({
    visible,
    onComplete,
    location,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateServiceRequestSchema);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const locationService = useService(LocationService);
    const [form] = Form.useForm();
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        resetErrors();
        onComplete(success);
    };

    const submit = async (values: Store): Promise<void> => {
        const newRequestData: CreateServiceRequestRequestDto = {
            locationId: location?.id,
            description: values.description,
        };

        if (!(await validateForm(newRequestData, false))) return;
        try {
            globalLoadingStore.addLoading();
            await locationService.createServiceRequest(newRequestData);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('ServiceRequest.request_created'),
                message: t('ServiceRequest.request_created_message'),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('ServiceRequest.request_cancel_confirm_title'),
                message: t('ServiceRequest.request_cancel_confirm_message'),
                positiveText: t('ServiceRequest.request_cancel_confirm_positive'),
                negativeText: t('ServiceRequest.request_cancel_confirm_negative'),
            }))
        )
            return;
        dismiss(false);
    };

    const handleMessageContentChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t('ServiceRequest.request_create_request')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateRequest">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('ServiceRequest.request_description')}
                                className="description"
                                required
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={''}
                                onChange={handleMessageContentChange}
                            />
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(CreateLocationServiceRequest);
