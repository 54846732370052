import './pdf-viewer.less';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
    file: Blob | undefined;
}

const PdfViewer: FunctionComponent<PdfViewerProps> = ({ file }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [pageWidth, setPageWidth] = useState<number>(0);

    const resizeObserver = new ResizeObserver(() => {
        if(ref.current) {
            setPageWidth( ref.current.offsetWidth);
        }
    });

    useEffect( () => {
        if(ref.current) {
            resizeObserver.observe(ref.current);
        }

        // disconnect observer on unmount
        return (): void => {
            resizeObserver.disconnect();
        }
    }, [file, resizeObserver]);

    return (
        <div className="PdfViewer">
            <div ref={ref} className='pdf-container'>
                {file && (
                    <Document file={file}>
                        <Page pageNumber={1} width={pageWidth} />
                    </Document>
                )}
            </div>
        </div>
    );
};

export default PdfViewer;
