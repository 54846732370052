import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
    useRef,
    ReactNode,
    useContext,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { Layout, Button, Table } from 'antd';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PAGE_SIZE } from 'Models/Constants';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import CompanyHeader from 'Components/company-header/company-header';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { Company as CompanyIcon, Member as MemberIcon, Users as UsersIcon } from 'Components/icons';
import FilterStore from 'Stores/FilterStore';
import { UserService } from 'Services/UserService';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { User } from 'Models/Users/User';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import InviteUserModal from './invite-user';

import './index.less';
import { UserStatusDto } from 'Api/Features/Users/Dtos/UserStatusDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { CompanyContext } from '../index';
import StatusBadge, { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { CompanyStatusDto } from 'Api/Features/Companies/Dtos/CompanyStatusDto';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'roles',
        nameKey: 'Roles',
        items: [
            {
                key: 'Administrator',
                displayNameKey: CompanyRoleDto.Administrator,
                checked: true,
            },
            {
                key: 'Member',
                displayNameKey: CompanyRoleDto.Member,
                checked: true,
            },
        ],
    },
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: 'Active',
                displayNameKey: UserStatusDto.Active,
                checked: true,
            },
            {
                key: 'Deactivated',
                displayNameKey: UserStatusDto.Deactivated,
                checked: false,
            },
        ],
    },
];

const CompanyTeam: FunctionComponent = observer(() => {
    const { id } = useParams<{id: string}>();
    const { t } = useTranslation();
    const history = useHistory();
    const company = useContext(CompanyContext);
    const { confirmationModalStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [inviteUserModalOpen, setInviteUserModalOpen] = useState<boolean>(false);
    const userService = useService(UserService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [usersData, setUsersData] = useState<User[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: company?.name || t('Company.company'),
        },
        {
            path: 'team',
            nameKey: 'Company.company_team',
        },
    ];

    //#region Filters
    const getFilterGroupOptions = (filterGroup: string, omitAll = true): string[] => {
        const filterStore = filterStoreRef.current;
        return filterStore.getGroupOptions(filterGroup, omitAll);
    };
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (usrA: User, usrB: User): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<User>,
        columnKey: GetUsersSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<User>): ColumnType<User>[] => {
        return [
            {
                key: GetUsersSortColumnDto.Name,
                title: t('User.user_name'),
                render: (user: User): ReactNode | null => {
                    return user ? (
                        <TdWithImage defaultImg={<UsersIcon />} imgSrc={user?.imageUrl}>
                            {user?.name}
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetUsersSortColumnDto.Name),
            },
            {
                title: t('User.role_title'),
                render: (user: User): ReactNode | null =>
                    user.isConfirmed ? (
                        t(`User.user_role_${user.companyRole}`)
                    ) : (
                        <StatusBadge
                            status={StatusBadgeStatus.Active}
                            text={t('User.user_invitation_email_sent')}
                        />
                    ),
            },
            {
                key: GetUsersSortColumnDto.Email,
                title: t('User.email'),
                render: (user: User): ReactNode | null => <div>{user.email}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetUsersSortColumnDto.Email),
            },
            {
                key: GetUsersSortColumnDto.Status,
                title: t('User.user_status'),
                render: (user: User): ReactNode | null => (
                    <StatusBadge status={user?.statusBadgeStatus} />
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetUsersSortColumnDto.Status),
            },
        ];
    };

    const defaultSortOrder: SorterResult<User> = {
        columnKey: GetUsersSortColumnDto.Name as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<User>
    ): [GetUsersSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetUsersSortColumnDto = tableSort.columnKey as GetUsersSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<User>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetUsersSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
        }) => {
            setLoading(true);
            try {
                const [usersData, totalItems] = await userService.getUsers({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    companyIds: [id],
                    companyRoles: getFilterGroupOptions('roles'),
                    statuses: getFilterGroupOptions('status'),
                    HasManagementRole: null,
                } as GetUsersRequestDto);

                if (usersData) {
                    setUsersData(usersData);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [userService, id]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<User> | SorterResult<User>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setInviteUserModalOpen(false);
            if (success) {
                fetch({
                    pagination,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            }
        },
        [fetch, pagination, defaultColumnSort, defaultDirectionSort]
    );

    const handleInviteUser = async (): Promise<void> => {
        if (company?.status === CompanyStatusDto.Active) {
            setInviteUserModalOpen(true);
        } else {
            await confirmationModalStore.confirm({
                icon: <CompanyIcon />,
                title: t(`Company.company_team_invite_inactive_error`),
                message: t(`Company.company_team_invite_inactive_error_message`),
                positiveText: t('ok'),
            });

            return;
        }
    };

    return (
        <div className="Team">
            <CompanyHeader
                title={t('Company.company_team')}
                subTitle={t('Company.company_team_subtitle')}
                defaultImg={<MemberIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [company?.location?.id].concat(
                                company?.locations
                                    ? company.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && (
                        <Button type="primary" onClick={handleInviteUser}>
                            {t('Company.company_invite_member')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(user: User): string => user.id!}
                    dataSource={usersData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(user: User) => ({
                        onClick: (): void => {
                            history.push(user.consoleTeamUrl);
                        },
                    })}
                />
            </Content>

            {inviteUserModalOpen && (
                <InviteUserModal
                    visible={inviteUserModalOpen}
                    onComplete={onCreationComplete}
                    company={company}
                />
            )}
        </div>
    );
});

export default CompanyTeam;
