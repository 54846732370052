import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Menu, Modal, Row, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Close } from 'Components/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import './reorder-food-items-modal.less';
import { SelectParam } from 'antd/es/menu';
import { useService, useStores } from 'Hooks';
import { useParams } from 'react-router-dom';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { FoodItemService } from 'Services/FoodItemService';
import { Gutter } from 'antd/es/grid/row';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { theme } from 'variant';

const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: theme['black-4'] }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => <tbody {...props} />);

interface CategoryElement {
    id: string | number | undefined;
    name: string | null | undefined;
}

interface TableRowElement {
    key: string | null | undefined;
    name: string | null | undefined;
    index: number | null | undefined;
    category: string | null | undefined;
    categoryId: string | null | undefined;
}

interface ReorderFoodItemsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
}

const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];

const ReorderFoodItemsModal: FunctionComponent<ReorderFoodItemsModalProps> = ({
    visible,
    onComplete,
}) => {
    const { t } = useTranslation();
    const { merchantId } = useParams();
    const { globalLoadingStore } = useStores();
    const foodItemService = useService(FoodItemService);
    const foodCategoryService = useService(FoodCategoryService);
    const [data, setData] = useState<any>([]);
    const [categories, setCategories] = useState<CategoryElement[]>([]);
    const [orderMethod, setOrderMethod] = useState<OrderingMethodDto | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            className: 'drag-visible',
        },
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => orderMethod === OrderingMethodDto.Manual && <DragHandle />,
        },
    ];

    const orderItemsAlphabetically = (items: TableRowElement[]): TableRowElement[] => {
        return items.sort((a: any, b: any) => a.name.localeCompare(b.name));
    };

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            setLoading(true);
            globalLoadingStore.addLoading();

            const [categories] = await foodCategoryService.getFoodCategories({
                merchantIds: [merchantId]
            });

            setCategories(
                categories.map((category): CategoryElement => {
                    return {
                        id: category.id || '',
                        name: category.name || ''
                    }
                })
            );

            const categoriesIds: string[] = categories.map((foodCategory) => foodCategory.id);

            const [items] = await foodItemService.getFoodItems({
                categoryIds: categoriesIds,
            });

            let newOrder = 0;

            let tableRowElements: TableRowElement[] = items.map(
                (row): TableRowElement => {
                    return {
                        key: row.id,
                        name: row.name,
                        index: newOrder++,
                        category: row.category?.name,
                        categoryId: row.category?.id
                    };
                }
            );

            const order = await foodItemService.getFoodItemsOrder(merchantId);
            if (order?.method !== OrderingMethodDto.Manual) {
                tableRowElements = orderItemsAlphabetically(tableRowElements);
                setOrderMethod(OrderingMethodDto.Alphabetical);
            } else {
                setOrderMethod(OrderingMethodDto.Manual);
            }

            setData(tableRowElements);

            setLoading(false);
            globalLoadingStore.removeLoading();
        };
        fetch();
    }, [merchantId, visible, foodItemService, foodCategoryService, globalLoadingStore]);

    useEffect(() => {
        if (orderMethod === OrderingMethodDto.Alphabetical) {
            let newOrder = 0;
            setData((prevState: any) => {
                return orderItemsAlphabetically(prevState).map(
                    (row): TableRowElement => {
                        return {
                            key: row.key,
                            name: row.name,
                            index: newOrder++,
                            category: row.category,
                            categoryId: row.categoryId,
                        };
                    }
                );
            });
        }
    }, [orderMethod]);

    const dismiss = (success = false): void => {
        onComplete(success);
    };

    const submit = async (): Promise<void> => {
        const ids: string[] = [];
        if(orderMethod === OrderingMethodDto.Manual)
        categories.forEach((category) => {
            const categoryData = data.filter(
                (data: TableRowElement) => data.categoryId === category.id
            );
            categoryData.forEach((row: TableRowElement) => ids.push(row.key || ''));
        });

        //const ids = data.map((x: TableRowElement) => x.key);
        await foodItemService.updateFoodItemsOrder(
            merchantId,
            orderMethod || OrderingMethodDto.Alphabetical,
            ids
        );
        dismiss(true);
    };

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el);
            setData(newData);
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props: any) => (
        <>
            <SortableContainerWrapper
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        </>
    );

    const onSelectItem = (param: SelectParam): void => {
        setOrderMethod(param.key as OrderingMethodDto);
    };

    return (
        <>
            {data.length > 0 && (
                <Modal
                    className="FormModal ReorderModal ReorderFoodItemsModal"
                    visible={visible}
                    closeIcon={<Close />}
                    width={800}
                    title={
                        <>
                            <div className="title">
                                {t('Food.food_and_beverages_items_redorder')}
                            </div>
                            <Menu
                                theme="light"
                                mode="horizontal"
                                defaultSelectedKeys={orderMethod ? [orderMethod] : []}
                                selectedKeys={orderMethod ? [orderMethod] : []}
                                onSelect={onSelectItem}
                            >
                                <Menu.Item key={OrderingMethodDto.Alphabetical}>{t('OrderingMethod.Alphabetical')}</Menu.Item>
                                <Menu.Item key={OrderingMethodDto.Manual}>{t('OrderingMethod.Manual')}</Menu.Item>
                            </Menu>
                        </>
                    }
                    footer={null}
                    onCancel={(): void => dismiss()}
                    maskClosable={false}
                    centered
                >
                    {categories &&
                        categories.map((category) => {
                            const categoryData = data.filter(
                                (data: TableRowElement) => data.categoryId === category.id
                            );
                            return (
                                categoryData.length > 0 && (
                                    <div key={category.id}>
                                        <Row gutter={titleGutter}>
                                            <Col span={24} className="formSection">
                                                <Title level={4}>{category.name}</Title>
                                            </Col>
                                        </Row>
                                        <Table
                                            pagination={false}
                                            showHeader={false}
                                            loading={loading}
                                            dataSource={categoryData}
                                            columns={columns}
                                            rowKey="index"
                                            components={{
                                                body: {
                                                    wrapper: DraggableContainer,
                                                    row: DraggableBodyRow,
                                                },
                                            }}
                                        />
                                    </div>
                                )
                            );
                        })}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): void => dismiss()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            type="primary"
                            className="positive"
                            htmlType="submit"
                            onClick={(): Promise<void> => submit()}
                        >
                            {t('save_new_order')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ReorderFoodItemsModal;
