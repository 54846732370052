import React, { FunctionComponent, useEffect } from 'react';
import { Modal, Form, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row'; 
import { Close, Credit } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import './edit-credit-value-modal.less'
import { EditCreditValueSchema } from 'Schemas/EditCreditValueSchema';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { moneyToFloat } from 'Utils/CurrencyUtils';
import { AmenityService } from 'Services/AmenityService';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditCreditValueModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    currentValue?: number;
}

const EditCreditValueModal: FunctionComponent<EditCreditValueModalProps> = ({
    visible,
    onComplete,
    currentValue,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditCreditValueSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const amenityService = useService(AmenityService);
    //#endregion

    //#region Effects
    useEffect(() => {
        form.setFieldsValue({
            value: currentValue
        })
    }, [currentValue, form]);
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Credit />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`Amenities.credit_confirm_positive`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            value: moneyToFloat(formValues.value),
        };
        if (!(await validateForm(data, false))) return;
        try {
            globalLoadingStore.addLoading();
            await amenityService.updateCreditValue(data.value!)
            toastStore.toast({
                type: 'success',
                messageKey: `Amenities.credit_edit_success`,
            });
            dismiss(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    return (
        <Modal
            visible={visible}
            centered
            title={t('Amenities.edit_credit_value')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="EditCreditValueModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter} className="value-container">
                        <Col span={12} offset={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="value"
                                label={t('Amenities.credit_value_in_dollars')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    placeholder="$0.00"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { value } = values;
                                        form.setFieldsValue({ value: value });
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(EditCreditValueModal);
