import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { Col, Layout, Row, Button } from 'antd';
import { theme } from 'variant';
import {
    Location as LocationIcon,
    Phone as PhoneIcon,
    Email as EmailIcon,
    Website as WebsiteIcon,
} from 'Components/icons';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import LocationHeader from 'Components/location-header/location-header';
import { SimpleList } from 'Components/simple-list';
import { Location } from 'Models/Location/Location';
import { ContactInfo } from 'Models/Contact/ContactInfo';
import './index.less';
import LocationModal from 'Routes/console/authenticated/locations/location-modal';
import moment from 'moment';
import { DATE_API_FORMAT, TIME_FORMAT_12_HOUR } from 'Models/Constants';
import ImageCarouselModal from 'Components/image-carousel-modal';
import SimpleListImagesComponent from 'Components/simple-list-images-component';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_title',
    },
    {
        path: 'details',
        nameKey: 'Location.location_details',
    },
];

interface SimpleListItemProps {
    title: string | React.ReactNode;
    description?: string;
    avatar?: React.ReactNode;
}

const LocationDetails: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();

    const { userStore } = useStores();
    const history = useHistory();
    const locationService = useService(LocationService);
    const { navigationStore, globalLoadingStore } = useStores();
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [locationName, setLocationName] = useState<string | undefined>(
        t('Location.location_details')
    );
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [imageCarouselModalState, setImageCarouselModalState] = useState<{
        visible: boolean;
        startingIndex: number;
    }>({ visible: false, startingIndex: 0 });

    useEffect(() => {
        if (location) {
            navigationStore.setSubmenu(location);
        }
    }, [location, navigationStore]);

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const locationData: Location | null = await locationService.getById(id);

            if (locationData) {
                setLocation(locationData);
                setLocationName(locationData.name!);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [locationService, id, globalLoadingStore]);

    useEffect(() => {
        fetch();
    }, [fetch, editModalOpen]);

    const onEditionComplete = useCallback(
        (success: boolean) => {
            setEditModalOpen(false);
            if (success) {
                fetch(); // Refresh page content
                history.replace(`/locations/${id}/management/details`); // Refresh menu and breadcrumbs when location name changes
            }
        },
        [id, fetch, setEditModalOpen, history]
    );

    const getSimpleContent = (content: string | null): SimpleListItemProps[] => {
        let contentProps: SimpleListItemProps[] = [];
        if (content) {
            contentProps = [{ title: content }];
        }
        return contentProps;
    };

    const getContactInfo = (): SimpleListItemProps[] => {
        let contact: SimpleListItemProps[] = [];
        if (location && location.info) {
            // Only display available info
            const info: ContactInfo = location.info;
            const contactInfoData = [
                { value: info.phoneNumber, avatar: <PhoneIcon fill={theme['primary-color']} /> },
                { value: info.websiteLink, avatar: <WebsiteIcon fill={theme['primary-color']} /> },
                { value: info.email, avatar: <EmailIcon fill={theme['primary-color']} /> },
            ];
            contact = contactInfoData
                .filter((info) => {
                    return info.value;
                })
                .map((info) => {
                    return { title: info.value, avatar: info.avatar };
                });
        }
        return contact;
    };

    const getBusinessHours = (): SimpleListItemProps[] => {
        const hours: SimpleListItemProps[] = [];
        if(location?.businessHoursStart && location.businessHoursEnd)
            hours.push({
                title: t('from_x_to_y', {
                    param1: moment(
                        moment().format(DATE_API_FORMAT) + ' ' + location.businessHoursStart
                    ).format(TIME_FORMAT_12_HOUR),
                    param2: moment(
                        moment().format(DATE_API_FORMAT) + ' ' + location.businessHoursEnd
                    ).format(TIME_FORMAT_12_HOUR),
                }),
            });
        return hours;
    }

    return (
        <div className="ConferenceRooms">
            {location && (
                <>
                    <LocationHeader
                        title={locationName}
                        subTitle={t('Location.location_details_subtitle')}
                        defaultImg={<LocationIcon fill={theme['primary-color']} />}
                        imgSrc={location?.mainImageUrl}
                        loading={loading}
                        routes={breadcrumbs}
                        action={
                            (userStore.isAdmin ||
                                new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                                <Button
                                    type="primary"
                                    onClick={(): void => {
                                        setEditModalOpen(true);
                                    }}
                                >
                                    {t('Location.location_edit_details')}
                                </Button>
                            )
                        }
                    />
                    <Content className="locationDetailContent">
                        <Row gutter={16}>
                            <Col span={12}>
                                {location && !location.introductionEmpty && (
                                    <SimpleList
                                        title={t('Location.location_description')}
                                        data={getSimpleContent(location.introduction!)}
                                    />
                                )}
                                {location?.type && (
                                    <SimpleList
                                        title={t('type')}
                                        data={getSimpleContent(location.type!)}
                                    />
                                )}
                            </Col>
                            {location && !location.info?.isEmpty && (
                                <Col span={12}>
                                    <SimpleList
                                        title={t('Contact.contact_information')}
                                        data={getContactInfo()}
                                    />
                                </Col>
                            )}
                            {location && location.businessHoursStart && location.businessHoursEnd && (
                                <Col span={12}>
                                    <SimpleList
                                        title={t('Location.business_hours')}
                                        data={getBusinessHours()}
                                    />
                                </Col>
                            )}
                            {location?.images && location.images.length > 0 && (
                                <Col span={12}>
                                    <SimpleListImagesComponent
                                        title={t('additionnal_images')}
                                        images={location.images
                                            .filter(
                                                (image) =>
                                                    image?.url !== null &&
                                                    image?.url !== undefined &&
                                                    image.id !== null &&
                                                    image.id !== undefined
                                            )
                                            .map((image) => ({
                                                imageUrl: image?.url!,
                                                id: image?.id!,
                                            }))}
                                        onImageClick={(id: string): void => {
                                            const index = location.images?.findIndex(
                                                (image) => image?.id === id
                                            );
                                            if (index !== undefined && index !== -1)
                                                setImageCarouselModalState({
                                                    visible: true,
                                                    startingIndex: index,
                                                });
                                        }}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Content>
                    {(userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) &&
                        editModalOpen && (
                            <LocationModal
                                visible={editModalOpen}
                                onComplete={onEditionComplete}
                                location={location}
                                isEditing={true}
                            />
                        )}

                    {imageCarouselModalState.visible && (
                        <ImageCarouselModal
                            onClose={(): void =>
                                setImageCarouselModalState({ visible: false, startingIndex: 0 })
                            }
                            images={
                                location?.images
                                    ?.filter((image) => image !== null)
                                    .map((image) => image!) ?? []
                            }
                            startingIndex={imageCarouselModalState.startingIndex}
                        />
                    )}
                </>
            )}
        </div>
    );
});

export default LocationDetails;
