import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { UserHidInfoProxy } from 'Api/Features/UserHidInfo/UserHidInfoProxy';
import { UserHidDetailsDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsDto';
import { GetUserHidInvitationsResponseDto } from 'Api/Features/UserHidInfo/Dtos/GetUserHidInvitationsResponseDto';
import { CreateUserHidAccessRequestDto } from 'Api/Features/UserHidInfo/Dtos/CreateUserHidAccessRequestDto';

@inject(UserHidInfoProxy)
export class BuildingAccessService extends ApiService {
    constructor(private readonly userHidInfoProxy: UserHidInfoProxy) {
        super();
    }

    public async getUserHidDetails(userId: string): Promise<UserHidDetailsDto | null> {
        const data: UserHidDetailsDto | null = await this.userHidInfoProxy.getUserHidDetails(userId);
        return data;
    }

    public async getUserHidInvitations(userId: string): Promise<GetUserHidInvitationsResponseDto | null> {
        const data: GetUserHidInvitationsResponseDto | null = await this.userHidInfoProxy.getUserHidInvitations(userId);
        return data;
    }

    public async createUserHidAccess(userId: string, request: CreateUserHidAccessRequestDto | null): Promise<void> {
        await this.userHidInfoProxy.createUserHidAccess(userId, request);
    }
}
