import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { GetFoodOrderDestinationsRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/GetFoodOrderDestinationsRequestDto';
import { GetFoodOrderDestinationsResponseDto } from 'Api/Features/FoodOrderDestinations/Dtos/GetFoodOrderDestinationsResponseDto';
import { FoodOrderDestinationProxy } from 'Api/Features/FoodOrderDestinations/FoodOrderDestinationProxy';
import { FoodOrderDestination } from 'Models/FoodOrderDestination/FoodOrderDestination';
import { FoodOrderDestinationDto } from 'Api/Features/FoodOrderDestinations/Dtos/FoodOrderDestinationDto';
import { UpdateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/UpdateFoodOrderDestinationRequestDto';
import { CreateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/CreateFoodOrderDestinationRequestDto';

@inject(FoodOrderDestinationProxy)
export class FoodOrderDestinationService extends ApiService {
    constructor(private readonly foodOrderDestinationProxy: FoodOrderDestinationProxy) {
        super();
    }

    public async getFoodOrderDestination(id: string): Promise<FoodOrderDestination | null> {
        const response: FoodOrderDestinationDto | null = await this.foodOrderDestinationProxy.getFoodOrderDestination(id);
        return response ? new FoodOrderDestination(response) : null;
    }

    public async getFoodOrderDestinations(
        request?: GetFoodOrderDestinationsRequestDto
    ): Promise<[FoodOrderDestination[], number]> {
        const response: GetFoodOrderDestinationsResponseDto | null = await this.foodOrderDestinationProxy.getFoodOrderDestinations(
            request!
        );
        return [
            response?.items?.map(
                (dto: FoodOrderDestinationDto | null) => new FoodOrderDestination(dto!)
            ) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createFoodOrderDestination(
        request: CreateFoodOrderDestinationRequestDto
    ): Promise<void> {
        await this.foodOrderDestinationProxy.createFoodOrderDestination(request);
    }

    public async updateFoodOrderDestination(
        request: UpdateFoodOrderDestinationRequestDto,
        id: string
    ): Promise<void> {
        await this.foodOrderDestinationProxy.updateFoodOrderDestination(id, request);
    }

    public async deleteFoodOrderDestination(id: string): Promise<void> {
        await this.foodOrderDestinationProxy.deleteFoodOrderDestination(id);
    }

}
