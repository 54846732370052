import { FoodItemFoodItemSpecificationDto } from 'Api/Features/FoodItems/Dtos/FoodItemFoodItemSpecificationDto';

export class FoodItemSpecification implements FoodItemFoodItemSpecificationDto {
    id = '0';
    name: string | null = '';
    price = 0;
    taxAmount = 0;
    promotionPrice: number | null = null;
    promotionTaxAmount: number | null = null;
}
