import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';
import CompanyNavigation from 'Components/company-navigation/company-navigation';
import { observer } from 'mobx-react';

import './index.less';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { CompanyService } from 'Services/CompanyService';
import { Company } from 'Models/Companies/Company';

export const CompanyContext = createContext<CompanyContext|undefined>(undefined);

interface CompanyContext extends Company {
    onConvertToHeyday: () => void;
}

const CompanyDetail: FunctionComponent = observer(({ children, ...props }: any) => {
    const { id } = useParams<{ id: string }>();
    const { navigationStore, globalLoadingStore } = useStores();
    const companyService = useService(CompanyService);
    const [company, setCompany] = useState<CompanyContext|undefined>(undefined);

    const fetchCompany: any = useCallback(async () => {

        const onConvertToHeyday = (): void => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            fetchCompany();
        }

        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await companyService.getById(id);
            setCompany(({ ...response, onConvertToHeyday: onConvertToHeyday } as CompanyContext) || undefined);
            navigationStore.setSubmenu(response);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [companyService, id, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    return (
        <CompanyContext.Provider value={company}>
            <div className="CompanyDetail">
                <CompanyNavigation />
                {children}
            </div>
        </CompanyContext.Provider>
    );
});

export default CompanyDetail;
