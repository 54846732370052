import React, {
    FunctionComponent,
    useCallback,
    useState,
    useEffect,
    useRef,
    ReactNode, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table, Button } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { Message as MessageIcon, User as UserIcon } from 'Components/icons';
import { LocationMessage } from 'Models/Messages/LocationMessage';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { PAGE_SIZE, DATE_API_FORMAT, TABLE_DATE_FORMAT } from 'Models/Constants';
import { GetLocationMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetLocationMessagesRequestDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import CreateLocationMessageModal from './create-message';
import './index.less';
import { LocationContext } from '../index';
import { TdWithImage } from 'Components/td-with-image';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = []; // # @TODO - Check filters to add

const LocationAnnouncements: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const location = useContext(LocationContext);
    const locationService = useService(LocationService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<LocationMessage[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { userStore } = useStores();
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'announcements',
            name: t('Location.location_announcements'),
        },
    ];
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (msgA: LocationMessage, msgB: LocationMessage): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<LocationMessage>,
        columnKey: GetMessagesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<LocationMessage>
    ): ColumnType<LocationMessage>[] => {
        return [
            {
                key: GetMessagesSortColumnDto.Title,
                title: t('Message.message_title'),
                render: (message: LocationMessage): ReactNode | null => <div>{message?.title}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Title),
            },
            {
                key: GetMessagesSortColumnDto.Body,
                title: t('Message.announcement'),
                render: (message: LocationMessage): ReactNode | null => <div>{message?.body}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Body),
            },
            {
                key: GetMessagesSortColumnDto.CreatedByUserName,
                title: t('Message.message_creator'),
                render: (message: LocationMessage): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<UserIcon />}
                        imgSrc={message?.createdByUser?.imageUrl}
                    >
                        <div className="title">{message?.fromName}</div>
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetMessagesSortColumnDto.CreatedByUserName
                ),
            },
            {
                key: GetMessagesSortColumnDto.Date,
                title: t('Message.message_date'),
                render: (message: LocationMessage): ReactNode | null => (
                    <div>{moment(message?.date, DATE_API_FORMAT).format(TABLE_DATE_FORMAT)}</div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Date),
            },
        ];
    };

    const defaultSortOrder: SorterResult<LocationMessage> = {
        columnKey: GetMessagesSortColumnDto.Date as Key,
        order: 'descend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<LocationMessage>
    ): [GetMessagesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetMessagesSortColumnDto = tableSort.columnKey as GetMessagesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<LocationMessage>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<LocationMessage> | SorterResult<LocationMessage>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
        });
    };
    //#endregion

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetMessagesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
        }) => {
            setLoading(true);
            try {
                const [messages, totalItems] = await locationService.getLocationMessages(id, {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                } as GetLocationMessagesRequestDto);

                if (messages) {
                    setMessages(messages);

                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [locationService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div className="Messages">
            <LocationHeader
                title={t('Location.location_announcements')}
                subTitle={t('Location.location_announcements_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                loading={loading}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('Message.message_send_announcement')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(message: LocationMessage): string => message.id!}
                    dataSource={messages}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(message: LocationMessage) => ({
                        onClick: (): void => {
                            history.push(message.getConsoleUrl(id));
                        },
                    })}
                />
            </Content>

            {(userStore.isAdmin || new UserPermissionUtils(userStore).UserCanViewContent([id])) &&
                createModalOpen && (
                    <CreateLocationMessageModal
                        location={location}
                        visible={createModalOpen}
                        onComplete={onCreationComplete}
                    />
                )}
        </div>
    );
});

export default LocationAnnouncements;
