import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { ServiceRequest as WrenchIcon} from 'Components/icons';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption, disableActionMenuItem } from 'Components/action-menu/action-menu';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TableFilters } from 'Components/table-filters';
import { autorun } from 'mobx';
import FilterStore from 'Stores/FilterStore';
import { MIN_ITEMS_FOR_REORDER, PAGE_SIZE, TABLE_COL_ORDER_DEFAULT_WIDTH } from 'Models/Constants';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { useHistory, useParams } from 'react-router-dom';
import { LocationServiceDto } from 'Api/Features/LocationServices/Dtos/LocationServiceDto';
import { GetLocationServicesSortColumnDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesSortColumnDto';
import { LocationServiceService } from 'Services/LocationServiceService';
import { GetLocationServicesRequestDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesRequestDto';
import EditLocationServiceModal from './edit-service-modal';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_title',
    },
    {
        path: 'locationServices',
        nameKey: 'Location.location_services',
    },
];

const Services: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const filterStoreRef = useRef(new FilterStore());
    const locationServiceService = useService(LocationServiceService);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<LocationServiceDto[]>();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { userStore } = useStores();

    const [actions, setActions] = useState<ActionMenuOption[]>([
        {
            key: 'create',
            title: t('LocationServices.create_service'),
            action: (): void => setCreateModalOpen(true),
        },
    ]);

    const getTableColumnsSortHandler = () => {
        return (a: LocationServiceDto, b: LocationServiceDto): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<LocationServiceDto>,
        columnKey: GetLocationServicesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<LocationServiceDto>
    ): ColumnType<LocationServiceDto>[] => {
        return [
            {
                key: GetLocationServicesSortColumnDto.Name,
                title: t('name'),
                dataIndex: 'name',
                render: (key: string): string => key,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetLocationServicesSortColumnDto.Name
                ),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                title: t('User.website'),
                dataIndex: 'website',
                render: (key: string): string => key,
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                key: GetLocationServicesSortColumnDto.Email,
                title: t('User.email'),
                dataIndex: 'email',
                render: (key: string): string => key,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetLocationServicesSortColumnDto.Email
                ),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                title: t('phone_number'),
                dataIndex: 'phoneNumber',
                render: (key: string): string => key,
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
        ];
    };

    const defaultSortOrder: SorterResult<LocationServiceDto> = {
        columnKey: GetLocationServicesSortColumnDto.Name as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<LocationServiceDto>
    ): [GetLocationServicesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetLocationServicesSortColumnDto = tableSort.columnKey as GetLocationServicesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<LocationServiceDto>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetLocationServicesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
        }) => {
            setLoading(true);
            try {
                const [items, totalItemCount] = await locationServiceService.getLocationServices({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    locationIds: [id],
                    searchTerm: params.searchTerm,
                } as GetLocationServicesRequestDto);

                if (items) {
                    setData(items);

                    // disable reorder option
                    setActions((value) =>
                        disableActionMenuItem(
                            value,
                            'reorder',
                            items.length < MIN_ITEMS_FOR_REORDER
                        )
                    );

                    setPagination({
                        ...params.pagination,
                        total: totalItemCount,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [locationServiceService, id]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<LocationServiceDto> | SorterResult<LocationServiceDto>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);

        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    const onRowClick = (serviceId: string | null): void => {
        history.push(`/locations/${id}/management/locationServices/${serviceId}`)
    };

    return (
        <div className="Services">
            <LocationHeader
                title={t('Location.location_services')}
                subTitle={t('LocationServices.services_subtitle')}
                defaultImg={<WrenchIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: LocationServiceDto): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: LocationServiceDto) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <EditLocationServiceModal
                    visible={createModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) {
                            fetch({
                                pagination,
                                sortColumn: defaultColumnSort,
                                sortDirection: defaultDirectionSort,
                                searchTerm: filterStoreRef.current.searchTerm,
                            });
                        }
                        setCreateModalOpen(false);
                    }}
                    locationId={id}
                />
            )}
        </div>
    );
};

export default Services;
