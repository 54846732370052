import React, { FunctionComponent, useState } from 'react';
import { Typography, Collapse, Checkbox, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { UpOutlined } from '@ant-design/icons';

import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { PanelHeader } from './';

import './advanced-filters.less';

const { Title } = Typography;
const { Panel } = Collapse;

interface ActiveFilters {
    key: string;
    active: boolean;
}

interface AdvancedFiltersProps {
    filters: AdvancedFilter[];
    onItemChange: (key: string, parentKey: string) => void;
    onToggleSection: (key: string) => void;
}

const AdvancedFilters: FunctionComponent<AdvancedFiltersProps> = observer(
    ({ filters, onItemChange, onToggleSection }) => {
        const { t } = useTranslation();

        const [activeFilters, setActiveFilters] = useState<ActiveFilters[]>(
            filters.map((filter) => {
                return {
                    key: filter.key,
                    active: true,
                };
            })
        );

        const toggleActiveFilter = (key: string) => {
            setActiveFilters((prevState) =>
                prevState.map((filter) => {
                    return {
                        ...filter,
                        active: filter.key === key ? !filter.active : filter.active,
                    };
                })
            );
        };

        const genExtra = (key: string) => {
            return (
                <div>
                    <span onClick={(e) => {
                        onToggleSection(key);
                        e.stopPropagation();
                    }}>
                        {filters.find((filter) => filter.key === key)?.items.some((x) => x.checked)
                            ? t('unselect_all')
                            : t('select_all')}
                    </span>

                    <UpOutlined
                        onClick={() => toggleActiveFilter(key)}
                        className="ant-collapse-arrow"
                        rotate={
                            activeFilters.find((filter) => filter.key === key)?.active ? 0 : 180
                        }
                    />
                </div>
            );
        };

        return (
            <>
                <div>
                    <Title level={3}>{t('Filter.advanced_filters')}</Title>
                </div>
                <Collapse
                    expandIconPosition="right"
                    activeKey={activeFilters.map((filter) => (filter.active ? filter.key : ''))}
                    className="AdvancedFilters"
                >
                    {filters.map((filter) => (
                        <Panel
                            key={filter.key}
                            header={<PanelHeader filter={filter} />}
                            extra={genExtra(filter.key)}
                            showArrow={false}
                        >
                            <Space size={40}>
                                {filter.items.map((filterItem) => (
                                    <Checkbox
                                        key={filterItem.key}
                                        checked={filterItem.checked}
                                        onChange={(): void =>
                                            onItemChange(filterItem.key, filter.key)
                                        }
                                    >
                                        {t(filterItem.displayNameKey)}
                                    </Checkbox>
                                ))}
                            </Space>
                        </Panel>
                    ))}
                </Collapse>
            </>
        );
    }
);

export default AdvancedFilters;
