import { Invoice } from './Invoice';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';

export class CompanyInvoice extends Invoice {
    constructor(dto: InvoiceDto, companyId: string) {
        super(dto);
        this.companyId = companyId;
    }
    companyId = '';

    get consoleUrl(): string {
        return `/companies/${this.companyId}/invoices/${this.id}`
    }
}