import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Delete,
    FoodSandwich as FoodItemsIcon,
    Promotion as PromotionIcon,
} from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { FoodItem } from 'Models/FoodItem/FoodItem';
import { FoodItemService } from 'Services/FoodItemService';
import { moneyFormat } from 'Utils';
import FoodItemModal from '../food-item-modal';
import { FoodMerchantContext } from '../../index';
import StatusBadge, { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { FoodPromotionService } from 'Services/FoodPromotionService';
import { FoodPromotionStatusDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionStatusDto';
import { DATE_DISPLAY_FORMAT } from 'Models/Constants';
import moment from 'moment';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const FoodItemsDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, itemId } = useParams<{ id: string; itemId: string}>();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const foodItemService = useService(FoodItemService);
    const foodPromotionService = useService(FoodPromotionService);
    const [data, setData] = useState<FoodItem | null>(null);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [specifications, setSpecifications] = useState<SimpleListItemProps[]>([]);
    const [upcomingPromotions, setUpcomingPromotions] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [foodItem, setFoodItem] = useState<FoodItem | null>();

    const deleteFoodItem = async (): Promise<void> => {
        let promotionItem = false;
        if (foodItem?.inPromotion || upcomingPromotions?.length > 0) {
            promotionItem = true
        }
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: promotionItem
                    ? t(`Food.delete_item_confirm_title_promotion`)
                    : t(`Food.delete_item_confirm_title`),
                message: promotionItem
                    ? t(`Food.delete_item_confirm_message_promotion`)
                    : t(`Food.delete_item_confirm_message`),
                positiveText: t(`Food.delete_item_confirm_positive`),
                negativeText: t(`Food.delete_item_confirm_negative`),
            }))
        )
            return;

        await foodItemService.deleteFoodItem(itemId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Food.edit_food_item'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Food.delete_food_item'),
            action: (): Promise<void> => deleteFoodItem(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const foodItem: FoodItem | null = await foodItemService.getFoodItem(itemId);
                setFoodItem(foodItem);

                if(foodItem?.inPromotion) {
                    const [foodPromotions] = await foodPromotionService.getFoodPromotions({
                        itemIds: [itemId],
                        statuses: [FoodPromotionStatusDto.Ongoing],
                    });
                    if(foodPromotions) {
                        foodItem.currentPromotion = foodPromotions?.pop();
                    }
                }

                const [upcomingPromotions] = await foodPromotionService.getFoodPromotions({
                    itemIds: [itemId],
                    statuses: [FoodPromotionStatusDto.Upcoming],
                });

                if (upcomingPromotions.length > 0) {
                    setUpcomingPromotions(
                        upcomingPromotions.map((promotion) => {
                            return {
                                avatar: <PromotionIcon fill={theme['primary-color']} />,
                                title: promotion.fixedOrReduction,
                                description: `${moment(promotion.startDate).format(
                                    DATE_DISPLAY_FORMAT
                                )} - ${moment(promotion.endDate).format(DATE_DISPLAY_FORMAT)}`,
                            } as SimpleListItemProps;
                        })
                    );
                }

                setData(foodItem);

            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [editModalOpen, itemId, foodItemService, foodPromotionService, globalLoadingStore, toastStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'merchants',
                nameKey: 'Food.food_and_beverages_merchant',
            },
            {
                path: foodMerchant?.id || '',
                name: foodMerchant?.name || '',
            },
            {
                path: 'items',
                name: t('Food.merchant_items_breadcrumb', { param1: foodMerchant?.name }),
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = [];
        if (data?.description) {
            description.push({
                title: data?.description,
            });
        }
        return description;
    }, [data]);

    const getSpecifications = useCallback((): SimpleListItemProps[] => {
        const specifications: SimpleListItemProps[] = [];
        if (data?.specifications) {
            data.specifications.forEach((specification) => {
                specifications.push({
                    content: (
                        <div className={`specification ${data?.inPromotion ? 'promoted': ''}`}>
                            <div className="name">{specification?.name}</div>
                            <div className="promotion">
                                {data?.inPromotion && (
                                    <>
                                        {data?.currentPromotion && (<div className="duration">
                                            {t('Food.promotion_ongoing_promotion_until', {
                                                param1: moment(data?.currentPromotion?.endDate).format(DATE_DISPLAY_FORMAT),
                                            })}
                                        </div>)}
                                        <div className="promotion-row">
                                            <div className="detail">
                                                <div className="label">
                                                    {t('Table.column_price')}
                                                </div>
                                                <div className="value">
                                                    {moneyFormat(specification?.promotionPrice)}
                                                </div>
                                            </div>
                                            <div className="detail">
                                                <div className="label">{t('tax_amount')}</div>
                                                <div className="value">
                                                    {moneyFormat(specification?.promotionTaxAmount)}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="pricing">
                                <div className="detail">
                                    <div className="label">{t('Table.column_price')}</div>
                                    <div className="value lined">{moneyFormat(specification?.price)}</div>
                                </div>
                                <div className="detail">
                                    <div className="label">{t('tax_amount')}</div>
                                    <div className="value lined">
                                        {moneyFormat(specification?.taxAmount)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                });
            });
        }
        return specifications;
    }, [data, t]);

    useEffect(() => {
        if (data) {
            setDescription(getDescription);
            setSpecifications(getSpecifications);
        }
    }, [data, getDescription, getSpecifications]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="FoodItemsDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={
                            <>
                                {data?.category?.name && (
                                    <StatusBadge
                                        status={StatusBadgeStatus.Active}
                                        text={data.category.name}
                                    />
                                )}
                                <div className="header-details">
                                    <div>
                                        <div>{t('Table.column_price')}</div>
                                        <strong
                                            className={data?.inPromotion ? 'promoted-item' : ''}
                                            dangerouslySetInnerHTML={{
                                                __html: data?.priceWithPromotionString,
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        imgSrc={data?.imageUrl}
                        defaultImg={<FoodItemsIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={
                            (userStore.isAdmin ||
                                new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                                <ActionMenu options={actions} type="primary" trigger="click" />
                            )
                        }
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                <SimpleList
                                    className="specifications"
                                    title={t('Food.food_item_detail_specifications_title')}
                                    data={specifications}
                                />
                                {upcomingPromotions.length > 0 && (
                                    <SimpleList
                                        className="upcomingPromotions"
                                        title={t('Food.promotion_upcoming_promotions')}
                                        data={upcomingPromotions}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {description.length > 0 && (
                                    <SimpleList
                                        className="description"
                                        title={t('description')}
                                        data={description}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {editModalOpen && (
                        <FoodItemModal
                            visible={editModalOpen}
                            onComplete={onEditComplete}
                            foodItem={data}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default FoodItemsDetail;
