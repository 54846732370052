import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import {
    Administration as AdministrationIcon,
    Amenity as AmenityIcon,
    Location as LocationIcon,
    FoodSalad as FoodMerchantIcon,
    ServiceRequest as ServiceRequestIcon,
} from 'Components/icons';
import AmenityModal from './amenities/create-amenity';
import FoodMerchantModal from './merchants/food-merchant-modal';
import './index.less';
import UserPermissionUtils from 'Utils/UserPermissionUtils';
import { useStores } from 'Hooks';

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.location_management',
    },
];

const LocationManagement: FunctionComponent = observer(() => {
    const { id } = useParams<{id: string}>();
    const { t } = useTranslation();
    const history = useHistory();
    const [createAmenityModalOpen, setCreateAmenityModalOpen] = useState(false);
    const [createMerchantModalOpen, setCreateMerchantModalOpen] = useState(false);
    const { userStore } = useStores();

    const actions: ActionMenuOption[] = [
        {
            key: 'createAmenity',
            title: t('Amenities.create_amenity'),
            action: (): void => setCreateAmenityModalOpen(true),
        },
        {
            key: 'createMerchant',
            title: t('Food.food_and_beverages_merchant_create'),
            action: (): void => setCreateMerchantModalOpen(true),
        },
    ];

    const onAmenityCreationComplete = (success: boolean): void => {
        setCreateAmenityModalOpen(false);
        if (success) history.push(`/locations/${id}/management/amenities`);
    };

    const onMerchantCreationComplete = (success: boolean): void => {
        setCreateMerchantModalOpen(false);
        if (success) history.push(`/locations/${id}/management/merchants`);
    };

    return (
        <>
            <LocationHeader
                title={t('Location.management_title')}
                subTitle={t('Location.management_subtitle')}
                defaultImg={<AdministrationIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />

            <div className="LocationManagement">
                <div className="column-container">
                    <Typography.Title level={2}>
                        {t('Location.management_amenities')}
                    </Typography.Title>
                    <Link to="management/amenities">
                        <div className="nav-btn-container">
                            <div className="nav-btn-content">
                                <div className="nav-btn-icon-container">
                                    <AmenityIcon fill={theme['primary-color']} />
                                </div>
                                <Typography.Text className="nav-btn-text">
                                    {t('Location.management_amenities')}
                                </Typography.Text>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="column-container">
                    <Typography.Title level={2}>{t('Food.food_and_beverages')}</Typography.Title>
                    <Link to="management/merchants">
                        <div className="nav-btn-container">
                            <div className="nav-btn-content">
                                <div className="nav-btn-icon-container">
                                    <FoodMerchantIcon fill={theme['primary-color']} />
                                </div>
                                <Typography.Text className="nav-btn-text">
                                    {t('Food.food_and_beverages_merchant')}
                                </Typography.Text>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="column-container">
                    <Typography.Title level={2}>{t('Location.location')}</Typography.Title>
                    <Link to="management/details">
                        <div className="nav-btn-container">
                            <div className="nav-btn-content">
                                <div className="nav-btn-icon-container">
                                    <LocationIcon fill={theme['primary-color']} />
                                </div>
                                <Typography.Text className="nav-btn-text">
                                    {t('Location.location_details')}
                                </Typography.Text>
                            </div>
                        </div>
                    </Link>

                    <Link to="management/locationServices">
                        <div className="nav-btn-container">
                            <div className="nav-btn-content">
                                <div className="nav-btn-icon-container">
                                    <ServiceRequestIcon fill={theme['primary-color']} />
                                </div>
                                <Typography.Text className="nav-btn-text">
                                    {t('Location.location_services')}
                                </Typography.Text>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            {createAmenityModalOpen && (
                <AmenityModal
                    visible={createAmenityModalOpen}
                    onComplete={onAmenityCreationComplete}
                    locationId={id}
                />
            )}
            {createMerchantModalOpen && (
                <FoodMerchantModal
                    visible={createMerchantModalOpen}
                    onComplete={onMerchantCreationComplete}
                    locationId={id}
                />
            )}
        </>
    );
});

export default LocationManagement;
