// GENERATED FILE - DO NOT MODIFY
import { CreateFoodPromotionRequestDto } from 'Api/Features/FoodPromotions/Dtos/CreateFoodPromotionRequestDto';
import { FoodPromotionDetailsDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionDetailsDto';
import { GetFoodPromotionsRequestDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsRequestDto';
import { GetFoodPromotionsResponseDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsResponseDto';
import { UpdateFoodPromotionRequestDto } from 'Api/Features/FoodPromotions/Dtos/UpdateFoodPromotionRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FoodPromotionProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFoodPromotion(foodPromotionId: string): Promise<FoodPromotionDetailsDto | null> {
        const uri = this.buildUri(
            "/food-promotions/{foodPromotionId}",
            { foodPromotionId: foodPromotionId },
            null
        );

        const data: FoodPromotionDetailsDto | null = await this.httpClient.get<FoodPromotionDetailsDto | null>(uri);
        return data;
    }

    public async getFoodPromotions(request: GetFoodPromotionsRequestDto | null): Promise<GetFoodPromotionsResponseDto | null> {
        const uri = this.buildUri(
            "/food-promotions",
            null,
            { ...request || undefined }
        );

        const data: GetFoodPromotionsResponseDto | null = await this.httpClient.get<GetFoodPromotionsResponseDto | null>(uri);
        return data;
    }

    public async createFoodPromotion(request: CreateFoodPromotionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-promotions",
            null,
            null
        );

        await this.httpClient.post<CreateFoodPromotionRequestDto | null, void>(uri, request);
    }

    public async updateFoodPromotion(foodPromotionId: string, request: UpdateFoodPromotionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-promotions/{foodPromotionId}",
            { foodPromotionId: foodPromotionId },
            null
        );

        await this.httpClient.patch<UpdateFoodPromotionRequestDto | null, void>(uri, request);
    }

    public async deleteFoodPromotion(foodPromotionId: string): Promise<void> {
        const uri = this.buildUri(
            "/food-promotions/{foodPromotionId}",
            { foodPromotionId: foodPromotionId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}