import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { Layout, Table } from 'antd';
import { images, theme } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Invoice as InvoiceIcon, Location as LocationIcon } from 'Components/icons';
import './index.less';
import { Invoice } from 'Models/Invoices/Invoice';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { GetInvoicesSortColumnDto } from 'Api/Features/Invoices/Dtos/GetInvoicesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { PAGE_SIZE } from 'Models/Constants';
import { autorun } from 'mobx';
import { TableFilters } from 'Components/table-filters';
import FilterStore from 'Stores/FilterStore';
import { InvoiceService } from 'Services/InvoiceService';
import { moneyFormat } from 'Utils';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { TdWithImage } from 'Components/td-with-image';
import { GetInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetInvoicesRequestDto';
import { ListSectionHeader } from 'Components/list-section-header';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import StatusBadge from 'Components/status-badge/status-badge';

const { Content } = Layout;
const { listSectionHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'invoiceStatuses',
        nameKey: 'Invoices.invoices_invoice_status',
        items: Object.keys(InvoiceStatusDto).map((key) => {
            return {
                key: key,
                checked: true,
                displayNameKey: 'Invoices.invoice_status_' + key,
            } as AdvancedFilterItem;
        }),
    },
];

const Invoices: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const invoiceService = useService(InvoiceService);
    const { locationStore, navigationStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Invoice[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'invoices',
            nameKey: 'Invoices.invoices_title',
        },
    ];

    useEffect(() => {
        navigationStore.clearSubmenu();
    }, [navigationStore]);

    const getTableColumnsSortHandler = () => {
        return (invoiceA: Invoice, invoiceB: Invoice): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<Invoice>,
        columnKey: GetInvoicesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<Invoice>): ColumnType<Invoice>[] => {
        return [
            {
                key: GetInvoicesSortColumnDto.IssueDate,
                title: t('Table.column_issued'),
                dataIndex: 'date',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetInvoicesSortColumnDto.IssueDate),
            },
            {
                key: GetInvoicesSortColumnDto.Number,
                title: t('Table.column_number'),
                dataIndex: 'number',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetInvoicesSortColumnDto.Number),
            },
            {
                key: GetInvoicesSortColumnDto.Description,
                title: t('Table.column_description'),
                dataIndex: 'description',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetInvoicesSortColumnDto.Description
                ),
            },
            {
                title: t('Table.column_receiver'),
                render: (invoice: Invoice): ReactNode | null => {
                    return (
                        <TdWithImage
                            defaultImg={<LocationIcon />}
                            imgSrc={invoice.receiverImageUrl}
                        >
                            <div>
                                <div className="title">{invoice.receiverName}</div>
                                <div className="subtitle">{invoice.paymentMethodHolderType}</div>
                            </div>
                        </TdWithImage>
                    );
                },
            },
            {
                key: GetInvoicesSortColumnDto.LocationName,
                title: t('Location.location'),
                render: (invoice: Invoice): ReactNode | null => {
                    return invoice.location ? (
                        <TdWithImage
                            defaultImg={<LocationIcon />}
                            imgSrc={invoice.location?.mainImageUrl}
                        >
                            <div>
                                <div className="title">{invoice.location?.name}</div>
                                <div className="subtitle">{invoice.location.type}</div>
                            </div>
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetInvoicesSortColumnDto.LocationName
                ),
            },
            {
                title: t('Invoices.invoices_status'),
                render: (invoice: Invoice): ReactNode | null => (
                    <StatusBadge status={invoice.statusBadgeStatus} />
                ),
            },
            {
                key: GetInvoicesSortColumnDto.Total,
                title: t('Table.column_total'),
                dataIndex: 'total',
                render: (key: number): string => moneyFormat(key) || '',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetInvoicesSortColumnDto.Total),
            },
        ];
    };

    const defaultSortOrder: SorterResult<Invoice> = {
        columnKey: GetInvoicesSortColumnDto.IssueDate as Key,
        order: 'descend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<Invoice>
    ): [GetInvoicesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetInvoicesSortColumnDto = tableSort.columnKey as GetInvoicesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<Invoice>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetInvoicesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            locationIds: string[];
            invoiceStatuses: string[];
        }) => {
            setLoading(true);
            try {

                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    locationIds: [],
                } as GetInvoicesRequestDto;

                if(params.invoiceStatuses.length > 0) {
                    request.statuses = params.invoiceStatuses.map((status) => status as InvoiceStatusDto);
                }

                if (params.locationIds.length > 0) {
                    request.locationIds = params.locationIds;
                } else if (userStore.isBuildingManager) {
                    request.locationIds = userStore.buildingManagerLocationIds;
                }

                const [items, totalItems] = await invoiceService.getInvoices(request);

                setData(items);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [invoiceService, userStore]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<Invoice> | SorterResult<Invoice>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            locationIds: filterStore.currentLocationIds,
            invoiceStatuses: filterStoreRef.current.checkedItemsByFilterKey('invoiceStatuses')
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                locationIds: filterStore.currentLocationIds,
                invoiceStatuses: filterStoreRef.current.checkedItemsByFilterKey('invoiceStatuses')
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    return (
        <div className="Invoices">
            <ListSectionHeader
                title={t('Invoices.invoices_title')}
                subTitle={t('Invoices.invoices_subtitle')}
                defaultImg={<InvoiceIcon fill={theme['primary-color']} />}
                backgroundImageUrl={listSectionHeader}
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                    includeLocations
                    availableLocations={locationStore.locations
                        .filter(
                            (location) => 
                                userStore.isAdmin ||
                                (userStore.isBuildingManager &&
                                    userStore.buildingManagerLocationIds.some(
                                        (buildingId) => location.id === buildingId
                                    ))
                        )
                        .map((location) => location as LightLocationInfo)}
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Invoice): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(invoice: Invoice) => ({
                        onClick: (): void => {
                            history.push(invoice.consoleUrl);
                        },
                    })}
                />
            </Content>
        </div>
    );
});

export default Invoices;
