import React, { FunctionComponent, ReactNode } from 'react';
import { Typography, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import StatusBadge from 'Components/status-badge/status-badge';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';

import './company-header.less';

const { Title, Paragraph } = Typography;

interface CompanyHeaderProps extends ImageWithPlaceholderProps {
    title?: string;
    subTitle?: ReactNode | string | null;
    mainImageUrl?: string;
    defaultBackgroundImageUrl: string;
    status?: StatusBadgeStatus;
    routes?: BreadcrumbSegment[];
    loading?: boolean;
    action?: ReactNode;
    children?: ReactNode;
}

const CompanyHeader: FunctionComponent<CompanyHeaderProps> = observer(
    ({
        title,
        subTitle,
        mainImageUrl,
        defaultBackgroundImageUrl,
        status,
        routes = [],
        loading,
        action,
        children,
        ...imageProps
    }) => {
        const headerStyle = (): Object => {
            if (mainImageUrl) {
                return {
                    background: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${mainImageUrl}`,
                };
            }

            return {
                backgroundImage: `url(${defaultBackgroundImageUrl})`,
            };
        };

        return (
            <div className="CompanyHeader" style={headerStyle()}>
                <Row>
                    <Col span={1} />
                    <Col span={3}>
                        {mainImageUrl ? (
                            <img alt="" className="location-avatar" src={mainImageUrl} />
                        ) : (
                            <ImageWithPlaceholder className="header-icon" {...imageProps} />
                        )}
                    </Col>
                    <Col span={action ? 16 : 20}>
                        <RoutedBreadcrumb routes={routes} />
                        <div className="company-details-container">
                            <div className="company-details-col">
                                <div className="company-avatar-info-container">
                                    <Title className="text-primary company-avatar-info">
                                        {title}
                                    </Title>
                                </div>
                                {subTitle ? <Paragraph>{subTitle}</Paragraph> : null}
                                {status && (
                                    <div className="company-avatar-badge">
                                        <StatusBadge status={status} />
                                    </div>
                                )}
                            </div>
                            {children && <div className="company-details-col">{children}</div>}
                        </div>
                    </Col>
                    {action && (
                        <Col span={4} className="alignCenter">
                            <div className="headerActions">{action}</div>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
);

export default CompanyHeader;
