import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { Col, Layout, Row } from 'antd';
import { images, theme } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { SimpleList } from 'Components/simple-list';
import { User as UserIcon, Email, Phone, Location, Calendar, Address } from 'Components/icons';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from 'Models/Constants';
import EditUserRoleModal from './edit-user-role';
import './index.less';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import UserPermissionUtils from 'Utils/UserPermissionUtils';
import { CompanyContext } from '../..';

const { usersHeader } = images;
const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

interface UsersHistory {
    from?: string | undefined;
}

const CompanyTeamUserDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, userId } = useParams();

    const history = useHistory<UsersHistory>();
    const { navigationStore, globalLoadingStore, confirmationModalStore, userStore } = useStores();
    const userService = useService(UserService);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [contact, setContact] = useState<SimpleListItemProps[] | null>(null);
    const [introduction, setIntroduction] = useState<SimpleListItemProps[] | null>(null);
    const [memberRole, setMemberRole] = useState<SimpleListItemProps[] | null>(null);
    const [jobPosition, setJobPosition] = useState<SimpleListItemProps[] | null>(null);
    const [location, setLocation] = useState<SimpleListItemProps[] | null>(null);
    const [personalInformation, setPersonalInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [editModalOpen, setEditModalOpen] = useState(false);
    const company = useContext(CompanyContext);

    const setSimpleListsData = useCallback(
        (user: UserDetails): void => {
            if (user?.companyRoles) {
                setMemberRole([
                    {
                        title: user?.companyRoles[0]
                            ? t(`User.user_role_${user?.companyRoles[0]}`)
                            : '',
                    },
                ]);
            }
            if (user?.jobPosition) setJobPosition([{ title: user.jobPosition || '' }]);
            if (user?.introduction) setIntroduction([{ title: user.introduction || '' }]);

            if (user?.contactInfo) {
                const contact: SimpleListItemProps[] = [];
                if (user.contactInfo.email)
                    contact.push({
                        title: `${user.contactInfo.email}`,
                        avatar: <Email fill={theme['primary-color']} />,
                    });
                if (user.contactInfo.phoneNumber)
                    contact.push({
                        title: (user.contactInfo as ContactInfoDto).phoneNumber || '',
                        avatar: <Phone fill={theme['primary-color']} />,
                    });
                setContact(contact);
            }

            if (user?.location) {
                setLocation([
                    {
                        title: user.location.name || '',
                        avatar: (
                            <ImageWithPlaceholder
                                width="32"
                                height="32"
                                defaultImg={<Location />}
                                imgSrc={user.location.mainImageUrl}
                            />
                        ),
                    },
                ]);
            }

            if (user?.birthDate || !user?.mainAddress?.isEmpty) {
                const info: SimpleListItemProps[] = [];
                if (!user.mainAddress?.isEmpty) {
                    info.push({
                        title: user.mainAddress!.text,
                        avatar: <Address fill={theme['primary-color']} />,
                    });
                }
                if (user.birthDate) {
                    info.push({
                        title:
                            moment(user.birthDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT) ||
                            '',
                        avatar: <Calendar fill={theme['primary-color']} />,
                    });
                }
                setPersonalInformation(info);
            }
        },
        [t]
    );

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const userData: UserDetailsDto | null = await userService.getById(userId);

            if (userData) {
                const userDetails: UserDetails = new UserDetails(userData);
                setUser(userDetails);
                navigationStore.setSubmenu(userDetails);
                setSimpleListsData(userDetails);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [userId, userService, navigationStore, globalLoadingStore, setSimpleListsData]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const onEditionComplete = useCallback(
        (success: boolean) => {
            setEditModalOpen(false);
            if (success) {
                fetch();
            }
        },
        [fetch]
    );

    const resendInvitationEmail = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Email />,
                title: t('User.user_resend_email_confirm_title'),
                positiveText: t('User.user_resend_email_confirm_positive'),
                negativeText: t('User.user_resend_email_confirm_negative'),
            }))
        )
            return;

        globalLoadingStore.addLoading();

        userService
            .resendCompanyInvitation(userId)
            .then(async () => {
                globalLoadingStore.removeLoading();
                if (
                    !(await confirmationModalStore.confirm({
                        icon: <Email />,
                        title: t('User.user_resend_email_success_title'),
                        message: t('User.user_resend_email_success_message'),
                        positiveText: t('ok'),
                    }))
                )
                    return;
            })
            .finally(() => globalLoadingStore.removeLoading());
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: user?.company?.name || t('Company.company'),
        },
        {
            path: 'team',
            nameKey: 'Company.company_team',
        },
        {
            path: user?.id || '',
            name: user?.name || '',
        },
    ];

    const actions: ActionMenuOption[] = [
        {
            key: 'manage',
            title: t('User.user_manage'),
            action: (): void =>
                history.push(user?.consoleUrl!, {
                    from: 'team',
                }),
        },
        {
            key: 'role',
            title: t('User.user_edit_role'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'resend',
            title: t('User.user_resend_email'),
            action: (): Promise<void> => resendInvitationEmail(),
        },
    ];

    const getUserStatus = (): StatusBadgeStatus => {
        const status = user?.isConfirmed ? user?.status : t('User.user_invitation_email_sent');
        return status as StatusBadgeStatus;
    };

    return (
        <div className="UserDetails">
            <ListSectionHeader
                title={user?.name || ''}
                subTitle={''}
                defaultImg={<UserIcon fill={theme['primary-color']} />}
                imgSrc={user?.imageUrl}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                routes={breadcrumbs}
                status={getUserStatus()}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [company?.location?.id].concat(
                                company?.locations
                                    ? company.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && <ActionMenu options={actions} type="primary" trigger="click" />
                }
            />
            <Content>
                <Row gutter={16}>
                    <Col span={12}>
                        {memberRole && (
                            <SimpleList title={t('User.role_title')} data={memberRole} />
                        )}
                        {jobPosition && (
                            <SimpleList title={t('User.job_position')} data={jobPosition} />
                        )}
                        {introduction && user && !user.introductionEmpty && (
                            <SimpleList title={t('User.user_introduction')} data={introduction} />
                        )}
                        {contact && (
                            <SimpleList title={t('Contact.contact_information')} data={contact} />
                        )}
                    </Col>

                    <Col span={12}>
                        {personalInformation && (
                            <SimpleList
                                title={t('User.user_information')}
                                data={personalInformation}
                            />
                        )}
                        {location && <SimpleList title={t('Location.location')} data={location} />}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && user && (
                <EditUserRoleModal
                    visible={editModalOpen}
                    onComplete={onEditionComplete}
                    user={user!}
                />
            )}
        </div>
    );
};

export default CompanyTeamUserDetail;
