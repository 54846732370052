import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Select, Typography, Button, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';

import './location-modal.less';
import { Close, Location, Search } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateLocationSchema, EditLocationSchema } from 'Schemas';
import { useFormValidation, useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { CreateLocationRequestDto } from 'Api/Features/Locations/Dtos/CreateLocationRequestDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import timezones from 'appcom-timezones';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment, { Moment } from 'moment';
import { SelectCountry } from 'Components/select-country';
import { LocationTypeDto } from 'Api/Features/Locations/Dtos/LocationTypeDto';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import GoogleMapReact from 'google-map-react';
import { DEFAULT_LAT, DEFAULT_LNG, MAP_DEFAULT_ZOOM, MAP_SEARCHED_ZOOM, TIME_FORMAT_12_HOUR } from 'Models/Constants';
import { SelectValue } from 'antd/es/select';

const { Option } = Select;
const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface Coords {
    lat: number;
    lng: number;
}

interface MarkerModel extends Coords {
    img: string;
}

const heydayMarkerImage = '/Heyday_location.png';
const passportMarkerImage = 'Passport_location.png';

const Marker = ({ lat, lng, img }: any) => (
    <div>
        <img src={img} style={{ position: 'relative', top: '-16px', left: '-16px' }} alt="marker" />
    </div>
);

interface LocationModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    location?: LocationDto;
    isEditing?: boolean;
}

const LocationModal: FunctionComponent<LocationModalProps> = ({
    visible,
    onComplete,
    location,
    isEditing,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, scrollToErrors] = useFormValidation(
        location ? EditLocationSchema : CreateLocationSchema,
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const locationService = useService(LocationService);

    const [otherImagesDetails, setOtherImagesDetails] = useState<ImageDetails[]>();
    const [homeBackgroundImageDetails, setHomeBackgroundImageDetails] = useState<ImageDetails[]>();
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [logoImageDetails, setLogoImageDetails] = useState<ImageDetails[]>();
    const [locationId, setLocationId] = useState<string>();
    const [locationType, setLocationType] = useState<LocationTypeDto>();

    const mapCenter: Coords = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
    const [geocoder, setGeocoder] = useState<any>();
    const [center, setCenter] = useState<Coords>(mapCenter);
    const [googleMaps, setGoogleMaps] = useState<any>();
    const [marker, setMarker] = useState<MarkerModel | null>();
    const [zoom, setZoom] = useState<number>(MAP_DEFAULT_ZOOM);
    const [isSeachingLocation, setIsSearchingLocation] = useState<boolean>(false);
    const [businessHoursStart, setBusinessHoursStart ] = useState<Moment | undefined>();
    const [businessHoursEnd, setBusinessHoursEnd] = useState<Moment | undefined>();
    //#endregion

    //#region Effects
    useEffect(() => {
        if (location) {
            setLocationId(location.id);
            setLocationType(location.type);
            setBusinessHoursStart(
                location.businessHoursStart !== null
                    ? moment(location.businessHoursStart, TIME_FORMAT_12_HOUR)
                    : undefined
            );
            setBusinessHoursEnd(
                location.businessHoursEnd !== null
                    ? moment(location.businessHoursEnd, TIME_FORMAT_12_HOUR)
                    : undefined
            );
            form.setFieldsValue({
                name: location.name,
                //legalName: location.legalName, // @TODO - Request legal name for DTO
                introduction: location.introduction,
                latitude: location.latitude,
                type: location.type,
                longitude: location.longitude,
                timeZone: location.timeZone, // @TODO - Get all possible values - 'America/Toronto' is valid

                phoneNumber: location.contactInfo?.phoneNumber,
                email: location.contactInfo?.email,
                website: location.contactInfo?.website,

                addressLine1: location.address?.addressLine1,
                city: location.address?.city,
                state: location.address?.state,
                countryCode: location.address?.countryCode,
                zipCode: location.address?.zipCode,
            });
        }
    }, [location, form, visible]);
    //#endregion

    interface SelectOption {
        id: string;
        name: string;
    }

    const timeZonesOptions: SelectOption[] = timezones.map((option) => ({
        id: option.id,
        // Format: "(GMT-04:00) Eastern Time - Toronto"
        name: `(GMT${moment.tz(new Date(), option.id).format('Z')}) ${option.description}`,
    }));

    const locationTypeOptions: SelectOption[] = Object.keys(LocationTypeDto).map((key: string) => ({
        id: key,
        name: t(`LocationType.${key}`),
    }));

    const selectOptions = (options: SelectOption[]) => {
        return options.map((option) => (
            <Option key={option.id} value={option.id}>
                {option.name}
            </Option>
        ));
    };

    const handleIntroductionChange = (value: string): void => {
        form.setFieldsValue({ introduction: value });
    };

    //#region Event handlers

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Location />,
                title: t(`Location.location_saved_success`),
                message: t(`Location.location_updated_success`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Location />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`Location.location_confirm_positive${isEditing ? '_edit' : ''}`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        const contactInfoData: ContactInfoDto = {
            email: values.email,
            phoneNumber: values.phoneNumber,
            website: values.website,
        };

        const addressData: AddressDto = {
            addressLine1: values.addressLine1,
            city: values.city,
            state: values.state,
            countryCode: values.countryCode,
            zipCode: values.zipCode,
        };

        const logoImage = logoImageDetails
            ? ({
                  delete: logoImageDetails[0].isDeleted,
                  uploadBase64: logoImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const mainImage = mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const homeBackground = homeBackgroundImageDetails
            ? ({
                  delete: homeBackgroundImageDetails[0].isDeleted,
                  uploadBase64: homeBackgroundImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const otherImages = otherImagesDetails
            ? otherImagesDetails
                  ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                  .map(
                      (image) =>
                          ({
                              delete: image.isDeleted,
                              uploadBase64: image.base64,
                              id: image.id,
                          } as UpdateFileRequestDto)
                  )
            : null;

        const newLocationData: CreateLocationRequestDto = {
            name: values.name,
            introduction: values.introduction,
            latitude: values.latitude,
            type: values.type,
            longitude: values.longitude,
            timeZone: values.timeZone,
            contactInfo: contactInfoData,
            address: addressData,
            businessHoursStart: businessHoursStart === undefined ? undefined : businessHoursStart.toString(),
            businessHoursEnd: businessHoursEnd === undefined ? undefined : businessHoursEnd.toString(),
            logoImage: logoImage,
            appHomeBackgroundImage: homeBackground,
            images: otherImages,
            mainImage: mainImage
        };

        if (!(await validateForm(newLocationData, true))) return;
        try {
            globalLoadingStore.addLoading();

            newLocationData.businessHoursStart =
                businessHoursStart === undefined
                    ? undefined
                    : businessHoursStart.format('HH:mm:00');
            newLocationData.businessHoursEnd =
                businessHoursEnd === undefined ? undefined : businessHoursEnd.format('HH:mm:00');

            if (locationId) {
                await locationService.editLocation(locationId, newLocationData);
            } else {
                await locationService.createLocation(newLocationData);
            }
            success();
        } catch (error) {
            if (error.response?.data?.errorCode === 'E015003') {
                toastStore.toast({
                    type: 'error',
                    message: t('Errors.error_setting_image'),
                });
            } else if (!error.treated) {
                toastStore.displayError(error);
            }
            scrollToErrors(errors);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const onGoogleApiLoaded = (map: any, maps: any): void => {
        setGoogleMaps(maps);
        setGeocoder(new maps.Geocoder());
    };

    const handleAddressSearch = (): void => {
        setIsSearchingLocation(true);
        const address = form.getFieldValue('addressSearch');
        geocoder.geocode({ address: address }, function (results: any, status: any) {
            if (status === googleMaps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                form.setFieldsValue({
                    latitude: latitude,
                    longitude: longitude,
                });
                setCenter({ lat: latitude, lng: longitude });
                const type = form.getFieldValue('type');
                setMarker({
                    lat: latitude,
                    lng: longitude,
                    img:
                        type === LocationTypeDto.Passport ? passportMarkerImage : heydayMarkerImage,
                });
                setZoom(MAP_SEARCHED_ZOOM);
            }
            setIsSearchingLocation(false);
        });
    };

    const handleUseLocationAddress = (): void => {
        const locationAddressInfo = [];
        const addressLine1 = form.getFieldValue('addressLine1');
        const city = form.getFieldValue('city');
        const state = form.getFieldValue('state');
        const zipCode = form.getFieldValue('zipCode');
        const countryCode = form.getFieldValue('countryCode');

        if (addressLine1) {
            locationAddressInfo.push(addressLine1.trim());
        }

        if (city) {
            locationAddressInfo.push(city.trim());
        }

        if (state) {
            locationAddressInfo.push(state.trim());
        }

        if (zipCode) {
            locationAddressInfo.push(zipCode.trim());
        }

        if (countryCode) {
            locationAddressInfo.push(countryCode.trim());
        }

        form.setFieldsValue({ addressSearch: locationAddressInfo.join(', ') });
    };

    //#region Render
    return (
        <Modal
            visible={visible}
            centered
            title={t(`Location.location_${location ? 'edit' : 'create_title'}`)}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateLocation">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('Location.location_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="type"
                                label={t('Location.location_type')}
                                required
                            >
                                <Select
                                    disabled={
                                        isEditing && location?.type === LocationTypeDto.Heyday
                                    }
                                    onChange={(value: SelectValue): void => {
                                        setLocationType(value as LocationTypeDto);
                                    }}
                                >
                                    {selectOptions(locationTypeOptions)}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="introduction"
                                label={t('Location.location_description')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={
                                    location?.introduction ||
                                    form.getFieldValue('introduction') ||
                                    null
                                }
                                onChange={handleIntroductionChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="businessHoursStart"
                                label={t('Location.opening_business_hours')}
                            >
                                <TimePicker
                                    use12Hours
                                    format={TIME_FORMAT_12_HOUR}
                                    style={{ width: '100%' }}
                                    onChange={(value): void => {
                                        if (value !== null) setBusinessHoursStart(value);
                                        else setBusinessHoursStart(undefined);
                                    }}
                                    defaultValue={
                                        businessHoursStart ? businessHoursStart : undefined
                                    }
                                    value={businessHoursStart ? businessHoursStart : null}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="businessHoursEnd"
                                label={t('Location.closing_business_hours')}
                            >
                                <TimePicker
                                    use12Hours
                                    format={TIME_FORMAT_12_HOUR}
                                    style={{ width: '100%' }}
                                    onChange={(value): void => {
                                        if (value !== null) setBusinessHoursEnd(value);
                                        else setBusinessHoursEnd(undefined);
                                    }}
                                    defaultValue={businessHoursEnd ? businessHoursEnd : undefined}
                                    value={businessHoursEnd ? businessHoursEnd : null}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Contact.contact_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="phoneNumber"
                                label={t('Contact.contact_phone')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="email"
                                label={t('Contact.contact_email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="website"
                                label={t('Contact.contact_website')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Contact.contact_address')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="addressLine1"
                                label={t('Contact.contact_address')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="city"
                                label={t('Contact.contact_city')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="state"
                                label={t('Contact.contact_state')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <SelectCountry
                                errors={errors}
                                name="countryCode"
                                label={t('Contact.contact_country')}
                                required
                            />
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="zipCode"
                                label={t('Contact.contact_zip_code')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Geographical.geographical_position')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="timeZone"
                                label={t('Geographical.timezone')}
                                required
                            >
                                <Select>{selectOptions(timeZonesOptions)}</Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="latitude"
                                label={t('Geographical.latitude')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="longitude"
                                label={t('Geographical.longitude')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row className="AddressSearch" gutter={formGutter}>
                        <Col span={24}>
                            <Form.Item name="addressSearch" noStyle={true}>
                                <Input
                                    size="large"
                                    placeholder={t('Geographical.search_to_move_pin')}
                                    prefix={<Search />}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                className="positive"
                                htmlType="button"
                                onClick={handleAddressSearch}
                                disabled={isSeachingLocation}
                            >
                                {t('search')}
                            </Button>
                            <Button
                                type="default"
                                className="secondary negative"
                                htmlType="button"
                                onClick={handleUseLocationAddress}
                            >
                                {t('Geographical.use_location_address')}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="MapDisplay" gutter={formGutter}>
                        <Col span={24}>
                            <div style={{ height: '374px', width: '880px' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: window.Environment.REACT_APP_GOOGLE_API_KEY || '',
                                    }}
                                    defaultCenter={mapCenter}
                                    zoom={zoom}
                                    center={center}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }): void =>
                                        onGoogleApiLoaded(map, maps)
                                    }
                                >
                                    {marker && (
                                        <Marker
                                            lat={marker.lat}
                                            lng={marker.lng}
                                            img={marker.img}
                                        />
                                    )}
                                </GoogleMapReact>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={8} className="formSection">
                            <Title level={4}>{t('Location.location_logo')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="logo">
                                <ImagePicker
                                    images={
                                        location?.logoImageUrl
                                            ? [
                                                  {
                                                      url: location?.logoImageUrl,
                                                      uid: 'logo',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setLogoImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={8} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="main">
                                <ImagePicker
                                    images={
                                        location?.mainImageUrl
                                            ? [
                                                  {
                                                      url: location?.mainImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_other')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer multipleImageContainer">
                            <ValidatedFormItem errors={errors} name="other">
                                <ImagePicker
                                    allowMultiple={true}
                                    images={location?.images?.map(
                                        (image) =>
                                            ({ url: image?.url, uid: image?.id } as UploadFile)
                                    )}
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setOtherImagesDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {locationType !== LocationTypeDto.Passport && (
                        <>
                            <Row gutter={titleGutter}>
                                <Col span={24} className="formSection">
                                    <Title level={4}>{t('Images.app_home_background')}</Title>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <div>
                                        <p className="help">
                                            {t('Images.app_home_background_description')}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem errors={errors} name="appHomeBackground">
                                        <ImagePicker
                                            images={
                                                location?.appHomeBackgroundImageUrl
                                                    ? [
                                                          {
                                                              url:
                                                                  location?.appHomeBackgroundImageUrl,
                                                              uid: 'appHome',
                                                          } as UploadFile,
                                                      ]
                                                    : undefined
                                            }
                                            setImagesDetails={(
                                                images: ImageDetails[] | undefined
                                            ): void => setHomeBackgroundImageDetails(images)}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t(isEditing ? 'save' : 'Location.location_save_new')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(LocationModal);
