// GENERATED FILE - DO NOT MODIFY
import { CreateBbotOrderRequestDto } from 'Api/Features/Bbot/Dtos/CreateBbotOrderRequestDto';
import { CreateBbotPriceCheckRequestDto } from 'Api/Features/Bbot/Dtos/CreateBbotPriceCheckRequestDto';
import { CreateBbotPriceCheckResponseDto } from 'Api/Features/Bbot/Dtos/CreateBbotPriceCheckResponseDto';
import { CreateBbotStripePaymentMethodRequestDto } from 'Api/Features/Bbot/Dtos/CreateBbotStripePaymentMethodRequestDto';
import { CreateBbotStripePaymentMethodResponseDto } from 'Api/Features/Bbot/Dtos/CreateBbotStripePaymentMethodResponseDto';
import { CreateBbotStripeSetupIntentRequestDto } from 'Api/Features/Bbot/Dtos/CreateBbotStripeSetupIntentRequestDto';
import { CreateBbotStripeSetupIntentResponseDto } from 'Api/Features/Bbot/Dtos/CreateBbotStripeSetupIntentResponseDto';
import { GetBbotApiInfoResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotApiInfoResponseDto';
import { GetBbotFulfillableMenuItemsResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotFulfillableMenuItemsResponseDto';
import { GetBbotRestaurantsRequestDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantsRequestDto';
import { GetBbotRestaurantsResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantsResponseDto';
import { GetBbotRestaurantTablesRequestDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantTablesRequestDto';
import { GetBbotRestaurantTablesResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantTablesResponseDto';
import { GetBbotStripePaymentMethodsRequestDto } from 'Api/Features/Bbot/Dtos/GetBbotStripePaymentMethodsRequestDto';
import { GetBbotStripePaymentMethodsResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotStripePaymentMethodsResponseDto';
import { GetBbotTableMenusRequestDto } from 'Api/Features/Bbot/Dtos/GetBbotTableMenusRequestDto';
import { GetBbotTableMenusResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotTableMenusResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class BbotProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getBbotApiInfo(): Promise<GetBbotApiInfoResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-api-info",
            null,
            null
        );

        const data: GetBbotApiInfoResponseDto | null = await this.httpClient.get<GetBbotApiInfoResponseDto | null>(uri);
        return data;
    }

    public async getBbotRestaurants(request: GetBbotRestaurantsRequestDto | null): Promise<GetBbotRestaurantsResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-restaurants",
            null,
            { ...request || undefined }
        );

        const data: GetBbotRestaurantsResponseDto | null = await this.httpClient.get<GetBbotRestaurantsResponseDto | null>(uri);
        return data;
    }

    public async getBbotRestaurantTables(restaurantId: string | null, request: GetBbotRestaurantTablesRequestDto | null): Promise<GetBbotRestaurantTablesResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-restaurants/{restaurantId}/tables",
            { restaurantId: restaurantId },
            { ...request || undefined }
        );

        const data: GetBbotRestaurantTablesResponseDto | null = await this.httpClient.get<GetBbotRestaurantTablesResponseDto | null>(uri);
        return data;
    }

    public async getBbotTableMenus(restaurantId: string | null, tableId: string | null, request: GetBbotTableMenusRequestDto | null): Promise<GetBbotTableMenusResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-restaurants/{restaurantId}/tables/{tableId}/menus",
            { restaurantId: restaurantId, tableId: tableId },
            { ...request || undefined }
        );

        const data: GetBbotTableMenusResponseDto | null = await this.httpClient.get<GetBbotTableMenusResponseDto | null>(uri);
        return data;
    }

    public async getBbotFulfillableMenuItems(restaurantId: string | null, tableId: string | null): Promise<GetBbotFulfillableMenuItemsResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-restaurants/{restaurantId}/tables/{tableId}/fulfillable-menu-items",
            { restaurantId: restaurantId, tableId: tableId },
            null
        );

        const data: GetBbotFulfillableMenuItemsResponseDto | null = await this.httpClient.get<GetBbotFulfillableMenuItemsResponseDto | null>(uri);
        return data;
    }

    public async createBbotPriceCheck(restaurantId: string | null, tableId: string | null, request: CreateBbotPriceCheckRequestDto | null): Promise<CreateBbotPriceCheckResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-restaurants/{restaurantId}/tables/{tableId}/price-checks",
            { restaurantId: restaurantId, tableId: tableId },
            null
        );

        const data: CreateBbotPriceCheckResponseDto | null = await this.httpClient.post<CreateBbotPriceCheckRequestDto | null, CreateBbotPriceCheckResponseDto | null>(uri, request);
        return data;
    }

    public async createBbotOrder(request: CreateBbotOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bbot-orders",
            null,
            null
        );

        await this.httpClient.post<CreateBbotOrderRequestDto | null, void>(uri, request);
    }

    public async createBbotStripeSetupIntent(request: CreateBbotStripeSetupIntentRequestDto | null): Promise<CreateBbotStripeSetupIntentResponseDto | null> {
        const uri = this.buildUri(
            "/bbot-stripe-setup-intents",
            null,
            null
        );

        const data: CreateBbotStripeSetupIntentResponseDto | null = await this.httpClient.post<CreateBbotStripeSetupIntentRequestDto | null, CreateBbotStripeSetupIntentResponseDto | null>(uri, request);
        return data;
    }

    public async getBbotStripePaymentMethods(userId: string, request: GetBbotStripePaymentMethodsRequestDto | null): Promise<GetBbotStripePaymentMethodsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/bbot-stripe-payment-methods",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetBbotStripePaymentMethodsResponseDto | null = await this.httpClient.get<GetBbotStripePaymentMethodsResponseDto | null>(uri);
        return data;
    }

    public async createBbotStripePaymentMethod(userId: string, request: CreateBbotStripePaymentMethodRequestDto | null): Promise<CreateBbotStripePaymentMethodResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/bbot-stripe-payment-methods",
            { userId: userId },
            null
        );

        const data: CreateBbotStripePaymentMethodResponseDto | null = await this.httpClient.post<CreateBbotStripePaymentMethodRequestDto | null, CreateBbotStripePaymentMethodResponseDto | null>(uri, request);
        return data;
    }
}