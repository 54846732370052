import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Affix } from 'antd';
import { ClickParam } from 'antd/es/menu';
import { useLocation, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { images, theme } from '../../variant';
import {
    Location,
    Company as CompanyIcon,
    Users,
    StaffUser as StaffIcon,
    LogOut,
    Invoice as InvoiceIcon,
    Gear,
    CircleCheckmark,
} from 'Components/icons';
import { useStores } from 'Hooks';
import { Link } from 'react-router-dom';
import './side-navigation.less';
import { SIDER_MENU_INDENT, SIDER_WIDTH } from 'Models/Constants';

const { Sider } = Layout;

const SideNavigation: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const {
        authenticationStore,
        confirmationModalStore,
        navigationStore,
        userStore
    } = useStores();

    navigationStore.setLocation(location.pathname);

    const onMenuItemClicked = (param: ClickParam): void => {
        history.push(param.key);
    };

    const logout = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LogOut />,
                title: t('LogOut.log_out_confirm_title'),
                message: t('LogOut.log_out_confirm_message'),
                positiveText: t('LogOut.log_out_confirm_positive'),
                negativeText: t('LogOut.log_out_confirm_negative'),
            }))
        )
            return;

        authenticationStore.clearSession();
        history.replace('/');
    };

    const fillColor = (menuItemLocation: string): string => {
        if (location.pathname === menuItemLocation) {
            return theme['white'];
        } else {
            return theme['secondary-color'];
        }
    };

    const selectedMenuItem = (pathname: string): string => {
        if (navigationStore.submenuPath) {
            return navigationStore.submenuPath;
        }
        return pathname;
    };

    const subMenu = (active = true): JSX.Element | null => {
        return active ? (
            <Menu.Item
                level={2}
                key={navigationStore.submenuPath}
                icon={<span className="anticon" />}
            >
                {navigationStore.submenu}
            </Menu.Item>
        ) : null;
    };

    return (
        <Sider
            className="SideNavigation no-select"
            width={SIDER_WIDTH}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <Link to="/">
                <div className="logo">
                    <img src={images.logoWhite} alt={t('brand_name')} />
                </div>
            </Link>

            <Menu
                theme="dark"
                defaultSelectedKeys={['/locations']}
                mode="inline"
                selectedKeys={[selectedMenuItem(location.pathname)]}
                onClick={onMenuItemClicked}
                inlineIndent={SIDER_MENU_INDENT}
            >
                <Menu.Item key="/locations" icon={<Location fill={fillColor('/locations')} />}>
                    {t('Location.location_title')}
                </Menu.Item>
                {subMenu(navigationStore.subIsLocation)}

                <Menu.Item key="/companies" icon={<CompanyIcon fill={fillColor('/companies')} />}>
                    {t('Company.companies')}
                </Menu.Item>
                {subMenu(navigationStore.subIsCompany)}

                <Menu.Item key="/users" icon={<Users fill={fillColor('/users')} />}>
                    {t('User.users')}
                </Menu.Item>
                {subMenu(navigationStore.subIsUser)}

                <Menu.Item key="/invoices" icon={<InvoiceIcon fill={fillColor('/invoices')} />}>
                    {t('Invoices.invoices_title')}
                </Menu.Item>
                {subMenu(navigationStore.subIsInvoice)}

                {userStore.isAdmin && (
                    <Menu.Item
                        key="/staff-accesses"
                        icon={<StaffIcon fill={fillColor('/staff-accesses')} />}
                    >
                        {t('Administration.administration_title')}
                    </Menu.Item>
                )}

                {userStore.isAdmin && subMenu(navigationStore.subIsStaff)}

                {userStore.isAdmin && (
                    <Menu.Item key="/settings" icon={<Gear fill={fillColor('/settings')} />}>
                        {t('settings')}
                    </Menu.Item>
                )}

                {userStore.isAdmin && subMenu(navigationStore.subIsAmenityCategories)}
                {userStore.isAdmin && subMenu(navigationStore.subIsAmenityCategory)}

                <Menu.Item key="/check-ins" icon={<CircleCheckmark fill={fillColor('/check-ins')} />}>
                    {t('CheckIn.check_in_title')}
                </Menu.Item>
            </Menu>

            <Affix offsetBottom={0.5} style={{ marginTop: 'auto' }}>
                <Menu
                    className="log-out"
                    theme="dark"
                    mode="inline"
                    onSelect={logout}
                    selectedKeys={['']}
                    inlineIndent={SIDER_MENU_INDENT}
                >
                    <Menu.Item key={'logout'} icon={<LogOut fill={theme['secondary-color']} />}>
                        {t('LogOut.log_out')}
                    </Menu.Item>
                </Menu>
            </Affix>
        </Sider>
    );
});

export default SideNavigation;
