import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { FoodHot as FoodCategoriesIcon } from 'Components/icons';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption, disableActionMenuItem } from 'Components/action-menu/action-menu';
import { useSearchDebounce, useService } from 'Hooks';
import { Layout, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { TdWithImage } from 'Components/td-with-image';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TableFilters } from 'Components/table-filters';
import { autorun } from 'mobx';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import FilterStore from 'Stores/FilterStore';
import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';
import { MIN_ITEMS_FOR_REORDER, PAGE_SIZE, TABLE_COL_ORDER_DEFAULT_WIDTH } from 'Models/Constants';
import FoodCategoryModal from './food-category-modal';
import { GetFoodCategoriesSortColumnDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesSortColumnDto';
import { FoodCategory } from 'Models/FoodCategory/FoodCategory';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import ReorderFoodCategoriesModal from './reorder-food-categories-modal';
import { FoodMerchantContext } from '../index';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const FoodCategories: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, merchantId } = useParams();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const filterStoreRef = useRef(new FilterStore());
    const foodCategoryService = useService(FoodCategoryService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<FoodCategory[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const [actions, setActions] = useState<ActionMenuOption[]>([
        {
            key: 'create',
            title: t('Food.food_and_beverages_categories_create'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('change_ordering'),
            action: (): void => setReorderModalOpen(true),
        },
    ]);

    const pageTitle = t('Food.merchant_categories_pagetitle', {param1: foodMerchant?.name});

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'merchants',
            nameKey: 'Food.food_and_beverages_merchant',
        },
        {
            path: foodMerchant?.id || '',
            name: foodMerchant?.name || '',
        },
        {
            path: 'categories',
            nameKey: 'Food.merchant_categories_breadcrumb',
        },
    ];

    const getTableColumnsSortHandler = () => {
        return (catA: FoodCategory, catB: FoodCategory): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<FoodCategory>,
        columnKey: GetFoodCategoriesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<FoodCategory>
    ): ColumnType<FoodCategory>[] => {
        return [
            {
                key: GetFoodCategoriesSortColumnDto.Order,
                title: t('Table.column_order'),
                dataIndex: 'order',
                render: (key: string): string => key || '0',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetFoodCategoriesSortColumnDto.Order
                ),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                key: GetFoodCategoriesSortColumnDto.Name,
                title: t('Table.column_category'),
                render: (foodCategory: FoodCategoryDto): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<FoodCategoriesIcon fill={theme['white']} />}
                        imgSrc={foodCategory?.imageUrl}
                    >
                        {foodCategory?.name}
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetFoodCategoriesSortColumnDto.Name
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<FoodCategory> = {
        columnKey: GetFoodCategoriesSortColumnDto.Order as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<FoodCategory>
    ): [GetFoodCategoriesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetFoodCategoriesSortColumnDto = tableSort.columnKey as GetFoodCategoriesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<FoodCategory>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetFoodCategoriesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
        }) => {
            setLoading(true);
            try {
                const [items, totalItems] = await foodCategoryService.getFoodCategories({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    merchantIds: [merchantId],
                    searchTerm: params.searchTerm,
                });

                setData(items);

                // disable reorder option
                setActions( value =>
                    disableActionMenuItem(value, 'reorder', items.length < MIN_ITEMS_FOR_REORDER)
                );

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [foodCategoryService, merchantId]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<FoodCategory> | SorterResult<FoodCategory>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);

        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    const onRowClick = (foodCategoryId: string | null): void => {
        if (foodCategoryId) {
            history.push(
                `/locations/${id}/management/merchants/${merchantId}/categories/${foodCategoryId}`
            );
        }
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                fetch({
                    pagination: pagination,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                });
            }
        },
        [fetch, pagination, defaultColumnSort, defaultDirectionSort]
    );

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: pagination,
            sortColumn: defaultColumnSort,
            sortDirection: defaultDirectionSort,
            searchTerm: filterStore.searchTerm,
        });
        setReorderModalOpen(false);
    };

    return (
        <div className="ConferenceRooms">
            <LocationHeader
                title={pageTitle}
                subTitle={t('Food.food_and_beverages_categories_subtitle')}
                defaultImg={<FoodCategoriesIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: FoodCategoryDto): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: FoodCategoryDto) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <FoodCategoryModal
                    visible={createModalOpen}
                    onComplete={onCreationComplete}
                    merchantId={merchantId}
                />
            )}
            {reorderModalOpen && (
                <ReorderFoodCategoriesModal
                    visible={reorderModalOpen}
                    onComplete={onReorderComplete}
                />
            )}
        </div>
    );
};

export default FoodCategories;
