import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Select, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import debounce from 'lodash.debounce';

import './create-manager.less';
import { Close, User as UserIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import {
    CreateManagerUserSchema,
    EditManagerUserSchema,
    CreateManagerUserFromExistingUserSchema,
} from 'Schemas';
import { useFormValidation, useService, useStores } from 'Hooks';
import { ManagementRoleNameDto } from 'Api/Features/Users/Dtos/ManagementRoleNameDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { MemberService } from 'Services/MemberService';
import { UserService } from 'Services/UserService';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserRequestRoleDto } from 'Api/Features/Users/Dtos/CreateUserRequestRoleDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { DEBOUNCE_DELAY } from 'Models/Constants';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';

const { Option } = Select;
const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];
const titleGutter: [Gutter, Gutter] = [0, 0];

interface ManagerUserModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    managerUser?: UserDto;
}

const ManagerUserModal: FunctionComponent<ManagerUserModalProps> = ({
    visible,
    onComplete,
    managerUser,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [existingUserId, setExistingUserId] = useState<string>();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        managerUser
            ? EditManagerUserSchema
            : existingUserId
            ? CreateManagerUserSchema
            : CreateManagerUserFromExistingUserSchema,
        form
    );
    const [userSearchResults, setUserSearchResults] = useState<UserDto[]>([]);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const memberService = useService(MemberService);
    const userService = useService(UserService);
    const [selectedManagementRole, setSelectedManagementRole] = useState<ManagementRoleNameDto | undefined>(
        managerUser ? managerUser.managementRoles?.[0]?.name : undefined
    );
    const [selectedLocations, setSelectedLocations] = useState<string[] | undefined>();
    //#endregion

    useEffect(() => {
        if (managerUser) {
            form.setFieldsValue({
                firstName: managerUser.firstName,
                lastName: managerUser.lastName,
                'contactInfo.email': managerUser.contactInfo?.email,
                managementRoles: managerUser.managementRoles?.[0]?.name,
            });
            const locationIds = managerUser.managementRoles
                ?.filter((role) => role?.context?.['locationId'] !== undefined)
                .map((role) => role?.context?.['locationId']!);
            setSelectedLocations(locationIds);
        }
    }, [managerUser, form]);
    //#endregion

    //#region Event handlers
    const searchUsers = useCallback(
        async (value: string, callback: (results: UserDto[]) => void): Promise<void> => {
            const [items] = await memberService.getMembers({
                pageSize: 10,
                page: 0,
                searchTerm: value,
            });
            callback(items);
        },
        [memberService]
    );

    const debouncedSearch = debounce(searchUsers, DEBOUNCE_DELAY);
    const handleSearch = (value: string): void => {
        if (value) {
            debouncedSearch(value, (results: UserDto[]) => {
                setUserSearchResults(results);
            });
        } else {
            setUserSearchResults([]);
        }
    };

    const handleUserSelect = (value: string): void => {
        const chosenUser = userSearchResults.find((user) => user.id === value);
        if (chosenUser !== undefined) {
            setExistingUserId(chosenUser.id!);
            form.setFieldsValue({
                firstName: chosenUser.firstName,
                lastName: chosenUser.lastName,
                'contactInfo.email': chosenUser.contactInfo?.email,
            });
        }
    };
    //#endregion

    const locationOptions: SelectCustomOption[] = locationStore.locations.map((location) => ({
        value: location.id!,
        label: location.name!,
    }));

    //#region Submit / Exit
    const dismiss = (success: boolean): void => {
        onComplete(success);
        form.resetFields();
        setUserSearchResults([]);
        setExistingUserId(undefined);
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `ManagerUser.manager_user_confirm_positive${managerUser ? '_edit' : ''}`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss(false);
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t(`ManagerUser.staff_profile_success`),
                message: t(`ManagerUser.staff_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const submit = async (values: Store): Promise<void> => {
        const baseModel = {
            firstName: values.firstName,
            lastName: values.lastName,
            managementRoles: values.managementRoles,
            locationIds: selectedLocations
        };
        let model = {};
        if (managerUser !== undefined)
            model = {
                ...baseModel,
                password: values.password,
                newPassword: values.password,
                confirmPassword: values.confirmPassword,
                contactInfo: { email: managerUser.contactInfo?.email },
            } as UpdateUserRequestDto;
        else
            model = {
                ...baseModel,
                password: values.password,
                confirmPassword: values.confirmPassword,
                contactInfo: {
                    email: values['contactInfo.email'],
                },
            } as CreateUserRequestDto;

        if (!(await validateForm(model, false))) return;
        
        try {
            globalLoadingStore.addLoading();
            model = {
                ...model,
                managementRoles:
                    baseModel.managementRoles === ManagementRoleNameDto.Administrator
                        ? ([
                              {
                                  name: baseModel.managementRoles,
                                  context: {},
                              } as CreateUserRequestRoleDto,
                          ] as CreateUserRequestRoleDto[])
                        : baseModel.locationIds?.map(
                              (locationId) =>
                                  ({
                                      name: baseModel.managementRoles,
                                      context: {
                                          locationId: locationId,
                                      },
                                  } as CreateUserRequestRoleDto)
                          ),
            };
            if (managerUser !== undefined) {
                await userService.editUser(managerUser.id!, model);
            } else if (!existingUserId) {
                await userService.createUser(model);
            } else if (existingUserId !== undefined) {
                await userService.createManagerUserFromExistingUser(existingUserId, model);
            }
            toastStore.toast({
                type: 'success',
                messageKey: `ManagerUser.manager_user_${managerUser ? 'edit' : 'create'}_success`,
            });
            success();
        } catch (error) {
            const errors = new Map<string, string[]>();
            if (error.response?.data?.errors?.['password'] !== undefined) {
                errors.set('password', [error.response?.data.errors['password'][0].description]);
                setErrors(errors);
            } else if (error.response?.data?.errors?.['confirmPassword'] !== undefined) {
                errors.set('confirmPassword', [
                    error.response?.data.errors['confirmPassword'][0].description,
                ]);
                setErrors(errors);
            } else if (error.response?.data?.errors?.['contactInfo.Email'] !== undefined) {
                errors.set('contactInfo.email', [
                    error.response?.data.errors['contactInfo.Email'][0].description,
                ]);
                setErrors(errors);
            } else if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <Modal
            visible={visible}
            centered
            title={t(`ManagerUser.manager_user_${managerUser ? 'edit' : 'create'}`)}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateManager">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    {!managerUser ? (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    label={t('ManagerUser.existing_user')}
                                >
                                    <Select
                                        value={existingUserId}
                                        showSearch
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        onChange={handleUserSelect}
                                        placeholder={t('SelectCustom.placeholder_managers')}
                                    >
                                        {/*
                                         * TODO: Find an alternative for searchable dropdowns,
                                         *       Antd's one doesn't scroll properly when items are higher than it's hardcoded value.
                                         */}
                                        {userSearchResults.map((user) => (
                                            <Option key={user.id!} value={user.id!}>
                                                {`${user.firstName} ${user.lastName}`}
                                                <br />
                                                {user.contactInfo?.email}
                                            </Option>
                                        ))}
                                    </Select>
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    ) : null}
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('User.first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('User.last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {managerUser === undefined && (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name={'contactInfo.email'}
                                    label={t('User.email')}
                                    required
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    {(!existingUserId || managerUser) && (
                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="password"
                                    label={t('User.password')}
                                    required
                                >
                                    <Input.Password />
                                </ValidatedFormItem>
                            </Col>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="confirmPassword"
                                    label={t('User.reset_confirm_password')}
                                    required
                                >
                                    <Input.Password />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('ManagerUser.management_role')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="managementRoles"
                                label={t('ManagerUser.management_role')}
                                required
                            >
                                <Select
                                    onChange={(value): void =>
                                        setSelectedManagementRole(value as ManagementRoleNameDto)
                                    }
                                >
                                    {Object.keys(ManagementRoleNameDto).map((role) => (
                                        <Option key={role} value={role}>
                                            {t(`ManagerUser.ManagementRoleNameDto_${role}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {selectedManagementRole === ManagementRoleNameDto.BuildingManager && (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="locationIds"
                                    label={t('buildings')}
                                    required
                                >
                                    <SelectCustom
                                    isMulti
                                    options={locationOptions}
                                    strongLabel={true}
                                    onMultiChange={(options: SelectCustomOption[]): void =>
                                        setSelectedLocations(
                                            options.map((option) => option.value)
                                        )
                                    }
                                    hideSelectedOptions={false}
                                    selected={selectedLocations}
                                />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(ManagerUserModal);
