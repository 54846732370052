import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './activation-email-status-tag.less';

export interface InvitationStatusTagProps {
    status: ActivationEmailStatus;
}

export enum ActivationEmailStatus {
    Sent = 'Sent',
    NotSent = 'NotSent'
}

const ActivationEmailStatusTag: FunctionComponent<InvitationStatusTagProps> = ({status}) => {
    const { t } = useTranslation();
    let className;
    
    switch(status) {
        case ActivationEmailStatus.Sent:
            className = 'primary'
            break;
        case ActivationEmailStatus.NotSent:
            className = 'grey'
            break;
    }

    return <span className={`ActivationEmailStatusTag ${className}`}>{t(`User.activation_email_status_${status}`)}</span>;
};

export default ActivationEmailStatusTag;
