import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { FoodPromotionProxy } from 'Api/Features/FoodPromotions/FoodPromotionProxy';
import { GetFoodPromotionsResponseDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsResponseDto';
import { GetFoodPromotionsRequestDto } from 'Api/Features/FoodPromotions/Dtos/GetFoodPromotionsRequestDto';
import { CreateFoodPromotionRequestDto } from 'Api/Features/FoodPromotions/Dtos/CreateFoodPromotionRequestDto';
import { UpdateFoodPromotionRequestDto } from 'Api/Features/FoodPromotions/Dtos/UpdateFoodPromotionRequestDto';
import { FoodPromotionDto } from 'Api/Features/FoodPromotions/Dtos/FoodPromotionDto';
import { FoodPromotion } from 'Models/FoodPromotion/FoodPromotion';

@inject(FoodPromotionProxy)
export class FoodPromotionService extends ApiService {
    constructor(private readonly foodItemProxy: FoodPromotionProxy) {
        super();
    }

    public async getFoodPromotion(id: string): Promise<FoodPromotion | null> {
        const response: FoodPromotionDto | null = await this.foodItemProxy.getFoodPromotion(id);
        return response ? new FoodPromotion(response) : null;
    }

    public async getFoodPromotions(
        request?: GetFoodPromotionsRequestDto
    ): Promise<[FoodPromotion[], number]> {
        const response: GetFoodPromotionsResponseDto | null = await this.foodItemProxy.getFoodPromotions(
            request || null
        );
        return [
            response?.items?.map((dto: FoodPromotionDto | null) => new FoodPromotion(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createFoodPromotion(request: CreateFoodPromotionRequestDto): Promise<void> {
        await this.foodItemProxy.createFoodPromotion(request);
    }

    public async updateFoodPromotion(
        request: UpdateFoodPromotionRequestDto,
        id: string
    ): Promise<void> {
        await this.foodItemProxy.updateFoodPromotion(id, request);
    }

    public async deleteFoodPromotion(id: string): Promise<void> {
        await this.foodItemProxy.deleteFoodPromotion(id);
    }
}
