import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Close, User as UserIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { CreateMessageSchema } from 'Schemas';
import { UserService } from 'Services/UserService';
import { UserDetails } from 'Models/Users/UserDetails';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';
import { MessageLinkedEntity } from 'Models/Messages/MessageLinkedEntity';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './create-message.less';
import { UserMessageTypes } from 'Models/Messages/UserMessageTypes';

const formGutter: [Gutter, Gutter] = [40, 0];

interface MessageModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: UserDetails;
    title?: string;
    linkedEntity?: MessageLinkedEntity;
    defaultMessageType: UserMessageTypes;
    messageTypeDisabled?: boolean;
}

const CreateUserMessageModal: FunctionComponent<MessageModalProps> = ({
    visible,
    onComplete,
    user,
    title,
    linkedEntity,
    defaultMessageType,
    messageTypeDisabled,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateMessageSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const userService = useService(UserService);
    const [contentState, setContentState] = useState('');
    //#endregion

    const packageDeliveryPlaceholders = useCallback((): void => {
        form.setFieldsValue({
            title: t('Message.package_delivery_default_title'),
            body: t('Message.package_delivery_default_message'),
        });
        setContentState(t('Message.package_delivery_default_message'));
    }, [form, t]);

    useEffect(() => {
        if (defaultMessageType === UserMessageTypes.PackageDelivery) {
            packageDeliveryPlaceholders();
        }
    }, [defaultMessageType, packageDeliveryPlaceholders]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        resetErrors();
        onComplete(success);
    };

    const submit = async (values: Store): Promise<void> => {
        const newMessageData: CreateMessageRequestDto = {
            receiverType: MessageReceiverTypeDto.User,
            receiverId: user?.id,
            type: values.type ?? defaultMessageType,
            entityType: linkedEntity?.entityType || null,
            entityId: linkedEntity?.entityId || null,
            title: title ? title : values.title,
            body: values.body,
        };

        if (!(await validateForm(newMessageData, false))) return;
        try {
            globalLoadingStore.addLoading();
            await userService.sendUserMessage(newMessageData);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('Message.message_sent'),
                message: t('Message.message_sent_message'),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('Message.message_send_cancel_title'),
                message: t('Message.message_send_cancel_message'),
                positiveText: t('Message.message_send_cancel_positive'),
                negativeText: t('Message.message_send_cancel_negative'),
            }))
        )
            return;
        dismiss(false);
    };

    const handleMessageContentChange = (value: string): void => {
        form.setFieldsValue({ body: value });
    };

    const messageTypeOptions = (): ReactNode => {
        return Object.values(UserMessageTypes).map((type) => (
            <Select.Option key={type} value={type}>
                {t(`MessageNotificationType.${type}`)}
            </Select.Option>
        ));
    };

    const handleTypeChange = (value: UserMessageTypes): void => {
        if (value === UserMessageTypes.PackageDelivery) {
            packageDeliveryPlaceholders();
        }
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t('Message.message_send_message')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateMessage">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="type"
                                label={t('Message.message_type')}
                                required
                            >
                                <Select
                                    disabled={messageTypeDisabled}
                                    defaultValue={defaultMessageType}
                                    onChange={(value): void => handleTypeChange(value)}
                                >
                                    {messageTypeOptions()}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="title"
                                label={t('Message.message_title')}
                                required={!title}
                            >
                                <Input disabled={!!title} defaultValue={title || ''} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="username"
                                label={t('User.tenant_user')}
                            >
                                <Input disabled defaultValue={user?.name || ''} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="body"
                                label={t('Message.message_content')}
                                className="body"
                                required
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={contentState}
                                onChange={handleMessageContentChange}
                            />
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Message.message_send')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(CreateUserMessageModal);
