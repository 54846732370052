import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { BbotProxy } from 'Api/Features/Bbot/BbotProxy';
import { GetBbotRestaurantsRequestDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantsRequestDto';
import { GetBbotRestaurantsResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotRestaurantsResponseDto';
import { BbotRestaurantDto } from 'Api/Features/Bbot/Dtos/BbotRestaurantDto';
import { GetBbotApiInfoResponseDto } from 'Api/Features/Bbot/Dtos/GetBbotApiInfoResponseDto';

@inject(BbotProxy)
export class BbotService extends ApiService {
    constructor(private readonly bbotProxy: BbotProxy) {
        super();
    }

    public async getBbotRestaurants(request: GetBbotRestaurantsRequestDto | null): Promise<[BbotRestaurantDto[], number]> {
        const response: GetBbotRestaurantsResponseDto | null = await this.bbotProxy.getBbotRestaurants(request);
        return [
            response?.items?.filter(item => item !== null).map(item => item!) || [],
            response?.totalItemCount || 0
        ]
    }

    public async getBbotApiInfo(): Promise<GetBbotApiInfoResponseDto | null> {
        const data: GetBbotApiInfoResponseDto | null = await this.bbotProxy.getBbotApiInfo();
        return data;
    }
}
