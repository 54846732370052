import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row'; 
import { Close, Waiver } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import './edit-verdocs-waiver-modal.less'
import { images } from 'variant';
import { VerdocsService } from 'Services/VerdocsService';
import { GetVerdocsTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsTemplatesRequestDto';
import { VerdocsTemplateDto } from 'Api/Features/Verdocs/Dtos/VerdocsTemplateDto';
import { VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED } from 'Models/Constants';
import { VerdocsMandatoryDocumentTemplateDto } from 'Api/Features/Verdocs/Dtos/VerdocsMandatoryDocumentTemplateDto';
import { CreateVerdocsMandatoryDocumentTemplateRequestDto } from 'Api/Features/Verdocs/Dtos/CreateVerdocsMandatoryDocumentTemplateRequestDto';
import { EditVerdocsMandatoryWaiverSchema } from 'Schemas/EditVerdocsMandatoryWaiverSchema';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditVerdocsWaiverModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    document?: VerdocsMandatoryDocumentTemplateDto | null;
}

const { Option } = Select;

const EditVerdocsWaiverModal: FunctionComponent<EditVerdocsWaiverModalProps> = ({
    visible,
    onComplete,
    document,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditVerdocsMandatoryWaiverSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const verdocsService = useService(VerdocsService);
    const [availableVerdocsTemplates, setAvailableVerdocsTemplates] = useState<VerdocsTemplateDto[]>();
    const [templatesFetched, setTemplatesFetched] = useState(false);
    //#endregion

    const fetchTemplates = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const response = await verdocsService.getVerdocsTemplates({

            } as GetVerdocsTemplatesRequestDto);
            if(response?.items) {
                setAvailableVerdocsTemplates(
                    response.items?.filter((item) => item !== null).map((item) => item!)
                );
            }
            setTemplatesFetched(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, verdocsService])

    //#region Effects
    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates])

    useEffect(() => {
        if(templatesFetched) {
            if(document) {
                form.setFieldsValue({
                    templateId: document.templateId
                });
            }
            else {
                form.setFieldsValue({
                    templateId: VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED
                })
            }
        }
    }, [document, form, templatesFetched])
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Waiver />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`Verdocs.waiver_edit_confirm_positive`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const confirmSubmit = async (): Promise<boolean> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Waiver />,
                title: t(`are_you_sure_you_want_to_continue`),
                message: t('Verdocs.verdocs_waiver_explanation'),
                positiveText: t(`yes_im_sure`),
                negativeText: t(`no_go_back`),
            }))
        )
            return false

        return true;
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        if(formValues.templateId !== VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED) {
            if(!await confirmSubmit()) {
                return;
            }
        }
        
        const data: CreateVerdocsMandatoryDocumentTemplateRequestDto = {
            templateId: formValues.templateId,
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();

            if(data.templateId === VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED && document?.id) {
                await verdocsService.deleteVerdocsMandatoryDocumentTemplate(document.id);
            }
            else if(data.templateId !== VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED) {
                await verdocsService.createVerdocsMandatoryDocumentTemplate(data);
            }
            
            toastStore.toast({
                type: 'success',
                messageKey: `Verdocs.waiver_edit_success`,
            });
            dismiss(true);
        } catch (error) {
            if(error.response?.data?.errorCode === "E026002"){
                toastStore.toast({
                    type: 'error',
                    message: error.response.data.errorDescription
                });
            }
            else if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const selectOptions = useCallback(() => {
        let options = [
            <Option
                key={VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED}
                value={VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED}
            >
                {VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED}
            </Option>,
        ];
        
        if (availableVerdocsTemplates) {
            options = options.concat(
                availableVerdocsTemplates?.map((item) => (
                    <Option key={item.id ?? ''} value={item.id ?? ''}>
                        {item.name}
                    </Option>
                ))
            );
        }
       
        return options;
    }, [availableVerdocsTemplates]);

    return (
        <Modal
            visible={visible}
            centered
            title={t('Verdocs.edit_mandatory_waiver')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="EditVerdocsWaiverModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter} className="value-container">
                        <Col span={12} offset={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="templateId"
                                label={t('Verdocs.choose_verdocs_template')}
                            >
                                <Select placeholder={`${t('Verdocs.choose_verdocs_template')}...`}>
                                    {selectOptions()}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <div style={{ backgroundImage: `url(${images.verdocsLogo}`, width: '120px', height: '31px' }}/>
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(EditVerdocsWaiverModal);
