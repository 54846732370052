import React, { FunctionComponent, useState, useContext, useCallback, useEffect } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    FoodHot as FoodCategoriesIcon,
    FoodSandwich as FoodItemsIcon,
    FoodEntree as FoodOrderIcon,
    Delete,
    FoodSalad as FoodMerchantIcon,
    Promotion as FoodPromotionsIcon,
} from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Button, Col, Layout, Row, Typography } from 'antd';
import FoodMerchantModal from '../../food-merchant-modal';
import { FoodMerchantContext } from '../index';
import { FoodMerchantService } from 'Services/FoodMerchantService';
import { htmlContainsText } from 'Utils';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { FoodMerchant } from 'Models/FoodMerchant/FoodMerchant';
import { BbotService } from 'Services/BbotService';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

interface FoodMerchantDetailProps {
    showNavigation?: boolean;
}

const FoodMerchantDetail: FunctionComponent<FoodMerchantDetailProps> = ({ showNavigation }) => {
    const { t } = useTranslation();
    const { id, merchantId } = useParams<{ id: string; merchantId: string }>();
    const history = useHistory();
    const foodMerchantContext = useContext(FoodMerchantContext);
    const foodMerchantService = useService(FoodMerchantService);
    const bbotService = useService(BbotService);
    const { confirmationModalStore, globalLoadingStore, userStore } = useStores();
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [data, setData] = useState<FoodMerchant | undefined>();

    const deleteFoodMerchant = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Food.delete_merchant_confirm_title`),
                message: t(`Food.delete_merchant_confirm_message`),
                positiveText: t(`Food.delete_merchant_confirm_positive`),
                negativeText: t(`Food.delete_merchant_confirm_negative`),
            }))
        )
            return;

        await foodMerchantService.deleteFoodMerchant(merchantId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Food.merchant_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Food.merchant_delete'),
            action: (): Promise<void> => deleteFoodMerchant(),
        },
    ];

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        const breadcrumb = [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'merchants',
                nameKey: 'Food.food_and_beverages_merchant',
            },
            {
                path: foodMerchantContext?.id || '',
                name: foodMerchantContext?.name || '',
            },
        ];

        if (!showNavigation) {
            breadcrumb.push({
                path: 'details',
                nameKey: 'Food.merchant_details',
            });
        }

        return breadcrumb;
    };

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = [];
        if (data?.description) {
            description.push({
                title: data?.description,
            });
        }
        return description;
    }, [data]);

    useEffect(() => {
        if (data) {
            setDescription(getDescription);
        }
    }, [data, getDescription]);

    useEffect(() => {
        setData(foodMerchantContext);
    }, [foodMerchantContext]);

    const onEditComplete = async (): Promise<void> => {
        setEditModalOpen(false);

        // refetch data
        globalLoadingStore.addLoading();
        try {
            // call api
            const merchantData = await foodMerchantService.getFoodMerchant(merchantId);
            setData(merchantData || undefined);
            foodMerchantContext?.fetch();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const getBbotUrl = async(): Promise<string | null | undefined> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await bbotService.getBbotApiInfo();
            return response?.bbotConsoleUrl;
        } finally {
            globalLoadingStore.removeLoading();
        }
    }

    const onViewInBbotConsole = async (): Promise<void> => {
        const url = await getBbotUrl()
        if(url) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    };

    return (
        <div className="FoodMerchantsDetail">
            {foodMerchantContext && (
                <>
                    <LocationHeader
                        title={foodMerchantContext?.name || ''}
                        subTitle={<></>}
                        imgSrc={foodMerchantContext?.imageUrl}
                        defaultImg={<FoodMerchantIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={
                            (userStore.isAdmin ||
                                new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                                <ActionMenu options={actions} type="primary" trigger="click" />
                            )
                        }
                    />

                    {showNavigation ? (
                        <div className="LocationManagement">
                            <div className="column-container">
                                <Typography.Title level={2}>
                                    {t('Food.food_and_beverages')}
                                </Typography.Title>
                                <Link to={`${merchantId}/categories`}>
                                    <div className="nav-btn-container">
                                        <div className="nav-btn-content">
                                            <div className="nav-btn-icon-container">
                                                <FoodCategoriesIcon fill={theme['primary-color']} />
                                            </div>
                                            <Typography.Text className="nav-btn-text">
                                                {t('Food.merchant_categories')}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`${merchantId}/items`}>
                                    <div className="nav-btn-container">
                                        <div className="nav-btn-content">
                                            <div className="nav-btn-icon-container">
                                                <FoodItemsIcon fill={theme['primary-color']} />
                                            </div>
                                            <Typography.Text className="nav-btn-text">
                                                {t('Food.merchant_items')}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`${merchantId}/promotions`}>
                                    <div className="nav-btn-container">
                                        <div className="nav-btn-content">
                                            <div className="nav-btn-icon-container">
                                                <FoodPromotionsIcon fill={theme['primary-color']} />
                                            </div>
                                            <Typography.Text className="nav-btn-text">
                                                {t('Food.promotions_label')}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="column-container">
                                <Typography.Title level={2}>{t('Food.merchant')}</Typography.Title>
                                <Link to={`${merchantId}/details`}>
                                    <div className="nav-btn-container">
                                        <div className="nav-btn-content">
                                            <div className="nav-btn-icon-container">
                                                <FoodMerchantIcon fill={theme['primary-color']} />
                                            </div>
                                            <Typography.Text className="nav-btn-text">
                                                {t('Food.merchant_details')}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`${merchantId}/order-destinations`}>
                                    <div className="nav-btn-container">
                                        <div className="nav-btn-content">
                                            <div className="nav-btn-icon-container">
                                                <FoodOrderIcon fill={theme['primary-color']} />
                                            </div>
                                            <Typography.Text className="nav-btn-text">
                                                {t('Food.merchant_order_destinations')}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="column-container"></div>
                        </div>
                    ) : (
                        <Content>
                            <Row gutter={40}>
                                {description && htmlContainsText(data?.description || '') && (
                                    <Col span={12}>
                                        <SimpleList
                                            className="description"
                                            title={t('description')}
                                            data={description}
                                        />
                                    </Col>
                                )}

                                {data?.bbotRestaurant ? (
                                    <Col span={12}>
                                        <SimpleList title={t('BBOT')}>
                                            <div className="bbot">
                                                <div>
                                                    {t('Food.this_merchant')}{' '}
                                                    <span className="bold">
                                                        {t('Food.is_a_bbot_merchant')}
                                                    </span>
                                                </div>
                                                <div className="bbot-btn-container">
                                                    <Button
                                                        type="default"
                                                        className="bbot-btn"
                                                        onClick={onViewInBbotConsole}
                                                    >
                                                        {t('Food.go_to_bbot_console')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </SimpleList>
                                    </Col>
                                ) : undefined}
                            </Row>
                        </Content>
                    )}

                    {(userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) &&
                        editModalOpen && (
                            <FoodMerchantModal
                                visible={editModalOpen}
                                onComplete={onEditComplete}
                                foodMerchant={data}
                                locationId={id || undefined}
                            />
                        )}
                </>
            )}
        </div>
    );
};

export default FoodMerchantDetail;
