import React, { FunctionComponent, useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { FoodHot as FoodCategoriesIcon } from 'Components/icons';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateFoodCategorySchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';

import './food-category-modal.less';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { CreateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/CreateFoodCategoryRequestDto';
import { UpdateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/UpdateFoodCategoryRequestDto';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import ReactQuill from 'react-quill';

const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface FoodCategoryModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    merchantId?: string;
    foodCategory?: FoodCategoryDto;
}

const FoodCategoryModal: FunctionComponent<FoodCategoryModalProps> = ({
    visible,
    onComplete,
    merchantId,
    foodCategory,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodCategoryService = useService(FoodCategoryService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        CreateFoodCategorySchema, form
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (foodCategory) {
            form.setFieldsValue({
                name: foodCategory.name,
                description: foodCategory.description ?? undefined
            });
        }
    }, [foodCategory, form]);

    const getMainImage = (): UpdateFileRequestDto | null => {
        return mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodCategoriesIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(
                    `Food.category_leave_confirm_positive${
                        foodCategory !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodCategoriesIcon />,
                title: t(`Food.food_category_saved_success`),
                message: t(`Food.food_category_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const errorsHandler = (error: any): void => {
        let treated = false;
        const errors = new Map<string, string[]>();
        const responseErrors = error.response?.data?.errors;
        if (responseErrors && responseErrors['image.UploadBase64'] !== undefined) {
            treated = true;
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
        }
        setErrors(errors);

        if (!treated) {
            toastStore.displayError(error);
        }
    };

    const createFoodCategory = async (values: Store): Promise<void> => {
        const data: CreateFoodCategoryRequestDto = {
            name: values.name,
            description: values.description,
            image: getMainImage(),
            merchantId: merchantId
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodCategoryService.createFoodCategory(data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editFoodCategory = async (values: Store): Promise<void> => {
        const oldValues = foodCategory as UpdateFoodCategoryRequestDto;
        const data: UpdateFoodCategoryRequestDto = {
            name: values.name || oldValues.name,
            description: values.description,
            image: getMainImage(),
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodCategoryService.updateFoodCategory(data, foodCategory!.id as string);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (foodCategory !== undefined) {
            await editFoodCategory(values);
        } else {
            await createFoodCategory(values);
        }
    };

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                foodCategory !== undefined
                    ? t('Food.food_and_beverages_categories_edit')
                    : t('Food.food_and_beverages_categories_create')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="FoodCategoryModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ name: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('description')}
                                className="description"
                            >
                                <Input hidden/>
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={
                                    foodCategory?.description || form.getFieldValue('description') || null
                                }
                                onChange={handleDescriptionChange}
                            />
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        foodCategory?.imageUrl
                                            ? [
                                                  {
                                                      url: foodCategory?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default FoodCategoryModal;
