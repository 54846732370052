import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { InvoiceProxy } from 'Api/Features/Invoices/InvoiceProxy';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { Invoice } from 'Models/Invoices/Invoice';
import { InvoiceDetails } from 'Models/Invoices/InvoiceDetails';
import { GetCompanyInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetCompanyInvoicesRequestDto';
import { GetInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetInvoicesResponseDto';
import { InvoiceDetailsDto } from 'Api/Features/Invoices/Dtos/InvoiceDetailsDto';
import { ExportInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/ExportInvoicesRequestDto';
import { arrayBufferToPdf } from '../Utils';
import { GetUserInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetUserInvoicesRequestDto';
import { UserInvoice } from 'Models/Invoices/UserInvoice';
import { CompanyInvoice } from 'Models/Invoices/CompanyInvoice';
import { GetInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetInvoicesRequestDto';

@inject(InvoiceProxy)
export class InvoiceService extends ApiService {
    constructor(private readonly invoiceProxy: InvoiceProxy) {
        super();
    }

    public async getInvoice(invoiceId: string): Promise<InvoiceDetails | null> {
        const response: InvoiceDetailsDto | null = await this.invoiceProxy.getInvoice(invoiceId);
        return response ? new InvoiceDetails(response) : null;
    }

    public async getInvoices(
        request?: GetInvoicesRequestDto
    ): Promise<[Invoice[], number]> {
        const response: GetInvoicesResponseDto | null = await this.invoiceProxy.getInvoices(
            request || null
        );
        return [
            response?.items?.map((dto: InvoiceDto | null) => new Invoice(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getCompanyInvoices(
        companyId: string,
        request?: GetCompanyInvoicesRequestDto
    ): Promise<[Invoice[], number]> {
        const response: GetInvoicesResponseDto | null = await this.invoiceProxy.getCompanyInvoices(
            companyId,
            request || null
        );
        return [
            response?.items?.map((dto: InvoiceDto | null) => new CompanyInvoice(dto!, companyId)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getUserInvoices(
        userId: string,
        request?: GetUserInvoicesRequestDto
    ): Promise<[Invoice[], number]> {
        const response: GetInvoicesResponseDto | null = await this.invoiceProxy.getUserInvoices(
            userId,
            request || null
        );
        return [
            response?.items?.map((dto: InvoiceDto | null) => new UserInvoice(dto!, userId)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async exportInvoices(request: ExportInvoicesRequestDto | null): Promise<Blob> {
        const document = await this.invoiceProxy.exportInvoices(request);
        return arrayBufferToPdf(document);
    }

    public async refundInvoice(invoiceId: string): Promise<void> {
        await this.invoiceProxy.refundInvoice(invoiceId);
    }

    public async voidInvoice(invoiceId: string): Promise<void> {
        await this.invoiceProxy.voidInvoice(invoiceId);
    }

    public async closeInvoice(invoiceId: string): Promise<void> {
        await this.invoiceProxy.closeInvoice(invoiceId);
    }

    public async payInvoice(invoiceId: string): Promise<void> {
        await this.invoiceProxy.payInvoice(invoiceId);
    }


}
