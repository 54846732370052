import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import CompanyHeader from 'Components/company-header/company-header';
import { Company as CompanyIcon } from 'Components/icons';
import { Layout } from 'antd';
import { theme, images } from 'variant';
import { CompanyService } from 'Services/CompanyService';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import './index.less';

const { listSectionHeader } = images;

const CompanyAgreements: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    const { globalLoadingStore, navigationStore } = useStores();
    const [loading, setLoading] = useState(true);

    const { Content } = Layout;

    const { id } = useParams();
    const companyService = useService(CompanyService);
    const [company, setCompany] = useState<Company | undefined>(undefined);
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: company?.name || t('Company.company'),
        },
        {
            path: 'agreements',
            nameKey: 'Company.company_agreements',
        },
    ];
    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const companyData: CompanyDto | null = await companyService.getById(id);

            if (companyData) {
                const company: Company = new Company(companyData);
                setCompany(company);
                navigationStore.setSubmenu(company);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [companyService, id, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <>
            <CompanyHeader
                title={company?.name || ''}
                subTitle={t('Company.company_agreements_subtitle')}
                imgSrc={company?.imageUrl}
                defaultImg={<CompanyIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
            />
            <Layout>
                <Content> </Content>
            </Layout>
        </>
    );
});

export default CompanyAgreements;
