import React, {
    FunctionComponent,
    useCallback,
    useState,
    useEffect,
    useRef,
    ReactNode, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';
import { useSearchDebounce, useService } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import {
    ServiceRequest as ServiceRequestIcon,
    User as UserIcon,
    Company as CompanyIcon,
} from 'Components/icons';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { GetServiceRequestsRequestDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsRequestDto';
import { ServiceRequestStatusDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestStatusDto';
import { ServiceRequest } from 'Models/ServiceRequests/ServiceRequest';
import { GetServiceRequestsSortColumnDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { PAGE_SIZE, DATETIME_API_FORMAT, DATETIME_DISPLAY_FORMAT } from 'Models/Constants';
import { stripHtml } from 'Utils';
import CreateLocationServiceRequest from './create-request';
import { LocationContext } from '../index';
import './index.less';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: ServiceRequestStatusDto.Open,
                displayNameKey: 'ServiceRequest.request_open',
                checked: true,
            },
            {
                key: ServiceRequestStatusDto.Closed,
                displayNameKey: 'ServiceRequest.request_closed',
                checked: false,
            },
        ],
    },
];

const LocationServiceRequests: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const location = useContext(LocationContext);

    const history = useHistory();
    const locationService = useService(LocationService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState<ServiceRequest[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'service',
            name: t('Location.location_service_requests'),
        },
    ];
    //#endregion

    //#region Filters
    const getFilterGroupOptions = (filterGroup: string, omitAll = true): string[] => {
        const filterStore = filterStoreRef.current;
        return filterStore.getGroupOptions(filterGroup, omitAll);
    };
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (reqA: ServiceRequest, reqB: ServiceRequest): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<ServiceRequest>,
        columnKey: GetServiceRequestsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<ServiceRequest>
    ): ColumnType<ServiceRequest>[] => {
        return [
            {
                title: t('ServiceRequest.request_description'),
                render: (request: ServiceRequest): ReactNode | null => (
                    <div>{stripHtml(request?.description!)}</div>
                ),
            },
            {
                key: GetServiceRequestsSortColumnDto.UserName,
                title: t('User.user'),
                render: (request: ServiceRequest): ReactNode | null => {
                    return request.from ? (
                        <TdWithImage defaultImg={<UserIcon />} imgSrc={request.from.imageUrl}>
                            {request?.fromName}
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetServiceRequestsSortColumnDto.UserName
                ),
            },
            {
                key: GetServiceRequestsSortColumnDto.CompanyName,
                title: t('Company.company'),
                render: (request: ServiceRequest): ReactNode | null => {
                    const company = request.getCompany();
                    return request.company && request.company.name ? (
                        <TdWithImage defaultImg={<CompanyIcon />} imgSrc={company?.imageUrl}>
                            {company?.name}
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetServiceRequestsSortColumnDto.CompanyName
                ),
            },
            {
                key: GetServiceRequestsSortColumnDto.Date,
                title: t('ServiceRequest.request_date'),
                render: (request: ServiceRequest): ReactNode | null => (
                    <div>
                        {moment(request?.sendingDate, DATETIME_API_FORMAT).format(
                            DATETIME_DISPLAY_FORMAT
                        )}
                    </div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetServiceRequestsSortColumnDto.Date
                ),
            },
            {
                key: GetServiceRequestsSortColumnDto.Status,
                title: t('ServiceRequest.request_status'),
                render: (request: ServiceRequest): ReactNode | null => (
                    <div className={'status' + (request.closed ? ' closed' : '')}>
                        {request?.opened
                            ? t('ServiceRequest.request_open')
                            : t('ServiceRequest.request_closed')}
                    </div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetServiceRequestsSortColumnDto.Status
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<ServiceRequest> = {
        columnKey: GetServiceRequestsSortColumnDto.Date as Key,
        order: 'descend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<ServiceRequest>
    ): [GetServiceRequestsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetServiceRequestsSortColumnDto = tableSort.columnKey as GetServiceRequestsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<ServiceRequest>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<ServiceRequest> | SorterResult<ServiceRequest>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
        });
    };
    //#endregion

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetServiceRequestsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
        }) => {
            setLoading(true);
            try {
                const [requests, totalItems] = await locationService.getLocationServiceRequests(
                    {
                        locationIds: [id],
                        pageSize: params.pagination.pageSize || PAGE_SIZE,
                        page: (params.pagination.current || 1) - 1,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        searchTerm: params.searchTerm,
                        statuses: getFilterGroupOptions('status'),
                    } as GetServiceRequestsRequestDto
                );

                if (requests) {
                    setRequests(requests);

                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [locationService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div className="ServiceRequests">
            <LocationHeader
                title={t('Location.location_service_requests')}
                subTitle={t('Location.location_service_requests_subtitle')}
                defaultImg={<ServiceRequestIcon fill={theme['primary-color']} />}
                loading={loading}
                routes={breadcrumbs}
                /*action={
                    <Button
                        type="primary"
                        onClick={() => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('ServiceRequest.request_create_request')}
                    </Button>
                }*/
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(request: ServiceRequest): string => request.id!}
                    dataSource={requests}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(request: ServiceRequest) => ({
                        onClick: (): void => {
                            history.push(request.getConsoleUrl(id));
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <CreateLocationServiceRequest
                    location={location}
                    visible={createModalOpen}
                    onComplete={onCreationComplete}
                />
            )}
        </div>
    );
});

export default LocationServiceRequests;
