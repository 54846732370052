import { SimpleList } from 'Components/simple-list';
import React, { FunctionComponent } from 'react';
import './index.less';

interface SimpleListImagesComponentProps {
    title: string;
    images: SimpleListImage[];
    onImageClick?: (id: string) => void;
}

interface SimpleListImage {
    imageUrl?: string;
    id?: string;
}

const SimpleListImagesComponent: FunctionComponent<SimpleListImagesComponentProps> = ({ title, images, onImageClick }) => {
    return (
        <div className="SimpleListImagesComponent">
            <SimpleList title={title}>
                <div className="images-container">
                    {images.map((image) => (
                        <img
                            className="image"
                            key={image.id}
                            src={image.imageUrl}
                            width={165}
                            height={93}
                            alt=""
                            onClick={(): void =>
                                onImageClick && image.id ? onImageClick(image.id) : undefined
                            }
                        />
                    ))}
                </div>
            </SimpleList>
        </div>
    );
};

export default SimpleListImagesComponent;
