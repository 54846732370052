/* eslint-disable react/display-name */
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { Button, Layout, Table } from 'antd';
import { theme, images } from 'variant';
import UserHeader from 'Components/user-header/user-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Key as KeyIcon } from 'Components/icons';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { BuildingAccessService } from 'Services/BuildingAccessService';
import { UserHidDetails } from 'Models/BuildingAccess/UserHidDetails';
import { UserHidDetailsInvitationDto } from 'Api/Features/UserHidInfo/Dtos/UserHidDetailsInvitationDto';
import { ColumnType } from 'antd/lib/table/interface';
import moment from 'moment';
import { TABLE_DATE_FORMAT } from 'Models/Constants';
import InvitationStatusTag from 'Components/invitation-status-tag';
import { InvitationStatus } from 'Models/BuildingAccess/InvitationStatus';
import Title from 'antd/lib/typography/Title';
import './index.less';
import CreateBuildingAccessModal from './create-building-access-modal';
import EmailActivationStatusTag, { ActivationEmailStatus } from 'Components/activation-email-status-tag/activation-email-status-tag';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { listSectionHeader } = images;
const { Content } = Layout;

const BuildingAccessDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const { globalLoadingStore, navigationStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [hidLoading, setHidLoading] = useState(true);

    const userService = useService(UserService);
    const buildingAccessService = useService(BuildingAccessService);

    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [invitations, setInviations] = useState<UserHidDetailsInvitationDto[] | undefined>([]);
    const [hidUserDetails, setHidUserDetails] = useState<UserHidDetails | undefined>();

    const [createBuildingAccessVisible, setCreateBuildingAccessVisible] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'users',
            nameKey: 'User.users',
        },
        {
            path: id,
            name: user?.name || t('User.user'),
        },
        {
            path: 'building-access',
            nameKey: 'User.building_access',
        },
    ];

    const fetchUserInfo = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const userData: UserDetailsDto | null = await userService.getById(id);

            if (userData) {
                const user: UserDetails = new UserDetails(userData);
                setUser(user);
                navigationStore.setSubmenu(user);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [userService, id, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetchUserInfo();
    }, [fetchUserInfo]);

    const fetchBuildingAccessInfo = useCallback(async () => {
        setHidLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const accessInfo = await buildingAccessService.getUserHidDetails(id);

            if (accessInfo) {
                const details: UserHidDetails = new UserHidDetails(accessInfo);
                setHidUserDetails(details);
                setInviations(
                    details.invitations
                        ?.filter((invitation) => invitation !== null)
                        .map((invitation) => invitation!)
                );
            }
        } finally {
            setHidLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [buildingAccessService, id, globalLoadingStore]);

    useEffect(() => {
        fetchBuildingAccessInfo();
    }, [fetchBuildingAccessInfo]);

    const detailsRender = (invitation: UserHidDetailsInvitationDto): ReactNode => (
        <div>
            {t('User.invitation_code')} <span className="bold">{invitation.invitationCode}</span>
        </div>
    );

    const columns: ColumnType<UserHidDetailsInvitationDto>[] = [
        {
            title: t('details'),
            render: detailsRender,
            sorter: {
                compare: (a: any, b: any): number =>
                    a.invitationCode?.localeCompare(b.invitationCode),
                multiple: 1,
            },
        },
        {
            title: t('User.invitation_send_date'),
            render: (invitation: UserHidDetailsInvitationDto): ReactNode =>
                moment(invitation.createdDate).format(TABLE_DATE_FORMAT),
            sorter: {
                compare: (a: any, b: any): number => a.createdDate?.localeCompare(b.createdDate),
                multiple: 2,
            },
        },
        {
            title: t('User.invitation_expiration_date'),
            render: (invitation: UserHidDetailsInvitationDto): ReactNode =>
                moment(invitation.expirationDate).format(TABLE_DATE_FORMAT),
            sorter: {
                compare: (a: any, b: any): number =>
                    a.expirationDate?.localeCompare(b.expirationDate),
                multiple: 3,
            },
        },
        {
            title: t('User.invitation_status'),
            render: (invitation: UserHidDetailsInvitationDto): ReactNode => {
                return invitation.status ? (
                    <InvitationStatusTag status={invitation.status as InvitationStatus} />
                ) : null;
            },
            sorter: {
                compare: (a: any, b: any): number =>
                    a.expirationDate?.localeCompare(b.expirationDate),
                multiple: 3,
            },
        },
        {
            title: t('User.activation_email'),
            render: (invitation: UserHidDetailsInvitationDto): ReactNode => {
                return (
                    <EmailActivationStatusTag
                        status={
                            invitation.datawatchNotificationEmailSendDate
                                ? ActivationEmailStatus.Sent
                                : ActivationEmailStatus.NotSent
                        }
                    />
                );
            },
            sorter: {
                compare: (a: any, b: any): number =>
                    a.expirationDate?.localeCompare(b.expirationDate),
                multiple: 3,
            },
        },
    ];

    return (
        <>
            <UserHeader
                title={t('User.building_access')}
                subTitle={t('User.building_access_subtitle')}
                defaultImg={<KeyIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
            />
            <Content className="BuildingAccessDetail">
                {!loading && !hidLoading && hidUserDetails && (
                    <Table
                        className="table-striped-rows table-action-rows"
                        bordered
                        columns={columns}
                        rowKey={(invitation: UserHidDetailsInvitationDto): string => invitation.id!}
                        dataSource={invitations}
                        loading={loading}
                    />
                )}

                {!loading && !hidLoading && !hidUserDetails && (
                    <div className="hid-not-created-container">
                        <Title className="title">{t('User.hid_user_not_created')}</Title>
                        {(userStore.isAdmin ||
                            new UserPermissionUtils(userStore).UserCanViewContent(
                                [user?.location?.id].concat(
                                    user?.locations
                                        ? user.locations?.map((location) => location.id)
                                        : undefined
                                )
                            )) && (
                            <Button
                                type="primary"
                                onClick={(): void => setCreateBuildingAccessVisible(true)}
                            >
                                {t('User.create_building_access')}
                            </Button>
                        )}
                    </div>
                )}
            </Content>

            {createBuildingAccessVisible && (
                <CreateBuildingAccessModal
                    visible={createBuildingAccessVisible}
                    onComplete={(success: boolean): void => {
                        setCreateBuildingAccessVisible(false);
                        if (success) fetchBuildingAccessInfo();
                    }}
                    user={user}
                />
            )}
        </>
    );
});

export default BuildingAccessDetail;
