import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { CompanyService } from 'Services/CompanyService';
import { Button, Col, Layout, Row } from 'antd';
import { images, theme } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { SimpleList } from 'Components/simple-list';
import {
    Address,
    Calendar,
    Company as CompanyIcon,
    Delete,
    Email,
    Location,
    LowercaseI,
    PadLock,
    Phone,
    User as UserIcon,
} from 'Components/icons';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { UpdateUserStatusRequestDto } from 'Api/Features/Users/Dtos/UpdateUserStatusRequestDto';
import { UserStatusDto } from 'Api/Features/Users/Dtos/UserStatusDto';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { Company } from 'Models/Companies/Company';
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from 'Models/Constants';
import EditUserModal from '../../edit-user';
import CreateUserMessageModal from '../messages/create-message';
import './index.less';
import { UserMessageTypes } from 'Models/Messages/UserMessageTypes';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import { TdWithImage } from 'Components/td-with-image';
import InformationModal from 'Components/information-modal/information-modal';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { usersHeader } = images;
const { Content } = Layout;

interface SimpleListItemProps {
    title: string | ReactNode;
    description?: string;
    avatar?: React.ReactNode;
}

interface UsersHistory {
    from?: string | undefined;
}

const UserDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { navigationStore, globalLoadingStore, confirmationModalStore, toastStore, userStore } = useStores();
    const userService = useService(UserService);
    const companyService = useService(CompanyService);
    const history = useHistory<UsersHistory>();
    const [loading, setLoading] = useState(true);
    const [statusHasChanged, completeStatusChange] = useState(false);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [contact, setContact] = useState<SimpleListItemProps[] | null>(null);
    const [privacy, setPrivacy] = useState<SimpleListItemProps[] | null>(null);
    const [introduction, setIntroduction] = useState<SimpleListItemProps[] | null>(null);
    const [jobPosition, setJobPosition] = useState<SimpleListItemProps[] | null>(null);
    const [companyModel, setCompanyModel] = useState<Company | null>(null);
    const [location, setLocation] = useState<SimpleListItemProps[] | null>(null);
    const [personalInformation, setPersonalInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
    const [privacyInformationModalOpen, setPrivacyInformationModalOpen] = useState(false);
    const { id } = useParams<{ id: string }>();

    const setSimpleListsData = useCallback((user: UserDetails): void => {
        setJobPosition(user.jobPosition ? [{ title: user.jobPosition || '' }] : null);
        if (user?.introduction) setIntroduction([{ title: user.introduction || '' }]);
        if (user?.contactInfo) {
            const contact: SimpleListItemProps[] = [];
            if (user.contactInfo.email) {
                contact.push({
                    title: `${user.contactInfo.email}`,
                    avatar: <Email fill={theme['primary-color']} />,
                });
            }

            if (user.contactInfo.phoneNumber) {
                contact.push({
                    title: (user.contactInfo as ContactInfoDto).phoneNumber || '',
                    avatar: <Phone fill={theme['primary-color']} />,
                });
            }
            setContact(contact);
        }

        setPrivacy([
            {
                title: (
                    <span className="user-privacy">
                        {t('User.this_account_is')}&nbsp;
                        <span className="bold">{user.isPrivate ? t('private') : t('public')}</span>
                        <span onClick={(): void => setPrivacyInformationModalOpen(true)} className="i-icon">
                            <LowercaseI width={17} height={17} fill={theme['black']} />
                        </span>
                    </span>
                ),
                avatar: <PadLock width={24} height={24} fill={theme['primary-color']} />,
            },
        ]);

        if (user?.location) {
            setLocation([
                {
                    title: user.location.name || '',
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<Location />}
                            imgSrc={user.location.mainImageUrl}
                        />
                    ),
                },
            ]);
        }

        if (user?.birthDate || !user?.mainAddress?.isEmpty) {
            const info: SimpleListItemProps[] = [];
            if (!user.mainAddress?.isEmpty) {
                info.push({
                    title: user.mainAddress!.text,
                    avatar: <Address fill={theme['primary-color']} />,
                });
            }
            if (user.birthDate) {
                info.push({
                    title:
                        moment(user.birthDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT) || '',
                    avatar: <Calendar fill={theme['primary-color']} />,
                });
            }
            setPersonalInformation(info);
        }
    }, [t]);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                const userData: UserDetailsDto | null = await userService.getById(id);

                if (userData) {
                    const user: UserDetails = new UserDetails(userData);
                    setUser(user);
                    navigationStore.setSubmenu(user);
                    setSimpleListsData(user);
                    if (user.company) {
                        setCompanyModel(new Company(user.company));
                    }
                }
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
            }
        }

        fetch();
    }, [editModalOpen, statusHasChanged, globalLoadingStore, id, userService, navigationStore, setSimpleListsData]);

    const onEditComplete = (success: boolean) => {
        setEditModalOpen(false);
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'User.users',
            path: 'users',
        },
        {
            name: user?.name || '',
            path: user?.id || '',
        },
    ];

    const toggleUserStatus = async (): Promise<void> => {
        // HEYD-1344
        if (!user?.isActive && user?.company?.id) {
            const userCompany: Company | null = await companyService.getById(user.company.id);
            if (!userCompany?.isActive) {
                await confirmationModalStore.confirm({
                    icon: <UserIcon />,
                    title: t('User.user_activate_error_title'),
                    message: t('User.user_activate_error_message'),
                    positiveText: t('ok'),
                });
                return;
            }
        }

        const transPrefix = user?.isActive
            ? 'User.user_deactivate_account_confirm_'
            : 'User.user_activate_account_confirm_';
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t(transPrefix + 'title'),
                message: t(transPrefix + 'message'),
                positiveText: t(transPrefix + 'positive'),
                negativeText: t(transPrefix + 'negative'),
            }))
        ) {
            return;
        }

        async function toggleStatus() {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                await userService.setUserStatus(id, {
                    status: user?.isActive ? UserStatusDto.Deactivated : UserStatusDto.Active,
                } as UpdateUserStatusRequestDto);
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
                completeStatusChange(!statusHasChanged);
            }
        }

        await toggleStatus();
    };

    const deleteUser = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`User.user_delete_confirm_title`),
                message: t(`User.user_delete_confirm_message`),
                positiveText: t(`User.user_delete_confirm_positive`),
                negativeText: t(`User.user_delete_confirm_negative`),
            }))
        )
            return;

        globalLoadingStore.addLoading();

        try {
            await userService.deleteUser(id);

            if (history.location.state?.from && history.location.state.from === 'team') {
                history.go(-2);
            } else {
                history.goBack();
            }
        } catch (error) {
            if (error.response.data.errorCode === 'E005005') {
                toastStore.toast({
                    type: 'error',
                    messageKey: error.response.data.errorDescription,
                });
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const actions = (): ActionMenuOption[] => {
        const actions = [
            {
                key: 'edit',
                title: t('User.user_edit_profile'),
                action: (): void => setEditModalOpen(true),
            },
            {
                key: 'activate',
                title: t(
                    user?.isActive ? 'User.user_deactivate_account' : 'User.user_activate_account'
                ),
                action: (): Promise<void> => toggleUserStatus(),
            },
            {
                key: 'delete',
                title: t('User.user_delete_account'),
                action: (): Promise<void> => deleteUser(),
            },
        ];
        if (user?.company?.type !== CompanyTypeDto.Passport) {
            actions.splice(1, 0, {
                key: 'sendPackageMessage',
                title: t('Message.send_package_delivery_message'),
                action: (): void => setSendMessageModalOpen(true),
            });
        }

        return actions;
    };

    const onManageCompany = (): void => {
        if (companyModel) {
            history.push(companyModel.consoleUrl);
        }
    };

    return (
        <div className="UserDetails">
            <ListSectionHeader
                title={user?.name || ''}
                subTitle={''}
                defaultImg={<UserIcon fill={theme['primary-color']} />}
                imgSrc={user?.imageUrl}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                routes={breadcrumbs}
                status={user?.statusBadgeStatus}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [user?.location?.id].concat(
                                user?.locations
                                    ? user.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && <ActionMenu options={actions()} type="primary" trigger="click" />
                }
            />
            <Content>
                <Row gutter={16}>
                    <Col span={12}>
                        {jobPosition && (
                            <SimpleList title={t('User.job_position')} data={jobPosition} />
                        )}
                        {introduction && user && !user.introductionEmpty && (
                            <SimpleList title={t('User.user_introduction')} data={introduction} />
                        )}
                        {contact && <SimpleList title={t('User.contact')} data={contact} />}
                        {privacy && (
                            <SimpleList
                                title={t('privacy')}
                                className="user-privacy-container"
                                data={privacy}
                            />
                        )}
                    </Col>

                    <Col span={12}>
                        {personalInformation && (
                            <SimpleList
                                title={t('User.user_information')}
                                data={personalInformation}
                            />
                        )}
                        {user?.company && (
                            <SimpleList title={t('Company.company')}>
                                <div className="company-container">
                                    <div>
                                        <TdWithImage
                                            width="32"
                                            height="32"
                                            defaultImg={<CompanyIcon />}
                                            imgSrc={user.company.imageUrl}
                                        >
                                            {user.company.name}
                                        </TdWithImage>
                                    </div>
                                    <div>
                                        <Button
                                            type="default"
                                            className="manage-company-btn"
                                            onClick={onManageCompany}
                                        >
                                            {t('Company.company_manage')}
                                        </Button>
                                    </div>
                                </div>
                            </SimpleList>
                        )}
                        {location && <SimpleList title={t('Location.location')} data={location} />}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && user && (
                <EditUserModal visible={editModalOpen} onComplete={onEditComplete} user={user!} />
            )}

            {sendMessageModalOpen && (
                <CreateUserMessageModal
                    user={user}
                    visible={sendMessageModalOpen}
                    onComplete={(): void => setSendMessageModalOpen(false)}
                    defaultMessageType={UserMessageTypes.PackageDelivery}
                    messageTypeDisabled
                />
            )}

            {privacyInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('User.privacy_explanation_title'),
                            messages: [
                                t('User.privacy_explanation_message1'),
                                t('User.privacy_explanation_message2'),
                            ],
                        },
                    ]}
                    onClose={(): void => setPrivacyInformationModalOpen(false)}
                />
            )}
        </div>
    );
};

export default UserDetail;
