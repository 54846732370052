import React, { FunctionComponent } from 'react';
import { Modal, Form, Row, Col, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Close, LowercaseI, User as UserIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { CreateMessageSchema } from 'Schemas';
import { LocationService } from 'Services/LocationService';
import { Location } from 'Models/Location/Location';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';
import { NotificationTypeDto } from 'Api/Features/Notifications/Dtos/NotificationTypeDto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './create-message.less';
import { theme } from 'variant';

const formGutter: [Gutter, Gutter] = [40, 0];

interface MessageModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    location?: Location;
    isEditing?: boolean;
}

const CreateLocationMessageModal: FunctionComponent<MessageModalProps> = ({
    visible,
    onComplete,
    location,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const locationService = useService(LocationService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateMessageSchema, form);
    //#endregion

    //#region Options values

    //#endregion

    //#region Effects

    //#region Event handlers
    interface SelectOption {
        id: string;
        name: string;
        order?: number;
    }

    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        resetErrors();
        onComplete(success);
    };

    const submit = async (values: Store): Promise<void> => {
        const newMessageData: CreateMessageRequestDto = {
            receiverType: MessageReceiverTypeDto.Location,
            receiverId: location?.id,
            type: NotificationTypeDto.Announcement,
            entityType: null,
            entityId: null,
            title: values.title,
            body: values.body,
        };

        if (!(await validateForm(newMessageData, false))) return;
        try {
            globalLoadingStore.addLoading();
            await locationService.sendLocationMessage(newMessageData);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('Message.announcement_sent'),
                message: t('Message.announcement_sent_message'),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('Message.message_send_cancel_title'),
                message: t('Message.message_send_cancel_message'),
                positiveText: t('Message.message_send_cancel_positive'),
                negativeText: t('Message.message_send_cancel_negative'),
            }))
        )
            return;
        dismiss(false);
    };

    const handleMessageContentChange = (value: string): void => {
        form.setFieldsValue({ body: value });
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t('Message.message_send_announcement')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateMessage">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row>
                        <Col span={5}></Col>
                        <Col span={14} className="subTitle warning">
                            <LowercaseI fill={theme['black']} />
                            <span>
                                {t('Message.note_that_only')} &nbsp;
                                <span className="bold">{t('Message.heyday_users')}</span> &nbsp;
                                {t('Message.will_receive_announcements')}
                            </span>
                        </Col>
                        <Col span={5}></Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="title"
                                label={t('Message.message_title')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="body"
                                label={t('Message.message_content')}
                                className="body"
                                required
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={''}
                                onChange={handleMessageContentChange}
                            />
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Message.message_send')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(CreateLocationMessageModal);
