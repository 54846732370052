import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { Modal, Form, Row, Col, Select, Button } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Close, LowercaseI, User as UserIcon } from 'Components/icons';
import { UserDetails } from 'Models/Users/UserDetails';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { UpdateUserCompanyRolesRequestDto } from 'Api/Features/Users/Dtos/UpdateUserCompanyRolesRequestDto';
import './edit-user-role.less';
import InformationModal from 'Components/information-modal/information-modal';
import { theme } from 'variant';

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditUserRoleModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: UserDetails;
}

const EditUserRoleModal: FunctionComponent<EditUserRoleModalProps> = ({
    visible,
    onComplete,
    user,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const userService = useService(UserService);
    const [roleInformationModalOpen, setRoleInformationModalOpen] = useState(false);
    //#endregion

    //#region Effects
    useEffect(() => {
        if (visible && user) {
            form.setFieldsValue({
                companyRoles: user.companyRole,
            });
        }
    }, [visible, user, form]);
    //#endregion

    //#region Form Content
    interface SelectOption {
        id: string;
        name: string;
    }

    const rolesOptions: SelectOption[] = Object.keys(CompanyRoleDto).map((key) => ({
        id: key,
        name: key,
    }));
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        onComplete(success);
    };

    const submit = async (values: Store): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            await userService.updateUserCompanyRoles(user!.id, {
                companyRoles: [values.companyRoles],
            } as UpdateUserCompanyRolesRequestDto);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t(`User.user_role_update_success`),
                message: t(`User.user_role_update_success_message`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        dismiss(false);
    };
    //#endregion

    return (
        <Modal
            visible={visible}
            centered
            title={t('User.user_edit_role')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="EditUserRole">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <div className="role-information-container">
                                <Form.Item
                                    name="companyRoles"
                                    label={t('Contact.contact_role')}
                                    className="role-dropdown"
                                >
                                    <Select>
                                        {rolesOptions.map((option) => (
                                            <Option key={option.id} value={option.id}>
                                                {t(`User.user_role_${option.name}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <span onClick={(): void => setRoleInformationModalOpen(true)}>
                                    <LowercaseI width={17} height={17} fill={theme['black']} />
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
            {roleInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('User.user_role_Administrator'),
                            messages: [t('User.role_info_administrator')],
                        },
                        {
                            title: t('User.user_role_BookingManager'),
                            messages: [t('User.role_info_booking_manager')],
                        },
                        { title: t('User.user_role_Member'), messages: [t('User.role_info_member')] },
                    ]}
                    onClose={(): void => setRoleInformationModalOpen(false)}
                />
            )}
        </Modal>
    );
    //#endregion
};

export default React.memo(EditUserRoleModal);
