import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Modal, Form, Row, Col, Input, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import 'react-quill/dist/quill.snow.css';
import './booking-approval-modal.less';
import { CreateBookingApprovalSchema } from 'Schemas/CreateBookingApprovalSchema';
import { BookingService } from 'Services/BookingService';

const { Text } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

const { TextArea } = Input;

interface MessageModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    bookingId: string;
    isEditing?: boolean;
}

const BookingApprovalModal: FunctionComponent<MessageModalProps> = ({
    visible,
    onComplete,
    bookingId,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore } = useStores();
    const bookingService = useService(BookingService);
    const [note, setNote] = useState<string>('');
    const [form] = Form.useForm();
    const [errors] = useFormValidation(CreateBookingApprovalSchema, form);

    const dismiss = (success = false): void => {
        form.resetFields();
        onComplete(success);
    };

    const success = async (): Promise<void> => {
        dismiss(true);
    };

    const submit = async (values: Store): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            await bookingService.approveBooking(bookingId, { note: values.note });
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const exit = async (): Promise<void> => {
        dismiss(false);
    };

    const handleNoteChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>): void => {
        form.setFieldsValue({ note: value });
        setNote(value);
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t('Booking.booking_approval_approve_title')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="BookingApprovalModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row>
                        <Col span={24} className="subTitle">
                            <Text className="subTitleText">
                                {t('Booking.booking_approval_approve_subtitle')}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Booking.booking_approval_message_to_tenant')}
                                className="body"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <TextArea
                                className="description-quill"
                                onChange={handleNoteChange}
                                value={note}
                            />
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('no')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Booking.booking_approval_approve_booking')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(BookingApprovalModal);
