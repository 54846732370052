// GENERATED FILE - DO NOT MODIFY
import { CreateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/CreateFoodCategoryRequestDto';
import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';
import { GetFoodCategoriesOrderResponseDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesOrderResponseDto';
import { GetFoodCategoriesRequestDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesRequestDto';
import { GetFoodCategoriesResponseDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesResponseDto';
import { UpdateFoodCategoriesOrderingRequestDto } from 'Api/Features/FoodCategories/Dtos/UpdateFoodCategoriesOrderingRequestDto';
import { UpdateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/UpdateFoodCategoryRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FoodCategoryProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFoodCategory(foodCategoryId: string): Promise<FoodCategoryDto | null> {
        const uri = this.buildUri(
            "/food-categories/{foodCategoryId}",
            { foodCategoryId: foodCategoryId },
            null
        );

        const data: FoodCategoryDto | null = await this.httpClient.get<FoodCategoryDto | null>(uri);
        return data;
    }

    public async getFoodCategories(request: GetFoodCategoriesRequestDto | null): Promise<GetFoodCategoriesResponseDto | null> {
        const uri = this.buildUri(
            "/food-categories",
            null,
            { ...request || undefined }
        );

        const data: GetFoodCategoriesResponseDto | null = await this.httpClient.get<GetFoodCategoriesResponseDto | null>(uri);
        return data;
    }

    public async createFoodCategory(request: CreateFoodCategoryRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/food-categories",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateFoodCategoryRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateFoodCategory(foodCategoryId: string, request: UpdateFoodCategoryRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-categories/{foodCategoryId}",
            { foodCategoryId: foodCategoryId },
            null
        );

        await this.httpClient.patch<UpdateFoodCategoryRequestDto | null, void>(uri, request);
    }

    public async deleteFoodCategory(foodCategoryId: string): Promise<void> {
        const uri = this.buildUri(
            "/food-categories/{foodCategoryId}",
            { foodCategoryId: foodCategoryId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getFoodCategoriesOrdering(foodMerchantId: string): Promise<GetFoodCategoriesOrderResponseDto | null> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}/food-categories/order",
            { foodMerchantId: foodMerchantId },
            null
        );

        const data: GetFoodCategoriesOrderResponseDto | null = await this.httpClient.get<GetFoodCategoriesOrderResponseDto | null>(uri);
        return data;
    }

    public async updateFoodCategoriesOrdering(foodMerchantId: string, request: UpdateFoodCategoriesOrderingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}/food-categories/order",
            { foodMerchantId: foodMerchantId },
            null
        );

        await this.httpClient.put<UpdateFoodCategoriesOrderingRequestDto | null, void>(uri, request);
    }
}