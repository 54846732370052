import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button, DatePicker, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';

import './edit-user.less';
import { Close, LowercaseI, PadLock, User } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { EditUserSchema } from 'Schemas';
import { useFormValidation, useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { DATE_API_FORMAT } from 'Models/Constants';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import { SelectCountry } from 'Components/select-country';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ImagePicker } from 'Components/image-picker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { theme } from 'variant';
import InformationModal from 'Components/information-modal/information-modal';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];
const titleGutter: [Gutter, Gutter] = [0, 0];

interface UserModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user: UserDetailsDto;
}

const EditUserModal: FunctionComponent<UserModalProps> = ({ visible, onComplete, user }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(EditUserSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const userService = useService(UserService);
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [isPrivateCheckboxState, setIsPrivateCheckboxState] = useState(user.isPrivate);
    const [privacyInformationModalOpen, setPrivacyInformationModalOpen] = useState(false);
    //#endregion

    //#region Effects
    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.contactInfo?.email,
                birthDate: user.birthDate ? moment(user.birthDate, DATE_API_FORMAT) : null,
                jobPosition: user.jobPosition,
                introduction: user.introduction,
                'contactInfo.email': user.contactInfo?.email,
                'contactInfo.phoneNumber': user.contactInfo?.phoneNumber,
                'address.addressLine1': user.address?.addressLine1,
                'address.city': user.address?.city,
                'address.state': user.address?.state,
                'address.countryCode': user.address?.countryCode,
                'address.zipCode': user.address?.zipCode,
            });
        }
    }, [user, form]);
    //#endregion

    function disableFuture(current: any) {
        return current && current > moment().endOf('day');
    }
    //#endregion

    //#region Event handlers
    const handleIntroductionChange = (value: string): void => {
        form.setFieldsValue({ introduction: value });
    };
    //#endregion

    //#region Submit / Exit
    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`User.user_profile_saved_success`),
                message: t(`User.user_profile_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`User.user_confirm_positive`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        const contactInfo: ContactInfoDto = {
            email: user.contactInfo?.email,
            phoneNumber: values['contactInfo.phoneNumber'],
        };
        const address: AddressDto = {
            addressLine1: values['address.addressLine1'],
            city: values['address.city'],
            countryCode: values['address.countryCode'],
            state: values['address.state'],
            zipCode: values['address.zipCode'],
        };
        const mainImage = mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const data: UpdateUserRequestDto = {
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: values.birthDate ? values.birthDate.format(DATE_API_FORMAT) : undefined,
            introduction: values.introduction,
            jobPosition: values.jobPosition,
            contactInfo: contactInfo,
            address: address,
            image: mainImage,
            isPrivate: isPrivateCheckboxState
        };

        if (!(await validateForm(data, false))) return;
        try {
            globalLoadingStore.addLoading();
            await userService.editUser(user.id!, data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            if (error.response?.data?.errors?.['image.UploadBase64'] !== undefined) {
                const errors = new Map<string, string[]>();
                errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
                setErrors(errors);
            } else if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    return (
        <Modal
            visible={visible}
            centered
            title={t('User.user_edit_profile')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="EditUser">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('User.first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('User.last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="jobPosition"
                                label={t('User.job_position')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="introduction"
                                label={t('User.user_introduction')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={
                                    user?.introduction || form.getFieldValue('introduction') || null
                                }
                                onChange={handleIntroductionChange}
                            />
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('privacy')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={1} className="is-private-icon-container">
                            <span onClick={(): void => setPrivacyInformationModalOpen(true)}>
                                <LowercaseI width={17} height={17} fill={theme['black']} />
                            </span>
                        </Col>
                        <Col span={7}>
                            <ValidatedFormItem errors={errors} name="isPrivate" className="privacy">
                                <div className="checkbox-container">
                                    <div>
                                        <Checkbox
                                            onChange={(event: CheckboxChangeEvent): void =>
                                                setIsPrivateCheckboxState(event.target.checked)
                                            }
                                            checked={isPrivateCheckboxState}
                                        >
                                            {t('User.make_this_account_private')}
                                        </Checkbox>
                                    </div>
                                    <div className="icon-container">
                                        <PadLock
                                            width={15}
                                            height={17}
                                            fill={theme['primary-color']}
                                        />
                                    </div>
                                </div>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('User.contact')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.phoneNumber"
                                label={t('User.phone')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        {/* Release v1 api does not support changing email */}
                        {/* <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.email"
                                label={t('User.email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col> */}
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('User.personal_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.addressLine1"
                                label={t('User.address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.city"
                                label={t('User.city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.state"
                                label={t('User.state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <SelectCountry
                                errors={errors}
                                name="address.countryCode"
                                label={t('User.country')}
                            />
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.zipCode"
                                label={t('User.zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="birthDate"
                                label={t('User.birth_date')}
                            >
                                <DatePicker
                                    disabledDate={disableFuture}
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="imageRow">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        user.imageUrl
                                            ? [
                                                  {
                                                      url: user.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>

            {privacyInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('User.privacy_explanation_title'),
                            messages: [t('User.privacy_explanation_message1'), t('User.privacy_explanation_message2')],
                        },
                    ]}
                    onClose={(): void => setPrivacyInformationModalOpen(false)}
                />
            )}
        </Modal>
    );
    //#endregion
};

export default React.memo(EditUserModal);
