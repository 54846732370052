import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { VerdocsDocumentRecipientDto } from 'Api/Features/Verdocs/Dtos/VerdocsDocumentRecipientDto';
import { VerdocsSignatureStatusDto } from 'Api/Features/Verdocs/Dtos/VerdocsSignatureStatusDto';

export class VerdocsDocumentRecipient implements VerdocsDocumentRecipientDto {
    constructor(dto: VerdocsDocumentRecipientDto) {
        Object.assign(this, dto);
    }
    
    email?: string | null;
    fullName?: string | null;
    roleName?: string | null;
    type?: string | null;
    status?: string | null;
    signatureStatus?: VerdocsSignatureStatusDto;

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if (this.status === 'invited') {
            return StatusBadgeStatus.Invited;
        } else if (this.status === 'pending') {
            return StatusBadgeStatus.Pending;
        }
        else if (this.status === 'signed') {
            return StatusBadgeStatus.Signed;
        }
        else if (this.status === 'submitted') {
            return StatusBadgeStatus.Submitted;
        }
        else if (this.status === 'declined') {
            return StatusBadgeStatus.Declined;
        }
        else if (this.status === 'canceled') {
            return StatusBadgeStatus.Canceled;
        }
        else if (this.status === 'opened') {
            return StatusBadgeStatus.Opened;
        }
        return undefined;
    }
}
