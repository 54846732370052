import React, { FunctionComponent, ReactNode } from 'react';
import { Layout, ConfigProvider } from 'antd';
import { EmptyResults } from 'Components/empty-results';

import { SideNavigation } from '../side-navigation';
import './authenticated-layout.less';
import { SIDER_WIDTH } from 'Models/Constants';

const AuthenticatedLayout: FunctionComponent = ({ children }) => {
    return (
        <Layout className="AuthenticatedLayout">
            <ConfigProvider renderEmpty={(): ReactNode => <EmptyResults />}>
                <SideNavigation />
                <Layout className="site-layout" style={{ marginLeft: SIDER_WIDTH }}>{children}</Layout>
            </ConfigProvider>
        </Layout>
    );
};

export default AuthenticatedLayout;
