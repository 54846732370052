import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { Alert, Button, Layout } from 'antd';
import { images, theme } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Invoice as InvoiceIcon } from 'Components/icons';
import './index.less';
import { InvoiceService } from 'Services/InvoiceService';
import { PdfViewer } from 'Components/pdf-viewer';
import { showFile } from 'Utils';
import { ListSectionHeader } from 'Components/list-section-header';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { ActionMenu } from 'Components/action-menu';
import { InvoiceDetails } from 'Models/Invoices/InvoiceDetails';
import StatusBadge from 'Components/status-badge/status-badge';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;
const { listSectionHeader } = images;

const InvoicesDetail: FunctionComponent = observer(() => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const invoiceService = useService(InvoiceService);
    const [data, setData] = useState<InvoiceDetails | null>(null);
    const [pdf, setPdf] = useState<Blob>();
    const [actionOptions, setActionOptions] = useState<ActionMenuOption[]>([]);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'invoices',
            nameKey: 'Invoices.invoices_title',
        },
        {
            path: id,
            name: data?.number || '',
        },
    ];

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const invoice = await invoiceService.getInvoice(id);
            setData(invoice);

            const document = await invoiceService.exportInvoices({ ids: id });
            setPdf(document);
        } catch (error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [id, invoiceService, globalLoadingStore, toastStore]);

    useEffect(() => {
        fetch();
    }, [id, fetch]);

    useEffect(() => {
        const refundInvoice = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <InvoiceIcon />,
                    title: t(`Invoices.invoice_refund_modal_title`),
                    message: t(`Invoices.invoice_refund_modal_message`),
                    positiveText: t('Invoices.invoice_refund_modal_positive'),
                    negativeText: t(`Invoices.invoice_refund_modal_negative`),
                }))
            )
                return;

            await invoiceService.refundInvoice(id);
            fetch();
        };

        const voidInvoice = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <InvoiceIcon />,
                    title: t(`Invoices.invoice_void_modal_title`),
                    message: t(`Invoices.invoice_void_modal_message`),
                    positiveText: t('Invoices.invoice_void_modal_positive'),
                    negativeText: t(`Invoices.invoice_void_modal_negative`),
                }))
            )
                return;

            await invoiceService.voidInvoice(id);
            fetch();
        };

        const closeInvoice = async (): Promise<void> => {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <InvoiceIcon />,
                    title: t(`Invoices.invoice_close_modal_title`),
                    message: t(`Invoices.invoice_close_modal_message`),
                    positiveText: t('Invoices.invoice_close_modal_positive'),
                    negativeText: t(`Invoices.invoice_close_modal_negative`),
                }))
            )
                return;

            await invoiceService.closeInvoice(id);
            fetch();
        };

        const actions: ActionMenuOption[] = [];

        if (data?.status === InvoiceStatusDto.Paid) {
            actions.push({
                key: 'refund',
                title: t('Invoices.refund_invoice'),
                action: (): Promise<void> => refundInvoice(),
            });
        }

        if (
            data?.status === InvoiceStatusDto.AwaitingPayment ||
            data?.status === InvoiceStatusDto.Failed
        ) {
            actions.push({
                key: 'void',
                title: t('Invoices.void_invoice'),
                action: (): Promise<void> => voidInvoice(),
            });
            actions.push({
                key: 'close',
                title: t('Invoices.close_invoice'),
                action: (): Promise<void> => closeInvoice(),
            });
        }

        actions.push({
            key: 'export',
            title: t('export_invoice'),
            action: (): void => {
                if (pdf) {
                    showFile(pdf, data?.number || '');
                }
            },
        });

        setActionOptions(actions);
    }, [data, pdf, t, confirmationModalStore, fetch, id, invoiceService]);

    const action = useCallback((): ReactNode | undefined => {
        if (
            !userStore.isAdmin &&
            !new UserPermissionUtils(userStore).UserCanViewContent([data?.location?.id])
        )
            return undefined;
        else if (
            data?.status === InvoiceStatusDto.Void ||
            data?.status === InvoiceStatusDto.Refunded
        )
            return (
                <Button
                    type="primary"
                    onClick={(): void => {
                        if (pdf) {
                            showFile(pdf, data?.number || '');
                        }
                    }}
                >
                    {t('export_invoice')}
                </Button>
            );
        else return <ActionMenu options={actionOptions} type="primary" trigger="click" />;
    }, [actionOptions, data, pdf, t, userStore]);

    return (
        <div className="InvoicesDetail">
            {data && (
                <>
                    <ListSectionHeader
                        title={data?.number || ''}
                        subTitle={<StatusBadge status={data?.statusBadgeStatus} />}
                        defaultImg={<InvoiceIcon fill={theme['primary-color']} />}
                        backgroundImageUrl={listSectionHeader}
                        loading={loading}
                        routes={breadcrumbs}
                        action={action()}
                    />

                    {data?.hasErrorMessage && (
                        <Alert type="error" showIcon={false} message={data?.errorMessage} banner />
                    )}

                    <Content>
                        <PdfViewer file={pdf} />
                    </Content>
                </>
            )}
        </div>
    );
});

export default InvoicesDetail;
