import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import UnauthorizedPage from '../../Routes/console/error/unauthorized/unauthorized';
import { useStores } from '../../Hooks';
import { observer } from 'mobx-react';

const ValidatedRoute: FunctionComponent<RouteProps> = observer(({ children, ...routeProps }) => {
    const { authorizationStore } = useStores();
    return (
        <Route {...routeProps}>
            {authorizationStore.currentRequestForbidden ? <UnauthorizedPage /> : children}
        </Route>
    );
});

export default ValidatedRoute;
