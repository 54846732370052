import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { CompanyService } from 'Services/CompanyService';
import { Col, Layout, Row } from 'antd';
import { theme, images } from 'variant';
import CompanyHeader from 'Components/company-header/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Message as MessageIcon } from 'Components/icons';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CompanyMessage } from 'Models/Messages/CompanyMessage';
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from 'Models/Constants';
import './index.less';

const { listSectionHeader } = images;
const { Content } = Layout;

const CompanyMessageDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    const { globalLoadingStore, navigationStore } = useStores();
    const [loading, setLoading] = useState(true);

    const { id, messageId } = useParams<{id: string; messageId: string}>();
    const companyService = useService(CompanyService);
    const [company, setCompany] = useState<Company | undefined>(undefined);
    const [message, setMessage] = useState<CompanyMessage | undefined>(undefined);
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: company?.name || t('Company.company'),
        },
        {
            path: 'messages',
            nameKey: 'Company.company_messages',
        },
        {
            path: messageId,
            nameKey: 'Message.message_details',
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const companyData: CompanyDto | null = await companyService.getById(id);

            if (companyData) {
                const company: Company = new Company(companyData);
                setCompany(company);
                navigationStore.setSubmenu(company);

                const messageData = await companyService.getCompanyMessageById(
                    company.id,
                    messageId
                );
                if (messageData) {
                    setMessage(new CompanyMessage(messageData));
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [companyService, id, messageId, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <>
            <CompanyHeader
                title={t('Company.company_messages')}
                subTitle={t('Company.company_messages_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
            />
            <Layout>
                <Content className="messageDetailContent">
                    <Row gutter={16}>
                        <Col span={24}>
                            <h1 className="messageTitle">{message?.title || ''}</h1>
                            <h2 className="messageInfo">
                                {t('Message.message_details_from')}&nbsp;{message?.fromName || '-'}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_to')}&nbsp;
                                {company?.name || ''}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_date')}&nbsp;
                                {moment(message?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_type')}&nbsp;{t(`MessageNotificationType.${message?.type}`)}
                            </h2>
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: message?.body || '' }} />
                            <hr className="closingLine" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
});

export default CompanyMessageDetail;
