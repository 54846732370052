import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { FoodCategoryProxy } from 'Api/Features/FoodCategories/FoodCategoryProxy';
import { GetFoodCategoriesRequestDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesRequestDto';
import { GetFoodCategoriesResponseDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesResponseDto';
import { CreateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/CreateFoodCategoryRequestDto';
import { UpdateFoodCategoryRequestDto } from 'Api/Features/FoodCategories/Dtos/UpdateFoodCategoryRequestDto';
import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';
import { FoodCategory } from 'Models/FoodCategory/FoodCategory';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { GetFoodCategoriesOrderResponseDto } from 'Api/Features/FoodCategories/Dtos/GetFoodCategoriesOrderResponseDto';

@inject(FoodCategoryProxy)
export class FoodCategoryService extends ApiService {
    constructor(private readonly foodCategoryProxy: FoodCategoryProxy) {
        super();
    }

    public async getFoodCategory(id: string): Promise<FoodCategoryDto | null> {
        const response: FoodCategoryDto | null = await this.foodCategoryProxy.getFoodCategory(id);
        return response || null;
    }

    public async getFoodCategories(
        request?: GetFoodCategoriesRequestDto
    ): Promise<[FoodCategory[], number]> {
        const response: GetFoodCategoriesResponseDto | null = await this.foodCategoryProxy.getFoodCategories(
            request || null
        );
        return [
            response?.items?.map((dto: FoodCategoryDto | null) => new FoodCategory(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createFoodCategory(request: CreateFoodCategoryRequestDto): Promise<void> {
        await this.foodCategoryProxy.createFoodCategory(request);
    }

    public async updateFoodCategory(
        request: UpdateFoodCategoryRequestDto,
        id: string
    ): Promise<void> {
        await this.foodCategoryProxy.updateFoodCategory(id, request);
    }

    public async deleteFoodCategory(id: string): Promise<void> {
        await this.foodCategoryProxy.deleteFoodCategory(id);
    }

    public async getFoodCategoriesOrder(
        id: string
    ): Promise<GetFoodCategoriesOrderResponseDto | null> {
        return await this.foodCategoryProxy.getFoodCategoriesOrdering(id);
    }

    public async updateFoodCategoriesOrder(
        id: string,
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.foodCategoryProxy.updateFoodCategoriesOrdering(id, { method: method, ids: ids });
    }
}
