import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { AmenityService } from 'Services/AmenityService';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption, disableActionMenuItem } from 'Components/action-menu/action-menu';
import { Amenity as AmenityIcon, Townhall as TownhallIcon, Coworking as CoworkingIcon } from 'Components/icons';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import { GetAmenitiesSortColumnDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { MIN_ITEMS_FOR_REORDER, PAGE_SIZE, TABLE_COL_ORDER_DEFAULT_WIDTH } from 'Models/Constants';
import AmenityModal from './create-amenity';
import './index.less';
import { Amenity } from 'Models/Amenities/Amenity';
import ReorderAmenitiesModal from './reorder-amenities-modal';
import Icon from 'Components/icons/Icon';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'amenityCategories',
        nameKey: 'Amenities.amenity_categories',
        items: [],
    },
];

const LocationAmenities: FunctionComponent = () => {
    //#region Hooks
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const amenityService = useService(AmenityService);
    const amenityCategoryService = useService(AmenityCategoryService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [amenities, setAmenities] = useState<Amenity[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const { userStore } = useStores();

    const [actions, setActions] = useState<ActionMenuOption[]>([
        {
            key: 'create',
            title: t('Amenities.create_amenity'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('change_ordering'),
            action: (): void => setReorderModalOpen(true),
        },
    ]);
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'amenities',
            nameKey: 'Location.management_amenities',
        },
    ];

    //#endregion

    //#region Table Content

    const getTableColumnsSortHandler = () => {
        return (amenA: Amenity, amenB: Amenity): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<Amenity>,
        columnKey: GetAmenitiesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getPriceColumn = (amenity: Amenity): ReactNode => {
        const isFree = !(amenity.pricePerBlock > 0) && !(amenity.creditsPerBlock > 0);
        if (isFree) {
            return t('free');
        } else {
            return (
                <div className="price-container">
                    <div>
                        {amenity.minutesPerBlock && amenity.minutesPerBlock === 60
                            ? t('Amenities.price_per_hour', { param1: amenity.pricePerBlock })
                            : t('Amenities.price_per_minutes', {
                                  param1: amenity.pricePerBlock,
                                  param2: amenity.minutesPerBlock,
                              })}
                    </div>
                    <div className="credit-container">
                        <Icon iconName="Coworking" fill={theme['primary-color']} />
                        {(amenity.creditsPerBlock === null || amenity.creditsPerBlock === 0) &&
                            t('free')}
                        {amenity.creditsPerBlock !== null &&
                            amenity.creditsPerBlock > 0 &&
                            (amenity.minutesPerBlock && amenity.minutesPerBlock === 60
                                ? t('Amenities.credits_per_hour', {
                                      param1: amenity.creditsPerBlock,
                                  })
                                : t('Amenities.credits_per_minutes', {
                                      param1: amenity.creditsPerBlock,
                                      param2: amenity.minutesPerBlock,
                                  }))}
                    </div>
                </div>
            );
        }
    }

    const getTableColumns = (currentSort: SorterResult<Amenity>): ColumnType<Amenity>[] => {
        return [
            {
                key: GetAmenitiesSortColumnDto.Order,
                title: t('Table.column_order'),
                dataIndex: 'order',
                render: (key: string): string => key || '0',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetAmenitiesSortColumnDto.Order),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                key: GetAmenitiesSortColumnDto.Name,
                title: t('Amenities.amenity_name'),
                render: (amenity: Amenity): ReactNode | null => {
                    return (
                        <TdWithImage defaultImg={<AmenityIcon />} imgSrc={amenity?.imageUrl}>
                            <div>
                                <div className="title">{amenity?.name}</div>
                                <div className="subtitle townhall">
                                    {amenity?.isTownhallExclusive && (
                                        <>
                                            <TownhallIcon fill={theme['primary-color']} />
                                            {t('townhall')}
                                        </>
                                    )}
                                </div>
                                <div className="subtitle townhall">
                                    {amenity?.isCoworking && (
                                        <>
                                            <CoworkingIcon fill={theme['primary-color']} />
                                            {t('coworking')}
                                        </>
                                    )}
                                </div>
                            </div>
                        </TdWithImage>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetAmenitiesSortColumnDto.Name),
            },
            {
                key: GetAmenitiesSortColumnDto.PricePerBlock,
                title: t('Table.column_price'),
                render: (amenity: Amenity): ReactNode => getPriceColumn(amenity),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetAmenitiesSortColumnDto.PricePerBlock
                ),
            },
            // HEYD-929 - Out of scope for v1
            // {
            //     title: t('Amenities.column_confirmation_needed'),
            //     dataIndex: 'staffConfirmationRequired',
            // },
            {
                key: GetAmenitiesSortColumnDto.CategoryName,
                title: t('Amenities.amenity_category'),
                render: (amenity: Amenity): ReactNode | null => amenity.category?.name || null,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetAmenitiesSortColumnDto.CategoryName
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<Amenity> = {
        columnKey: GetAmenitiesSortColumnDto.Order as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<Amenity>
    ): [GetAmenitiesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetAmenitiesSortColumnDto = tableSort.columnKey as GetAmenitiesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<Amenity>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<Amenity> | SorterResult<Amenity>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            townhallExclusive: filterStore.townhallExclusive,
            coworkingExclusive: filterStore.coworkingExclusive
        });
    };
    //#endregion

    //#region Fetch & Effects

    useEffect(() => {
        const fetchAmenityCategories = async (): Promise<void> => {
            const [items] = await amenityCategoryService.getAmenityCategories({includeEmpty: true});
            if (items) {
                const filter = filterStoreRef.current.advancedFilters?.find(
                    (x) => x.key === 'amenityCategories'
                );
                if (filter?.items) {
                    filter.items = items.map((x) => {
                        return {
                            key: x?.id,
                            displayNameKey: x?.name,
                            checked: true,
                        } as AdvancedFilterItem;
                    });
                }
            }
        };

        fetchAmenityCategories();
    }, [amenityCategoryService]);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetAmenitiesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
            townhallExclusive: boolean;
            coworkingExclusive: boolean;
        }) => {
            const categoriesIds = filterStoreRef.current.checkedItemsByFilterKey(
                'amenityCategories'
            );

            if (categoriesIds.length > 0) {
                setLoading(true);

                try {
                    // call api
                    const [items, totalItems] = await amenityService.getAmenities({
                        pageSize: params.pagination.pageSize || PAGE_SIZE,
                        page: (params.pagination.current || 1) - 1,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        locationIds: [id],
                        searchTerm: params.searchTerm,
                        categoryIds: categoriesIds,
                        isTownhallExclusive: params.townhallExclusive ? true : undefined,
                        isCoworking: params.coworkingExclusive ? true : undefined,
                    });

                    setAmenities(items);

                    // disable reorder option
                    setActions( value =>
                        disableActionMenuItem(
                            value,
                            'reorder',
                            items.length < MIN_ITEMS_FOR_REORDER
                        )
                    );

                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                } finally {
                    setLoading(false);
                }
            } else {
                setAmenities([]);
            }
        },
        [amenityService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
                townhallExclusive: filterStore.townhallExclusive,
                coworkingExclusive: filterStore.coworkingExclusive,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    const onRowClick = (roomId: string | null): void => {
        if (roomId) {
            history.push(`/locations/${id}/management/amenities/${roomId}`);
        }
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    townhallExclusive: filterStore.townhallExclusive,
                    coworkingExclusive: filterStore.coworkingExclusive,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: pagination,
            sortColumn: defaultColumnSort,
            sortDirection: defaultDirectionSort,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            townhallExclusive: filterStore.townhallExclusive,
            coworkingExclusive: filterStore.coworkingExclusive,
        });
        setReorderModalOpen(false);
    };
    //#endregion

    return (
        <div className="Amenities">
            <LocationHeader
                title={t('Location.management_amenities')}
                subTitle={t('Amenities.amenities_subtitle')}
                defaultImg={<AmenityIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                    includeAmenityAccess
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Amenity): string => record.id || ''}
                    dataSource={amenities}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: Amenity) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
            {(userStore.isAdmin || new UserPermissionUtils(userStore).UserCanViewContent([id])) &&
                createModalOpen && (
                    <AmenityModal
                        visible={createModalOpen}
                        onComplete={onCreationComplete}
                        locationId={id}
                    />
                )}
            {(userStore.isAdmin || new UserPermissionUtils(userStore).UserCanViewContent([id])) &&
                reorderModalOpen && (
                    <ReorderAmenitiesModal
                        visible={reorderModalOpen}
                        onComplete={onReorderComplete}
                    />
                )}
        </div>
    );
};

export default LocationAmenities;
