import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import { Close, Key, MainLocation } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import 'react-quill/dist/quill.snow.css';
import './create-building-access-modal.less';
import { BUILDING_ACCESS_SITE_CODE } from 'Models/Constants';
import { UserDetails } from 'Models/Users/UserDetails';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { theme } from 'variant';
import { CreateBuildingAccessSchema } from 'Schemas/CreateBuildingAccessSchema';
import { BuildingAccessService } from 'Services/BuildingAccessService';
import { CreateUserHidAccessRequestDto } from 'Api/Features/UserHidInfo/Dtos/CreateUserHidAccessRequestDto';

const formGutter: [Gutter, Gutter] = [40, 0];

interface CreateBuildingAccessModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: UserDetails;
}

const CreateBuildingAccessModal: FunctionComponent<CreateBuildingAccessModalProps> = ({
    visible,
    onComplete,
    user,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateBuildingAccessSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const buildingAccessService = useService(BuildingAccessService);
    const [locationOptions, setLocationOptions] = useState<SelectCustomOption[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string>();
    //#endregion

    useEffect(() => {
        setLocationOptions(
            locationStore.locations.map(
                (location) =>
                    ({
                        value: location.id,
                        label: location?.name,
                        content: (
                            <div className="location-dropdown-address">
                                {user?.location?.id === location.id && (
                                    <MainLocation
                                        width={15}
                                        height={15}
                                        fill={theme['primary-color']}
                                    />
                                )}
                                {`${location.address?.addressLine1} ${location.address?.city}, ${location.address?.state} ${location.address?.zipCode}`}
                            </div>
                        ),
                    } as SelectCustomOption)
            )
        );
    }, [locationStore.locations, user]);

    useEffect(() => {
        setSelectedLocation(user?.location?.id);
        form.setFieldsValue({
            locationId: user?.location?.id,
        });
    }, [user, form]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Key />,
                title: t(`User.building_access_created_success`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Key />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`User.building_access_confirm_positive`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const values = form.getFieldsValue();
        const data: CreateUserHidAccessRequestDto = {
            locationId: selectedLocation,
            note: values.note,
        };

        if (!(await validateForm(data, false))) return;
        try {
            globalLoadingStore.addLoading(true);
            await buildingAccessService.createUserHidAccess(user?.id!, data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    return (
        <Modal
            visible={visible}
            centered
            title={t('User.create_building_access')}
            className="FormModal CreateBuildingAccessModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <Form layout="vertical" onFinish={submit} form={form}>
                <div className="first-row-container">
                    <div className="info-container">
                        <div>
                            {t('User.building_access_full_name')}{' '}
                            <span className="bold">{`${user?.firstName} ${user?.lastName}`}</span>
                        </div>
                        <div>
                            {t('User.building_access_company_name')}{' '}
                            <span className="bold">{user?.company?.name}</span>
                        </div>
                        <div className="mb-15">
                            {t('User.building_access_site_code')}{' '}
                            <span className="bold">{BUILDING_ACCESS_SITE_CODE}</span>
                        </div>
                    </div>

                    <div>
                        <ValidatedFormItem
                            errors={errors}
                            name="locationId"
                            label={t('User.building_access_building_address')}
                            required
                        >
                            <SelectCustom
                                options={locationOptions}
                                strongLabel={true}
                                onChange={(option: SelectCustomOption): void => {setSelectedLocation(option.value);}}
                                hideSelectedOptions={false}
                                selected={selectedLocation ? [selectedLocation] : undefined}
                            />
                        </ValidatedFormItem>
                    </div>
                </div>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="note"
                            label={t('note')}
                            className="note"
                        >
                            <Input.TextArea />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter} className="building-access-explanation">
                    <Col span={24}>{t('User.building_access_explanation')}</Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('send')}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
    //#endregion
};

export default React.memo(CreateBuildingAccessModal);
