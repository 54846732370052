import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { autorun } from 'mobx';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import FilterStore from 'Stores/FilterStore';
import { Button, Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme, images } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { TableFilters } from 'Components/table-filters';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TdWithImage } from 'Components/td-with-image';
import { Users as UsersIcon, StaffUser as StaffIcon } from 'Components/icons';
import { User } from 'Models/Users/User';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { PAGE_SIZE } from 'Models/Constants';
import ManagerUserModal from './create-manager';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'Administration.administration_title',
        path: 'staff-accesses',
    },
];

const ManagerUsers: FunctionComponent = () => {
    //#region Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const { navigationStore } = useStores();
    const userService = useService(UserService);
    const filterStoreRef = useRef(new FilterStore());
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { userStore } = useStores();
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (usrA: User, usrB: User): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<User>,
        columnKey: GetUsersSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<User>): ColumnType<User>[] => {
        return [
            {
                key: GetUsersSortColumnDto.Name,
                title: t('User.user_name'),
                render: (user: User): ReactNode | null => {
                    return user ? (
                        <TdWithImage defaultImg={<UsersIcon />} imgSrc={user?.imageUrl}>
                            {user?.name}
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetUsersSortColumnDto.Name),
            },
            {
                key: GetUsersSortColumnDto.Email,
                title: t('User.email'),
                render: (user: User): ReactNode | null => <div>{user.email}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetUsersSortColumnDto.Email),
            },
            {
                title: t('User.roles'),
                render: (user: User): ReactNode | null =>
                    user.managementRoles?.[0]?.name
                        ? t(`ManagerUser.ManagementRoleNameDto_${user.managementRoles?.[0]?.name}`)
                        : null,
            },
        ];
    };

    const defaultSortOrder: SorterResult<User> = {
        columnKey: GetUsersSortColumnDto.Name as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<User>
    ): [GetUsersSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetUsersSortColumnDto = tableSort.columnKey as GetUsersSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<User>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<User> | SorterResult<User>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
        });
    };
    //#endregion

    //#region Fetch & Effects
    useEffect(() => {
        navigationStore.clearSubmenu();
    }, [navigationStore]);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetUsersSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
        }) => {
            setLoading(true);
            try {
                // call api
                const [usersData, totalItems] = await userService.getUsers({
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    hasManagementRole: true,
                } as GetUsersRequestDto);
                setUsers(usersData);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [userService]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div>
            <ListSectionHeader
                title={t('ManagerUser.manager_users')}
                subTitle=""
                defaultImg={<StaffIcon fill={theme['primary-color']} />}
                backgroundImageUrl={listSectionHeader}
                action={
                    userStore.isAdmin && (
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('ManagerUser.manager_user_create')}
                        </Button>
                    )
                }
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(user: User): string => user.id}
                    dataSource={users}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(user: User) => ({
                        onClick: (): void => {
                            history.push(user.consoleManagementUrl);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <ManagerUserModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
};

export default ManagerUsers;
