import React, { FunctionComponent, useEffect, useState, useCallback, ReactNode } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { User as UserIcon, Email, Delete } from 'Components/icons';
import { images, theme } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import ManagerUserModal from 'Routes/console/authenticated/staff-accesses/create-manager';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserDetails } from 'Models/Users/UserDetails';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import { ManagementRoleNameDto } from 'Api/Features/Users/Dtos/ManagementRoleNameDto';
import './index.less';

const { usersHeader } = images;
const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const ManagerUserDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        locationStore,
        globalLoadingStore,
        navigationStore,
        confirmationModalStore,
        userStore
    } = useStores();
    const userService = useService(UserService);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [basicInformation, setBasicInformation] = useState<SimpleListItemProps[] | null>(null);
    const [managementRoles, setManagementRoles] = useState<ReactNode| null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const { id } = useParams<{ id: string }>();

    const deleteManagerUser = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`ManagerUser.manager_user_delete_confirm_title`),
                message: t(`ManagerUser.manager_user_delete_confirm_message`),
                positiveText: t(`ManagerUser.manager_user_delete_confirm_positive`),
                negativeText: t(`ManagerUser.manager_user_delete_confirm_negative`),
            }))
        )
            return;

        globalLoadingStore.addLoading();
        await userService.deleteManagerUser(id);
        globalLoadingStore.removeLoading();
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('ManagerUser.manager_user_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('ManagerUser.manager_user_delete'),
            action: (): Promise<void> => deleteManagerUser(),
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const userData: UserDetailsDto | null = await userService.getById(id);

            if (userData) {
                const user: UserDetails = new UserDetails(userData);
                setUser(user);
                navigationStore.setSubmenu(user);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [id, userService, navigationStore, globalLoadingStore]);

    useEffect(() => {
        const disposer = autorun(() => {
            fetch();
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const getBasicInformations = useCallback((): SimpleListItemProps[] => {
        let basicInformations: SimpleListItemProps[] = [];
        if (user) {
            basicInformations = [
                {
                    title: user.email!,
                    avatar: <Email fill={theme['primary-color']} />,
                },
            ];
        }
        return basicInformations;
    }, [user]);

    const getManagementRoles = useCallback((): ReactNode => {
        if(user?.managementRoles?.[0]?.name === ManagementRoleNameDto.Administrator)
            return (
                <div className="role-container">{t(`ManagerUser.ManagementRoleNameDto_${user.managementRoles[0].name}`)}</div>
            );
        else {
            return (
                <div className="role-container">
                    <div className="bold building">{t('ManagerUser.building_manager_for')}</div>
                    <ul>
                        {user?.managementRoles
                            ?.filter((role) => role?.name === ManagementRoleNameDto.BuildingManager)
                            .map((role, i) => {
                                return (<li key={i}>
                                    {
                                        locationStore.locations.find(
                                            (location) =>
                                                location.id === role?.context?.['locationId']
                                        )?.name
                                    }
                                </li>);
                            })}
                    </ul>
                </div>
            );
        }
    }, [user, t, locationStore.locations]);

    useEffect(() => {
        function getData() {
            if (user) {
                setBasicInformation(getBasicInformations);
                setManagementRoles(getManagementRoles);
            }
        }

        getData();
    }, [user, getBasicInformations, getManagementRoles]);

    const onEditComplete = useCallback(
        (success: boolean) => {
            setEditModalOpen(false);
            if (success) {
                fetch();
            }
        },
        [fetch]
    );

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'Administration.administration_title',
            path: 'staff-accesses',
        },
        {
            name: user?.name || '',
            path: user?.id || '',
        },
    ];

    return (
        <div className="ManagerUserDetail">
            <ListSectionHeader
                title={user?.name || ''}
                subTitle={''}
                defaultImg={<UserIcon fill={theme['primary-color']} />}
                imgSrc={user?.imageUrl}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                routes={breadcrumbs}
                action={
                    userStore.isAdmin && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />
            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {basicInformation && (
                            <SimpleList title={t('basic_information')} data={basicInformation} />
                        )}
                    </Col>
                    <Col span={12}>
                        {managementRoles && (
                            <SimpleList title={t('ManagerUser.management_role')}>
                                {managementRoles}
                            </SimpleList>
                        )}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <ManagerUserModal
                    visible={editModalOpen}
                    onComplete={onEditComplete}
                    managerUser={user}
                />
            )}
        </div>
    );
};

export default ManagerUserDetail;
