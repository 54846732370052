import React, { FunctionComponent, useCallback, useEffect, createContext, useState } from 'react';
import { observer } from 'mobx-react';
import { useService, useStores } from 'Hooks';
import { useParams } from 'react-router-dom';
import { FoodMerchant as FoodMerchantModel } from 'Models/FoodMerchant/FoodMerchant';
import { FoodMerchantService } from 'Services/FoodMerchantService';

export const FoodMerchantContext = createContext<FoodMerchantContext | undefined>(undefined);

interface FoodMerchantContext extends FoodMerchantModel {
    fetch: () => void;
}

const FoodMerchant: FunctionComponent = observer(({ children, ...props }: any) => {
    const { merchantId } = useParams<{ merchantId: string }>();
    const { globalLoadingStore } = useStores();
    const foodMerchantService = useService(FoodMerchantService);
    const [merchant, setMerchant] = useState<FoodMerchantContext | undefined>(undefined);

    const fetchFoodMerchant = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const merchantData = await foodMerchantService.getFoodMerchant(merchantId);
            
            const fetch = (): void => {
                fetchFoodMerchant();
            }

            setMerchant({...merchantData, fetch} as FoodMerchantContext|| undefined);

        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [merchantId, foodMerchantService, globalLoadingStore]);

    useEffect(() => {
        fetchFoodMerchant();
    }, [fetchFoodMerchant]);

    return (
        <FoodMerchantContext.Provider value={merchant}>
            {children}
        </FoodMerchantContext.Provider>
    );
});

export default FoodMerchant;
