import React, { FunctionComponent, useEffect, useState, useCallback, useContext } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Delete, Email as EmailIcon, FoodEntree as FoodOrderIcon } from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import FoodOrderDestinationModal from '../order-destination-modal';
import { FoodOrderDestinationService } from 'Services/FoodOrderDestinationService';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import { FoodOrderDestination } from 'Models/FoodOrderDestination/FoodOrderDestination';
import { FoodMerchantContext } from '../../index';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const FoodOrderDestinationsDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, merchantId, orderDestinationId } = useParams<{
        id: string;
        merchantId: string;
        orderDestinationId: string;
    }>();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const foodOrderDestinationService = useService(FoodOrderDestinationService);
    const [data, setData] = useState<FoodOrderDestination | null>(null);
    const [basicInformation, setBasicInformation] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const deleteFoodOrderDestination = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Food.delete_order_destination_confirm_title`),
                message: t(`Food.delete_order_destination_confirm_message`),
                positiveText: t(`Food.delete_order_destination_confirm_positive`),
                negativeText: t(`Food.delete_order_destination_confirm_negative`),
            }))
        )
            return;

        await foodOrderDestinationService.deleteFoodOrderDestination(orderDestinationId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Food.order_destinations_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Food.order_destinations_delete'),
            action: (): Promise<void> => deleteFoodOrderDestination(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const response: FoodOrderDestination | null = await foodOrderDestinationService.getFoodOrderDestination(
                    orderDestinationId
                );
                if (response) {
                    setData(response);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [editModalOpen, orderDestinationId, foodOrderDestinationService, globalLoadingStore, toastStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'merchants',
                nameKey: 'Food.food_and_beverages_merchant',
            },
            {
                path: foodMerchant?.id || '',
                name: foodMerchant?.name || '',
            },
            {
                path: 'order-destinations',
                nameKey: 'Food.order_destinations'
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };



    const getBasicInformation = useCallback((): SimpleListItemProps[] => {
        const basicInformations: SimpleListItemProps[] = [];
        if (data?.email) {
            basicInformations.push({
                title: data.email,
                avatar: (
                    <ImageWithPlaceholder
                        img={<EmailIcon fill={theme['primary-color']} />}
                    />
                ),
            });
        }
        return basicInformations;
    }, [data]);

    useEffect(() => {
        if (data) {
            setBasicInformation(getBasicInformation);
        }
    }, [data, getBasicInformation]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="FoodOrderDestinationsDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={<></>}
                        defaultImg={<FoodOrderIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={
                            (userStore.isAdmin ||
                                new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                                <ActionMenu options={actions} type="primary" trigger="click" />
                            )
                        }
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                <SimpleList
                                    className="basicInformation"
                                    title={t('basic_information')}
                                    data={basicInformation}
                                />
                            </Col>
                        </Row>
                    </Content>
                    <FoodOrderDestinationModal
                        visible={editModalOpen}
                        onComplete={onEditComplete}
                        foodOrderDestination={data}
                        merchantId={merchantId}
                    />
                </>
            )}
        </div>
    );
};

export default FoodOrderDestinationsDetail;
