import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu, Tooltip } from 'antd';
import { CompanyContext } from 'Routes/console/authenticated/companies/id/index';

import './company-navigation.less';
import { ClickParam } from 'antd/es/menu';
import { theme } from 'variant';
import { PresetColorType } from 'antd/lib/_util/colors';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';

interface MenuItem {
    key: string;
    label: string;
    disabled: boolean;
}

const CompanyNavigation: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const context = useContext(CompanyContext);

    const menuItems = (): MenuItem[] => {
        const items = [
            {
                key: `/companies/${id}/profile`,
                label: t('Company.company_profile'),
                disabled: false
            },
            // Out of scope for v1 - All service requests are managed in the locations for now
            // {
            //     key: `/companies/${id}/service`,
            //     label: t('Company.company_service_requests'),
            // },
            // HEYD-928 - Out of scope for v1
            // {
            //     key: `/companies/${id}/agreements`,
            //     label: t('Company.company_agreements'),
            // },
            {
                key: `/companies/${id}/invoices`,
                label: t('Company.company_invoices'),
                disabled: false
            },
            {
                key: `/companies/${id}/messages`,
                label: t('Company.company_messages'),
                disabled: context?.type === CompanyTypeDto.Passport
            },
            {
                key: `/companies/${id}/team`,
                label: t('Company.company_team'),
                disabled: false
            },
        ];

        return items;
    };

    const onMenuItemClicked = (param: ClickParam): void => {
        if (history.location.pathname !== param.key) {
            history.push(param.key);
        }
    };

    const getSelectedKeys = (): string[] => {
        const path = history.location.pathname;
        return menuItems()
            .filter((x) => {
                return path.includes(x.key);
            })
            .map((x) => {
                return x.key;
            });
    };

    const toolTipContent = (): ReactNode => {
        return (
            <div>
                <div className="tooltip-content">{t('Company.this_feature_not_available_for_a')}</div>
                <div className="tooltip-content">{t('Company.passport_company')}</div>
            </div>
        );
    };

    return (
        <div className="CompanyNavigation no-select">
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={getSelectedKeys()}
                onClick={onMenuItemClicked}
            >
                {menuItems().map((x: MenuItem) => {
                    if (x.disabled) {
                        return (
                            <Menu.Item key={x.key} disabled>
                                <Tooltip
                                    color={theme['white'] as PresetColorType}
                                    title={toolTipContent}
                                >
                                    <div>{x.label}</div>
                                </Tooltip>
                            </Menu.Item>
                        );
                    } else return <Menu.Item key={x.key}>{x.label}</Menu.Item>;
                })}
            </Menu>
        </div>
    );
});

export default CompanyNavigation;
