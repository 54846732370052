import { InvitationStatus } from 'Models/BuildingAccess/InvitationStatus';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export interface InvitationStatusTagProps {
    status: InvitationStatus;
}

const InvitationStatusTag: FunctionComponent<InvitationStatusTagProps> = ({status}) => {
    const { t } = useTranslation();
    let className;
    
    switch(status) {
        case InvitationStatus.Acknowledged:
            className = 'primary'
            break;
        case InvitationStatus.Pending:
            className = 'grey'
            break;
        case InvitationStatus.NotSupported:
            className = 'orange'
            break;
        case InvitationStatus.Failed:
        case InvitationStatus.Expired:
        case InvitationStatus.Canceled:
        case InvitationStatus.CancelFailed:
            className = 'error'
            break;
    }

    return <span className={`InvitationStatusTag ${className}`}>{t(`User.invitation_status_${status}`)}</span>;
};

export default InvitationStatusTag;
