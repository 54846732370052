import React, { FunctionComponent, useCallback, useEffect, createContext, useState } from 'react';
import LocationNavigation from 'Components/location-navigation/location-navigation';
import { observer } from 'mobx-react';

import './index.less';
import { Location } from 'Models/Location/Location';
import { useService, useStores } from 'Hooks';
import { useParams } from 'react-router-dom';
import { LocationService } from 'Services/LocationService';
import { observe } from 'mobx';

export const LocationContext = createContext<Location|undefined>(undefined);

const LocationDetail: FunctionComponent = observer(({ children, ...props }: any) => {
    const { id } = useParams();
    const { navigationStore, globalLoadingStore, bookingStore } = useStores();
    const locationService = useService(LocationService);
    const [location, setLocation] = useState<Location|undefined>(undefined);
    const [pendingBookingApprovals, setPendingBookingApprovals] = useState<number>(0);

    const fetchLocation = useCallback( async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const locationData = await locationService.getById(id);
            setLocation(locationData || undefined);
            navigationStore.setSubmenu(locationData);
            bookingStore.setCurrentLocationPendingBookingApprovals(id);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, locationService, globalLoadingStore, navigationStore, bookingStore]);

    useEffect(() => {
        fetchLocation();
    }, [fetchLocation]);

    useEffect( () => {
        const disposer = observe(bookingStore, "currentLocationPendingBookingApprovals", change => {
            setPendingBookingApprovals(change.newValue)
        });
        return (): void => {
            disposer();
        }
    },[bookingStore]);

    return (
        <LocationContext.Provider value={location}>
            <div className="LocationDetail">
                <LocationNavigation pendingBookingApprovals={pendingBookingApprovals} />
                {children}
            </div>
        </LocationContext.Provider>
    );
});

export default LocationDetail;
