import React, { FunctionComponent } from 'react';
import { Modal, Typography } from 'antd';

import './information-modal.less';
import { Close } from 'Components/icons';

const { Title } = Typography;

export interface InformationModalProps {
    titleMessageBlocks: { title: string; messages: string[] }[];
    width?: number | null;
    onClose: () => void;
}

const InformationModal: FunctionComponent<InformationModalProps> = ({
    titleMessageBlocks,
    width,
    onClose
}) => {
    return (
        <Modal
            visible
            className="InformationModal"
            footer={null}
            centered
            width={width ? width : 780}
            closeIcon={<Close />}
            onCancel={onClose}
        >
            {titleMessageBlocks.map((block, index, arr) => (
                <>
                    <Title level={2} className="title">
                        {block.title}
                    </Title>
                    <div className={`message ${index < arr.length - 1 ? 'pb-20' : ''}`}>
                        {block.messages.map((message) => (
                            <>
                                {message}
                                <br></br>
                            </>
                        ))}
                    </div>
                </>
            ))}
        </Modal>
    );
};

export default InformationModal;
