import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Booking as BookingIcon, Delete } from 'Components/icons';
import { images, theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import { useService, useStores } from 'Hooks';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import AmenityCategoryModal from '../amenity-category-modal';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import ListSectionHeader from 'Components/list-section-header/list-section-header';

const { listSectionHeader } = images;
const { Content } = Layout;

const AmenityCategoriesDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { globalLoadingStore, toastStore, confirmationModalStore, navigationStore } = useStores();
    const amenityCategoryService = useService(AmenityCategoryService);
    const [data, setData] = useState<AmenityCategory | null>(null);
    const [icon, setIcon] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const deleteAmenityCategory = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Category.delete_category_confirm_title`),
                message: t(`Category.delete_category_confirm_message`),
                positiveText: t(`Category.delete_category_confirm_positive`),
                negativeText: t(`Category.delete_category_confirm_negative`),
            }))
        )
            return;

        globalLoadingStore.addLoading();
        await amenityCategoryService.deleteAmenityCategory(id);
        globalLoadingStore.removeLoading();
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Amenities.amenity_categories_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Amenities.amenity_categories_delete'),
            action: (): Promise<void> => deleteAmenityCategory(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const response: AmenityCategory | null = await amenityCategoryService.getAmenityCategory(
                    id
                );
                if (response) {
                    setData(response);
                    navigationStore.setSubmenu(response);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [editModalOpen, id, amenityCategoryService, globalLoadingStore, toastStore, navigationStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'settings',
                nameKey: 'settings',
            },
            {
                path: 'amenity-categories',
                nameKey: 'Amenities.amenity_categories',
            },
            {
                path: data?.id || '',
                nameKey: data?.name || '',
            },
        ];
    };

    const getIcon = useCallback((): SimpleListItemProps[] => {
        const icon: SimpleListItemProps[] = [];

        if(data?.iconUrl) {
            icon.push({
                title: data?.name,
                avatar: (
                    <ImageWithPlaceholder
                        defaultImg={<BookingIcon fill={theme['white']}/>}
                        imgSrc={data?.iconUrl}
                    />
                ),
            });
        }

        return icon;
    }, [data]);

    useEffect(() => {
        if (data) {
            setIcon(getIcon);
        }
    }, [data, getIcon]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="AmenityCategoriesDetail">
            {data && (
                <>
                    <ListSectionHeader
                        title={data?.name || ''}
                        subTitle=""
                        imgSrc={data?.imageUrl}
                        defaultImg={<BookingIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={<ActionMenu options={actions} type="primary" trigger="click" />}
                        backgroundImageUrl={listSectionHeader}
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                {icon.length > 0 && (
                                    <SimpleList
                                        className="icon"
                                        title={t('icon')}
                                        data={icon}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {editModalOpen && (<AmenityCategoryModal
                        visible={editModalOpen}
                        onComplete={onEditComplete}
                        amenityCategory={data}
                    />)}
                </>
            )}
        </div>
    );
};

export default AmenityCategoriesDetail;
