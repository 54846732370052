import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Row, Col, Typography } from 'antd';
import { theme } from 'variant';
import {
    Administration as AdministrationIcon,
    Amenity as AmenityIcon,
    Credit,
    Pencil,
    Waiver,
} from 'Components/icons';
import SettingsHeader from 'Components/settings-header';
import { useService, useStores } from 'Hooks';
import EditCreditValueModal from './edit-credit-value-modal';
import './index.less'
import { AmenityService } from 'Services/AmenityService';
import { SimpleList } from 'Components/simple-list';
import { VerdocsService } from 'Services/VerdocsService';
import { GetVerdocsMandatoryDocumentTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsMandatoryDocumentTemplatesRequestDto';
import { VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED } from 'Models/Constants';
import EditVerdocsWaiverModal from './edit-verdocs-waiver-modal';
import { VerdocsMandatoryDocumentTemplateDto } from 'Api/Features/Verdocs/Dtos/VerdocsMandatoryDocumentTemplateDto';

const Settings: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const [creditModalOpen, setCreditModalOpen] = useState(false);
    const { navigationStore, globalLoadingStore, toastStore } = useStores();
    const verdocsService = useService(VerdocsService);
    const amenityService = useService(AmenityService);
    const [creditPrice, setCreditPrice] = useState<number>();
    const [verdocsDocument, setVerdocsDocument] = useState<VerdocsMandatoryDocumentTemplateDto | null>();
    const [verdocsDocumentName, setVerdocsDocumentName] = useState<string>(
        VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED
    );
    const [editWaiverModalOpen, setEditWaiverModalOpen] = useState(false);

    navigationStore.setSubmenu(null);

    const fetchCreditPrice = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const response = await amenityService.getCreditPrice();
            setCreditPrice(response);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, amenityService]);

    useEffect(() => {
        fetchCreditPrice();
    }, [fetchCreditPrice]);

    const fetchVerdocsDocument = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const response = await verdocsService.getVerdocsMandatoryDocumentTemplates(
                {} as GetVerdocsMandatoryDocumentTemplatesRequestDto
            );
            if (response && response.items && response.items.length > 0) {
                setVerdocsDocument(response.items[0]);
                setVerdocsDocumentName(response.items[0]?.templateName ?? '')
            }
            else {
                setVerdocsDocument(null);
                setVerdocsDocumentName(VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED);
            }
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, verdocsService]);

    useEffect(() => {
        fetchVerdocsDocument();
    }, [fetchVerdocsDocument]);

    return (
        <>
            <SettingsHeader
                title={t('settings')}
                subTitle={t('Settings.settings_header_subtitle')}
                defaultImg={<AdministrationIcon fill={theme['primary-color']} />}
            />

            <div className="Settings">
                <Row>
                    <Col span={6}>
                        <Typography.Title level={2}>
                            {t('Amenities.column_amenities')}
                        </Typography.Title>
                        <Link to="/settings/amenity-categories">
                            <div className="nav-btn-container">
                                <div className="nav-btn-content">
                                    <div className="nav-btn-icon-container">
                                        <AmenityIcon fill={theme['primary-color']} />
                                    </div>
                                    <Typography.Text className="nav-btn-text">
                                        {t('Amenities.amenity_categories')}
                                    </Typography.Text>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col span={1} />
                    <Col span={17}>
                        <Typography.Title level={2}>{t('settings')}</Typography.Title>
                        <SimpleList title={t('Verdocs.verdocs_waiver')}>
                            <div className="nav-btn-container-with-edit">
                                <div className="nav-btn-content">
                                    <div className="nav-btn-icon-container-with-edit">
                                        <div className="nav-btn-icon-container">
                                            <Waiver fill={theme['primary-color']} />
                                        </div>
                                        <Typography.Text className="nav-btn-text">
                                            {verdocsDocumentName}
                                        </Typography.Text>
                                    </div>

                                    <div
                                        className="edit-container"
                                        onClick={(): void => setEditWaiverModalOpen(true)}
                                    >
                                        <span className="edit">{t('edit')}</span>
                                        <Pencil fill={theme['primary-color']} />
                                    </div>
                                </div>
                            </div>
                        </SimpleList>
                        <SimpleList title={t('Amenities.credit_value')}>
                            <div className="nav-btn-container-with-edit">
                                <div className="nav-btn-content">
                                    <div className="nav-btn-icon-container-with-edit">
                                        <div className="nav-btn-icon-container">
                                            <Credit fill={theme['primary-color']} />
                                        </div>
                                        <Typography.Text className="nav-btn-text uppercase">
                                            {t('Amenities.credit_per_dollar', {
                                                param1: creditPrice,
                                            })}
                                        </Typography.Text>
                                    </div>

                                    <div
                                        className="edit-container"
                                        onClick={(): void => setCreditModalOpen(true)}
                                    >
                                        <span className="edit">{t('edit')}</span>
                                        <Pencil fill={theme['primary-color']} />
                                    </div>
                                </div>
                            </div>
                        </SimpleList>
                    </Col>
                </Row>
            </div>

            {creditModalOpen && (
                <EditCreditValueModal
                    visible={creditModalOpen}
                    onComplete={async (success: boolean): Promise<void> => {
                        if (success) await fetchCreditPrice();
                        setCreditModalOpen(false);
                    }}
                    currentValue={creditPrice}
                />
            )}

            {editWaiverModalOpen && (
                <EditVerdocsWaiverModal
                    visible={editWaiverModalOpen}
                    onComplete={async (success: boolean): Promise<void> => {
                        if (success) await fetchVerdocsDocument();
                        setEditWaiverModalOpen(false);
                    }}
                    document={verdocsDocument}
                />
            )}
        </>
    );
});

export default Settings;
