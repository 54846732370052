import React, { FunctionComponent, useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HomeHeader } from 'Components/home-header';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import LocationModal from './location-modal';

import './index.less';
import FilterStore from 'Stores/FilterStore';
import { LocationService } from 'Services/LocationService';
import { Location } from 'Models/Location/Location';
import { autorun } from 'mobx';
import { LocationTypeDto } from 'Api/Features/Locations/Dtos/LocationTypeDto';
import { EmptyResults } from 'Components/empty-results';

const Locations: FunctionComponent = observer(() => {
    const { navigationStore, userStore } = useStores();
    const locationService = useService(LocationService);
    const [locations, setLocations] = useState<Location[]>([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [personName, setPersonName] = useState<string>('');
    const filterStoreRef = useRef(new FilterStore());

    useEffect(() => {
        navigationStore.clearSubmenu();
    }, [navigationStore]);

    const createLocation = (): void => {
        setCreateModalOpen(true);
    };

    const fetch = useCallback(async (params: { searchTerm: string; locationType: string }) => {

        const types: LocationTypeDto[] = params.locationType ? [params.locationType] as LocationTypeDto[] : [];

        const [items] = await locationService.getLocations({
            searchTerm: params.searchTerm,
            types: types,
            ids: userStore.isBuildingManager ? userStore.buildingManagerLocationIds : undefined
        });
        setLocations(items);
    }, [locationService, userStore.buildingManagerLocationIds, userStore.isBuildingManager]);

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                searchTerm: filterStore.searchTerm,
                locationType: filterStore.locationType,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch]);

    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                fetch({
                    searchTerm: filterStore.searchTerm,
                    locationType: filterStore.locationType,
                });
            }
        },
        [fetch]
    );

    useEffect(() => {
        setPersonName(userStore.firstName);
    }, [userStore.user, userStore.firstName]);

    return (
        <div className="Locations">
            <HomeHeader
                personName={personName}
                onCreateLocation={createLocation}
            />

            <Row gutter={[40, 40]} className="m-5">
                {locations.length > 0 ? locations.map((location: Location) => {
                    return (
                        <Col span={8} key={location.id!}>
                            <Link to={{ pathname: `locations/${location.id}/service` }}>
                                <div
                                    className="location-image-container"
                                    style={{
                                        backgroundImage:
                                            `url(${location.mainImageUrl})` || undefined,
                                    }}
                                >
                                    <div className="gradient" />
                                    <div className="location-info">
                                        <h1 className="location-name">{location.name}</h1>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    );
                }) : <EmptyResults/> }
            </Row>
            {createModalOpen && (
                <LocationModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
});

export default Locations;
