// GENERATED FILE - DO NOT MODIFY
import { CreateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/CreateFoodMerchantRequestDto';
import { FoodMerchantDto } from 'Api/Features/FoodMerchants/Dtos/FoodMerchantDto';
import { GetFoodMerchantsOrderingResponseDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsOrderingResponseDto';
import { GetFoodMerchantsRequestDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsRequestDto';
import { GetFoodMerchantsResponseDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsResponseDto';
import { UpdateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/UpdateFoodMerchantRequestDto';
import { UpdateFoodMerchantsOrderingRequestDto } from 'Api/Features/FoodMerchants/Dtos/UpdateFoodMerchantsOrderingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FoodMerchantProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFoodMerchant(foodMerchantId: string): Promise<FoodMerchantDto | null> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}",
            { foodMerchantId: foodMerchantId },
            null
        );

        const data: FoodMerchantDto | null = await this.httpClient.get<FoodMerchantDto | null>(uri);
        return data;
    }

    public async getFoodMerchants(request: GetFoodMerchantsRequestDto | null): Promise<GetFoodMerchantsResponseDto | null> {
        const uri = this.buildUri(
            "/food-merchants",
            null,
            { ...request || undefined }
        );

        const data: GetFoodMerchantsResponseDto | null = await this.httpClient.get<GetFoodMerchantsResponseDto | null>(uri);
        return data;
    }

    public async createFoodMerchant(request: CreateFoodMerchantRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/food-merchants",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateFoodMerchantRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateFoodMerchant(foodMerchantId: string, request: UpdateFoodMerchantRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}",
            { foodMerchantId: foodMerchantId },
            null
        );

        await this.httpClient.patch<UpdateFoodMerchantRequestDto | null, void>(uri, request);
    }

    public async deleteFoodMerchant(foodMerchantId: string): Promise<void> {
        const uri = this.buildUri(
            "/food-merchants/{foodMerchantId}",
            { foodMerchantId: foodMerchantId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getFoodMerchantsOrdering(locationId: string): Promise<GetFoodMerchantsOrderingResponseDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}/food-merchants/order",
            { locationId: locationId },
            null
        );

        const data: GetFoodMerchantsOrderingResponseDto | null = await this.httpClient.get<GetFoodMerchantsOrderingResponseDto | null>(uri);
        return data;
    }

    public async updateFoodMerchantsOrdering(locationId: string, request: UpdateFoodMerchantsOrderingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/food-merchants/order",
            { locationId: locationId },
            null
        );

        await this.httpClient.put<UpdateFoodMerchantsOrderingRequestDto | null, void>(uri, request);
    }
}