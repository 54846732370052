import React, { FunctionComponent, useEffect } from 'react';
import { Modal, Form, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row'; 
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import './edit-credit-balance-modal.less'
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Credit from 'Components/icons/Credit';
import { EditCreditBalanceSchema } from 'Schemas/EditCreditBalanceSchema';
import { CompanyService } from 'Services/CompanyService';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditCreditBalanceModalProps {
    visible: boolean;
    companyId?: string;
    balance?: number;
    creditsPerInterval?: number;
    onComplete: (success: boolean) => void;
}

const EditCreditBalanceModal: FunctionComponent<EditCreditBalanceModalProps> = ({
    visible,
    companyId,
    balance,
    creditsPerInterval,
    onComplete,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditCreditBalanceSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const companyService = useService(CompanyService);
    //#endregion

    //#region Effects
    useEffect(() => {
        form.setFieldsValue({
            balance: balance ?? undefined,
            creditsPerInterval: creditsPerInterval,
        });
    }, [balance, creditsPerInterval, form])
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Credit />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`Company.credit_balance_confirm_positive`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            balance: formValues.balance === '' ? undefined : formValues.balance,
            creditsPerInterval: formValues.creditsPerInterval === '' ? undefined : formValues.creditsPerInterval
        };

        if (!(await validateForm(data, false))) return;
        try {
            globalLoadingStore.addLoading();
            await companyService.updateCompanyCreditBalance(companyId!, { balance: data.balance });
            await companyService.updateCompanyCreditsPerInterval(companyId!, { creditsPerInterval: data.creditsPerInterval });
            toastStore.toast({
                type: 'success',
                messageKey: `Amenities.credit_edit_success`,
            });
            dismiss(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    return (
        <Modal
            visible={visible}
            centered
            title={t('Company.company_edit_credit_balance')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="EditCreditBalance">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter} className="value-container">
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="balance"
                                label={t('credit_balance')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    decimalScale={0}
                                    allowNegative={false}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { value } = values;
                                        form.setFieldsValue({ balance: value });
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="creditsPerInterval"
                                label={t('Company.monthly_credits')}
                                required
                            >
                                <NumberFormat
                                    className="ant-input"
                                    decimalScale={0}
                                    allowNegative={false}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default React.memo(EditCreditBalanceModal);
