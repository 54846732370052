import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Menu, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Close } from 'Components/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import './reorder-amenity-categories-modal.less';
import { SelectParam } from 'antd/es/menu';
import { useService } from 'Hooks';
import { useParams } from 'react-router-dom';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import { theme } from 'variant';

const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: theme['black-4'] }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => <tbody {...props} />);

interface TableRowElement {
    key: string | null | undefined;
    name: string | null | undefined;
    index: number | null | undefined;
}

interface ReorderAmenityCategoriesModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
}

const ReorderAmenityCategoriesModal: FunctionComponent<ReorderAmenityCategoriesModalProps> = ({
    visible,
    onComplete,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const amenityCategoryService = useService(AmenityCategoryService);
    const [data, setData] = useState<any>([]);
    const [orderMethod, setOrderMethod] = useState<OrderingMethodDto | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            className: 'drag-visible',
        },
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => orderMethod === OrderingMethodDto.Manual && <DragHandle />,
        },
    ];

    const orderItemsAlphabetically = (items: AmenityCategory[]): AmenityCategory[] => {
        return items.sort((a: any, b: any) => a.name.localeCompare(b.name))
    };

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            setLoading(true);

            let [items] = await amenityCategoryService.getAmenityCategories({includeEmpty: true});

            const order = await amenityCategoryService.getAmenityCategoriesOrder();
            if(order?.method !== OrderingMethodDto.Manual) {
                items = orderItemsAlphabetically(items);
                setOrderMethod(OrderingMethodDto.Alphabetical);
            }
            else {
                setOrderMethod(OrderingMethodDto.Manual);
            }

            let newOrder = 0;
            setData(
                items.map(
                    (row): TableRowElement => {
                        return {
                            key: row.id,
                            name: row.name,
                            index: newOrder++,
                        };
                    }
                )
            );
            setLoading(false);
        };
        fetch();
    }, [id, visible, amenityCategoryService]);

    useEffect(() => {
        if (orderMethod === OrderingMethodDto.Alphabetical) {
            let newOrder = 0;
            setData((prevState: any) => {
                return orderItemsAlphabetically(prevState).map(
                    (row): TableRowElement => {
                        return {
                            key: row.id,
                            name: row.name,
                            index: newOrder++,
                        };
                    }
                );
            });
        }
    }, [orderMethod]);

    const dismiss = (success = false): void => {
        onComplete(success);
    };

    const submit = async (): Promise<void> => {
        const ids = orderMethod === OrderingMethodDto.Manual ? data.map((x: TableRowElement) => x.key) : [];
        await amenityCategoryService.updateAmenityCategoriesOrder(orderMethod || OrderingMethodDto.Alphabetical, ids);
        dismiss(true);
    };

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el);
            setData(newData);
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props: any) => (
        <>
            <SortableContainerWrapper
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        </>
    );

    const onSelectItem = (param: SelectParam): void => {
        setOrderMethod(param.key as OrderingMethodDto);
    };

    return (
        <Modal
            className="FormModal ReorderModal ReorderAmenityCategoriesModal"
            visible={visible}
            closeIcon={<Close />}
            width={800}
            title={
                <>
                    <div className="title">{t('Amenities.amenity_categories_reorder')}</div>
                    <Menu
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={orderMethod ? [orderMethod] : []}
                        selectedKeys={orderMethod ? [orderMethod] : []}
                        onSelect={onSelectItem}
                    >
                        <Menu.Item key={OrderingMethodDto.Alphabetical}>{t('OrderingMethod.Alphabetical')}</Menu.Item>
                        <Menu.Item key={OrderingMethodDto.Manual}>{t('OrderingMethod.Manual')}</Menu.Item>
                    </Menu>
                </>
            }
            footer={null}
            onCancel={(): void => dismiss()}
            maskClosable={false}
            centered
        >
            <Table
                pagination={false}
                showHeader={false}
                loading={loading}
                dataSource={data}
                columns={columns}
                rowKey="index"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />

            <div className="actions">
                <Button
                    type="default"
                    className="secondary negative"
                    htmlType="button"
                    onClick={(): void => dismiss()}
                >
                    {t('cancel')}
                </Button>
                <Button
                    type="primary"
                    className="positive"
                    htmlType="submit"
                    onClick={(): Promise<void> => submit()}
                >
                    {t('save_new_order')}
                </Button>
            </div>
        </Modal>
    );
};

export default ReorderAmenityCategoriesModal;
