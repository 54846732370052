// GENERATED FILE - DO NOT MODIFY
import { CheckUserMandatoryDocumentStatusResponseDto } from 'Api/Features/Verdocs/Dtos/CheckUserMandatoryDocumentStatusResponseDto';
import { CreateUserVerdocsDocumentRequestDto } from 'Api/Features/Verdocs/Dtos/CreateUserVerdocsDocumentRequestDto';
import { CreateVerdocsMandatoryDocumentTemplateRequestDto } from 'Api/Features/Verdocs/Dtos/CreateVerdocsMandatoryDocumentTemplateRequestDto';
import { GetUserVerdocsDocumentsRequestDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentsRequestDto';
import { GetUserVerdocsDocumentsResponseDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentsResponseDto';
import { GetUserVerdocsDocumentWebLinkRequestDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentWebLinkRequestDto';
import { GetUserVerdocsDocumentWebLinkResponseDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentWebLinkResponseDto';
import { GetVerdocsMandatoryDocumentTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsMandatoryDocumentTemplatesRequestDto';
import { GetVerdocsMandatoryDocumentTemplatesResponseDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsMandatoryDocumentTemplatesResponseDto';
import { GetVerdocsTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsTemplatesRequestDto';
import { GetVerdocsTemplatesResponseDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsTemplatesResponseDto';
import { UserVerdocsDetailsDto } from 'Api/Features/Verdocs/Dtos/UserVerdocsDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class VerdocsProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUserVerdocsDetails(userId: string): Promise<UserVerdocsDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-details",
            { userId: userId },
            null
        );

        const data: UserVerdocsDetailsDto | null = await this.httpClient.get<UserVerdocsDetailsDto | null>(uri);
        return data;
    }

    public async getUserVerdocsDocuments(userId: string, request: GetUserVerdocsDocumentsRequestDto | null): Promise<GetUserVerdocsDocumentsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-documents",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetUserVerdocsDocumentsResponseDto | null = await this.httpClient.get<GetUserVerdocsDocumentsResponseDto | null>(uri);
        return data;
    }

    public async createUserVerdocsDocument(userId: string, request: CreateUserVerdocsDocumentRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-documents",
            { userId: userId },
            null
        );

        const data: string = await this.httpClient.post<CreateUserVerdocsDocumentRequestDto | null, string>(uri, request);
        return data;
    }

    public async getUserVerdocsDocumentWebLink(userId: string, documentId: string, request: GetUserVerdocsDocumentWebLinkRequestDto | null): Promise<GetUserVerdocsDocumentWebLinkResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-documents/{documentId}/web-link",
            { userId: userId, documentId: documentId },
            { ...request || undefined }
        );

        const data: GetUserVerdocsDocumentWebLinkResponseDto | null = await this.httpClient.get<GetUserVerdocsDocumentWebLinkResponseDto | null>(uri);
        return data;
    }

    public async getUserVerdocsDocumentFile(userId: string, documentId: string): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-documents/{documentId}/file",
            { userId: userId, documentId: documentId },
            null
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async getVerdocsTemplates(request: GetVerdocsTemplatesRequestDto | null): Promise<GetVerdocsTemplatesResponseDto | null> {
        const uri = this.buildUri(
            "/verdocs-templates",
            null,
            { ...request || undefined }
        );

        const data: GetVerdocsTemplatesResponseDto | null = await this.httpClient.get<GetVerdocsTemplatesResponseDto | null>(uri);
        return data;
    }

    public async getVerdocsMandatoryDocumentTemplates(request: GetVerdocsMandatoryDocumentTemplatesRequestDto | null): Promise<GetVerdocsMandatoryDocumentTemplatesResponseDto | null> {
        const uri = this.buildUri(
            "/verdocs-mandatory-document-templates",
            null,
            { ...request || undefined }
        );

        const data: GetVerdocsMandatoryDocumentTemplatesResponseDto | null = await this.httpClient.get<GetVerdocsMandatoryDocumentTemplatesResponseDto | null>(uri);
        return data;
    }

    public async createVerdocsMandatoryDocumentTemplate(request: CreateVerdocsMandatoryDocumentTemplateRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/verdocs-mandatory-document-templates",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateVerdocsMandatoryDocumentTemplateRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteVerdocsMandatoryDocumentTemplate(mandatoryTemplateId: string): Promise<void> {
        const uri = this.buildUri(
            "/verdocs-mandatory-document-templates/{mandatoryTemplateId}",
            { mandatoryTemplateId: mandatoryTemplateId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async checkUserMandatoryDocumentStatus(userId: string): Promise<CheckUserMandatoryDocumentStatusResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/verdocs-mandatory-document-status-check",
            { userId: userId },
            null
        );

        const data: CheckUserMandatoryDocumentStatusResponseDto | null = await this.httpClient.post<void, CheckUserMandatoryDocumentStatusResponseDto | null>(uri, );
        return data;
    }
}