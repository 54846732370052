import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { Col, Layout, Row } from 'antd';
import { theme, images } from 'variant';
import UserHeader from 'Components/user-header/user-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Message as MessageIcon } from 'Components/icons';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserMessage } from 'Models/Messages/UserMessage';
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from 'Models/Constants';
import './index.less';

const { listSectionHeader } = images;
const { Content } = Layout;

const UserMessageDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    const { globalLoadingStore, navigationStore } = useStores();
    const [loading, setLoading] = useState(true);

    const { id, messageId } = useParams<{ id: string; messageId: string }>();
    const userService = useService(UserService);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [message, setMessage] = useState<UserMessage | undefined>(undefined);
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'users',
            nameKey: 'User.users',
        },
        {
            path: id,
            name: user?.name || t('User.user'),
        },
        {
            path: 'messages',
            nameKey: 'User.user_messages',
        },
        {
            path: messageId,
            nameKey: 'Message.message_details',
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const userData: UserDetailsDto | null = await userService.getById(id);

            if (userData) {
                const user: UserDetails = new UserDetails(userData);
                setUser(user);
                navigationStore.setSubmenu(user);

                const messageData = await userService.getUserMessageById(user.id, messageId);
                if (messageData) {
                    setMessage(new UserMessage(messageData));
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [userService, id, messageId, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <>
            <UserHeader
                title={t('User.user_messages')}
                subTitle={t('User.user_messages_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
            />
            <Layout>
                <Content className="messageDetailContent">
                    <Row gutter={16}>
                        <Col span={24}>
                            <h1 className="messageTitle">{message?.title || ''}</h1>
                            <h2 className="messageInfo">
                                {t('Message.message_details_from')}&nbsp;{message?.fromName || '-'}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_to')}&nbsp;
                                {user?.name || ''}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_date')}&nbsp;
                                {moment(message?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}
                            </h2>
                            <h2 className="messageInfo">
                                {t('Message.message_details_type')}&nbsp;
                                {t(`MessageNotificationType.${message?.type}`)}
                            </h2>
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: message?.body || '' }} />
                            <hr className="closingLine" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
});

export default UserMessageDetail;
