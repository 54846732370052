import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
    useRef,
    ReactNode, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { CompanyService } from 'Services/CompanyService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table, Button } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import CompanyHeader from 'Components/company-header/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { Message as MessageIcon, User as UserIcon } from 'Components/icons';
import { theme, images } from 'variant';
import { CompanyMessage } from 'Models/Messages/CompanyMessage';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { PAGE_SIZE, DATE_API_FORMAT, TABLE_DATE_FORMAT } from 'Models/Constants';
import { GetCompanyMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetCompanyMessagesRequestDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import CreateCompanyMessageModal from './create-message';
import './index.less';
import { CompanyContext } from '../index';
import { CompanyMessageTypes } from 'Models/Messages/CompanyMessageTypes';
import { TdWithImage } from 'Components/td-with-image';
import Icon from 'Components/icons/Icon';
import { NotificationTypeDto } from 'Api/Features/Notifications/Dtos/NotificationTypeDto';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'type',
        nameKey: 'Message.message_type',
        items: [
            {
                key: CompanyMessageTypes.GeneralUserMessage,
                displayNameKey: `Message.message_type_${CompanyMessageTypes.GeneralUserMessage}`,
                checked: true,
            },
            {
                key: CompanyMessageTypes.PackageDelivery,
                displayNameKey: `Message.message_type_${CompanyMessageTypes.PackageDelivery}`,
                checked: true,
            },
            {
                key: CompanyMessageTypes.Announcement,
                displayNameKey: `Message.message_type_${CompanyMessageTypes.Announcement}`,
                checked: true,
            },
        ],
    },
];

const CompanyMessages: FunctionComponent = observer(() => {
    //#region Hooks
    const { id } = useParams<{id: string}>();
    const { t } = useTranslation();
    const history = useHistory();
    const company = useContext(CompanyContext);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<CompanyMessage[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const companyService = useService(CompanyService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { userStore } = useStores();
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: company?.name || t('Company.company'),
        },
        {
            path: 'messages',
            nameKey: 'Company.company_messages',
        },
    ];
    //#endregion

    //#region Table Content
    const getFilterGroupOptions = (filterGroup: string, omitAll = true): string[] => {
        const filterStore = filterStoreRef.current;
        return filterStore.getGroupOptions(filterGroup, omitAll);
    };

    const getTableColumnsSortHandler = () => {
        return (msgA: CompanyMessage, msgB: CompanyMessage): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<CompanyMessage>,
        columnKey: GetMessagesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getMessageTypeIconName = (messageType: NotificationTypeDto | null | undefined): string  => {
        switch(messageType){
            case NotificationTypeDto.Announcement: return NotificationTypeDto.Announcement;
            case NotificationTypeDto.GeneralUserMessage: return "GeneralMessage";
            case NotificationTypeDto.PackageDelivery: return "Box";
            default: return NotificationTypeDto.Announcement;
        }
    }

    const getTableColumns = (
        currentSort: SorterResult<CompanyMessage>
    ): ColumnType<CompanyMessage>[] => {
        return [
            {
                key: GetMessagesSortColumnDto.Type,
                title: t('Message.message_type'),
                render: (message: CompanyMessage): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<Icon iconName={getMessageTypeIconName(message.type)} />}
                        className="primary-4"
                    >
                         <div>{message?.type ? t(`MessageNotificationType.${message.type}`) : '-'}</div>
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Type),
            },
            {
                key: GetMessagesSortColumnDto.Title,
                title: t('Message.message_title'),
                render: (message: CompanyMessage): ReactNode | null => <div>{message?.title}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Title),
            },
            {
                key: GetMessagesSortColumnDto.Body,
                title: t('Message.message_body'),
                render: (message: CompanyMessage): ReactNode | null => <div>{message?.body}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Body),
            },
            {
                key: GetMessagesSortColumnDto.CreatedByUserName,
                title: t('Message.message_creator'),
                render: (message: CompanyMessage): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<UserIcon />}
                        imgSrc={message?.createdByUser?.imageUrl}
                    >
                        <div className="title">{message?.fromName}</div>
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetMessagesSortColumnDto.CreatedByUserName
                ),
            },
            {
                key: GetMessagesSortColumnDto.Date,
                title: t('Message.message_date'),
                render: (message: CompanyMessage): ReactNode | null => (
                    <div>{moment(message?.date, DATE_API_FORMAT).format(TABLE_DATE_FORMAT)}</div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Date),
            },
        ];
    };

    const defaultSortOrder: SorterResult<CompanyMessage> = {
        columnKey: GetMessagesSortColumnDto.Date as Key,
        order: 'descend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<CompanyMessage>
    ): [GetMessagesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetMessagesSortColumnDto = tableSort.columnKey as GetMessagesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<CompanyMessage>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<CompanyMessage> | SorterResult<CompanyMessage>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
        });
    };
    //#endregion

    //#region Fetch & Effects
    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetMessagesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
        }) => {
            setLoading(true);
            try {
                const [messages, totalItems] = await companyService.getCompanyMessages(
                    id,
                    {
                        pageSize: params.pagination.pageSize || PAGE_SIZE,
                        page: (params.pagination.current || 1) - 1,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        searchTerm: params.searchTerm,
                        types: getFilterGroupOptions('type')
                    } as GetCompanyMessagesRequestDto
                );

                if (messages) {
                    setMessages(messages);

                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [companyService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div className="Messages">
            <CompanyHeader
                title={t('Company.company_messages')}
                subTitle={t('Company.company_messages_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [company?.location?.id].concat(
                                company?.locations
                                    ? company.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && (
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('Message.message_send_message')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(message: CompanyMessage): string => message.id!}
                    dataSource={messages}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(message: CompanyMessage) => ({
                        onClick: (): void => {
                            history.push(message.getConsoleUrl(company!.id));
                        },
                    })}
                />
            </Content>
            <CreateCompanyMessageModal
                company={company}
                visible={createModalOpen}
                onComplete={onCreationComplete}
                defaultMessageType={CompanyMessageTypes.GeneralUserMessage}
            />
        </div>
    );
});

export default CompanyMessages;
