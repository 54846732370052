import { InvoiceDetailsDto } from 'Api/Features/Invoices/Dtos/InvoiceDetailsDto';
import { Invoice } from './Invoice';

export class InvoiceDetails extends Invoice {
    constructor(dto: InvoiceDetailsDto) {
        super(dto);
        this.paymentErrorMessage = dto.paymentErrorMessage;
        this.paymentErrorDescription = dto.paymentErrorDescription;
    }

    paymentErrorMessage?: string | null;
    paymentErrorDescription?: string | null;

    get hasErrorMessage(): boolean {
        return !!this.paymentErrorMessage || !!this.paymentErrorDescription;
    }

    get errorMessage(): string {
        const errorMsg: string[] = [];

        if(this.paymentErrorDescription) {
            errorMsg.push(this.paymentErrorDescription);
        }

        return errorMsg.join(' ');
    }
}