// GENERATED FILE - DO NOT MODIFY
import { CreateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/CreateFoodOrderDestinationRequestDto';
import { FoodOrderDestinationDto } from 'Api/Features/FoodOrderDestinations/Dtos/FoodOrderDestinationDto';
import { GetFoodOrderDestinationsRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/GetFoodOrderDestinationsRequestDto';
import { GetFoodOrderDestinationsResponseDto } from 'Api/Features/FoodOrderDestinations/Dtos/GetFoodOrderDestinationsResponseDto';
import { UpdateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/UpdateFoodOrderDestinationRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FoodOrderDestinationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFoodOrderDestination(foodOrderDestinationId: string): Promise<FoodOrderDestinationDto | null> {
        const uri = this.buildUri(
            "/food-order-destinations/{foodOrderDestinationId}",
            { foodOrderDestinationId: foodOrderDestinationId },
            null
        );

        const data: FoodOrderDestinationDto | null = await this.httpClient.get<FoodOrderDestinationDto | null>(uri);
        return data;
    }

    public async getFoodOrderDestinations(request: GetFoodOrderDestinationsRequestDto | null): Promise<GetFoodOrderDestinationsResponseDto | null> {
        const uri = this.buildUri(
            "/food-order-destinations",
            null,
            { ...request || undefined }
        );

        const data: GetFoodOrderDestinationsResponseDto | null = await this.httpClient.get<GetFoodOrderDestinationsResponseDto | null>(uri);
        return data;
    }

    public async createFoodOrderDestination(request: CreateFoodOrderDestinationRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/food-order-destinations",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateFoodOrderDestinationRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateFoodOrderDestination(foodOrderDestinationId: string, request: UpdateFoodOrderDestinationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/food-order-destinations/{foodOrderDestinationId}",
            { foodOrderDestinationId: foodOrderDestinationId },
            null
        );

        await this.httpClient.patch<UpdateFoodOrderDestinationRequestDto | null, void>(uri, request);
    }

    public async deleteFoodOrderDestination(foodOrderDestinationId: string): Promise<void> {
        const uri = this.buildUri(
            "/food-order-destinations/{foodOrderDestinationId}",
            { foodOrderDestinationId: foodOrderDestinationId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}