import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { theme } from 'variant';
import { useTranslation } from 'react-i18next';
import { MIN_ITEMS_FOR_REORDER, PAGE_SIZE, TABLE_COL_ORDER_DEFAULT_WIDTH } from 'Models/Constants';
import { Layout, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import FilterStore from 'Stores/FilterStore';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import {
    ColumnType,
    Key,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { FoodItemService } from 'Services/FoodItemService';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { ActionMenuOption, disableActionMenuItem } from 'Components/action-menu/action-menu';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { FoodSandwich as FoodItemsIcon } from 'Components/icons';
import LocationHeader from 'Components/location-header/location-header';
import { TdWithImage } from 'Components/td-with-image';
import { TableFilters } from 'Components/table-filters';
import { ActionMenu } from 'Components/action-menu';
import { FoodItem } from 'Models/FoodItem/FoodItem';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import FoodItemModal from './food-item-modal';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { GetFoodItemsSortColumnDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsSortColumnDto';
import ReorderFoodItemsModal from './reorder-food-items-modal';
import { FoodMerchantContext } from '../index';
import { GetFoodItemsRequestDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsRequestDto';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'foodCategories',
        nameKey: 'Food.food_and_beverages_categories',
        items: [],
    },
];

const FoodItems: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, merchantId } = useParams();
    const history = useHistory();
    const foodMerchant = useContext(FoodMerchantContext);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const foodItemService = useService(FoodItemService);
    const foodCategoryService = useService(FoodCategoryService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<FoodItem[]>([]);
    const { userStore } = useStores();

    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const [actions, setActions] = useState<ActionMenuOption[]>([
        {
            key: 'create',
            title: t('Food.food_and_beverages_items_create'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('change_ordering'),
            action: (): void => setReorderModalOpen(true),
        },
    ]);

    const pageTitle = t('Food.merchant_items_pagetitle', { param1: foodMerchant?.name });

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_title',
        },
        {
            path: 'merchants',
            nameKey: 'Food.food_and_beverages_merchant',
        },
        {
            path: foodMerchant?.id || '',
            name: foodMerchant?.name || '',
        },
        {
            path: 'items',
            nameKey: 'Food.merchant_items_breadcrumb',
        },
    ];

    useEffect(() => {
        const fetchFoodCategories = async (): Promise<void> => {
            const [items] = await foodCategoryService.getFoodCategories({
                merchantIds: [merchantId],
            });
            if (items) {
                const filter = filterStoreRef.current.advancedFilters?.find(
                    (x) => x.key === 'foodCategories'
                );
                if (filter?.items) {
                    filter.items = items.map((x) => {
                        return {
                            key: x?.id,
                            displayNameKey: x?.name,
                            checked: true,
                        } as AdvancedFilterItem;
                    });
                }
            }
        };

        fetchFoodCategories();
    }, [foodCategoryService, merchantId]);

    const getTableColumnsSortHandler = () => {
        return (itemA: FoodItem, itemB: FoodItem): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<FoodItem>,
        columnKey: GetFoodItemsSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (currentSort: SorterResult<FoodItem>): ColumnType<FoodItem>[] => {
        return [
            {
                key: GetFoodItemsSortColumnDto.Order,
                title: t('Table.column_order'),
                dataIndex: 'order',
                render: (key: string): string => key || '0',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetFoodItemsSortColumnDto.Order),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                key: GetFoodItemsSortColumnDto.Name,
                title: t('Table.column_item'),
                render: (foodItem: FoodItem): ReactNode => (
                    <TdWithImage
                        defaultImg={<FoodItemsIcon fill={theme['white']} />}
                        imgSrc={foodItem?.imageUrl}
                    >
                        {foodItem?.name}
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetFoodItemsSortColumnDto.Name),
            },
            {
                title: t('Table.column_price'),
                render: (foodItem: FoodItem): ReactNode => (
                    <div className={foodItem.inPromotion ? 'promoted-item': ''} dangerouslySetInnerHTML={{ __html: foodItem.priceWithPromotionString }} />
                ),
            },
            {
                key: GetFoodItemsSortColumnDto.CategoryName,
                title: t('Table.column_category'),
                render: (foodItem: FoodItem): ReactNode => <>{foodItem.category?.name}</>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetFoodItemsSortColumnDto.CategoryName
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<FoodItem> = {
        columnKey: GetFoodItemsSortColumnDto.Order as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<FoodItem>
    ): [GetFoodItemsSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetFoodItemsSortColumnDto = tableSort.columnKey as GetFoodItemsSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<FoodItem>[]>(defaultTableColumns);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetFoodItemsSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            categoriesIds: string[];
        }) => {
            setLoading(true);
            try {
                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    merchantIds: [merchantId],
                    searchTerm: params.searchTerm,
                } as GetFoodItemsRequestDto;

                if (params.categoriesIds.length > 0) {
                    request.categoryIds = params.categoriesIds;
                }

                const [items, totalItems] = await foodItemService.getFoodItems(request);

                setData(items);

                // disable reorder option
                setActions((value) =>
                    disableActionMenuItem(value, 'reorder', items.length < MIN_ITEMS_FOR_REORDER)
                );

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [foodItemService, merchantId]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<FoodItem> | SorterResult<FoodItem>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            categoriesIds: filterStore.checkedItemsByFilterKey('foodCategories'),
        });
    };

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                categoriesIds: filterStore.checkedItemsByFilterKey('foodCategories'),
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);

    const onRowClick = (itemId: string | null): void => {
        if (itemId) {
            history.push(`/locations/${id}/management/merchants/${merchantId}/items/${itemId}`);
        }
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                fetch({
                    pagination: pagination,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    categoriesIds: filterStore.checkedItemsByFilterKey('foodCategories'),
                });
            }
        },
        [fetch, pagination, defaultColumnSort, defaultDirectionSort]
    );

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: pagination,
            sortColumn: defaultColumnSort,
            sortDirection: defaultDirectionSort,
            searchTerm: filterStore.searchTerm,
            categoriesIds: filterStore.checkedItemsByFilterKey('foodCategories'),
        });
        setReorderModalOpen(false);
    };

    return (
        <div className="FoodItems">
            <LocationHeader
                title={pageTitle}
                subTitle={t('Food.food_and_beverages_items_subtitle')}
                defaultImg={<FoodItemsIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: FoodItem): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: FoodItem) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <FoodItemModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
            {reorderModalOpen && (
                <ReorderFoodItemsModal visible={reorderModalOpen} onComplete={onReorderComplete} />
            )}
        </div>
    );
};

export default FoodItems;
