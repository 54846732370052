import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { VerdocsDocumentDto } from 'Api/Features/Verdocs/Dtos/VerdocsDocumentDto';
import { VerdocsDocumentRecipient } from './VerdocsDocumentRecipient';

export class VerdocsDocument implements VerdocsDocumentDto {
    constructor(dto: VerdocsDocumentDto) {
        Object.assign(this, dto);
    }
    
    id?: string | null;
    name?: string | null;
    templateId?: string | null;
    status?: string | null;
    createdAt?: string;
    updatedAt?: string;
    recipients?: (VerdocsDocumentRecipient | null)[] | null;

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if (this.status === 'pending') {
            return StatusBadgeStatus.Pending;
        } else if (this.status === 'in progress') {
            return StatusBadgeStatus.InProgress;
        }
        else if (this.status === 'canceled') {
            return StatusBadgeStatus.Canceled;
        }
        else if (this.status === 'declined') {
            return StatusBadgeStatus.Declined;
        }
        else if (this.status === 'complete') {
            return StatusBadgeStatus.Complete;
        }
        return undefined;
    }
}
