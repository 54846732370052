import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce';
import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Select,
    Typography,
    Button,
    DatePicker,
    Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import { Close, Company, LowercaseI, PineappleSmall, User as UserIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { ImagePicker } from 'Components/image-picker';
import { SelectCustom } from 'Components/select-custom';
import { SelectCountry } from 'Components/select-country';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { useFormValidation, useService, useStores } from 'Hooks';
import { CreateCompanySchema, EditCompanySchema } from 'Schemas';
import { CompanyService } from 'Services/CompanyService';
import { UserService } from 'Services/UserService';
import { User } from 'Models/Users/User';
import { DATE_API_FORMAT, DEBOUNCE_DELAY } from 'Models/Constants';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { CreateCompanyRequestDto } from 'Api/Features/Companies/Dtos/CreateCompanyRequestDto';
import { UpdateCompanyRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyRequestDto';
import { CreateCompanyRequestMainContactDto } from 'Api/Features/Companies/Dtos/CreateCompanyRequestMainContactDto';
import { SectorOfBusinessDto } from 'Api/Features/Companies/Dtos/SectorOfBusinessDto';
import { CompanyNumberOfEmployeesDto } from 'Api/Features/Companies/Dtos/CompanyNumberOfEmployeesDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './edit-create-company.less';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { theme } from 'variant';
import ConvertToHeydayModal from './id/ConvertToHeyday';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import Icon from 'Components/icons/Icon';
import { SelectValue } from 'antd/lib/select';
import InformationModal from 'Components/information-modal/information-modal';
import { GetSectorsOfBusinessResponseItemDto } from 'Api/Features/Companies/Dtos/GetSectorsOfBusinessResponseItemDto';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';

const { Option } = Select;
const { Title, Text } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface CompanyModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    company?: CompanyDto;
    isEditing?: boolean;
}

const EditCreateCompanyModal: FunctionComponent<CompanyModalProps> = ({
    visible,
    onComplete,
    company,
    isEditing,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { locationStore, globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const companyService = useService(CompanyService);
    const userService = useService(UserService);
    const [contactSearchResults, setContactSearchResults] = useState<User[]>([]);
    const [contactSearchTerm, setContactSearchTerm] = useState('');
    const [contactMaxResults, setContactMaxResults] = useState(false);
    const [contactCurrentPage, setContactCurrentPage] = useState(0);
    const [contactOptions, setContactOptions] = useState<SelectCustomOption[]>([]);
    const [selectedContactId, setSelectedContactId] = useState<string>();
    const [selectedContactOption, setSelectedContactOption] = useState<SelectCustomOption[]>([]);
    const contactsPageSize = 25;
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        company ? EditCompanySchema : CreateCompanySchema,
        form
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [isHeydayCheckState, setIsHeydayCheckState] = useState(
        company?.type === CompanyTypeDto.Heyday
    );
    const [convertToHeydayModalOpen, setConvertToHeydayModalOpen] = useState(false);
    const [selectedMainLocationId, setSelectedMainLocationId] = useState<string | undefined>(
        company?.location?.id
    );
    const [selectedOtherLocations, setSelectedOtherLocations] = useState<string[] | undefined>();
    const [mainLocationInformationModalOpen, setMainLocationInformationModalOpen] = useState(false);
    const [availableSectorsOfBusiness, setAvailableSectorsOfBusiness] = useState<
        GetSectorsOfBusinessResponseItemDto[]
    >();

    //#endregion

    //#region Effects

    const searchContacts = async (
        company: CompanyDto,
        page: number,
        searchTerm: string
    ): Promise<User[]> => {
        const [results, totalItemCount] = await userService.getUsers({
            pageSize: contactsPageSize,
            page: page,
            searchTerm: searchTerm,
            companyIds: [company.id],
            HasManagementRole: null,
        } as GetUsersRequestDto);

        if (results.length + contactsPageSize * page >= totalItemCount) {
            setContactMaxResults(true);
        }

        return results;
    };

    const debounceUserSearch = useRef(
        debounce((company: CompanyDto, page: number, searchTerm: string) => {
            searchContacts(company, page, searchTerm).then((results) => {
                setContactSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, DEBOUNCE_DELAY)
    );

    const resetContactSearch = (emptyDropdown = true): void => {
        setContactCurrentPage(0);
        if (emptyDropdown) setContactSearchResults([]);
        setContactSearchTerm('');
        setContactMaxResults(false);
    };

    const handleCompanyKeywordsChange = useCallback((value: string): void => {
        resetContactSearch();
        setContactSearchTerm(value);
    }, []);

    const handleContactMenuScrollToBottom = () => {
        if (!contactMaxResults) {
            setContactCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        if (visible && company) {
            debounceUserSearch.current(company, contactCurrentPage, contactSearchTerm);
        }
    }, [visible, company, contactCurrentPage, contactSearchTerm]);

    useEffect(() => {
        const searchResults = contactSearchResults?.map(
            (user) =>
                ({
                    value: user?.id,
                    label: user?.name,
                    content: user.companyRole,
                    imageUrl: user?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedContactOption);
        setContactOptions(merged);
    }, [contactSearchResults, selectedContactOption]);

    const fetchSectorsOfBusiness = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await companyService.getSectorsOfBusiness();
            if (response?.items) {
                setAvailableSectorsOfBusiness(
                    response.items.filter((item) => item !== null).map((item) => item!)
                );
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [companyService, globalLoadingStore]);

    useEffect(() => {
        fetchSectorsOfBusiness();
    }, [fetchSectorsOfBusiness]);

    useEffect(() => {
        if (visible && company) {
            form.setFieldsValue({
                name: company.name,
                locationId: company.location?.id,
                introduction: company.introduction ?? undefined,
                //some sectors of business are possibly unexistant now. If so the user will have to choose a new one
                // https://appcom.atlassian.net/browse/HEYD-3191
                sectorOfBusiness: availableSectorsOfBusiness?.some(
                    (sector) => sector.id === company.sectorOfBusiness
                )
                    ? company.sectorOfBusiness
                    : undefined,
                numberOfEmployees: company.numberOfEmployees,
                'address.addressLine1': company.address?.addressLine1 ?? undefined,
                'address.city': company.address?.city ?? undefined,
                'address.state': company.address?.state ?? undefined,
                'address.countryCode': company.address?.countryCode ?? undefined,
                'address.zipCode': company.address?.zipCode ?? undefined,
                'contactInfo.phoneNumber': company.contactInfo?.phoneNumber,
                'contactInfo.email': company.contactInfo?.email,
                'contactInfo.website': company.contactInfo?.website,
                mainContactId: company.mainContact?.id,
                isTownhall: company.isTownhall,
                isCoworking: company.isCoworking,
                consentToConvert: false,
            });

            const mainLocationIndex = company.locations?.findIndex(
                (location) => location?.id === company.location?.id
            );
            if (mainLocationIndex !== -1 && mainLocationIndex !== undefined) {
                const locations = company.locations
                    ?.filter((location) => location !== null)
                    .map((location) => location!);
                locations?.splice(mainLocationIndex, 1);

                setSelectedOtherLocations(
                    locations
                        ?.filter((location) => location.id !== undefined)
                        .map((location) => location.id!)
                );
            }
            setSelectedContactId(company.mainContact?.id);
        }
    }, [visible, company, form, locationStore.locations, availableSectorsOfBusiness]);
    //#endregion

    //#region Event handlers
    interface SelectOption {
        id: string;
        name: string;
        order?: number;
        disabled?: boolean;
    }

    const locationsOptions: SelectOption[] = locationStore.locations.map((location) => ({
        id: location.id!,
        name: location.name!,
        disabled:
            !userStore.isAdmin &&
            !(
                userStore.isBuildingManager &&
                userStore.buildingManagerLocationIds.some(
                    (managerLocation) => managerLocation === location.id
                )
            ),
    }));

    const otherLocationsOptions: SelectCustomOption[] = locationStore.locations
        .filter((location) => location.id !== selectedMainLocationId)
        .map((location) => ({
            value: location.id!,
            label: location.name!,
        }));

    const employeesNumOptions: SelectOption[] = Object.keys(CompanyNumberOfEmployeesDto).map(
        (key) => ({
            id: key,
            name: t(`CompanyNumEmployees.${key}`),
        })
    );

    const sectorOfBusinessOptions: SelectOption[] | undefined = availableSectorsOfBusiness
        ?.filter((sector) => sector.id !== undefined)
        .map((sector) => ({ id: sector.id!, description: sector.description }))
        .map(
            (sector) =>
                ({
                    id: sector.id,
                    name: t(`CompanySector.company_sector_${sector.id}`),
                } ?? undefined)
        );

    const contactRolesOptions: SelectOption[] = Object.keys(CompanyRoleDto).map((key) => ({
        id: key,
        name: key,
    }));

    const selectOptions = (options: SelectOption[]) => {
        return options.map((option) => (
            <Option key={option.id} value={option.id} disabled={option.disabled}>
                {option.name}
            </Option>
        ));
    };
    //#endregion

    const handleIntroductionChange = (value: string): void => {
        form.setFieldsValue({ introduction: value });
    };

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        resetErrors();
        resetContactSearch();
        setContactOptions([]);
        setSelectedContactId(undefined);
        onComplete(success);
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company />,
                title: t(`Company.company_profile_success`),
                message: t(`Company.company_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const errorsHandler = (error: any): void => {
        let treated = false;
        const errors = new Map<string, string[]>();
        if (error.response?.data?.errors?.['mainContact.ContactInfo.Email'] !== undefined) {
            errors.set('mainContact.contactInfo.email', [
                error.response?.data.errors?.['mainContact.ContactInfo.Email'][0].description,
            ]);
        }
        if (error.response?.data?.errors?.['image.UploadBase64'] !== undefined) {
            treated = true;
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
        }
        setErrors(errors);

        if (!treated) {
            toastStore.displayError(error);
        }
    };

    const submit = async (): Promise<void> => {
        const values = form.getFieldsValue();

        if (
            company &&
            company.type === CompanyTypeDto.Passport &&
            isHeydayCheckState &&
            !values.consentToConvert
        ) {
            setConvertToHeydayModalOpen(true);
            return;
        }

        const addressData: AddressDto = {
            addressLine1: values['address.addressLine1'],
            city: values['address.city'],
            state: values['address.state'],
            countryCode: values['address.countryCode'],
            zipCode: values['address.zipCode'],
        };

        const companyContactInfoData: ContactInfoDto = {
            email: values['contactInfo.email'],
            phoneNumber: values['contactInfo.phoneNumber'],
            website: values['contactInfo.website'],
        };

        const mainContactInfoData: ContactInfoDto = {
            email: values['mainContact.contactInfo.email'],
            phoneNumber: values['mainContact.contactInfo.phoneNumber'],
        };

        const mainContactAddressData: AddressDto = {
            addressLine1: values['mainContact.address.addressLine1'],
            city: values['mainContact.address.city'],
            state: values['mainContact.address.state'],
            countryCode: values['mainContact.address.countryCode'],
            zipCode: values['mainContact.address.zipCode'],
        };

        const mainContactData: CreateCompanyRequestMainContactDto = {
            firstName: values['mainContact.firstName'],
            lastName: values['mainContact.lastName'],
            birthDate: values['mainContact.birthDate']
                ? values['mainContact.birthDate'].format(DATE_API_FORMAT)
                : undefined,
            contactInfo: mainContactInfoData,
            address: mainContactAddressData,
            companyRoles: values['mainContact.companyRoles']
                ? [values['mainContact.companyRoles']]
                : undefined,
        };

        const mainImage = mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;

        const newCompanyData: CreateCompanyRequestDto = {
            name: values.name,
            locationId: values.locationId,
            locationIds: selectedOtherLocations,
            introduction: values.introduction,
            numberOfEmployees:
                (values.numberOfEmployees as CompanyNumberOfEmployeesDto) ?? undefined,
            sectorOfBusiness: (values.sectorOfBusiness as SectorOfBusinessDto) ?? undefined,
            address: addressData,
            contactInfo: companyContactInfoData,
            mainContact: mainContactData,
            image: mainImage,
            isTownhall: values.isTownhall,
            isCoworking: values.isCoworking,
            type: isHeydayCheckState ? CompanyTypeDto.Heyday : CompanyTypeDto.Passport,
        };

        const updatedCompanyData: UpdateCompanyRequestDto = {
            name: values.name,
            locationId: values.locationId,
            locationIds: selectedOtherLocations,
            introduction: values.introduction,
            numberOfEmployees:
                (values.numberOfEmployees as CompanyNumberOfEmployeesDto) ?? undefined,
            sectorOfBusiness: (values.sectorOfBusiness as SectorOfBusinessDto) ?? undefined,
            address: addressData,
            contactInfo: companyContactInfoData,
            mainContactId: selectedContactId,
            image: mainImage,
            isTownhall: values.isTownhall,
            isCoworking: values.isCoworking,
            type: isHeydayCheckState ? CompanyTypeDto.Heyday : CompanyTypeDto.Passport,
        };

        const companyData = isEditing ? updatedCompanyData : newCompanyData;

        if (!(await validateForm(companyData, false))) return;
        try {
            globalLoadingStore.addLoading();
            if (company) {
                await companyService.editCompany(company.id!, updatedCompanyData);
            } else {
                await companyService.createCompany(newCompanyData);
            }
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(`Company.company_confirm_positive${company ? '_edit' : ''}`),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss(false);
    };

    const handleConvertToHeydayComplete = (response: boolean): void => {
        setConvertToHeydayModalOpen(false);
        form.setFieldsValue({
            consentToConvert: response,
        });
        if (response) submit();
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t(`Company.company_${company ? 'edit' : 'create'}`)}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="CreateCompany">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24}>
                            <Text>{t('Images.images_main')}</Text>
                        </Col>
                    </Row>
                    <Row gutter={formGutter} className="imageRow">
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        company?.imageUrl
                                            ? [
                                                  {
                                                      url: company?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('Company.company_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <div className="main-location-container">
                                <ValidatedFormItem
                                    errors={errors}
                                    name="locationId"
                                    label={t('Location.main_location')}
                                    required
                                    className="main-location-dropdown"
                                >
                                    <Select
                                        onChange={(value: SelectValue): void =>
                                            setSelectedMainLocationId(value.toString())
                                        }
                                        disabled={isEditing && !userStore.isAdmin}
                                    >
                                        {selectOptions(locationsOptions)}
                                    </Select>
                                </ValidatedFormItem>
                                <span
                                    onClick={(): void => setMainLocationInformationModalOpen(true)}
                                >
                                    <LowercaseI width={17} height={17} fill={theme['black']} />
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="locationIds"
                                label={t('Location.other_locations')}
                                required
                            >
                                <SelectCustom
                                    isMulti
                                    options={otherLocationsOptions}
                                    strongLabel={true}
                                    onMultiChange={(options: SelectCustomOption[]): void =>
                                        setSelectedOtherLocations(
                                            options.map((option) => option.value)
                                        )
                                    }
                                    hideSelectedOptions={false}
                                    selected={selectedOtherLocations}
                                    disabled={isEditing && !userStore.isAdmin}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="introduction"
                                label={t('Company.company_presentation')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                value={
                                    company?.introduction ||
                                    form.getFieldValue('introduction') ||
                                    null
                                }
                                onChange={handleIntroductionChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="numberOfEmployees"
                                label={t('Company.company_total_number_of_employees')}
                                required
                            >
                                <Select>{selectOptions(employeesNumOptions)}</Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="sectorOfBusiness"
                                label={t('Company.company_sector_of_business')}
                                required
                            >
                                <Select>
                                    {sectorOfBusinessOptions &&
                                        selectOptions(sectorOfBusinessOptions)}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Company.company_type_optional')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <ValidatedFormItem errors={errors} name="consentToConvert">
                            <Input hidden />
                        </ValidatedFormItem>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="isHeyday"
                                className="make-heyday"
                            >
                                <Checkbox
                                    disabled={company?.type === CompanyTypeDto.Heyday}
                                    defaultChecked={company?.type === CompanyTypeDto.Heyday}
                                    onChange={(event: CheckboxChangeEvent) => {
                                        setIsHeydayCheckState(event.target.checked);
                                        form.setFieldsValue({
                                            isHeyday: event.target.checked,
                                        });
                                    }}
                                    checked={isHeydayCheckState}
                                >
                                    {company
                                        ? t('Company.convert_company_to_heyday')
                                        : t('Company.make_company_heyday')}
                                </Checkbox>
                                <PineappleSmall fill={theme['primary-color']} />
                            </ValidatedFormItem>
                            <div className="convert-warning">{t('Company.convert_warning')}</div>
                        </Col>
                        <Col span={1}>
                            <div className="vertical-line" />
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="isTownhall"
                                className="mb-0 isTownhall"
                            >
                                <Checkbox
                                    defaultChecked={company?.isTownhall}
                                    onChange={(event: CheckboxChangeEvent) =>
                                        form.setFieldsValue({
                                            isTownhall: event.target.checked,
                                        })
                                    }
                                    className="company-type"
                                >
                                    <span>{t('Company.company_is')}</span>
                                    <Icon iconName="Townhall" fill={theme['primary-color']} />
                                    <span className="type-text">
                                        {t('Company.townhall_tenant')}
                                    </span>
                                </Checkbox>
                            </ValidatedFormItem>
                            <ValidatedFormItem errors={errors} name="isCoworking">
                                <Checkbox
                                    defaultChecked={company?.isCoworking}
                                    onChange={(event: CheckboxChangeEvent) => {
                                        form.setFieldsValue({
                                            isCoworking: event.target.checked,
                                        });
                                    }}
                                    className="company-type"
                                >
                                    <span>{t('Company.company_is')}</span>
                                    <Icon iconName="Coworking" fill={theme['primary-color']} />
                                    <span className="type-text">
                                        {t('Company.coworking_tenant')}
                                    </span>
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Contact.contact_address')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.addressLine1"
                                label={t('Contact.contact_address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.city"
                                label={t('Contact.contact_city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.state"
                                label={t('Contact.contact_state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <SelectCountry
                                errors={errors}
                                name="address.countryCode"
                                label={t('Contact.contact_country')}
                            />
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="address.zipCode"
                                label={t('Contact.contact_zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Company.company_contact_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.phoneNumber"
                                label={t('Contact.contact_phone')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.email"
                                label={t('Contact.contact_email')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.website"
                                label={t('Contact.contact_website')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Contact.contact_main')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <div>
                                <p className="help">{t('Company.company_invite_info')}</p>
                            </div>
                        </Col>
                    </Row>
                    {/* Main contact fields are available on create form only. */}
                    {/* Main contact will be edited apart from company edit form once the company is created. */}
                    {/* Main contact will be selectable from a select component for existing company. */}
                    {!company ? (
                        <React.Fragment>
                            <Row gutter={formGutter}>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.firstName"
                                        label={t('Contact.contact_first_name')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.lastName"
                                        label={t('Contact.contact_last_name')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.contactInfo.phoneNumber"
                                        label={t('Contact.contact_phone')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.contactInfo.email"
                                        label={t('Contact.contact_email')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.birthDate"
                                        label={t('Contact.contact_birth_date')}
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.companyRoles"
                                        label={t('Contact.contact_role')}
                                        required
                                    >
                                        <Select>
                                            {contactRolesOptions.map((option) => (
                                                <Option key={option.id} value={option.id}>
                                                    {t(`User.user_role_${option.name}`)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.address.addressLine1"
                                        label={t('Contact.contact_address')}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={formGutter}>
                                <Col span={6}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.address.city"
                                        label={t('Contact.contact_city')}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={6}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.address.state"
                                        label={t('Contact.contact_state')}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={6}>
                                    <SelectCountry
                                        errors={errors}
                                        name="mainContact.address.countryCode"
                                        label={t('Contact.contact_country')}
                                    />
                                </Col>
                                <Col span={6}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContact.address.zipCode"
                                        label={t('Contact.contact_zip_code')}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="mainContactId"
                                        label={t('Contact.contact_main')}
                                    >
                                        <SelectCustom
                                            options={contactOptions}
                                            defaultImg={<UserIcon />}
                                            strongLabel={true}
                                            placeholder={t('SelectCustom.placeholder_member')}
                                            onChange={(option: SelectCustomOption): void => {
                                                setSelectedContactId(option.value);
                                                setSelectedContactOption([option]);
                                            }}
                                            onKeywordsChange={handleCompanyKeywordsChange}
                                            onMenuScrollToBottom={handleContactMenuScrollToBottom}
                                            hideSelectedOptions={false}
                                            selected={[selectedContactId || '']}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>

            {convertToHeydayModalOpen && (
                <ConvertToHeydayModal
                    isEditing={false}
                    visible={convertToHeydayModalOpen}
                    onComplete={(response): void => handleConvertToHeydayComplete(response)}
                />
            )}

            {mainLocationInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('Company.main_location_explanation_title'),
                            messages: [t('Company.main_location_explanation_message')],
                        },
                    ]}
                    onClose={(): void => setMainLocationInformationModalOpen(false)}
                />
            )}
        </Modal>
    );
    //#endregion
};

export default React.memo(EditCreateCompanyModal);
