import React, { FunctionComponent, useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { Add, FoodSandwich as FoodItemsIcon } from 'Components/icons';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateFoodItemSchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './food-item-modal.less';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/CreateFoodItemRequestDto';
import { UpdateFoodItemRequestDto } from 'Api/Features/FoodItems/Dtos/UpdateFoodItemRequestDto';
import { FoodItemService } from 'Services/FoodItemService';
import { FoodCategory } from 'Models/FoodCategory/FoodCategory';
import { FoodItemSpecification } from 'Models/FoodItem/FoodItemSpecification';
import { FoodItem } from 'Models/FoodItem/FoodItem';
import FoodItemSpecificationRow from './food-item-specification-row';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { useParams } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';

const { Title } = Typography;
const { Option } = Select;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface FoodItemModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    foodItem?: FoodItem;
}

const FoodItemModal: FunctionComponent<FoodItemModalProps> = ({
    visible,
    onComplete,
    foodItem,
}) => {
    const { t } = useTranslation();
    const { merchantId } = useParams<{ merchantId: string }>();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodItemService = useService(FoodItemService);
    const foodCategoryService = useService(FoodCategoryService);
    const [form] = Form.useForm();
    const [specifications, setSpecifications] = useState<FoodItemSpecification[]>([
        new FoodItemSpecification(),
    ]);
    const [categories, setCategories] = useState<FoodCategory[]>([]);
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(CreateFoodItemSchema, form);
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodItemsIcon />,
                title: t(`Food.food_item_saved_success`),
                message: t(`Food.food_item_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    useEffect(() => {
        const fetchFoodCategories = async () => {
            const [items] = await foodCategoryService.getFoodCategories({
                merchantIds: [merchantId],
            });
            setCategories(items);
        };
        fetchFoodCategories();
    }, [merchantId, foodCategoryService]);

    useEffect(() => {
        if (foodItem) {
            form.setFieldsValue({
                name: foodItem.name,
                description: foodItem.description ?? undefined
            });
            setSpecifications(foodItem.specifications);
        }
    }, [foodItem, form]);

    const getMainImage = (): UpdateFileRequestDto | null => {
        return mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;
    };

    const errorsHandler = (error: any): void => {
        if (error.response?.data?.errors?.['image.UploadBase64'] !== undefined) {
            const errors = new Map<string, string[]>();
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
            setErrors(errors);
        } else if (!error.treated) {
            toastStore.displayError(error);
        }
    };

    const createFoodItem = async (values: Store): Promise<void> => {
        const data: CreateFoodItemRequestDto = {
            name: values.name,
            categoryId: values.categoryId,
            specifications: specifications,
            description: values.description ?? undefined,
            image: getMainImage(),
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodItemService.createFoodItem(data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editFoodItem = async (values: Store): Promise<void> => {
        const oldValues = foodItem as FoodItem;
        const data: UpdateFoodItemRequestDto = {
            name: values.name || oldValues.name,
            description: values.description ?? undefined,
            specifications: specifications,
            categoryId: values.categoryId || oldValues.category?.id,
            image: getMainImage(),
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodItemService.updateFoodItem(data, foodItem!.id as string);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (foodItem !== undefined) {
            await editFoodItem(values);
        } else {
            await createFoodItem(values);
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodItemsIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(
                    `Food.food_item_leave_confirm_positive${foodItem !== undefined ? '_edit' : ''}`
                ),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const addSpecification = (): void => {
        const newId = specifications.length;
        const specification = new FoodItemSpecification();
        specification.id = newId.toString();
        const newSpecifications = specifications.concat([specification]);
        setSpecifications(newSpecifications);
    };

    const removeSpecification = (specificationId: string): void => {
        if (specifications.length > 1) {
            const newSpecifications = specifications.filter((role) => role.id !== specificationId);
            setSpecifications(newSpecifications);
        }
    };

    const onSpecificationNameChange = (specificationId: string, value: string): void => {
        const newSpecifications = specifications.map((specification) => {
            if (specification.id !== specificationId) return specification;

            return {
                ...specification,
                name: value,
            };
        });
        setSpecifications(newSpecifications);
    };

    const onSpecificationPriceChange = (
        specificationId: string,
        value: string | number | undefined
    ): void => {
        const newSpecifications = specifications.map((specification) => {
            if (specification.id !== specificationId) return specification;

            return {
                ...specification,
                price: Number(value),
            };
        });
        setSpecifications(newSpecifications);
    };

    const onSpecificationTaxAmountChange = (
        specificationId: string,
        value: string | number | undefined
    ): void => {
        const newSpecifications = specifications.map((specification) => {
            if (specification.id !== specificationId) return specification;

            return {
                ...specification,
                taxAmount: Number(value),
            };
        });
        setSpecifications(newSpecifications);
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                foodItem !== undefined
                    ? t('Food.food_and_beverages_items_edit')
                    : t('Food.food_and_beverages_items_create')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="FoodItemModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('Food.food_item_name')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ name: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="categoryId"
                                label={t('Food.food_and_beverages_category')}
                                required={true}
                            >
                                <Select defaultValue={foodItem ? foodItem.category?.id : undefined}>
                                    {categories.map((option) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('description')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                onChange={(value: string): void =>
                                    form.setFieldsValue({ description: value })
                                }
                                value={form.getFieldValue('description') || null}
                            />
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Food.food_item_prices')}</Title>
                        </Col>
                    </Row>

                    {specifications.map((specification, i) => (
                        <FoodItemSpecificationRow
                            key={specification.id}
                            id={specification.id}
                            specification={specification}
                            errors={errors}
                            errorKey={`specifications[${i}]`}
                            gutter={formGutter}
                            onSpecificationNameChange={onSpecificationNameChange}
                            onSpecificationPriceChange={onSpecificationPriceChange}
                            onSpecificationTaxAmountChange={onSpecificationTaxAmountChange}
                            onRemoveSpecification={removeSpecification}
                            showDelete={specifications.length > 1}
                        />
                    ))}
                    <div className="add-specification-container mb-1">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<Add />}
                            htmlType="button"
                            onClick={addSpecification}
                        />
                    </div>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_main')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        foodItem?.imageUrl
                                            ? [
                                                  {
                                                      url: foodItem?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default FoodItemModal;
