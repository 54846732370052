import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CheckInProxy } from 'Api/Features/CheckIns/CheckInProxy';
import { CheckInDetailsDto } from 'Api/Features/CheckIns/Dtos/CheckInDetailsDto';
import { GetCheckInsRequestDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsRequestDto';
import { GetCheckInsResponseDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsResponseDto';
import { CheckInDto } from 'Api/Features/CheckIns/Dtos/CheckInDto';
import moment from 'moment';
import { arrayBufferToPdf } from 'Utils';

@inject(CheckInProxy)
export class CheckInService extends ApiService {
    constructor(private readonly checkInProxy: CheckInProxy) {
        super();
    }

    public async getCheckIn(checkInId: string): Promise<CheckInDetailsDto | null> {
        const data: CheckInDetailsDto | null = await this.checkInProxy.getCheckIn(checkInId);
        return data;
    }

    public async getCheckIns(
        request: GetCheckInsRequestDto | null
    ): Promise<[CheckInDto[], number]> {
        const requestWithApiDates: GetCheckInsRequestDto = {
            ...request,
            from: request?.from === null ? null : moment.utc(request?.from).format(),
            to: request?.to === null ? null : moment.utc(request?.to).format()
        };
        
        const data: GetCheckInsResponseDto | null = await this.checkInProxy.getCheckIns(requestWithApiDates);
        const items = data?.items?.filter((item) => item !== null).map((item) => item!);
        return [items ?? [], data?.totalItemCount ?? 0];
    }

    public async getCheckInSummaryDocument(checkInId: string): Promise<Blob> {
        const data: ArrayBuffer = await this.checkInProxy.getCheckInSummaryDocument(checkInId);
        return arrayBufferToPdf(data);
    }
}
