import { FoodItemDto } from 'Api/Features/FoodItems/Dtos/FoodItemDto';
import { FoodItemSpecification } from './FoodItemSpecification';
import { FoodItemFoodCategory } from './FoodItemFoodCategory';
import { FoodMerchant } from 'Models/FoodMerchant/FoodMerchant';
import { moneyFormat } from 'Utils';
import { FoodPromotion } from 'Models/FoodPromotion/FoodPromotion';

interface VariationPrice {
    min: number;
    max: number;
    promotionMin: number;
    promotionMax: number;
}

export class FoodItem implements FoodItemDto {
    constructor(dto: FoodItemDto) {
        Object.assign(this, dto);
        this.setPrice();
    }
    id = '';
    name: string | null = null;
    description: string | null = null;
    imageUrl: string | null = null;
    category: FoodItemFoodCategory | null = null;
    merchant: FoodMerchant | null = null;
    specifications: FoodItemSpecification[] = [];
    order = 0;
    price = 0; // single specification only
    promotionPrice = 0; // single specification only
    priceVariation: VariationPrice = {
        min: 0,
        max: 0,
        promotionMin: 0,
        promotionMax: 0,
    }; // multiple specification only
    private _currentPromotion: FoodPromotion | null | undefined = null;

    get hasMultipleSpecifications(): boolean {
        return this.specifications?.length > 1;
    }

    get inPromotion(): boolean {
        return (
            this.promotionPrice > 0 ||
            (this.priceVariation.promotionMin > 0 && this.priceVariation.promotionMax > 0)
        );
    }

    get currentPromotion(): FoodPromotion | null | undefined {
        return this._currentPromotion;
    }

    set currentPromotion(promotion: FoodPromotion | null | undefined) {
        this._currentPromotion = promotion;
    }

    private setPrice(): void {
        if (this.hasMultipleSpecifications) {
            this.specifications.forEach((specification) => {
                if (specification) {
                    // normal price
                    if (
                        this.priceVariation.min === 0 ||
                        specification.price < this.priceVariation.min
                    ) {
                        this.priceVariation.min = specification.price;
                    }
                    if (specification.price > this.priceVariation.max) {
                        this.priceVariation.max = specification.price;
                    }
                    // promotion price
                    if (
                        this.priceVariation.promotionMin === 0 ||
                        (specification.promotionPrice || 0) < this.priceVariation.promotionMin
                    ) {
                        this.priceVariation.promotionMin = specification.promotionPrice || 0;
                    }
                    if ((specification.promotionPrice || 0) > this.priceVariation.promotionMax) {
                        this.priceVariation.promotionMax = specification.promotionPrice || 0;
                    }
                }
            });
        } else if (this.specifications && this.specifications[0]) {
            const specification = this.specifications[0];
            this.price = specification.price;
            this.promotionPrice = specification.promotionPrice || 0;
        }
    }

    get priceString(): string {
        if (this.hasMultipleSpecifications) {
            // variation string without promotion
            return `${moneyFormat(this.priceVariation.min)} - ${moneyFormat(
                this.priceVariation.max
            )}`;
        } else {
            return `${moneyFormat(this.price)}`;
        }
    }

    get priceWithPromotionString(): string {
        if (this.inPromotion) {
            if (this.hasMultipleSpecifications) {
                if (this.priceVariation.promotionMin === this.priceVariation.promotionMax) {
                    // fixed price
                    return `<span>${this.priceString}</span> ${moneyFormat(
                        this.priceVariation.promotionMin
                    )}`;
                } else {
                    // reduction percentage
                    return `<span>${this.priceString}</span> ${moneyFormat(
                        this.priceVariation.promotionMin
                    )} - ${moneyFormat(this.priceVariation.promotionMax)}`;
                }
            } else {
                return `<span>${this.priceString}</span> ${moneyFormat(this.promotionPrice)}`;
            }
        }
        return this.priceString;
    }
}
