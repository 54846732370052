import { Invoice } from './Invoice';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';

export class UserInvoice extends Invoice {
    constructor(dto: InvoiceDto, userId: string) {
        super(dto);
        this.userId = userId;
    }
    userId = '';

    get consoleUrl(): string {
        return `/users/${this.userId}/invoices/${this.id}`
    }
}