import React, { FunctionComponent, useLayoutEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import AuthenticatedLayout from 'Components/authenticated-layout/authenticated-layout';
import { ValidatedRoute } from 'Components/validated-route';
import { useStores, useService } from 'Hooks';
import { BaseDataService } from 'Services/BaseDataService';
import Locations from './locations';
import LocationDetail from './locations/id';
import LocationServiceRequests from './locations/id/service';
import LocationServiceRequestDetail from './locations/id/service/id';
import LocationBookings from './locations/id/booking';
import LocationBookingApproval from './locations/id/booking-approval';
import LocationAnnouncements from './locations/id/announcements';
import LocationMessageDetail from './locations/id/announcements/id';
import LocationManagement from './locations/id/management';
import LocationAmenities from './locations/id/management/amenities';
import LocationAmenitiesDetail from './locations/id/management/amenities/id';
import LocationDetails from './locations/id/management/details';
import FoodMerchants from './locations/id/management/merchants';
import FoodMerchant from './locations/id/management/merchants/id';
import FoodMerchantDetail from './locations/id/management/merchants/id/details';
import FoodCategories from './locations/id/management/merchants/id/categories';
import FoodCategoriesDetail from './locations/id/management/merchants/id/categories/id';
import FoodItems from './locations/id/management/merchants/id/items';
import FoodItemsDetail from './locations/id/management/merchants/id/items/id';
import FoodOrderDestinations from './locations/id/management/merchants/id/order-destinations';
import FoodOrderDestinationsDetail from './locations/id/management/merchants/id/order-destinations/id';
import FoodPromotions from './locations/id/management/merchants/id/promotions';
import FoodPromotionsDetail from './locations/id/management/merchants/id/promotions/id';
import Companies from './companies';
import CompanyDetail from './companies/id';
import CompanyProfile from './companies/id/profile';
import CompanyServiceRequests from './companies/id/service';
import CompanyAgreements from './companies/id/agreements';
import CompanyInvoices from './companies/id/invoices';
import CompanyInvoicesDetail from './companies/id/invoices/id';
import CompanyMessages from './companies/id/messages';
import CompanyMessageDetail from './companies/id/messages/id';
import CompanyTeam from './companies/id/team';
import CompanyTeamUserDetail from './companies/id/team/id';
import Users from './users';
import UserDetail from './users/id';
import UserProfile from './users/id/profile';
import UserInvoices from './users/id/invoices';
import UserMessages from './users/id/messages';
import UserMessageDetail from './users/id/messages/id';
import { NotFoundPage } from '../error/not-found';
import ManagerUserDetail from './staff-accesses/id';
import ManagerUsers from './staff-accesses';
import AmenityCategories from './settings/amenity-categories';
import AmenityCategoriesDetail from './settings/amenity-categories/id';
import UserInvoicesDetail from './users/id/invoices/id';
import Invoices from './invoices';
import InvoicesDetail from './invoices/id';
import LocationBookingApprovalDetail from './locations/id/booking-approval/id';
import BuildingAccessDetail from './users/id/building-access';
import Settings from './settings';
import LocationServices from './locations/id/management/services';
import LocationServiceDetails from './locations/id/management/services/id';
import CheckIns from './check-ins';
import DocumentsDetails from './users/id/documents';

interface AuthenticatedProps {
    returnUrl: string;
}

const AuthenticatedRoutes: FunctionComponent<AuthenticatedProps> = ({ returnUrl }) => {
    const location = useLocation();
    const { authorizationStore } = useStores();
    const baseDataService = useService(BaseDataService);
    useLayoutEffect(() => {
        // Reset forbidden state on route change.
        authorizationStore.resetForbidden();

        //initialise data the app needs to function
        baseDataService.initBaseData();
    }, [location, authorizationStore, baseDataService]);

    return (
        <AuthenticatedLayout>
            <Switch>
                <Redirect exact from="/" to={returnUrl || '/locations'} />
                <ValidatedRoute exact path="/locations">
                    <Locations />
                </ValidatedRoute>
                <ValidatedRoute path="/locations/:id">
                    {/* @TODO - Works, but bookings tab is not selected by default in this case */}
                    <Route
                        exact
                        path="/locations/:id"
                        render={({ match }) => (
                            <Redirect to={`/locations/${match.params.id}/service`} />
                        )}
                    />
                    <LocationDetail>
                        <ValidatedRoute exact path="/locations/:id/service">
                            <LocationServiceRequests />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/service/:requestId">
                            <LocationServiceRequestDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/bookings">
                            <LocationBookings />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/booking-approval">
                            <LocationBookingApproval />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/booking-approval/:bookingId">
                            <LocationBookingApprovalDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/announcements">
                            <LocationAnnouncements />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/announcements/:messageId">
                            <LocationMessageDetail />
                        </ValidatedRoute>
                        <ValidatedRoute path="/locations/:id/management">
                            <ValidatedRoute exact path="/locations/:id/management">
                                <LocationManagement />
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/amenities">
                                <LocationAmenities />
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/details">
                                <LocationDetails />
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/amenities/:amenityId"
                            >
                                <LocationAmenitiesDetail />
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/merchants">
                                <FoodMerchants />
                            </ValidatedRoute>
                            <ValidatedRoute path="/locations/:id/management/merchants/:merchantId">
                                <FoodMerchant>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId"
                                    >
                                        <FoodMerchantDetail showNavigation />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/details"
                                    >
                                        <FoodMerchantDetail />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/categories"
                                    >
                                        <FoodCategories />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/categories/:categoryId"
                                    >
                                        <FoodCategoriesDetail />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/items"
                                    >
                                        <FoodItems />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/items/:itemId"
                                    >
                                        <FoodItemsDetail />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/order-destinations"
                                    >
                                        <FoodOrderDestinations />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/order-destinations/:orderDestinationId"
                                    >
                                        <FoodOrderDestinationsDetail />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/promotions"
                                    >
                                        <FoodPromotions />
                                    </ValidatedRoute>
                                    <ValidatedRoute
                                        exact
                                        path="/locations/:id/management/merchants/:merchantId/promotions/:promotionId"
                                    >
                                        <FoodPromotionsDetail />
                                    </ValidatedRoute>
                                </FoodMerchant>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/locationServices">
                                <LocationServices />
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/locationServices/:serviceId">
                                <LocationServiceDetails />
                            </ValidatedRoute>
                        </ValidatedRoute>
                    </LocationDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/companies">
                    <Companies />
                </ValidatedRoute>
                <ValidatedRoute path="/companies/:id">
                    {/* @TODO - Works, but profile tab is not selected by default in this case */}
                    <Route
                        exact
                        path="/companies/:id"
                        render={({ match }) => (
                            <Redirect to={`/companies/${match.params.id}/profile`} />
                        )}
                    />
                    <CompanyDetail>
                        <ValidatedRoute exact path="/companies/:id/profile">
                            <CompanyProfile />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/service">
                            <CompanyServiceRequests />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/agreements">
                            <CompanyAgreements />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/invoices">
                            <CompanyInvoices />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/invoices/:invoiceId">
                            <CompanyInvoicesDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/messages">
                            <CompanyMessages />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/messages/:messageId">
                            <CompanyMessageDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/team">
                            <CompanyTeam />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/team/:userId">
                            <CompanyTeamUserDetail />
                        </ValidatedRoute>
                    </CompanyDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/users">
                    <Users />
                </ValidatedRoute>
                <ValidatedRoute path="/users/:id">
                    {/* @TODO - Works, but profile tab is not selected by default in this case */}
                    <Route
                        exact
                        path="/users/:id"
                        render={({ match }) => (
                            <Redirect to={`/users/${match.params.id}/profile`} />
                        )}
                    />
                    <UserDetail>
                        <ValidatedRoute exact path="/users/:id/profile">
                            <UserProfile />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/invoices">
                            <UserInvoices />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/invoices/:invoiceId">
                            <UserInvoicesDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/messages">
                            <UserMessages />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/messages/:messageId">
                            <UserMessageDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/building-access">
                            <BuildingAccessDetail />
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/users/:id/documents">
                            <DocumentsDetails />
                        </ValidatedRoute>
                    </UserDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/invoices">
                    <Invoices />
                </ValidatedRoute>
                <ValidatedRoute exact path="/invoices/:id">
                    <InvoicesDetail />
                </ValidatedRoute>
                <ValidatedRoute exact path="/staff-accesses">
                    <ManagerUsers />
                </ValidatedRoute>
                <ValidatedRoute path="/staff-accesses/:id">
                    <ManagerUserDetail />
                </ValidatedRoute>
                <ValidatedRoute exact path="/settings">
                    <Settings />
                </ValidatedRoute>
                <ValidatedRoute exact path="/settings/amenity-categories">
                    <AmenityCategories />
                </ValidatedRoute>
                <ValidatedRoute exact path="/settings/amenity-categories/:id">
                    <AmenityCategoriesDetail />
                </ValidatedRoute>

                <ValidatedRoute exact path="/check-ins">
                    <CheckIns />
                </ValidatedRoute>

                <Route>
                    {/* must be last route*/}
                    <NotFoundPage />
                </Route>
            </Switch>
        </AuthenticatedLayout>
    );
};

export default AuthenticatedRoutes;
