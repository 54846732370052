export function showFile(blob: Blob, fileName: string) {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.target = '_blank';
    link.click();
    // This is to open in a new tab instead, but we can't choose the filename of the Blob.
    // window.open(data);
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}

export function arrayBufferToPdf(buffer: ArrayBuffer): Blob {
    return new Blob([new Uint8Array(buffer)], { type: 'application/pdf' });
}
