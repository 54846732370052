import React, { FunctionComponent, useState } from 'react';
import { Input, Row, Col, Button, DatePicker, Select } from 'antd';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import './table-filters.less';
import { Filter, Search } from 'Components/icons';
import { theme } from 'variant';
import { LocationSelect } from './location-select';
import { AdvancedFilters } from './advanced-filters';
import { Badge } from 'Components/badge';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import FilterStore from 'Stores/FilterStore';
import { LocationTypeDto } from '../../Api/Features/Locations/Dtos/LocationTypeDto';
import { useTranslation } from 'react-i18next';
import { AccessFilter } from 'Models/Filters/AccessFilter';
import { DocumentStatusFilter } from 'Models/Filters/DocumentStatusFilter';

const { Option } = Select;

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;
    includeLocations?: boolean;
    includeLocationTypes?: boolean;
    availableLocations?: LightLocationInfo[];
    includeAdvancedFilters?: boolean;
    includeDates?: boolean;
    includeClear?: boolean;
    includeAmenityAccess?: boolean;
    includeCompanyAccess?: boolean;
    includeDocumentStatus?: boolean;
}

const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({
        filterStore,
        includeSearch,
        includeLocations,
        includeLocationTypes,
        availableLocations,
        includeAdvancedFilters,
        includeDates,
        includeClear,
        includeAmenityAccess,
        includeCompanyAccess,
        includeDocumentStatus
    }) => {
        const { t } = useTranslation();

        const [showAdvanced, setShowAdvanced] = useState(false);

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        const onLocationChange = (locationIds: string[]): void => {
            filterStore.updateLocation(locationIds);
        };

        const onLocationTypesChange = (type: string): void => {
            filterStore.updateLocationType(type);
        };

        const onAmenityAccessChange = (value: AccessFilter): void => {
            filterStore.updateTownhallExclusive(value === AccessFilter.TownhallExclusive);
            filterStore.updateCoworkingExclusive(value === AccessFilter.CoworkingExclusive);
        };

        const onDocumentStatutChange = (value: DocumentStatusFilter): void => {
            filterStore.updateDocumentStatus(value);
        };

        const onAdvancedFilterChange = (key: string, parentKey: string): void => {
            filterStore.toggleAdvancedFilter(key, parentKey);
        };

        const onToggleSection = (key: string): void => {
            filterStore.toggleSection(key);
        };

        const toggleAdvanced = (): void => {
            setShowAdvanced(!showAdvanced);
        };

        const checkedCount = filterStore?.advancedFilters?.reduce(
            (prev, filter) => prev + filter.items.filter((item) => item.checked).length,
            0
        );

        const onStartDateChange = (date: Moment | null, dateString: string): void => {
            filterStore.updateStartDate(dateString);
        };

        const onEndDateChange = (date: Moment | null, dateString: string): void => {
            filterStore.updateEndDate(dateString);
        };

        return (
            <div className="TableFilters">
                {includeDates && (
                    <Row gutter={[20, 20]}>
                        <Col>
                            <DatePicker
                                onChange={onStartDateChange}
                                placeholder="Start Date"
                                value={filterStore.startDate ? moment(filterStore.startDate) : null}
                            />
                        </Col>

                        <Col>
                            <DatePicker
                                onChange={onEndDateChange}
                                placeholder="End Date"
                                value={filterStore.endDate ? moment(filterStore.endDate) : null}
                            />
                        </Col>
                    </Row>
                )}
                <Row gutter={[20, 20]}>
                    {includeLocations && availableLocations ? (
                        <Col span={3}>
                            <LocationSelect
                                availableLocations={availableLocations}
                                onLocationChange={onLocationChange}
                                selected={filterStore.currentLocationIds}
                            />
                        </Col>
                    ) : null}
                    {includeLocationTypes && (
                        <Col span={3}>
                            <Select defaultValue="" onChange={onLocationTypesChange}>
                                <Option key='all' value=''>
                                    {t(`LocationType.all_location_types`)}
                                </Option>
                                {Object.keys(LocationTypeDto).map((key: string) => (
                                    <Option key={key} value={key}>
                                        {t(`LocationType.${key}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    {includeAmenityAccess && (
                        <Col span={3}>
                            <Select placeholder={t('Amenities.amenity_access')} onChange={onAmenityAccessChange}>
                                <Option key='all' value={AccessFilter.All}>
                                    {t('Amenities.all_amenities')}
                                </Option>
                                <Option key='townhallExclusive' value={AccessFilter.TownhallExclusive}>
                                    {t('townhall')}
                                </Option>
                                <Option key='coworkingExclusive' value={AccessFilter.CoworkingExclusive}>
                                    {t('coworking')}
                                </Option>
                            </Select>
                        </Col>
                    )}
                    {includeCompanyAccess && (
                        <Col span={3}>
                            <Select placeholder={t('Company.company_access')} onChange={onAmenityAccessChange}>
                                <Option key='all' value={AccessFilter.All}>
                                    {t('Company.all_companies')}
                                </Option>
                                <Option key='townhallExclusive' value={AccessFilter.TownhallExclusive}>
                                    {t('Company.townhall_tenant')}
                                </Option>
                                <Option key='coworkingExclusive' value={AccessFilter.CoworkingExclusive}>
                                    {t('Company.coworking_tenant')}
                                </Option>
                            </Select>
                        </Col>
                    )}
                    {includeDocumentStatus && (
                        <Col span={4}>
                            <Select  onChange={onDocumentStatutChange} defaultValue={DocumentStatusFilter.All}>
                                <Option key={DocumentStatusFilter.All} value={DocumentStatusFilter.All}>
                                    {t('Verdocs.DocumentStatusFilter_All')}
                                </Option>
                                <Option key={DocumentStatusFilter.Complete} value={DocumentStatusFilter.Complete}>
                                    {t('Verdocs.DocumentStatusFilter_Complete')}
                                </Option>
                                <Option key={DocumentStatusFilter.InProgress} value={DocumentStatusFilter.InProgress}>
                                    {t('Verdocs.DocumentStatusFilter_InProgress')}
                                </Option>
                                <Option key={DocumentStatusFilter.Pending} value={DocumentStatusFilter.Pending}>
                                    {t('Verdocs.DocumentStatusFilter_Pending')}
                                </Option>
                                <Option key={DocumentStatusFilter.Canceled} value={DocumentStatusFilter.Canceled}>
                                    {t('Verdocs.DocumentStatusFilter_Canceled')}
                                </Option>
                                <Option key={DocumentStatusFilter.Declined} value={DocumentStatusFilter.Declined}>
                                    {t('Verdocs.DocumentStatusFilter_Declined')}
                                </Option>
                            </Select>
                        </Col>
                    )}
                    {includeAdvancedFilters && filterStore.advancedFilters ? (
                        <Col span={6}>
                            <div className="advanced-filters-actions">
                                <Button
                                    type="default"
                                    onClick={toggleAdvanced}
                                    className={`advanced-filters-btn ${
                                        showAdvanced ? ' active' : ''
                                    }`}
                                >
                                    <Filter fill={theme['black']} />
                                    {t('Filter.advanced_filters')}
                                    <Badge type="primary">{checkedCount}</Badge>
                                </Button>

                                {includeClear && (
                                    <span
                                        className="clear-filters"
                                        onClick={() => filterStore.clear()}
                                    >
                                        {t('Filter.clear_filters')}
                                    </span>
                                )}
                            </div>
                        </Col>
                    ) : null}
                    <Col flex="auto" />
                    {includeSearch && (
                        <Col span={6}>
                            <Input
                                onChange={onSearchChange}
                                prefix={<Search fill={theme['primary-3']} />}
                                value={filterStore.searchTerm}
                            />
                        </Col>
                    )}
                </Row>
                {showAdvanced && includeAdvancedFilters && filterStore.advancedFilters ? (
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <AdvancedFilters
                                filters={filterStore.advancedFilters}
                                onItemChange={onAdvancedFilterChange}
                                onToggleSection={onToggleSection}
                            />
                        </Col>
                    </Row>
                ) : null}
            </div>
        );
    }
);

export default TableFilters;
