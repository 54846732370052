import { Button, Checkbox, Col, Form, Row, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import { UpdateCompanyRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyRequestDto';
import { Close, Company, Coworking, Pineapple, Townhall } from 'Components/icons';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CompanyService } from 'Services/CompanyService';
import { theme } from 'variant';
import './ConvertToHeyday.less'

interface ConvertToHeydayModalProps {
    visible: boolean;
    onComplete: (response: boolean) => void;
    company?: CompanyDto;
    isEditing?: boolean;
}

const { Title } = Typography;

const ConvertToHeydayModal: FunctionComponent<ConvertToHeydayModalProps> = ({
    visible,
    onComplete,
    company,
    isEditing,
}) => {
    const { t } = useTranslation();
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const companyService = useService(CompanyService);
    const [form] = Form.useForm();
    const [townhallCheckState, setTownhallCheckState] = useState(company?.isTownhall);
    const [coworkingCheckState, setCoworkingCheckState] = useState(company?.isCoworking);

    const dismiss = (response: boolean): void => {
        form.resetFields();
        onComplete(response);
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company />,
                title: t(`Company.company_profile_success`),
                message: t(`Company.company_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };


    const exit = async (): Promise<void> => {
        dismiss(false);
    };

    const submit = async (): Promise<void> => {
        const updatedCompanyData: UpdateCompanyRequestDto = {
            ...company,
            isTownhall: townhallCheckState,
            isCoworking: coworkingCheckState,
            type: CompanyTypeDto.Heyday,
        };

        try {
            if (company) {
                globalLoadingStore.addLoading();
                await companyService.editCompany(company.id!, updatedCompanyData);
                globalLoadingStore.removeLoading();
                await success();
            }
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <Modal
            visible={visible}
            className="ConvertToHeyday"
            footer={null}
            centered
            width={780}
            closeIcon={<Close />}
            onCancel={(): Promise<void> => exit()}
        >
            <div className="icon">
                <Pineapple />
            </div>
            <Title level={2} className="title">
                {t('Company.about_to_convert')}
            </Title>

            <div className="message">{t('Company.convert_warning')}</div>

            {isEditing && (
                <Form onFinish={submit} form={form}>
                    <Row className="type-container">
                        <Col className="type-text pt-20 pl-20" span={10}>
                            {t('Company.would_you_like_to_give_additionnal_permissions')}
                        </Col>
                        <Col span={1}>
                            <div className="vertical-line" />
                        </Col>
                        <Col className="pt-15 pl-20" span={13}>
                            <div
                                className="d-flex checkbox-container"
                                onClick={(): void => setTownhallCheckState((old) => !old)}
                            >
                                <Checkbox checked={townhallCheckState} />
                                <div className="checkbox-type-text d-flex">
                                    {t('Company.make_this_company')}
                                </div>
                                <Townhall fill={theme['primary-color']} />
                                <div className="checkbox-type-text-type d-flex">
                                    {t('townhall')}
                                </div>
                            </div>
                            <div
                                className="d-flex checkbox-container"
                                onClick={(): void => setCoworkingCheckState((old) => !old)}
                            >
                                <Checkbox checked={coworkingCheckState} />
                                <div className="checkbox-type-text d-flex">
                                    {t('Company.make_this_company')}
                                </div>
                                <Coworking fill={theme['primary-color']} />
                                <div className="d-flex checkbox-type-text-type">
                                    {t('coworking')}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="negative secondary"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('no_go_back')}
                        </Button>
                        <Button type="default" className="positive" htmlType="submit">
                            {t('yes_im_sure')}
                        </Button>
                    </div>
                </Form>
            )}

            {!isEditing && (
                <div className="actions">
                    <Button
                        type="default"
                        className="negative secondary"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('no_go_back')}
                    </Button>
                    <Button type="default" className="positive" onClick={(): void => onComplete(true)}>
                        {t('yes_im_sure')}
                    </Button>
                </div>
            )}
        </Modal>
    );
};

export default ConvertToHeydayModal;
