import { FoodMerchantBbotRestaurantDto } from 'Api/Features/FoodMerchants/Dtos/FoodMerchantBbotRestaurantDto';
import { FoodMerchantDto } from 'Api/Features/FoodMerchants/Dtos/FoodMerchantDto';

export class FoodMerchant implements FoodMerchantDto{
    constructor(dto: FoodMerchantDto) {
        Object.assign(this, dto);
    }
    id = '';
    name: string | null = null;
    description: string | null = null;
    imageUrl: string | null = null;
    order = 0;
    bbotRestaurant?: FoodMerchantBbotRestaurantDto | null;
}