import React, { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { Layout, Table } from 'antd';
import { images, theme } from 'variant';
import UserHeader from 'Components/user-header/user-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Download, Waiver } from 'Components/icons';
import {
    ColumnType,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { DATE_DISPLAY_FORMAT, PAGE_SIZE } from 'Models/Constants';
import { autorun } from 'mobx';
import { TableFilters } from 'Components/table-filters';
import FilterStore from 'Stores/FilterStore';
import { UserContext } from '../index';
import StatusBadge from 'Components/status-badge/status-badge';
import { VerdocsService } from 'Services/VerdocsService';
import { GetUserVerdocsDocumentsRequestDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentsRequestDto';
import { DocumentStatusFilter } from 'Models/Filters/DocumentStatusFilter';
import { arrayBufferToPdf, showFile } from 'Utils';
import moment from 'moment';
import { VerdocsDocument } from 'Models/Verdocs/VerdocsDocument';
import './index.less';

const { Content } = Layout;
const { listSectionHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    pageSize: PAGE_SIZE,
    defaultPageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const UserDocuments: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const user = useContext(UserContext);
    const filterStoreRef = useRef(new FilterStore());
    const verdocsService = useService(VerdocsService)
    const { globalLoadingStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<VerdocsDocument[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'users',
            nameKey: 'User.users',
        },
        {
            path: id,
            name: user?.name || t('User.user'),
        },
        {
            path: 'documents',
            nameKey: 'User.user_documents',
        },
    ];

    const downloadFile = async (document: VerdocsDocument): Promise<void> => {
        if (document.id && id) {
            try {
                globalLoadingStore.addLoading();
                const buffer = await verdocsService.getUserVerdocsDocumentFile(id, document.id);
                const file = arrayBufferToPdf(buffer);
                showFile(file, `${moment(document.createdAt).format(DATE_DISPLAY_FORMAT)} waiver.pdf`);
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const getTableColumns = (): ColumnType<VerdocsDocument>[] => {
        return [
            {
                title: t('date'),
                render: (document: VerdocsDocument): ReactNode | null =>
                    moment(document.createdAt).format(DATE_DISPLAY_FORMAT),
            },
            {
                title: t('name'),
                dataIndex: 'name',
            },
            {
                title: t('Verdocs.document_status'),
                render: (document: VerdocsDocument): ReactNode | null => (
                    <StatusBadge status={document.statusBadgeStatus} />
                ),
            },
            {
                title: t('Verdocs.recipient_status'),
                render: (document: VerdocsDocument): ReactNode | null => (
                    <StatusBadge status={document.recipients?.[0]?.statusBadgeStatus} />
                ),
            },
            {
                align: 'center',
                render: (document: VerdocsDocument): ReactNode | null => (
                    <div
                        className="download"
                        onClick={(): void => {
                            downloadFile(document);
                        }}
                    >
                        <Download fill={theme['primary-color']} width={16} height={16} />
                    </div>
                ),
            },
        ];
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            documentStatus: DocumentStatusFilter;
        }) => {
            setLoading(true);
            try {
                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    statuses: params.documentStatus === DocumentStatusFilter.All ? [] : [params.documentStatus]
                } as GetUserVerdocsDocumentsRequestDto;

                const [items, totalItems] = await verdocsService.getUserVerdocsDocuments(id, request);

                setData(items);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [verdocsService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                documentStatus: filterStore.documentStatus
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch]);

    return (
        <div className="UserDocuments">
            <UserHeader
                title={t('User.user_documents')}
                subTitle={t('User.user_documents_subtitle')}
                defaultImg={<Waiver fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDocumentStatus
                />
                <Table
                    className="table-striped-rows table-action-rows no-hover"
                    bordered
                    columns={getTableColumns()}
                    rowKey={(record: VerdocsDocument): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                />
            </Content>
        </div>
    );
});

export default UserDocuments;
