import React, { FunctionComponent, useRef, useState } from 'react';
import { Upload } from 'antd';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';
import './image-picker.less';
import ImageCropModal from './image-crop-modal';
import { theme } from 'variant';
import { Upload as UploadIcon } from 'Components/icons';

interface ImagePickerProps {
    allowMultiple?: boolean;
    images?: UploadFile[];
    setImagesDetails: (images: ImageDetails[] | undefined) => void;
    forIcon?: boolean;
    enableCropping?: boolean;
    croppingModalTitle?: string;
    croppingAspect?: number;
}
export interface ImageDetails {
    id: string;
    base64: string;
    isDeleted: boolean;
    fileUid: string;
}

const ImagePicker: FunctionComponent<ImagePickerProps> = ({
    allowMultiple,
    images,
    setImagesDetails,
    forIcon,
    enableCropping,
    croppingModalTitle,
    croppingAspect
}) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState<UploadFile[] | undefined>(
        images ? images : allowMultiple ? [] : undefined
    );
    const imagesDetails = useRef<ImageDetails[]>(
        images?.map((x) => ({ id: x.uid, isDeleted: false } as ImageDetails)) || []
    );
    const [showAddImage, setShowAddImage] = useState<boolean>(
        allowMultiple || images === undefined
    );

    const [croppingImageSrc, setCroppingImageSrc] = useState<string>('');
    const [croppingImageUid, setCroppingImageUid] = useState<string>('');
    const [croppingModalOpen, setCroppingModalOpen] = useState<boolean>(false);

    const getBase64 = async (file: any): Promise<string | undefined> => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        return src?.substring(src?.lastIndexOf(',') + 1);
    };

    const getFileSrc = async (file: any): Promise<string> => {
        const src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
        return src as string;
    };

    const handleOnChange = async (event: any): Promise<void> => {
        setFileList(event.fileList);
        if (event.file.status === ('done' as UploadFileStatus)) {
            const base = await getBase64(event.file);
            if (!allowMultiple) {
                imagesDetails.current = [
                    { fileUid: event.file.uid, base64: base, isDeleted: false } as ImageDetails,
                ];
            } else {
                imagesDetails.current = [
                    ...imagesDetails.current,
                    { fileUid: event.file.uid, base64: base, isDeleted: false } as ImageDetails,
                ];
            }
            setImagesDetails(imagesDetails.current);
        }
        if (event.file.status === ('removed' as UploadFileStatus)) {
            const image = images?.find((x) => x.uid === event.file.uid);
            if (image) {
                //image was an existing image from api model
                const imageDetails = imagesDetails.current?.find(
                    (imageDetails: ImageDetails) => imageDetails.id === event.file.uid
                );
                imageDetails!.isDeleted = true;
            } else {
                //image was added in form and must now be ignored
                imagesDetails.current = imagesDetails.current?.filter(
                    (imageDetails: ImageDetails) => imageDetails.fileUid !== event.file.uid
                );
            }
            setImagesDetails(imagesDetails.current.length > 0 ? imagesDetails.current : undefined);
        }
        setShowAddImage(allowMultiple || event.fileList?.length === 0);
    };

    const handleOnChangeWithCropping = async (event: any): Promise<void> => {
        if (event.file.status === ('uploading' as UploadFileStatus)) {
            const src = await getFileSrc(event.file);
            setCroppingImageSrc(src);
            setCroppingImageUid(event.file.uid);
            setCroppingModalOpen(true);
        } else {
            await handleOnChange(event);
        }
    };

    const onPreview = async (file: any): Promise<void> => {
        let src = file.url;
        if (!src) {
            src = await getFileSrc(file);
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        if (imgWindow) imgWindow.document.write(image.outerHTML);
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };

    const onImageCroppingComplete = async (success: boolean, url: string|null, base64: string|null) => {
        if (success) {
            const newFile = {
                url: url,
                uid: croppingImageUid,
            } as UploadFile;

            if (allowMultiple) {
                imagesDetails.current = [
                    ...imagesDetails.current,
                    { fileUid: croppingImageUid, base64: base64, isDeleted: false } as ImageDetails,
                ];
                fileList?.push(newFile);
                setFileList(fileList);
                setShowAddImage(true);
            } else {
                imagesDetails.current = [
                    { fileUid: croppingImageUid, base64: base64, isDeleted: false } as ImageDetails,
                ];
                setFileList([newFile]);
                setShowAddImage(false);
            }
            setImagesDetails(imagesDetails.current);
        }
        setCroppingModalOpen(false);
    };

    const uploadButton = (
        <div>
            <UploadIcon fill={theme['primary-color']}/>
            <div className="upload-label">{t('upload_image')}</div>
        </div>
    );

    return (
        <div className="ImagePicker">
            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={enableCropping ? handleOnChangeWithCropping : handleOnChange}
                onPreview={onPreview}
                customRequest={dummyRequest}
                className={forIcon ? 'for-icon' : ''}
                accept={'image/*'}
            >
                {(allowMultiple || showAddImage) && uploadButton}
            </Upload>
            {enableCropping && croppingImageSrc && (
                <ImageCropModal
                    title={croppingModalTitle}
                    visible={croppingModalOpen}
                    src={croppingImageSrc}
                    onComplete={onImageCroppingComplete}
                    aspect={croppingAspect}
                />
            )}
        </div>
    );
};

export default ImagePicker;
