import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Typography } from 'antd';
import { theme, images } from '../../variant';
import { Actions3 as ActionIcon } from 'Components/icons';
import './home-header.less';
import { useStores } from 'Hooks';

const { Title } = Typography;

interface HomeHeaderProps {
    personName: string;
    onCreateLocation: () => void;
}

const HomeHeader: FunctionComponent<HomeHeaderProps> = ({ personName, onCreateLocation }) => {
    const { t } = useTranslation();
    const [actionsPopOverOpen, setActionsPopOverOpen] = useState(false);
    const { userStore } = useStores();

    const onActionCreateLocation = (): void => {
        setActionsPopOverOpen(false);
        onCreateLocation();
    };

    // @TODO - Remove disabled prop when Reorder will be available
    const actionsPopoverContent = (
        <div className="locationActionsPopover">
            <Button type="text" onClick={onActionCreateLocation}>
                {t('Location.location_create')}
            </Button>
        </div>
    );

    return (
        <div className="HomeHeader" style={{ backgroundImage: `url(${images.homeHeader}` }}>
            <Title className="text-secondary">
                {t('Home.welcome_to_console', { param1: personName })}
            </Title>
            <div
                className="text-lg text-white"
                dangerouslySetInnerHTML={{
                    __html: t('Location.location_section_subtitle'),
                }}
            />
            {userStore.isAdmin && (
                <Popover
                    trigger="click"
                    placement="bottomRight"
                    arrowPointAtCenter
                    visible={actionsPopOverOpen}
                    onVisibleChange={(visible: boolean) => {
                        setActionsPopOverOpen(visible);
                    }}
                    content={actionsPopoverContent}
                >
                    <Button className="locationsOptionsButton">
                        <ActionIcon fill={theme['white']} />
                    </Button>
                </Popover>
            )}
        </div>
    );
};

export default HomeHeader;
