import { FoodCategoryDto } from 'Api/Features/FoodCategories/Dtos/FoodCategoryDto';

export class FoodCategory implements FoodCategoryDto{
    constructor(dto: FoodCategoryDto) {
        Object.assign(this, dto);
    }
    id = '';
    name: string | null = null;
    description: string | null = null;
    imageUrl: string | null = null;
    iconUrl: string | null = null;
    order = 0;
}