import React, { FunctionComponent, useCallback, useState, useEffect, useRef } from 'react';
import { Modal, Form, Row, Col, Input, Select, Typography, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { useFormValidation, useService, useStores } from 'Hooks';
import { InviteUserSchema } from 'Schemas';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { UserService } from 'Services/UserService';
import {
    Close,
    Company as CompanyIcon,
    Key,
    LowercaseI,
    MainLocation,
    PadLock,
} from 'Components/icons';
import { CompanyService } from 'Services/CompanyService';
import debounce from 'lodash.debounce';
import { Company } from 'Models/Companies/Company';
import { theme } from 'variant';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import './invite-user.less';
import { BUILDING_ACCESS_SITE_CODE, DEBOUNCE_DELAY } from 'Models/Constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { BuildingAccessService } from 'Services/BuildingAccessService';
import { CreateUserHidAccessRequestDto } from 'Api/Features/UserHidInfo/Dtos/CreateUserHidAccessRequestDto';
import InformationModal from 'Components/information-modal/information-modal';
import  { mergeSelectedOptionsWithSearchResults }  from 'Components/select-custom/select-custom-utils';
import { GetCompaniesRequestDto } from 'Api/Features/Companies/Dtos/GetCompaniesRequestDto';

const { Option } = Select;
const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface InviteUserModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    company?: Company;
}

const InviteUserModal: FunctionComponent<InviteUserModalProps> = ({
    visible,
    onComplete,
    company,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        InviteUserSchema,
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore, userStore } = useStores();
    const userService = useService(UserService);
    const companyService = useService(CompanyService);
    const buildingAccessService = useService(BuildingAccessService);
    const [companySearchResults, setCompanySearchResults] = useState<Company[]>([]);
    const [companySearchTerm, setCompanySearchTerm] = useState('');
    const [companyMaxResults, setCompanyMaxResults] = useState(false);
    const [companyCurrentPage, setCompanyCurrentPage] = useState(0);
    const [companyOptions, setCompanyOptions] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string[] | undefined>();
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectCustomOption[]>([]);

    const pageSize = 25;
    const [locationOptions, setLocationOptions] = useState<SelectCustomOption[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string>();
    const [createBuildingAccessCheckboxState, setCreateBuildingAccessCheckboxState] = useState(
        false
    );
    const [isPrivateCheckboxState, setIsPrivateCheckboxState] = useState(false);
    const [roleInformationModalOpen, setRoleInformationModalOpen] = useState(false);
    const [privacyInformationModalOpen, setPrivacyInformationModalOpen] = useState(false);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        resetErrors();
        setSelectedCompanyId(undefined);
        setCompanyOptions([]);
        resetCompanySearch();
        onComplete(success);
    };

    const inactiveCompanyErrorMessage = async (): Promise<void> => {
        await confirmationModalStore.confirm({
            icon: <CompanyIcon />,
            title: t(`Company.company_team_invite_inactive_error`),
            message: t(`Company.company_team_invite_inactive_error_message`),
            positiveText: t('ok'),
        });

        return;
    };

    const submit = async (values: Store): Promise<void> => {
        const model = {
            firstName: values.firstName,
            lastName: values.lastName,
            contactInfo: {
                email: values['contactInfo.email'],
                phoneNumber: values['contactInfo.phoneNumber'],
            } as ContactInfoDto,
            companyRoles: [values.companyRoles] as CompanyRoleDto[],
            companyId: company?.id || selectedCompanyId?.[0],
            createBuildingAccess: createBuildingAccessCheckboxState,
            locationId: selectedLocation,
            note: values.note,
            isPrivate: isPrivateCheckboxState
        } as CreateUserRequestDto;
        if (!(await validateForm(model, false))) return;
        try {
            globalLoadingStore.addLoading(true);
            const userId = await userService.createUser(model);

            if (userId && createBuildingAccessCheckboxState) {
                try {
                    await buildingAccessService.createUserHidAccess(userId, {
                        locationId: selectedLocation,
                        note: values.note,
                    } as CreateUserHidAccessRequestDto);
                } catch (e) {
                    if (!e.treated) {
                        toastStore.toast({
                            type: 'error',
                            messageKey: `Errors.building_access_create_error`,
                        });
                    }
                }
            }

            globalLoadingStore.removeLoading();
            await success(model.companyRoles!);
        } catch (error) {
            if (error.response?.data?.errors?.['contactInfo.Email'] !== undefined) {
                const errors = new Map<string, string[]>();
                errors.set('contactInfo.email', [
                    error.response?.data.errors?.['contactInfo.Email'][0].description,
                ]);
                setErrors(errors);
            } else if (error.response?.data?.errorCode === 'E005004') {
                globalLoadingStore.removeLoading();
                await inactiveCompanyErrorMessage();
            } else if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    const searchCompanies = async (page: number, searchTerm: string): Promise<Company[]> => {
        const args: GetCompaniesRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            locationIds: userStore.isAdmin ? undefined : userStore.buildingManagerLocationIds
        };

        const [results, totalItemCount] = await companyService.getCompanies(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setCompanyMaxResults(true);
        }

        return results;
    };

    const debounceCompanySearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompanySearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, DEBOUNCE_DELAY)
    );

    const resetCompanySearch = (): void => {
        setCompanyCurrentPage(0);
        setCompanySearchResults([]);
        setCompanySearchTerm('');
        setCompanyMaxResults(false);
    };

    const handleCompanyKeywordsChange = useCallback((value: string): void => {
        resetCompanySearch();
        setCompanySearchTerm(value);
    }, []);

    const handleCompanyMenuScrollToBottom = (): void => {
        if (!companyMaxResults) {
            setCompanyCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        debounceCompanySearch.current(companyCurrentPage, companySearchTerm);
    }, [companySearchTerm, companyCurrentPage]);

    useEffect(() => {
        const searchResults = companySearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedCompanyOptions)
        setCompanyOptions(merged);
    }, [companySearchResults, selectedCompanyOptions]);

    useEffect(() => {
        setLocationOptions(
            locationStore.locations.map(
                (location) =>
                    ({
                        value: location.id,
                        label: location?.name,
                        content: (
                            <div className="location-dropdown-address">
                                {company?.location?.id === location.id && (
                                    <MainLocation
                                        width={15}
                                        height={15}
                                        fill={theme['primary-color']}
                                    />
                                )}
                                {`${location.address?.addressLine1} ${location.address?.city}, ${location.address?.state} ${location.address?.zipCode}`}
                            </div>
                        ),
                    } as SelectCustomOption)
            )
        );
    }, [locationStore.locations, company]);

    useEffect(() => {
        if (company) setSelectedLocation(company.location?.id);
    }, [company]);

    interface SelectOption {
        id: string;
        name: string;
    }

    const rolesOptions: SelectOption[] = Object.keys(CompanyRoleDto).map((key) => ({
        id: key,
        name: key,
    }));

    const success = async (roles: CompanyRoleDto[]): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <CompanyIcon />,
                title: t(`User.user_invited_success`),
                message: t('User.user_successfully_invited', {
                    param1:
                        roles[0] === CompanyRoleDto.Administrator
                            ? `an ${CompanyRoleDto.Administrator}`
                            : `a ${CompanyRoleDto.Member}`,
                }),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <CompanyIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t('Company.invite_member_confirm_positive'),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss(false);
    };

    return (
        <Modal
            visible={visible}
            centered
            title={t('Company.invite_member')}
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
        >
            <div className="InviteUser">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('Contact.contact_first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('Contact.contact_last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.phoneNumber"
                                label={t('Contact.contact_phone')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.email"
                                label={t('Contact.contact_email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('privacy')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={1} className="is-private-icon-container">
                            <span onClick={(): void => setPrivacyInformationModalOpen(true)}>
                                <LowercaseI width={17} height={17} fill={theme['black']} />
                            </span>
                        </Col>
                        <Col span={7}>
                            <ValidatedFormItem
                                errors={errors}
                                name="isPrivate"
                                className="create-building-access"
                            >
                                <div className="checkbox-container">
                                    <div>
                                        <Checkbox
                                            onChange={(event: CheckboxChangeEvent): void =>
                                                setIsPrivateCheckboxState(event.target.checked)
                                            }
                                            checked={isPrivateCheckboxState}
                                        >
                                            {t('User.make_this_account_private')}
                                        </Checkbox>
                                    </div>
                                    <div className="icon-container">
                                        <PadLock
                                            width={15}
                                            height={17}
                                            fill={theme['primary-color']}
                                        />
                                    </div>
                                </div>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Company.company_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <div>
                                <p className="help">{t('Company.company_invite_info')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        {company === undefined && (
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    label={t('Company.company')}
                                    name="companyId"
                                    required
                                >
                                    <SelectCustom
                                        options={companyOptions}
                                        defaultImg={<CompanyIcon fill={theme['white']} />}
                                        strongLabel={true}
                                        placeholder={t('SelectCustom.placeholder_company')}
                                        onChange={(option: SelectCustomOption): void => {
                                            setSelectedCompanyId([option.value]);
                                            setSelectedCompanyOptions([option]);
                                        }}
                                        onKeywordsChange={handleCompanyKeywordsChange}
                                        onMenuScrollToBottom={handleCompanyMenuScrollToBottom}
                                        hideSelectedOptions={false}
                                        selected={selectedCompanyId}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        )}

                        <Col span={12}>
                            <div className="role-information-container">
                                <ValidatedFormItem
                                    errors={errors}
                                    name="companyRoles"
                                    label={t('Contact.contact_role')}
                                    required
                                    className="role-dropdown"
                                >
                                    <Select>
                                        {rolesOptions.map((option) => (
                                            <Option key={option.id} value={option.id}>
                                                {t(`User.user_role_${option.name}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </ValidatedFormItem>
                                <span onClick={(): void => setRoleInformationModalOpen(true)}>
                                    <LowercaseI width={17} height={17} fill={theme['black']} />
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('User.building_access')}</Title>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="createBuildingAccess"
                                className="create-building-access"
                            >
                                <div className="checkbox-container">
                                    <div>
                                        <Checkbox
                                            onChange={(event: CheckboxChangeEvent): void =>
                                                setCreateBuildingAccessCheckboxState(
                                                    event.target.checked
                                                )
                                            }
                                            checked={createBuildingAccessCheckboxState}
                                        >
                                            {t('User.create_building_access')}
                                        </Checkbox>
                                    </div>
                                    <div className="icon-container">
                                        <Key width={15} height={17} fill={theme['primary-color']} />
                                    </div>
                                </div>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {createBuildingAccessCheckboxState && (
                        <>
                            <div className="building-access-container">
                                <div className="info-container">
                                    <div>
                                        {t('User.building_access_site_code')}{' '}
                                        <span className="bold">{BUILDING_ACCESS_SITE_CODE}</span>
                                    </div>
                                </div>

                                <div>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="locationId"
                                        label={t('User.building_access_building_address')}
                                        required
                                    >
                                        <SelectCustom
                                            options={locationOptions}
                                            strongLabel={true}
                                            onChange={(selected: SelectCustomOption): void =>
                                                setSelectedLocation(selected.value)
                                            }
                                            hideSelectedOptions={false}
                                            selected={
                                                selectedLocation ? [selectedLocation] : undefined
                                            }
                                        />
                                    </ValidatedFormItem>
                                </div>
                            </div>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="note"
                                        label={t('note')}
                                        className="note"
                                    >
                                        <Input.TextArea />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={formGutter} className="building-access-explanation">
                                <Col span={24}>{t('User.building_access_explanation')}</Col>
                            </Row>
                        </>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
            {roleInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('User.user_role_Administrator'),
                            messages: [t('User.role_info_administrator')],
                        },
                        {
                            title: t('User.user_role_BookingManager'),
                            messages: [t('User.role_info_booking_manager')],
                        },
                        {
                            title: t('User.user_role_Member'),
                            messages: [t('User.role_info_member')],
                        },
                    ]}
                    onClose={(): void => setRoleInformationModalOpen(false)}
                />
            )}
            {privacyInformationModalOpen && (
                <InformationModal
                    titleMessageBlocks={[
                        {
                            title: t('User.privacy_explanation_title'),
                            messages: [t('User.privacy_explanation_message1'), t('User.privacy_explanation_message2')],
                        },
                    ]}
                    onClose={(): void => setPrivacyInformationModalOpen(false)}
                />
            )}
        </Modal>
    );
    //#endregion
};

export default React.memo(InviteUserModal);
