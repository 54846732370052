import { Checkbox, Select } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import './checkbox-dropdown.less';

interface CheckboxDropdownProps {
    options: { label: string; value: string }[];
    defaultCheckboxChecked?: boolean;
    dropdownValue?: string;
    onDropdownValueChange: (value: string | undefined) => void;
}
const { Option } = Select;

const CheckboxDropdown: FunctionComponent<CheckboxDropdownProps> = ({
    options,
    defaultCheckboxChecked,
    dropdownValue,
    onDropdownValueChange
}) => {
    const [checkboxState, setCheckBoxState] = useState(defaultCheckboxChecked);

    useEffect(() => {
        if (!checkboxState) {
            onDropdownValueChange(undefined);
        }
    }, [checkboxState, onDropdownValueChange]);

    useEffect(() => {
        if(dropdownValue) {
            onDropdownValueChange(dropdownValue);
        }
    }, [dropdownValue, onDropdownValueChange, options])

    return (
        <div className="CheckboxDropdown">
            <div className="checkbox-container">
                <Checkbox
                    checked={checkboxState}
                    onChange={(e): void => setCheckBoxState(e.target.checked)}
                />
            </div>

            <Select
                className={`checkbox-dropdown ${checkboxState ? 'enabled' : 'disabled'}`}
                disabled={!checkboxState}
                onChange={(value: string): void => {
                    onDropdownValueChange(value);
                }}
                value={dropdownValue}
            >
                {options.map((x) => (
                    <Option key={x.value} value={x.value}>
                        {x.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default CheckboxDropdown;
