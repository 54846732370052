import React, { FC, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Close } from 'Components/icons';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './image-crop-modal.less';

interface ImageCropModalProps {
    src: string;
    aspect?: number;
    title?: string;
    visible: boolean;
    onComplete: (success: boolean, url: string|null, base64: string|null) => void;
}

const ImageCropModal: FC<ImageCropModalProps> = ({ src, aspect, title, visible, onComplete }) => {
    const { t } = useTranslation();
    const [imageRef, setImageRef] = useState<HTMLImageElement>();
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        width: 30,
        x: 40,
        y: 40,
        aspect: aspect ? aspect : 16 / 9,
    });

    const dismiss = (success = false, url = null, base64 = null): void => {
        onComplete(success, url, base64);
    };

    const onImageLoaded = (image: HTMLImageElement): void => {
        setImageRef(image);
    };

    const getCroppedImageUrl = async (image: HTMLImageElement, crop: any): Promise<[string, string]> => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width || 0;
        canvas.height = crop.height || 0;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        let base64 = canvas.toDataURL();
        // remove image type and base64 from base64 str keep only data.
        base64 = base64.split(',').pop() || '';

        return new Promise((resolve) => {
            canvas.toBlob(async (blob: any) => {
                if (!blob) {
                    return;
                }
                window.URL.revokeObjectURL(src);
                src = window.URL.createObjectURL(blob);
                resolve([src, base64]);
            }, 'image/jpeg');
        });
    };

    const onCropChange = (crop: Crop): void => {
        setCrop(crop);
    };

    const submit = async (): Promise<void> => {
        if (imageRef && crop.width && crop.height) {
            const [croppedImageUrl, croppedImageBase64] = await getCroppedImageUrl(imageRef, crop);
            onComplete(true, croppedImageUrl, croppedImageBase64);
        }
    };

    return (
        <Modal
            centered
            visible={visible}
            title={title ? title : t('Images.images_cropping')}
            className="FormModal CroppingModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
        >
            <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange={onCropChange}
            />
            <div className="actions">
                <Button
                    type="default"
                    className="secondary negative"
                    htmlType="button"
                    onClick={(): void => dismiss(false)}
                >
                    {t('cancel')}
                </Button>
                <Button type="primary" className="positive" htmlType="submit" onClick={submit}>
                    {t('confirm')}
                </Button>
            </div>
        </Modal>
    );
};

export default ImageCropModal;
