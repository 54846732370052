import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';
import UserNavigation from 'Components/user-navigation/user-navigation';
import { observer } from 'mobx-react';

import './index.less';
import { UserDetails } from 'Models/Users/UserDetails';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';

export const UserContext = createContext<UserDetails | undefined>(undefined);

const UserDetail: FunctionComponent = observer(({ children, ...props }: any) => {
    const { id } = useParams();
    const { navigationStore, globalLoadingStore } = useStores();
    const userService = useService(UserService);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);

    const fetchCompany = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await userService.getById(id);
            setUser(response || undefined);
            navigationStore.setSubmenu(response);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [userService, id, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    return (
        <UserContext.Provider value={user}>
            <div className="UserDetail">
                <UserNavigation />
                {children}
            </div>
        </UserContext.Provider>
    );
});

export default UserDetail;
