import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { FoodMerchantProxy } from 'Api/Features/FoodMerchants/FoodMerchantProxy';
import { GetFoodMerchantsResponseDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsResponseDto';
import { GetFoodMerchantsRequestDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsRequestDto';
import { FoodMerchantDto } from 'Api/Features/FoodMerchants/Dtos/FoodMerchantDto';
import { FoodMerchant } from 'Models/FoodMerchant/FoodMerchant';
import { CreateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/CreateFoodMerchantRequestDto';
import { UpdateFoodMerchantRequestDto } from 'Api/Features/FoodMerchants/Dtos/UpdateFoodMerchantRequestDto';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { GetFoodMerchantsOrderingResponseDto } from 'Api/Features/FoodMerchants/Dtos/GetFoodMerchantsOrderingResponseDto';

@inject(FoodMerchantProxy)
export class FoodMerchantService extends ApiService {
    constructor(private readonly foodMerchantProxy: FoodMerchantProxy) {
        super();
    }

    public async getFoodMerchant(id: string): Promise<FoodMerchant | null> {
        const response: FoodMerchantDto | null = await this.foodMerchantProxy.getFoodMerchant(id);
        return response ? new FoodMerchant(response) : null;
    }

    public async getFoodMerchants(
        request?: GetFoodMerchantsRequestDto
    ): Promise<[FoodMerchant[], number]> {
        const response: GetFoodMerchantsResponseDto | null = await this.foodMerchantProxy.getFoodMerchants(
            request || null
        );
        return [
            response?.items?.map((dto: FoodMerchantDto | null) => new FoodMerchant(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createFoodMerchant(request: CreateFoodMerchantRequestDto): Promise<void> {
        await this.foodMerchantProxy.createFoodMerchant(request);
    }

    public async updateFoodMerchant(
        request: UpdateFoodMerchantRequestDto,
        id: string
    ): Promise<void> {
        await this.foodMerchantProxy.updateFoodMerchant(id, request);
    }

    public async deleteFoodMerchant(id: string): Promise<void> {
        await this.foodMerchantProxy.deleteFoodMerchant(id);
    }

    public async getFoodMerchantsOrdering(
        id: string
    ): Promise<GetFoodMerchantsOrderingResponseDto | null> {
        return await this.foodMerchantProxy.getFoodMerchantsOrdering(id);
    }

    public async updateFoodMerchantsOrdering(
        id: string,
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.foodMerchantProxy.updateFoodMerchantsOrdering(id, { method: method, ids: ids });
    }
}
