import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { VerdocsProxy } from 'Api/Features/Verdocs/VerdocsProxy';
import { CreateUserVerdocsDocumentRequestDto } from 'Api/Features/Verdocs/Dtos/CreateUserVerdocsDocumentRequestDto';
import { GetUserVerdocsDocumentsRequestDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentsRequestDto';
import { GetUserVerdocsDocumentsResponseDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentsResponseDto';
import { GetUserVerdocsDocumentWebLinkRequestDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentWebLinkRequestDto';
import { GetUserVerdocsDocumentWebLinkResponseDto } from 'Api/Features/Verdocs/Dtos/GetUserVerdocsDocumentWebLinkResponseDto';
import { GetVerdocsTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsTemplatesRequestDto';
import { GetVerdocsTemplatesResponseDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsTemplatesResponseDto';
import { UserVerdocsDetailsDto } from 'Api/Features/Verdocs/Dtos/UserVerdocsDetailsDto';
import { CreateVerdocsMandatoryDocumentTemplateRequestDto } from 'Api/Features/Verdocs/Dtos/CreateVerdocsMandatoryDocumentTemplateRequestDto';
import { GetVerdocsMandatoryDocumentTemplatesRequestDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsMandatoryDocumentTemplatesRequestDto';
import { GetVerdocsMandatoryDocumentTemplatesResponseDto } from 'Api/Features/Verdocs/Dtos/GetVerdocsMandatoryDocumentTemplatesResponseDto';
import { VerdocsDocument } from 'Models/Verdocs/VerdocsDocument';
import { VerdocsDocumentRecipient } from 'Models/Verdocs/VerdocsDocumentRecipient';

@inject(VerdocsProxy)
export class VerdocsService extends ApiService {
    constructor(private readonly verdocsProxy: VerdocsProxy) {
        super();
    }

    public async getUserVerdocsDetails(userId: string): Promise<UserVerdocsDetailsDto | null> {
        const data: UserVerdocsDetailsDto | null = await this.verdocsProxy.getUserVerdocsDetails(
            userId
        );
        return data;
    }

    public async getUserVerdocsDocuments(
        userId: string,
        request: GetUserVerdocsDocumentsRequestDto | null
    ): Promise<[VerdocsDocument[], number]> {
        const data: GetUserVerdocsDocumentsResponseDto | null = await this.verdocsProxy.getUserVerdocsDocuments(
            userId,
            request
        );

        const documents = data?.items
        ?.filter((item) => item !== null)
        .map((item) => new VerdocsDocument(item!)) ?? [];

        documents.map(
            (item) =>
                (item.recipients = item.recipients
                    ?.filter((recipient) => recipient !== null)
                    .map((recipient) => new VerdocsDocumentRecipient(recipient!)))
        );
        return [
            documents,
            data?.totalItemCount ?? 0,
        ];
    }

    public async createUserVerdocsDocument(
        userId: string,
        request: CreateUserVerdocsDocumentRequestDto | null
    ): Promise<string> {
        const data: string = await this.verdocsProxy.createUserVerdocsDocument(userId, request);
        return data;
    }

    public async getUserVerdocsDocumentWebLink(
        userId: string,
        documentId: string,
        request: GetUserVerdocsDocumentWebLinkRequestDto | null
    ): Promise<GetUserVerdocsDocumentWebLinkResponseDto | null> {
        const data: GetUserVerdocsDocumentWebLinkResponseDto | null = await this.verdocsProxy.getUserVerdocsDocumentWebLink(
            userId,
            documentId,
            request
        );
        return data;
    }

    public async getUserVerdocsDocumentFile(
        userId: string,
        documentId: string
    ): Promise<ArrayBuffer> {
        const data: ArrayBuffer = await this.verdocsProxy.getUserVerdocsDocumentFile(
            userId,
            documentId
        );
        return data;
    }

    public async getVerdocsTemplates(
        request: GetVerdocsTemplatesRequestDto | null
    ): Promise<GetVerdocsTemplatesResponseDto | null> {
        const data: GetVerdocsTemplatesResponseDto | null = await this.verdocsProxy.getVerdocsTemplates(
            request
        );
        return data;
    }

    public async getVerdocsMandatoryDocumentTemplates(
        request: GetVerdocsMandatoryDocumentTemplatesRequestDto | null
    ): Promise<GetVerdocsMandatoryDocumentTemplatesResponseDto | null> {
        const data: GetVerdocsMandatoryDocumentTemplatesResponseDto | null = await this.verdocsProxy.getVerdocsMandatoryDocumentTemplates(
            request
        );
        return data;
    }

    public async createVerdocsMandatoryDocumentTemplate(
        request: CreateVerdocsMandatoryDocumentTemplateRequestDto | null
    ): Promise<string> {
        const data: string = await this.verdocsProxy.createVerdocsMandatoryDocumentTemplate(
            request
        );
        return data;
    }

    public async deleteVerdocsMandatoryDocumentTemplate(
        mandatoryTemplateId: string
    ): Promise<void> {
        await this.verdocsProxy.deleteVerdocsMandatoryDocumentTemplate(mandatoryTemplateId);
    }
}
