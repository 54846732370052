import React, { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button, Col, Divider, Layout, Row } from 'antd';
import {
    Company as CompanyIcon,
    Location as LocationIcon,
    User as UserIcon,
    Address as AddressIcon,
    Phone as PhoneIcon,
    Email as EmailIcon,
    Website as WebsiteIcon,
    MainLocation,
    Credit,
    User,
} from 'Components/icons';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import CompanyHeader from 'Components/company-header/company-header';
import { SimpleList } from 'Components/simple-list';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { theme, images } from 'variant';
import { CompanyService } from 'Services/CompanyService';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { ContactInfo } from 'Models/Contact/ContactInfo';
import { CompanyStatusDto } from 'Api/Features/Companies/Dtos/CompanyStatusDto';
import { UpdateCompanyStatusRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyStatusRequestDto';
import EditCreateCompanyModal from '../../edit-create-company';
import CreateCompanyMessageModal from '../messages/create-message';
import './index.less';
import { CompanyMessageTypes } from 'Models/Messages/CompanyMessageTypes';
import ConvertToHeydayModal from '../ConvertToHeyday';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import Icon from 'Components/icons/Icon';
import { CompanyContext } from '..';
import { CompanyLocationDto } from 'Api/Features/Companies/Dtos/CompanyLocationDto';
import CreditBalanceModal from './edit-credit-balance-modal';
import { TdWithImage } from 'Components/td-with-image';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { listSectionHeader } = images;
const { Content } = Layout;

interface SimpleListItemProps {
    title: string | React.ReactNode;
    description?: string;
    avatar?: React.ReactNode;
}

const CompanyProfile: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { globalLoadingStore, navigationStore, confirmationModalStore, userStore } = useStores();
    const companyService = useService(CompanyService);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState<Company | undefined>(undefined);
    const [statusHasChanged, completeStatusChange] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
    const [convertToHeydayModalOpen, setConvertToHeydayModalOpen] = useState(false);
    const context = useContext(CompanyContext);
    const [editCreditBalanceModalOpen, setEditCreditBalanceModalOpen] = useState(false);
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'companies',
            nameKey: 'Company.companies',
        },
        {
            path: id,
            name: company?.name || t('Company.company'),
        },
        {
            path: 'profile',
            nameKey: 'Company.company_profile',
        },
    ];

    const actions = (): ActionMenuOption[] => {
        const actions: ActionMenuOption[] = [
            {
                key: 'edit',
                title: t('Company.company_edit_profile'),
                action: (): void => setEditModalOpen(true),
            },
            {
                key: 'activate',
                title: t(company?.isActive ? 'Company.company_deactivate' : 'Company.company_activate'),
                action: (): Promise<void> => toggleCompanyStatus(),
            },
            {
                key: 'delete',
                title: t('Company.company_delete'),
                action: (): Promise<void> => deleteCompany(),
            },
        ];
        if (company?.isCoworking) {
            actions.splice(1, 0, {
                key: 'editCredits',
                title: t('Company.manage_credits'),
                action: (): void => setEditCreditBalanceModalOpen(true),
            });
        }

        if(company?.type !== CompanyTypeDto.Passport){
            actions.splice(1, 0, {
                key: 'sendPackageMessage',
                title: t('Message.send_package_delivery_message'),
                action: (): void => setSendMessageModalOpen(true),
            });
        }

        return actions;
    }
    //#endregion

    //#region Effects
    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const companyData: CompanyDto | null = await companyService.getById(id);

            if (companyData) {
                const company: Company = new Company(companyData);
                setCompany(company);
                navigationStore.setSubmenu(company);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [companyService, id, globalLoadingStore, navigationStore]);

    useEffect(() => {
        fetch();
    }, [fetch, editModalOpen, statusHasChanged]);
    //#endregion

    //#region Actions & events
    const onManageMainContactUser = (): void => {
        if (company && company.mainContactUser) {
            history.push(company.mainContactUser.consoleUrl);
        }
    };

    const onEditionComplete = useCallback(
        (success: boolean) => {
            setEditModalOpen(false);
            if (success) {
                fetch();
            }
        },
        [fetch]
    );

    const toggleCompanyStatus = async (): Promise<void> => {
        const trans_prefix = company?.isActive
            ? 'Company.company_deactivate_confirm_'
            : 'Company.company_activate_confirm_';
        if (
            !(await confirmationModalStore.confirm({
                icon: <CompanyIcon />,
                title: t(trans_prefix + 'title'),
                message: t(trans_prefix + 'message'),
                positiveText: t(trans_prefix + 'positive'),
                negativeText: t(trans_prefix + 'negative'),
                width: 800,
            }))
        ) {
            return;
        }

        async function toggleStatus() {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                await companyService.updateCompanyStatus(id, {
                    status: company?.isActive
                        ? CompanyStatusDto.Deactivated
                        : CompanyStatusDto.Active,
                } as UpdateCompanyStatusRequestDto);
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
                completeStatusChange(!statusHasChanged);
            }
        }

        await toggleStatus();
    };

    const deleteCompany = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UserIcon />,
                title: t('Company.company_delete_confirm_title'),
                message: t('Company.company_delete_confirm_message'),
                positiveText: t('Company.company_delete_confirm_positive'),
                negativeText: t('Company.company_delete_confirm_negative'),
            }))
        ) {
            return;
        }

        async function doDelete() {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                await companyService.deleteCompany(id);
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
            }
        }

        async function confirmDelete() {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <CompanyIcon />,
                    title: t(`Company.company_delete_success`),
                    message: t(`Company.company_delete_success_message`),
                    positiveText: t('ok'),
                }))
            ) {
                return;
            }
        }

        await doDelete();
        await confirmDelete();

        // Redirect to companies list
        history.replace('/companies');
    };
    //#endregion

    //#region Page content
    const getSimpleContent = (content: string | null): SimpleListItemProps[] => {
        let contentProps: SimpleListItemProps[] = [];
        if (content) {
            contentProps = [{ title: content }];
        }
        return contentProps;
    };

    const locationItem = (location: CompanyLocationDto): ReactNode => {
        return (
            <div className="item-container">
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    defaultImg={<LocationIcon />}
                    imgSrc={location.mainImageUrl}
                />
                <div className="info-container">
                    {location.id === company?.location?.id && (
                        <div className="main-location">
                            <MainLocation width={14} height={14} fill={theme['primary-color']} />
                            <span>{t('Location.main_location')}</span>
                        </div>
                    )}

                    <div className="location-name">
                        <span>{location.name}</span>
                    </div>
                    <div className="location-type">
                        <span>{t(`LocationType.${location.type}`)}</span>
                    </div>
                </div>
            </div>
        );
    };

    const getLocationContent = (mainLocation: CompanyLocationDto, locations?: CompanyLocationDto[] | null): ReactNode => {
        return (
            <div className="locations-container">
                {locationItem(mainLocation)}
                {locations
                    ? locations
                          .filter((location) => location.id !== mainLocation.id)
                          .map((location) => locationItem(location))
                    : null}
            </div>
        );
    };

    const getCompanyAddress = (): SimpleListItemProps[] => {
        let contact: SimpleListItemProps[] = [];
        if (company && company.mainAddress) {
            const contactAddressData = [
                {
                    value: company.mainAddress.text,
                    avatar: <AddressIcon fill={theme['primary-color']} />,
                },
            ];
            contact = contactAddressData
                .filter((info) => {
                    return info.value;
                })
                .map((info) => {
                    return { title: info.value || '', avatar: info.avatar };
                });
        }
        return contact;
    };

    const getCompanyContactInfo = (): SimpleListItemProps[] => {
        let contact: SimpleListItemProps[] = [];
        if (company && company.info) {
            // Only display available info
            const info: ContactInfo = company.info;
            const contactInfoData = [
                { value: info.phoneNumber, avatar: <PhoneIcon fill={theme['primary-color']} /> },
                { value: info.websiteLink, avatar: <WebsiteIcon fill={theme['primary-color']} /> },
                { value: info.email, avatar: <EmailIcon fill={theme['primary-color']} /> },
            ];
            contact = contactInfoData
                .filter((info) => {
                    return info.value;
                })
                .map((info) => {
                    return { title: info.value, avatar: info.avatar };
                });
        }
        return contact;
    };

    const getTownhallCoworking = (company: Company): ReactNode => {
        if(!company.isTownhall && !company.isCoworking)
            return null;

        let props = {
            iconName: '',
            text: '',
        };
        
        if (company.isTownhall) props = { iconName: 'Townhall', text: 'Company.townhall_tenant' };
        if (company.isCoworking)
            props = { iconName: 'Coworking', text: 'Company.coworking_tenant' };
        if (company.isTownhall && company.isCoworking)
            props = {
                iconName: 'TownhallAndCoworking',
                text: 'Company.townhall_and_coworking_tenant',
            };
        return (
            <div className="permission-container">
                <Icon iconName={props.iconName} fill={theme['primary-color']} />
                <span className="permission-text">{t(props.text)}</span>
            </div>
        );
    };

    const getTypeInfo = (): SimpleListItemProps[] => {
        let type: SimpleListItemProps[] = [];
        if(company) {
            type = [
                {
                    title: (
                        <div>
                            <div>{company.type}</div>
                            {getTownhallCoworking(company)}
                        </div>
                    ),
                },
            ];
        }
        
        return type;
    }

    const getTypeActions = (): ReactNode => {
        return (
            <div className="type-actions">
                <Divider />
                <Row>
                    <Col className="convert-btn-container" span={8}>
                        <Button
                            type="default"
                            className="convert-btn"
                            onClick={(): void => setConvertToHeydayModalOpen(true)}
                        >
                            {t('Company.convert_to_heyday')}
                        </Button>
                    </Col>
                    <Col span={16}>
                        <span className="convert-warning">{t('Company.convert_warning')}</span>
                    </Col>
                </Row>
            </div>
        );
    }
    //#endregion
    
    return (
        <>
            <CompanyHeader
                title={company?.name || ''}
                subTitle={null}
                imgSrc={company?.imageUrl}
                defaultImg={<CompanyIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                status={company?.statusBadgeStatus}
                loading={loading}
                routes={breadcrumbs}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [company?.location?.id].concat(
                                company?.locations
                                    ? company.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && <ActionMenu options={actions()} type="primary" trigger="click" />
                }
            />
            <Layout>
                <Content className="companyDetailContent">
                    <Row gutter={16}>
                        <Col span={12}>
                            {company && !company.introductionEmpty && (
                                <SimpleList
                                    title={t('Company.company_introduction')}
                                    data={getSimpleContent(company.introduction!)}
                                />
                            )}

                            {company?.sector && (
                                <SimpleList
                                    title={t('Company.company_sector_of_business')}
                                    data={getSimpleContent(
                                        t('CompanySector.company_sector_' + company.sector)
                                    )}
                                />
                            )}

                            {company?.numEmployees && (
                                <SimpleList
                                    title={t('Company.company_number_of_employees')}
                                    data={getSimpleContent(
                                        t('CompanyNumEmployees.' + company.numEmployees)
                                    )}
                                />
                            )}
                            {company && !company.info?.isEmpty && (
                                <SimpleList
                                    title={t('Contact.contact_information')}
                                    data={getCompanyContactInfo()}
                                />
                            )}
                            {company && !company.mainAddress?.isEmpty && (
                                <SimpleList
                                    title={t('Contact.contact_address')}
                                    data={getCompanyAddress()}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            {company?.mainContactUser && (
                                <SimpleList title={t('Contact.contact_main')}>
                                    <div className="main-contact-container">
                                        <div>
                                            <TdWithImage
                                                width="32"
                                                height="32"
                                                defaultImg={<User />}
                                                imgSrc={company.mainContactUser.imageUrl}
                                            >
                                                <div>
                                                    <div className="contact-name">
                                                        {company.mainContactUser.name}
                                                    </div>
                                                    <div className="contact-role">
                                                        {company.mainContactUser.companyRole}
                                                    </div>
                                                </div>
                                            </TdWithImage>
                                        </div>
                                        <div className="button-container">
                                            <Button
                                                type="default"
                                                className="manage-user-btn"
                                                onClick={onManageMainContactUser}
                                            >
                                                {t('User.user_manage')}
                                            </Button>
                                        </div>
                                    </div>
                                </SimpleList>
                            )}

                            {company?.location && (
                                <SimpleList title={t('Location.location')}>
                                    {getLocationContent(company.location, company.locations)}
                                </SimpleList>
                            )}

                            <SimpleList title={t('type')} className="type" data={getTypeInfo()}>
                                {(userStore.isAdmin ||
                                    new UserPermissionUtils(userStore).UserCanViewContent(
                                        [company?.location?.id].concat(
                                            company?.locations
                                                ? company.locations?.map((location) => location.id)
                                                : undefined
                                        )
                                    )) &&
                                    company?.type === CompanyTypeDto.Passport &&
                                    getTypeActions()}
                            </SimpleList>

                            {company?.isCoworking && (
                                <SimpleList title={t('credit_balance')} className="credit-balance">
                                    <div className="credit-balance-container border">
                                        <div className="credit-container">
                                            <Credit
                                                width={24}
                                                height={24}
                                                fill={theme['primary-color']}
                                            />
                                            <span className="credit-amount">
                                                {company.creditBalance?.available}
                                            </span>
                                            <span>{t('Company.available_booking_credits')}</span>
                                        </div>
                                    </div>
                                    <div className="credit-balance-container">
                                        <div className="credit-container">
                                            <Credit
                                                width={24}
                                                height={24}
                                                fill={theme['primary-color']}
                                            />
                                            <span className="credit-amount">
                                                {company.creditsPerInterval}
                                            </span>
                                            <span>{t('Company.monthly_credits')}</span>
                                        </div>
                                    </div>
                                </SimpleList>
                            )}
                        </Col>
                    </Row>
                </Content>
            </Layout>
            {editModalOpen && (
                <EditCreateCompanyModal
                    visible={editModalOpen}
                    company={company}
                    onComplete={onEditionComplete}
                    isEditing={true}
                />
            )}
            {sendMessageModalOpen && (
                <CreateCompanyMessageModal
                    company={company}
                    visible={sendMessageModalOpen}
                    onComplete={(): void => setSendMessageModalOpen(false)}
                    defaultMessageType={CompanyMessageTypes.PackageDelivery}
                    messageTypeDisabled
                />
            )}

            {convertToHeydayModalOpen && (
                <ConvertToHeydayModal
                    visible={convertToHeydayModalOpen}
                    onComplete={(response: boolean): void => {
                        setConvertToHeydayModalOpen(false);
                        if (response) {
                            fetch();
                            context?.onConvertToHeyday();
                        }
                    }}
                    company={company}
                    isEditing
                />
            )}

            {editCreditBalanceModalOpen && (
                <CreditBalanceModal
                    visible={editCreditBalanceModalOpen}
                    companyId={company?.id}
                    balance={company?.creditBalance?.available}
                    creditsPerInterval={company?.creditsPerInterval ?? undefined}
                    onComplete={(success: boolean): void => {
                        setEditCreditBalanceModalOpen(false);
                        if (success) fetch();
                    }}
                />
            )}
        </>
    );
});

export default CompanyProfile;
