import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { LocationServiceProxy } from 'Api/Features/LocationServices/LocationServiceProxy';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateLocationServiceRequestDto } from 'Api/Features/LocationServices/Dtos/CreateLocationServiceRequestDto';
import { GetLocationServicesRequestDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesRequestDto';
import { GetLocationServicesResponseDto } from 'Api/Features/LocationServices/Dtos/GetLocationServicesResponseDto';
import { LocationServiceDto } from 'Api/Features/LocationServices/Dtos/LocationServiceDto';
import { UpdateLocationServiceImagesRequest } from 'Api/Features/LocationServices/Dtos/UpdateLocationServiceImagesRequest';
import { UpdateLocationServiceRequestDto } from 'Api/Features/LocationServices/Dtos/UpdateLocationServiceRequestDto';


@inject(LocationServiceProxy)
export class LocationServiceService extends ApiService {
    constructor(private readonly locationServiceProxy: LocationServiceProxy) {
        super();
    }

    public async getLocationService(locationServiceId: string): Promise<LocationServiceDto | null> {
        const data: LocationServiceDto | null = await this.locationServiceProxy.getLocationService(locationServiceId);
        return data;
    }

    public async getLocationServices(request: GetLocationServicesRequestDto | null): Promise<[LocationServiceDto[], number]> {
        const data: GetLocationServicesResponseDto | null = await this.locationServiceProxy.getLocationServices(request);
        return [
            data?.items?.filter(service => service !== null).map(service => service!) ?? [],
            data?.totalItemCount || 0,
        ];
    }

    public async createLocationService(request: CreateLocationServiceRequestDto | null): Promise<string> {
        const data: string = await this.locationServiceProxy.createLocationService(request);
        return data;
    }

    public async updateLocationService(locationServiceId: string, request: UpdateLocationServiceRequestDto | null): Promise<void> {
        await this.locationServiceProxy.updateLocationService(locationServiceId, request);
    }

    public async deletelocationServices(locationServiceId: string): Promise<void> {
        await this.locationServiceProxy.deletelocationServices(locationServiceId);
    }

    public async updateLocationServiceMainImage(locationServiceId: string, request: UpdateFileRequestDto | null): Promise<void> {
        await this.locationServiceProxy.updateLocationServiceMainImage(locationServiceId, request);
    }

    public async deleteLocationServiceMainImage(locationServiceId: string): Promise<void> {
        await this.locationServiceProxy.deleteLocationServiceMainImage(locationServiceId);
    }

    public async updateLocationServiceImages(locationServiceId: string, request: UpdateLocationServiceImagesRequest | null): Promise<void> {
        await this.locationServiceProxy.updateLocationServiceImages(locationServiceId, request);
    }
}
