import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { AmenityService } from 'Services/AmenityService';
import moment from 'moment';
import { Col, Layout, Row } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import { Amenity as AmenityIcon, Delete } from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { Location } from 'Models/Location/Location';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { MOMENT_TIME_FORMAT } from 'Models/Constants';
import AmenityModal from '../create-amenity';
import './index.less';
import Icon from 'Components/icons/Icon';
import { convertTimeSpanStringToMinutes } from 'Utils/TimeUtils';

const { Content } = Layout;

const LocationAmenitiesDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const { amenityId } = useParams<{amenityId: string}>();
    const history = useHistory();
    const { navigationStore, globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const locationService = useService(LocationService);
    const amenityService = useService(AmenityService);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [data, setData] = useState<AmenityDto | null>(null);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [settings, setSettings] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        if (location) {
            navigationStore.setSubmenu(location);
        }
    }, [location, navigationStore]);

    const deleteConferenceRoom = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Amenities.delete_confirm_title`),
                message: t(`Amenities.delete_confirm_message`),
                positiveText: t(`Amenities.delete_confirm_positive`),
                negativeText: t(`Amenities.delete_confirm_negative`),
            }))
        )
            return;

        await amenityService.deleteAmenity(amenityId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Amenities.cta_edit_amenity'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Amenities.cta_delete_amenity'),
            action: (): Promise<void> => deleteConferenceRoom(),
        },
    ];

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const locationData: LocationDto | null = await locationService.getById(id);

                if (locationData) {
                    setLocation(new Location(locationData));
                }

                const response: AmenityDto | null = await amenityService.getAmenity(amenityId);
                if (response) {
                    setData(response);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [
        id,
        locationService,
        editModalOpen,
        amenityId,
        amenityService,
        globalLoadingStore,
        toastStore,
    ]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_title',
            },
            {
                path: 'amenities',
                nameKey: 'Location.management_amenities',
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const getPrice = (amenity: AmenityDto): string => {
        if (amenity.pricePerBlock === 0) {
            return t('free');
        } else if (amenity.minutesPerBlock && amenity.minutesPerBlock === 60) {
            return t('Amenities.price_per_hour', { param1: amenity.pricePerBlock });
        } else if (amenity.minutesPerBlock) {
            return t('Amenities.price_per_minutes', {
                param1: amenity.pricePerBlock,
                param2: amenity.minutesPerBlock,
            });
        }
        return '';
    };

    const getCredit = (amenity: AmenityDto): string => {
        if (amenity.creditsPerBlock === 0) {
            return t('free');
        } else if (amenity.minutesPerBlock && amenity.minutesPerBlock === 60) {
            return t('Amenities.credits_per_hour', { param1: amenity.creditsPerBlock });
        } else if (amenity.minutesPerBlock) {
            return t('Amenities.credits_per_minutes', {
                param1: amenity.creditsPerBlock,
                param2: amenity.minutesPerBlock,
            });
        }
        return '';
    };

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = [];
        if (data?.description) {
            description.push({
                title: data?.description,
            });
        }
        return description;
    }, [data]);

    const getSettings = useCallback((): SimpleListItemProps[] => {
        const settings: SimpleListItemProps[] = [];
        if (data?.openingTime === null && data?.closingTime === null) {
            settings.push({
                title: t('Amenities.settings_open_24h'),
            });
        }
        if (data?.openingTime) {
            settings.push({
                title: t('Amenities.opening_time'),
                description: moment(data.openingTime, MOMENT_TIME_FORMAT).format(
                    MOMENT_TIME_FORMAT
                ),
            });
        }
        if (data?.closingTime) {
            settings.push({
                title: t('Amenities.closing_time'),
                description: moment(data.closingTime, MOMENT_TIME_FORMAT).format(
                    MOMENT_TIME_FORMAT
                ),
            });
        }
        if (data?.maximumBookingDuration) {
            settings.push({
                title: t('Amenities.max_booking_duration'),
                description: convertTimeSpanStringToMinutes(data.maximumBookingDuration).toString()
            });
        }
        if (data?.sameDayMaximumTotalBookingDuration) {
            settings.push({
                title: t('Amenities.same_day_max_booking'),
                description: convertTimeSpanStringToMinutes(data.sameDayMaximumTotalBookingDuration).toString()
            });
        }
        if (data?.maximumContiguousBookingDuration) {
            settings.push({
                title: t('Amenities.max_contigous_booking'),
                description: convertTimeSpanStringToMinutes(data.maximumContiguousBookingDuration).toString()
            });
        }
        if (data?.isTownhallExclusive) {
            settings.push({
                title: (
                    <div className="permission-container">
                        <Icon iconName='Townhall' fill={theme['primary-color']} />
                        <span className="permission-text">{t('townhall')}</span>
                    </div>
                ),
            });
        }
        if (data?.isCoworking) {
            settings.push({
                title: (
                    <div className="permission-container">
                        <Icon iconName="Coworking" fill={theme['primary-color']} />
                        <span className="permission-text">{t('coworking')}</span>
                    </div>
                ),
            });
        }
        if (data?.isApprovalRequired) {
            settings.push({
                title: t('Amenities.settings_confirmation_needed'),
            });
        }
        if (data?.bookingWindowMaxDays) {
            settings.push({
                title: (
                    <span>
                        {t('Amenities.booking_window_limit')}
                        {' - '}
                        <span className="bold">
                            {t(`Amenities.AmenityBookingWindow_${data.bookingWindowMaxDays}`)}
                        </span>
                    </span>
                ),
            });
        }
        
        return settings;
    }, [data, t]);

    useEffect(() => {
        if (data) {
            setDescription(getDescription);
            setSettings(getSettings);
        }
    }, [data, getDescription, getSettings]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };

    return (
        <div className="AmenitiesDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={
                            <>
                                {data?.category && (
                                    <div className="header-badge">{data?.category?.name}</div>
                                )}
                                <div className="header-details">
                                    <div className="price">
                                        <div>{t('Table.column_price')}</div>
                                        <strong>{getPrice(data)}</strong>
                                    </div>
                                    <div className="credit">
                                        <div>{t('Amenities.coworking_price')}</div>
                                        <strong>{getCredit(data)}</strong>
                                    </div>
                                </div>
                            </>
                        }
                        imgSrc={data?.imageUrl}
                        defaultImg={<AmenityIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={<ActionMenu options={actions} type="primary" trigger="click" />}
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                {description.length > 0 && (
                                    <SimpleList
                                        className="description"
                                        title={t('Location.location_description')}
                                        data={description}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {settings.length > 0 && (
                                    <SimpleList
                                        title={t('Amenities.settings_title')}
                                        data={settings}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {editModalOpen && (
                        <AmenityModal
                            visible={editModalOpen}
                            onComplete={onEditComplete}
                            amenity={data}
                            locationId={data?.location?.id || undefined}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default LocationAmenitiesDetail;
