import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { BookingService } from 'Services/BookingService';
import { Button, Col, Layout, Row } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import {
    Amenity as AmenityIcon,
    Booking as BookingIcon,
    Company as CompanyIcon,
    User as UserIcon,
} from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import './index.less';
import { Booking } from 'Models/Bookings/Booking';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Company } from 'Models/Companies/Company';
import { AmenityService } from 'Services/AmenityService';
import { UserService } from 'Services/UserService';
import { UserDetails } from 'Models/Users/UserDetails';
import BookingDenyModal from './deny-modal';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import StatusBadge from 'Components/status-badge/status-badge';
import UserPermissionUtils from 'Utils/UserPermissionUtils';
import BookingApprovalModal from './booking-approval-modal';

const { Content } = Layout;

const LocationBookingApprovalDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id, bookingId } = useParams<{ id: string; bookingId: string }>();
    const history = useHistory();
    const { globalLoadingStore, toastStore, bookingStore } = useStores();
    const amenityService = useService(AmenityService);
    const bookingService = useService(BookingService);
    const userService = useService(UserService);
    const [data, setData] = useState<Booking | null>(null);
    const [bookingSummary, setBookingSummary] = useState<SimpleListItemProps[] | null>();
    const [company, setCompany] = useState<SimpleListItemProps[] | null>();
    const [bookingAmenity, setBookingAmenity] = useState<SimpleListItemProps[] | null>();
    const [bookingCreator, setBookingCreator] = useState<SimpleListItemProps[] | null>();

    const [denyModalOpen, setDenyModalOpen] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [user, setUser] = useState<UserDetails | null>();
    const [companyModel, setCompanyModel] = useState<Company | null>(null);
    const { userStore } = useStores();

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            globalLoadingStore.addLoading();
            try {
                const response = await bookingService.getBooking(bookingId);
                if (response) {
                    setData(response);

                    if (response.company) {
                        setCompanyModel(new Company(response.company));
                    }

                    if (response.amenity?.id) {
                        const amenityData = await amenityService.getAmenity(response.amenity?.id);
                        setBookingAmenity([
                            {
                                avatar: (
                                    <ImageWithPlaceholder
                                        width="32"
                                        height="32"
                                        defaultImg={<AmenityIcon />}
                                        imgSrc={amenityData?.imageUrl}
                                    />
                                ),
                                title: response.amenity?.name,
                                description: amenityData?.category?.name || '',
                            },
                        ]);
                    }

                    if (response.organizer?.id) {
                        const userData = await userService.getById(response.organizer?.id);
                        if (userData) {
                            setUser(userData);
                            setBookingCreator([
                                {
                                    title: userData.name,
                                    description: userData.companyRole,
                                    avatar: (
                                        <ImageWithPlaceholder
                                            width="32"
                                            height="32"
                                            defaultImg={<UserIcon />}
                                            imgSrc={userData.imageUrl}
                                        />
                                    ),
                                },
                            ]);
                        }
                    }
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        fetch();
    }, [amenityService, bookingId, bookingService, userService, globalLoadingStore, toastStore]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'booking-approval',
                nameKey: 'Booking.approval_requests',
            },
            {
                path: data?.id || '',
                nameKey: 'Booking.approval_requests_detail',
            },
        ];
    };

    useEffect(() => {
        if (data) {
            setBookingSummary([
                {
                    content: (
                        <div className="bookingSummary">
                            <div>
                                <strong>{data?.dateWeekDay}</strong>
                            </div>
                            <div>{data?.time}</div>
                        </div>
                    ),
                },
            ]);

            setCompany([
                {
                    title: data.company?.name || '',
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<CompanyIcon />}
                            imgSrc={data.company?.imageUrl}
                        />
                    ),
                },
            ]);
        }
    }, [data]);

    const onManageCompany = (): void => {
        if (companyModel) {
            history.push(companyModel.consoleUrl);
        }
    };

    const onManageUser = (): void => {
        if (user) {
            history.push(user.consoleUrl);
        }
    };

    const onApprovalComplete = useCallback(
        (success: boolean) => {
            bookingStore.setCurrentLocationPendingBookingApprovals(id);
            setApproveModalOpen(false);
            if (success) {
                history.goBack();
            }
        },
        [id, history, bookingStore]
    );

    const onDenyComplete = useCallback(
        (success: boolean) => {
            bookingStore.setCurrentLocationPendingBookingApprovals(id);
            setDenyModalOpen(false);
            if (success) {
                history.goBack();
            }
        },
        [id, history, bookingStore]
    );

    return (
        <div className="LocationBookingApprovalDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.amenity?.name || ''}
                        subTitle={
                            <>
                                <div className="header-subtitle">
                                    {data.dateWeekDay} {data.time}
                                </div>
                                <div className="header-badge">
                                    <StatusBadge status={data?.statusBadgeStatus} />
                                </div>
                            </>
                        }
                        defaultImg={<BookingIcon fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={
                            data?.approvalStatus ===
                                BookingApprovalStatusDto.WaitingForApproval && (
                                <>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setDenyModalOpen(true);
                                        }}
                                    >
                                        {t('Booking.booking_deny_request')}
                                    </Button>

                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setApproveModalOpen(true);
                                        }}
                                    >
                                        {t('Booking.booking_approve_request')}
                                    </Button>
                                </>
                            )
                        }
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                {bookingSummary && (
                                    <SimpleList
                                        className="description"
                                        title={t('Booking.booking_summary_label')}
                                        data={bookingSummary}
                                    />
                                )}
                                {bookingAmenity && (
                                    <SimpleList
                                        className="description"
                                        title={t('Amenities.amenity_label')}
                                        data={bookingAmenity}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {company && (
                                    <SimpleList title={t('Company.company')} data={company}>
                                        <Button
                                            type="default"
                                            className="manageCompany"
                                            onClick={onManageCompany}
                                        >
                                            {t('Company.company_manage')}
                                        </Button>
                                    </SimpleList>
                                )}
                                {bookingCreator && (
                                    <SimpleList
                                        title={t('Booking.book_a_room_creator')}
                                        data={bookingCreator}
                                    >
                                        <Button
                                            type="default"
                                            className="manageUser"
                                            onClick={onManageUser}
                                        >
                                            {t('User.user_manage')}
                                        </Button>
                                    </SimpleList>
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {(userStore.isAdmin || new UserPermissionUtils(userStore).UserCanViewContent([id])) && approveModalOpen && (
                        <BookingApprovalModal
                            visible={approveModalOpen}
                            onComplete={onApprovalComplete}
                            bookingId={bookingId}
                        />
                    )}
                    {denyModalOpen && (
                        <BookingDenyModal
                            visible={denyModalOpen}
                            onComplete={onDenyComplete}
                            bookingId={bookingId}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default LocationBookingApprovalDetail;
