import React, { FunctionComponent, ReactNode, useState, useEffect, useRef } from 'react';
import { Checkbox } from 'antd';
import Select, { components, ValueType } from 'react-select';
import { Location } from 'Components/icons';
import ChevronDown from 'Components/icons/ChevronDown';
import { useTranslation } from 'react-i18next';
import './location-select.less';
import { theme as themeColor } from 'variant';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { truncate } from 'Utils/TextUtils';

interface LocationSelectProps {
    availableLocations: LightLocationInfo[];
    onLocationChange: (locationIds: string[]) => void;
    selected?: string[] | null;
}

const styles: any = {
    placeholder: (styles: any) => ({
        ...styles,
        color: themeColor['black'],
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: '0.476191px',
        textTransform: 'uppercase',
        margin: 0,
    }),
    menu: (styles: any) => ({
        ...styles,
        width: '250px',
        border: '1px solid ' + themeColor['primary-4'],
        marginTop: '5px',
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        maxWidth: '6.5rem',
        width: '102px',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        margin: 0,
    }),
    control: (styles: any, state: any) => ({
        ...styles,
        padding: '9px 8px 9px 20px',
        minHeight: '50px',
        border: '1px solid ' + themeColor['primary-4'],
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: '1px solid ' + themeColor['primary-4'],
        },
    }),
    option: (styles: any, state: any) => ({
        ...styles,
        padding: '6px 20px',
        display: 'flex',
        alignItems: 'center',
        color: themeColor['black'],
        backgroundColor: null,
        '&:hover': {
            backgroundColor: themeColor['primary-4'],
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};

const locationImage = (locationImage?: string): ReactNode => {
    return (
        <>
            {locationImage ? (
                <img
                    style={{ flex: '0 0 auto' }}
                    className="dropdown-option-img"
                    width="24px"
                    height="24px"
                    src={locationImage}
                    alt=""
                />
            ) : (
                <span className="dropdown-option-img">
                    <Location />
                </span>
            )}
        </>
    );
};

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDown />
        </components.DropdownIndicator>
    );
};

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                {locationImage(props.data.image)}
                <div className="dropdown-option-label">{props.data.label}</div>
                <Checkbox style={{ flex: '0 0 auto' }} checked={props.isSelected} />
            </components.Option>
        </div>
    );
};

const ValueContainer = ({ children, ...props }: any): any => {
    const { getValue, hasValue } = props;
    if (!hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    } else {
        const values = getValue();
        return (
            <components.ValueContainer {...props}>
                {values.map((x: any) => x.name).join(', ')}
            </components.ValueContainer>
        );
    }
};

const LocationSelect: FunctionComponent<LocationSelectProps> = ({
    availableLocations,
    onLocationChange,
    selected
}) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<any>();
    const [value, setValue] = useState<any>();

    useEffect(() => {
        if (selected && options) {
            setValue(
                options.filter((x: any) => {
                    return selected?.includes(x.value);
                })
            );
        }
    }, [selected, options]);

    useEffect(() => {
        setOptions(
            availableLocations.map((location) => {
                return {
                    name: location.name,
                    value: location.id,
                    label: <><div className="location-name">{truncate(location.name ? location.name : '',15)}</div><div className="location-type">{location.type}</div></>,
                    image: location.mainImageUrl,
                };
            })
        );
    }, [availableLocations]);

    const handleOnChange = (data: ValueType<any>): void => {
        if (onLocationChange) {
            onLocationChange(data.map((x: any) => x.value) || '');
        }
    };

    //react-select bug when custom value container, closeMenuOnSelect={false} and isMulti. Cross click does not close.
    //Checks for clicks outside component and sets state to open or close.
    const [openState, setOpenState] = useState(false);
    const containerRef = useRef(null);
    const useOutsideAlerter = (ref: any): void => {
        useEffect(() => {
            function handleClickOutside(event: any): void {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenState(false);
                } else {
                    setOpenState(true);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return (): void => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
   useOutsideAlerter(containerRef);

    return (
        <div ref={containerRef}>
            <Select
                className="LocationSelect"
                onChange={handleOnChange}
                options={options}
                components={{ Option, ValueContainer, DropdownIndicator }}
                placeholder={t('All Locations')}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={false}
                styles={styles}
                isMulti
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: themeColor['primary-4'],
                    },
                })}
                value={value}
                menuIsOpen={openState}
            />
        </div>
    );
};

export default LocationSelect;
