import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import { ImageWithPlaceholder } from '../image-with-placeholder';

import { Gear } from 'Components/icons';
import './index.less';

const { Title } = Typography;

interface LocationHeaderProps extends ImageWithPlaceholderProps {
    title: string;
    subTitle?: ReactElement | string | null;
    routes?: BreadcrumbSegment[];
    action?: ReactNode;
}

const SettingsHeader: FunctionComponent<LocationHeaderProps> = observer(
    ({
        title,
        subTitle,
        routes = [],
        action,
        ...imageProps
    }) => {
        return (
            <div
                className="SettingsHeader"
                style={{
                    backgroundImage: `url(${images.settingsHeader})`,
                }}
            >
                <ImageWithPlaceholder
                    className="header-icon"
                    defaultImg={<Gear fill={theme['primary-color']} />}
                    {...imageProps}
                />

                <div className="header-info">
                    {routes && <RoutedBreadcrumb routes={routes} />}
                    <Title className="header-title">{title}</Title>
                    {subTitle ? <div className="header-subtitle">{subTitle}</div> : null}
                </div>
                {action && <div className="header-action">{action}</div>}
            </div>
        );
    }
);

export default SettingsHeader;
