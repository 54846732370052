import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu, Badge } from 'antd';

import './location-navigation.less';
import { ClickParam } from 'antd/es/menu';
import { theme } from 'variant';

interface MenuItem {
    key: string;
    label: string;
    badge?: number;
}

interface LocationNavigationProps {
    pendingBookingApprovals?: number;
}

const LocationNavigation: FunctionComponent<LocationNavigationProps> = observer(
    ({ pendingBookingApprovals = 0 }) => {
        const { t } = useTranslation();
        const { id } = useParams();
        const history = useHistory();
        let menuItems: MenuItem[] = [];

        menuItems = [
            {
                key: `/locations/${id}/service`,
                label: t('Location.location_service_requests'),
            },
            {
                key: `/locations/${id}/bookings`,
                label: t('Location.location_bookings'),
            },
            {
                key: `/locations/${id}/booking-approval`,
                label: t('Booking.approval_requests'),
                badge: pendingBookingApprovals,
            },
            {
                key: `/locations/${id}/announcements`,
                label: t('Location.location_announcements'),
            },
            {
                key: `/locations/${id}/management`,
                label: t('Location.location_management'),
            },
        ];

        const onMenuItemClicked = (param: ClickParam): void => {
            if (history.location.pathname !== param.key) {
                history.push(param.key);
            }
        };

        const getSelectedKeys = (): string[] => {
            const path = history.location.pathname;
            return menuItems
                .filter((x) => {
                    return path.includes(x.key);
                })
                .map((x) => {
                    return x.key;
                });
        };

        return (
            <div className="LocationNavigation no-select">
                <Menu
                    theme="light"
                    mode="horizontal"
                    selectedKeys={getSelectedKeys()}
                    onClick={onMenuItemClicked}
                >
                    {menuItems.map((x: MenuItem) => {
                        return (
                            <Menu.Item key={x.key}>
                                {x.badge && x.badge > 0 ? (
                                    <Badge style={{ backgroundColor: theme['primary-color'] }} count={x.badge}>{x.label}</Badge>
                                ) : (
                                    x.label
                                )}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        );
    }
);

export default LocationNavigation;
