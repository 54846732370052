import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { CompanyService } from 'Services/CompanyService';
import FilterStore from 'Stores/FilterStore';
import { Button, Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme, images } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { TdWithImage } from 'Components/td-with-image';
import { TableFilters } from 'Components/table-filters';
import { Company as CompanyIcon, Location } from 'Components/icons';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CompanyStatusDto } from 'Api/Features/Companies/Dtos/CompanyStatusDto';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { GetCompaniesRequestDto } from 'Api/Features/Companies/Dtos/GetCompaniesRequestDto';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { GetCompaniesSortColumnDto } from 'Api/Features/Companies/Dtos/GetCompaniesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { PAGE_SIZE } from 'Models/Constants';
import EditCreateCompanyModal from './edit-create-company';
import './index.less';
import StatusBadge from 'Components/status-badge/status-badge';
import Icon from 'Components/icons/Icon';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: 'Active',
                displayNameKey: CompanyStatusDto.Active,
                checked: true,
            },
            {
                key: 'Deactivated',
                displayNameKey: CompanyStatusDto.Deactivated,
                checked: true,
            },
        ],
    },
    {
        key: 'type',
        nameKey: 'Type',
        items: [
            {
                key: 'Passport',
                displayNameKey: CompanyTypeDto.Passport,
                checked: true,
            },
            {
                key: 'Heyday',
                displayNameKey: CompanyTypeDto.Heyday,
                checked: true,
            },
        ],
    },
];

const Companies: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const { locationStore, navigationStore } = useStores();
    const companyService = useService(CompanyService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    //#endregion

    //#region Filters
    const getFilterGroupOptions = (filterGroup: string, omitAll = true): string[] => {
        const filterStore = filterStoreRef.current;
        return filterStore.getGroupOptions(filterGroup, omitAll);
    };
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (cieA: Company, cieB: Company): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<Company>,
        columnKey: GetCompaniesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTownhallCoworking = (company: Company): ReactNode => {
        if(!company.isTownhall && !company.isCoworking)
            return null;

        let props = {
            iconName: '',
            text: '',
        };
        
        if (company.isTownhall) props = { iconName: 'Townhall', text: 'Company.townhall_tenant' };
        if (company.isCoworking)
            props = { iconName: 'Coworking', text: 'Company.coworking_tenant' };
        if (company.isTownhall && company.isCoworking)
            props = {
                iconName: 'TownhallAndCoworking',
                text: 'Company.townhall_and_coworking_tenant',
            };
        return (
            <div className="subtitle townhall">
                <Icon iconName={props.iconName} fill={theme['primary-color']} />
                {t(props.text)}
            </div>
        );
    };

    const getTableColumns = (currentSort: SorterResult<Company>): ColumnType<Company>[] => {
        return [
            {
                key: GetCompaniesSortColumnDto.Name,
                title: t('Company.company_name'),
                render: (company: Company): ReactNode | null => {
                    return company ? (
                        <TdWithImage defaultImg={<CompanyIcon />} imgSrc={company?.imageUrl}>
                            <div>
                                <div className="title">{company?.name}</div>
                                {getTownhallCoworking(company)}
                            </div>
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCompaniesSortColumnDto.Name),
            },
            {
                key: GetCompaniesSortColumnDto.MembersCount,
                title: t('Company.company_members_count'),
                render: (company: Company): ReactNode | null => (
                    <div>
                        {t('Company.company_members_count_members', {
                            param1: company?.membersCount,
                            count: company?.membersCount,
                        })}
                    </div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetCompaniesSortColumnDto.MembersCount
                ),
            },
            // // @TODO - HEYD-928 - Out of scope for v1
            // {
            //     title: t('Company.company_agreements'),
            // },
            {
                key: GetCompaniesSortColumnDto.LocationName,
                title: t('Company.company_location'),
                render: (company: Company): ReactNode | null => {
                    return company.location ? (
                        <TdWithImage
                            defaultImg={<Location />}
                            imgSrc={company.location?.mainImageUrl}
                        >
                            <div className="title">{company.location?.name}</div>
                        </TdWithImage>
                    ) : null;
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetCompaniesSortColumnDto.LocationName
                ),
            },
            {
                key: GetCompaniesSortColumnDto.Status,
                title: t('Company.company_status'),
                render: (company: Company): ReactNode | null => (
                    <StatusBadge status={company?.statusBadgeStatus} />
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetCompaniesSortColumnDto.Status),
            },
        ];
    };

    const defaultSortOrder: SorterResult<Company> = {
        columnKey: GetCompaniesSortColumnDto.Name as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<Company>
    ): [GetCompaniesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetCompaniesSortColumnDto = tableSort.columnKey as GetCompaniesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<Company>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<Company> | SorterResult<Company>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            locationIds: filterStore.currentLocationIds,
            townhallExclusive: filterStore.townhallExclusive,
            coworkingExclusive: filterStore.coworkingExclusive,
        });
    };
    //#endregion

    //#region Fetch & Effects
    useEffect(() => {
        navigationStore.clearSubmenu();
    }, [navigationStore]);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetCompaniesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
            locationIds: string[];
            townhallExclusive: boolean;
            coworkingExclusive: boolean;
        }) => {
            setLoading(true);
            try {
                const request = {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    statuses: getFilterGroupOptions('status'),
                    types: getFilterGroupOptions('type'),
                    isTownhall: params.townhallExclusive ? true : undefined,
                    isCoworking: params.coworkingExclusive ? true : undefined,
                } as GetCompaniesRequestDto;

                if (params.locationIds.length > 0) {
                    request.locationIds = params.locationIds;
                }

                // call api
                const [companiesData, totalItems] = await companyService.getCompanies(request);
                setCompanies(
                    companiesData.map((companyData: CompanyDto) => new Company(companyData))
                );

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [companyService]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
                locationIds: filterStore.currentLocationIds,
                townhallExclusive: filterStore.townhallExclusive,
                coworkingExclusive: filterStore.coworkingExclusive,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    locationIds: filterStore.currentLocationIds,
                    townhallExclusive: filterStore.townhallExclusive,
                    coworkingExclusive: filterStore.coworkingExclusive,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div className="Companies">
            <ListSectionHeader
                title={t('Company.companies')}
                subTitle={t('Company.companies_manage_all')}
                defaultImg={<CompanyIcon fill={theme['primary-color']} />}
                backgroundImageUrl={listSectionHeader}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('Company.company_create')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeLocations
                    availableLocations={locationStore.locations.map(
                        (location) => location as LightLocationInfo
                    )}
                    includeSearch
                    includeAdvancedFilters
                    includeCompanyAccess
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(company: Company): string => company.id!}
                    dataSource={companies}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(company: Company) => ({
                        onClick: (): void => {
                            history.push(company.consoleUrl);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <EditCreateCompanyModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
});

export default Companies;
