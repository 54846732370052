import React, { FC, useState, useEffect } from 'react';
import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    InputNumber,
    Typography,
    Button,
    DatePicker,
    Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import {
    Promotion as PromotionIcon,
    Calendar as CalendarIcon,
    Dollar as DollarIcon,
} from 'Components/icons';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateFoodPromotionSchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';

import './food-promotion-modal.less';
import { ImagePicker } from 'Components/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { FoodItemService } from 'Services/FoodItemService';
import { FoodCategoryService } from 'Services/FoodCategoryService';
import { useParams } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { ImageDetails } from 'Components/image-picker/image-picker';
import NumberFormat from 'react-number-format';
import { SelectCustom } from 'Components/select-custom';
import { GroupedOptions, SelectCustomOption } from 'Components/select-custom/select-custom';
import { theme } from 'variant';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CreateFoodPromotionRequestDto } from 'Api/Features/FoodPromotions/Dtos/CreateFoodPromotionRequestDto';
import { FoodPromotionService } from 'Services/FoodPromotionService';
import moment, { Moment } from 'moment';
import { moneyToFloat } from 'Utils/CurrencyUtils';
import { FoodPromotion } from 'Models/FoodPromotion/FoodPromotion';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

enum PromotionType {
    FIXED = 'fixed',
    REDUCTION = 'reduction',
}

interface PromotionValidation extends CreateFoodPromotionRequestDto {
    isFixed: boolean;
}

interface FoodPromotionModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    foodPromotion?: FoodPromotion;
}

const CustomInputField: FC = (props: any) => {
    return <Input addonBefore={<DollarIcon fill={theme['primary-color']} />} {...props} />;
};

const FoodPromotionModal: FC<FoodPromotionModalProps> = ({
    visible,
    onComplete,
    foodPromotion,
}) => {
    const { t } = useTranslation();
    const { merchantId } = useParams<{ merchantId: string }>();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodItemService = useService(FoodItemService);
    const foodCategoryService = useService(FoodCategoryService);
    const foodPromotionService = useService(FoodPromotionService);
    const [form] = Form.useForm();
    const [groups, setGroups] = useState<GroupedOptions[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        CreateFoodPromotionSchema,
        form
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [promotionType, setPromotionType] = useState<PromotionType>();
    const [isFixed, setIsFixed] = useState<boolean>(true);
    const [promotionTypeDisabled, setPromotionTypeDisabled] = useState(false);

    const promotionTypeOptions = [
        { label: t('Food.promotions_modal_fixed'), value: PromotionType.FIXED },
        { label: t('Food.promotions_modal_reduction'), value: PromotionType.REDUCTION },
    ];

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PromotionIcon />,
                title: t(`Food.food_promotion_saved_success`),
                message: t(`Food.food_promotion_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    useEffect(() => {
        const fetch = async () => {
            const [categories] = await foodCategoryService.getFoodCategories({
                merchantIds: [merchantId],
            });

            const [items] = await foodItemService.getFoodItems({
                categoryIds: categories.map((category) => category.id),
            });

            setGroups(
                categories.map((category) => {
                    return {
                        value: category.id,
                        label: category.name,
                        isSelected: false,
                        options: items
                            .filter((item) => item.category?.id === category.id)
                            .map((item) => {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    content: item.priceString,
                                    disabled: false,
                                    isSelected: false,
                                } as SelectCustomOption;
                            }),
                    } as GroupedOptions;
                })
            );
        };
        fetch();
    }, [merchantId, foodCategoryService, foodItemService]);

    useEffect(() => {
        if (foodPromotion) {
            form.setFieldsValue({
                name: foodPromotion.name,
                fixedAmount: foodPromotion.price,
                reductionAmount: foodPromotion.reductionPercentage,
                taxAmount: foodPromotion.taxAmount,
                description: foodPromotion.description ?? undefined
            });

            const isFixed = !!foodPromotion?.price;
            setIsFixed(isFixed);
            setPromotionType(isFixed ? PromotionType.FIXED : PromotionType.REDUCTION);

            if (foodPromotion.items) {
                setSelectedItems(foodPromotion.items.map((item) => item?.id || ''));
            }

            if (foodPromotion.categories) {
                const foodCategoryIds = foodPromotion.categories.map((category) => category?.id || '')
                setSelectedCategories(foodCategoryIds);
                const itemsInCategories = groups.filter(group => foodCategoryIds.includes(group.value)).flatMap(group => group.options);
                setSelectedItems(
                    itemsInCategories
                        .map((item) => item.value)
                        .concat(
                            foodPromotion.items
                                ? foodPromotion.items.map((item) => item?.id || '')
                                : []
                        )
                );
            }
        } else {
            setPromotionType(PromotionType.FIXED);
        }
    }, [foodPromotion, form, groups]);

    const getMainImage = (): UpdateFileRequestDto | null => {
        return mainImageDetails
            ? ({
                  delete: mainImageDetails[0].isDeleted,
                  uploadBase64: mainImageDetails[0].base64,
              } as UpdateFileRequestDto)
            : null;
    };

    const errorsHandler = (error: any): void => {
        const responseErrors = error.response?.data?.errors;
        if (responseErrors && responseErrors?.['image.UploadBase64'] !== undefined) {
            const errors = new Map<string, string[]>();
            errors.set('image', [error.response?.data.errors['image.UploadBase64'][0].description]);
            setErrors(errors);
        } else if (!error.treated) {
            toastStore.displayError(error);
        }
    };

    const getDurationDefaultValue = (): any => {
        if (foodPromotion?.startDate && foodPromotion?.endDate) {
            return [moment(foodPromotion.startDate), moment(foodPromotion?.endDate)];
        }
        return [];
    };

    const getStartDate = (duration: Moment[]): string => {
        if (duration && duration[0]) {
            return duration[0].format();
        }
        return '';
    };

    const getEndDate = (duration: Moment[]): string => {
        if (duration && duration[1]) {
            return duration[1].format();
        }
        return '';
    };

    const getSelectedItemsOutsideOfSelectedCategories = (): string[] => {
        if (!selectedCategories || selectedCategories.length < 1) return selectedItems;

        const itemIdsInSelectedCategories = groups.filter(group => selectedCategories.includes(group.value)).flatMap(group => group.options).map(item => item.value);
        return selectedItems.filter(item => !itemIdsInSelectedCategories.includes(item))
    }

    const createFoodPromotion = async (values: Store): Promise<void> => {
        const data: PromotionValidation = {
            name: values.name,
            startDate: getStartDate(values.duration),
            endDate: getEndDate(values.duration),
            itemIds: selectedItems,
            categoryIds: selectedCategories.length > 0 ? selectedCategories : null,
            price: moneyToFloat(values.fixedAmount),
            taxAmount: values.taxAmount,
            reductionPercentage: values.reductionAmount,
            bannerImage: getMainImage(),
            merchantId: merchantId,
            isFixed: isFixed,
            description: values.description
        };
        if (!(await validateForm(data, false))) return;

        data.itemIds = getSelectedItemsOutsideOfSelectedCategories();

        try {
            globalLoadingStore.addLoading();
            await foodPromotionService.createFoodPromotion(data);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editFoodPromotion = async (values: Store): Promise<void> => {
        const oldValues = foodPromotion as FoodPromotion;
        const data: PromotionValidation = {
            name: values.name || oldValues.name,
            startDate: getStartDate(values.duration) || oldValues.startDate,
            endDate: getEndDate(values.duration) || oldValues.endDate,
            itemIds: selectedItems,
            categoryIds: selectedCategories.length > 0 ? selectedCategories : null,
            price: moneyToFloat(values.fixedAmount),
            taxAmount: values.taxAmount,
            reductionPercentage: values.reductionAmount,
            bannerImage: getMainImage(),
            merchantId: merchantId,
            isFixed: isFixed,
            description: values.description
        };
        if (!(await validateForm(data, false))) return;

        data.itemIds = getSelectedItemsOutsideOfSelectedCategories();

        try {
            globalLoadingStore.addLoading();
            await foodPromotionService.updateFoodPromotion(data, foodPromotion!.id as string);
            globalLoadingStore.removeLoading();
            await success();
        } catch (e) {
            errorsHandler(e);
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (foodPromotion !== undefined) {
            await editFoodPromotion(values);
        } else {
            await createFoodPromotion(values);
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PromotionIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(
                    `Food.food_promotion_leave_confirm_positive${
                        foodPromotion !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const onPromotionTypeSelect = (event: RadioChangeEvent): void => {
        setPromotionType(event.target.value);
        setIsFixed(event.target.value === PromotionType.FIXED);
    };

    const disablePromotionType = (): void => {
        setIsFixed(false);
        setPromotionType(PromotionType.REDUCTION)
        setPromotionTypeDisabled(true);
    }

    const onItemsChange = (options: SelectCustomOption[]) => {
        setSelectedItems(options.map(option => option.value));
        if (options.length > 1) disablePromotionType();
        else setPromotionTypeDisabled(false);
            
    };

    const onCategoriesChange = (values: string[]) => {
        setSelectedCategories(values);
        if (values.length > 0) disablePromotionType();
        else setPromotionTypeDisabled(false);
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                foodPromotion !== undefined
                    ? t('Food.promotions_modal_title_edit')
                    : t('Food.promotions_modal_title_create')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="FoodPromotionModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('Food.promotions_modal_name')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ name: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('Food.promotion_description')}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ description: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="duration"
                                label={t('Food.promotions_modal_duration')}
                                required={true}
                            >
                                <RangePicker
                                    suffixIcon={<CalendarIcon fill={theme['primary-color']} />}
                                    defaultValue={getDurationDefaultValue()}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="itemIds"
                                label={t('Food.promotions_modal_items_categories')}
                                required={true}
                            >
                                <SelectCustom
                                    groups={groups}
                                    selected={selectedItems}
                                    selectedGroups={selectedCategories}
                                    onMultiChange={onItemsChange}
                                    onGroupsChange={onCategoriesChange}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isMulti
                                    checkBoxes
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter} className="promotion-row">
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="promotionType"
                                label={t('Food.promotions_modal_promotion')}
                                required={true}
                            >
                                <Input.Group compact className="promotion-input-group">
                                    <Radio.Group
                                        onChange={onPromotionTypeSelect}
                                        value={promotionType}
                                        buttonStyle="solid"
                                        style={{ width: 'auto' }}
                                    >
                                        {promotionTypeOptions.map((option) => (
                                            <Radio.Button
                                                key={option.value}
                                                value={option.value}
                                                disabled={
                                                    option.value === PromotionType.FIXED &&
                                                    promotionTypeDisabled
                                                }
                                            >
                                                {option.label}
                                            </Radio.Button>
                                        ))}
                                    </Radio.Group>
                                    {isFixed ? (
                                        <ValidatedFormItem
                                            errors={errors}
                                            name="fixedAmount"
                                            required={isFixed}
                                            style={{ width: '49.7%' }}
                                        >
                                            <NumberFormat
                                                className="ant-input"
                                                thousandSeparator={true}
                                                placeholder="$0.00"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={'$'}
                                                onChange={(e): void =>
                                                    form.setFieldsValue({ amount: e.target.value })
                                                }
                                            />
                                        </ValidatedFormItem>
                                    ) : (
                                        <ValidatedFormItem
                                            errors={errors}
                                            name="reductionAmount"
                                            required={!isFixed}
                                            style={{ width: '49.7%' }}
                                        >
                                            <InputNumber
                                                defaultValue={0}
                                                min={0}
                                                max={100}
                                                formatter={(
                                                    value: string | number | undefined
                                                ): string => (value ? `${value}%` : '')}
                                                parser={(
                                                    displayValue: string | undefined
                                                ): string =>
                                                    displayValue
                                                        ? displayValue.replace('%', '')
                                                        : ''
                                                }
                                                onChange={(value): void =>
                                                    form.setFieldsValue({ amount: value })
                                                }
                                            />
                                        </ValidatedFormItem>
                                    )}
                                </Input.Group>
                            </ValidatedFormItem>
                            <div>
                                <p className="help promotion-help">
                                    {isFixed
                                        ? t('Food.promotions_modal_fixed_help')
                                        : t('Food.promotions_modal_reduction_help')}
                                </p>
                            </div>
                        </Col>
                        <Col span={12}>
                            {isFixed && (
                                <ValidatedFormItem
                                    errors={errors}
                                    name="taxAmount"
                                    label={t('Food.promotions_modal_tax')}
                                    required={true}
                                >
                                    <NumberFormat
                                        thousandSeparator={true}
                                        placeholder="0.00"
                                        decimalScale={2}
                                        allowNegative={false}
                                        fixedDecimalScale={true}
                                        onChange={(e): void =>
                                            form.setFieldsValue({ tax: e.target.value })
                                        }
                                        customInput={CustomInputField}
                                    />
                                </ValidatedFormItem>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('Images.images_banner')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <div>
                                <p className="help">{t('Images.images_banner_help')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24} className="buttonContainer">
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        foodPromotion?.bannerImageUrl
                                            ? [
                                                  {
                                                      url: foodPromotion?.bannerImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setMainImageDetails(images)
                                    }
                                    croppingModalTitle={t('Images.images_banner_cropping')}
                                    enableCropping
                                    croppingAspect={3 / 1}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default FoodPromotionModal;
