import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
    useRef,
    ReactNode, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table, Button } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme, images } from 'variant';
import UserHeader from 'Components/user-header/user-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { Message as MessageIcon, User as UserIcon } from 'Components/icons';
import { UserMessage } from 'Models/Messages/UserMessage';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { PAGE_SIZE, DATE_API_FORMAT, TABLE_DATE_FORMAT } from 'Models/Constants';
import { GetUserMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetUserMessagesRequestDto';
import { MessageReadStatusDto } from 'Api/Features/Messages/Dtos/MessageReadStatusDto';
import { NotificationTypeDto } from 'Api/Features/Notifications/Dtos/NotificationTypeDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import CreateUserMessageModal from './create-message';
import './index.less';
import { UserContext } from '../index';
import StatusBadge from 'Components/status-badge/status-badge';
import { UserMessageTypes } from 'Models/Messages/UserMessageTypes';
import { TdWithImage } from 'Components/td-with-image';
import Icon from 'Components/icons/Icon';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const msgStatusCopyPrefix = 'MessageStatus.';
const msgTypeCopyPrefix = 'MessageNotificationType.';
const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: MessageReadStatusDto.Read,
                displayNameKey: msgStatusCopyPrefix + MessageReadStatusDto.Read,
                checked: true,
            },
            {
                key: MessageReadStatusDto.Unread,
                displayNameKey: msgStatusCopyPrefix + MessageReadStatusDto.Unread,
                checked: true,
            },
            // We probably dont want deleted message here.
        ],
    },
    {
        key: 'type',
        nameKey: 'Type',
        items: Object.keys(NotificationTypeDto).map((key) => {
            return {
                key: key,
                displayNameKey: msgTypeCopyPrefix + key,
                checked: true,
            };
        })
    },
];

const UserMessages: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<UserMessage[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const { id } = useParams<{id: string}>();
    const userService = useService(UserService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { userStore } = useStores();

    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'users',
            nameKey: 'User.users',
        },
        {
            path: id,
            name: user?.name || t('User.user'),
        },
        {
            path: 'messages',
            nameKey: 'User.user_messages',
        },
    ];
    //#endregion

    //#region Filters
    const getFilterGroupOptions = (filterGroup: string, omitAll = true): string[] => {
        const filterStore = filterStoreRef.current;
        return filterStore.getGroupOptions(filterGroup, omitAll);
    };
    //#endregion

    //#region Table Content

    // # Note: As sorting is provided by the API, and affect all records regardless of the pagination,
    // # we do not bother to sort anything locally. That is why the sortHandler below does nothing.

    // # The Ant Table interface is only used to get the user sort options that are carried over the API call.

    const getTableColumnsSortHandler = () => {
        return (msgA: UserMessage, msgB: UserMessage): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<UserMessage>,
        columnKey: GetMessagesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getMessageTypeIconName = (messageType: NotificationTypeDto | null | undefined): string  => {
        switch(messageType){
            case NotificationTypeDto.Announcement: return NotificationTypeDto.Announcement;
            case NotificationTypeDto.GeneralUserMessage: return "GeneralMessage";
            case NotificationTypeDto.PackageDelivery: return "Box";
            default: return NotificationTypeDto.Announcement;
        }
    }

    const getTableColumns = (currentSort: SorterResult<UserMessage>): ColumnType<UserMessage>[] => {
        return [
            {
                key: GetMessagesSortColumnDto.Type,
                title: t('Message.message_type'),
                render: (message: UserMessage): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<Icon iconName={getMessageTypeIconName(message.type)} />}
                        className="primary-4"
                    >
                         <div>{message?.type ? t(`MessageNotificationType.${message.type}`) : '-'}</div>
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetMessagesSortColumnDto.Type
                ),
            },
            {
                key: GetMessagesSortColumnDto.Title,
                title: t('Message.message_title'),
                render: (message: UserMessage): ReactNode | null => <div>{message?.title}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Title),
            },
            {
                key: GetMessagesSortColumnDto.Body,
                title: t('Message.message_body'),
                render: (message: UserMessage): ReactNode | null => <div>{message?.body}</div>,
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Body),
            },
            {
                key: GetMessagesSortColumnDto.CreatedByUserName,
                title: t('Message.message_creator'),
                render: (message: UserMessage): ReactNode | null => (
                    <TdWithImage
                        defaultImg={<UserIcon />}
                        imgSrc={message?.createdByUser?.imageUrl}
                    >
                        <div className="title">{message?.fromName}</div>
                    </TdWithImage>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetMessagesSortColumnDto.CreatedByUserName
                ),
            },
            {
                key: GetMessagesSortColumnDto.Date,
                title: t('Message.message_date'),
                render: (message: UserMessage): ReactNode | null => (
                    <div>{moment(message?.date, DATE_API_FORMAT).format(TABLE_DATE_FORMAT)}</div>
                ),
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(currentSort, GetMessagesSortColumnDto.Date),
            },
            {
                title: t('Booking.booking_approval_status'),
                render: (message: UserMessage): ReactNode | null => (
                    <StatusBadge status={message.statusBadgeStatus} />
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<UserMessage> = {
        columnKey: GetMessagesSortColumnDto.Date as Key,
        order: 'descend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<UserMessage>
    ): [GetMessagesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetMessagesSortColumnDto = tableSort.columnKey as GetMessagesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<UserMessage>[]>(defaultTableColumns);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<UserMessage> | SorterResult<UserMessage>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
        });
    };
    //#endregion

    //#region Fetch & Effects
    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetMessagesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
        }) => {
            setLoading(true);
            try {
                const [messages, totalItems] = await userService.getUserMessages(id, {
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    statuses: getFilterGroupOptions('status'),
                    types: getFilterGroupOptions('type'),
                } as GetUserMessagesRequestDto);

                if (messages) {
                    setMessages(messages);

                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [userService, id]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            const filterStore = filterStoreRef.current;
            setCreateModalOpen(false);
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );
    //#endregion

    return (
        <div className="Messages">
            <UserHeader
                title={t('User.user_messages')}
                subTitle={t('User.user_messages_subtitle')}
                defaultImg={<MessageIcon fill={theme['primary-color']} />}
                defaultBackgroundImageUrl={listSectionHeader}
                loading={loading}
                routes={breadcrumbs}
                action={
                    userStore.isAdmin ||
                    ((userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent(
                            [user?.location?.id].concat(
                                user?.locations
                                    ? user.locations?.map((location) => location.id)
                                    : undefined
                            )
                        )) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('Message.message_send_message')}
                        </Button>
                    ))
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(message: UserMessage): string => message.id!}
                    dataSource={messages}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(message: UserMessage) => ({
                        onClick: (): void => {
                            history.push(message.getConsoleUrl(user!.id));
                        },
                    })}
                />
            </Content>
            <CreateUserMessageModal
                user={user}
                visible={createModalOpen}
                onComplete={onCreationComplete}
                defaultMessageType={UserMessageTypes.GeneralUserMessage}
            />
        </div>
    );
});

export default UserMessages;
