import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { Row, Col, Button, Layout } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { SimpleList } from 'Components/simple-list';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import {
    Location as LocationIcon,
    ServiceRequest as ServiceRequestIcon,
    Company as CompanyIcon,
    User as UserIcon,
    Calendar as CalendarIcon,
} from 'Components/icons';
import { Location } from 'Models/Location/Location';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { ServiceRequest } from 'Models/ServiceRequests/ServiceRequest';
import { Company } from 'Models/Companies/Company';
import { ServiceRequestStatusDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestStatusDto';
import { UpdateServiceRequestStatusRequestDto } from 'Api/Features/ServiceRequests/Dtos/UpdateServiceRequestStatusRequestDto';
import { MessageLinkedEntity } from 'Models/Messages/MessageLinkedEntity';
import { MessageEntityTypeDto } from 'Api/Features/Messages/Dtos/MessageEntityTypeDto';
import { DATETIME_API_FORMAT, DATETIME_DISPLAY_FORMAT } from 'Models/Constants';
import CreateUserMessageModal from 'Routes/console/authenticated/users/id/messages/create-message';
import './index.less';
import { UserMessageTypes } from 'Models/Messages/UserMessageTypes';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

const { Content } = Layout;

const LocationServiceRequestDetail: FunctionComponent = observer(() => {
    //#region Hooks
    const { id, requestId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const locationService = useService(LocationService);
    const { navigationStore, globalLoadingStore, confirmationModalStore, userStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [serviceRequest, setServiceRequest] = useState<ServiceRequest | undefined>(undefined);
    const [statusHasChanged, completeStatusChange] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    //#endregion

    //#region Header
    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'service',
            nameKey: 'Location.location_service_requests',
        },
        {
            path: 'service_detail',
            nameKey: 'ServiceRequest.request_details',
        },
    ];

    const actions: ActionMenuOption[] = [
        {
            key: 'message',
            title: t('Message.send_message_to_user'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'status',
            title: serviceRequest?.opened
                ? t('ServiceRequest.request_close')
                : t('ServiceRequest.request_reopen'),
            action: (): Promise<void> => toggleRequestStatus(),
        },
    ];
    //#endregion

    //#region Fetch & Effects
    useEffect(() => {
        if (location) {
            navigationStore.setSubmenu(location);
        }
    }, [location, navigationStore]);

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const locationData: LocationDto | null = await locationService.getById(id);

            if (locationData) {
                const location: Location = new Location(locationData);
                setLocation(location);

                const serviceRequest: ServiceRequest | null = await locationService.getServiceRequestById(
                    requestId
                );

                if (serviceRequest) {
                    setServiceRequest(serviceRequest);
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [locationService, id, requestId, globalLoadingStore]);

    useEffect(() => {
        fetch();
    }, [fetch, statusHasChanged]);
    //#endregion

    //#region Actions
    const onMessageCreationComplete = (success: boolean): void => {
        setCreateModalOpen(false);
    };

    const toggleRequestStatus = async (): Promise<void> => {
        const trans_prefix = serviceRequest?.opened
            ? 'ServiceRequest.request_close_confirm_'
            : 'ServiceRequest.request_reopen_confirm_';
        if (
            !(await confirmationModalStore.confirm({
                icon: <ServiceRequestIcon />,
                title: t(trans_prefix + 'title'),
                message: t(trans_prefix + 'message'),
                positiveText: t(trans_prefix + 'positive'),
                negativeText: t(trans_prefix + 'negative'),
            }))
        ) {
            return;
        }

        async function toggleStatus() {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                await locationService.setServiceRequestStatus(requestId, {
                    status: serviceRequest?.opened
                        ? ServiceRequestStatusDto.Closed
                        : ServiceRequestStatusDto.Open,
                } as UpdateServiceRequestStatusRequestDto);
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
                completeStatusChange(!statusHasChanged);
            }
        }

        await toggleStatus();
    };
    //#endregion

    //#region Content
    interface SimpleListItemProps {
        title: string | React.ReactNode;
        description?: string;
        avatar?: React.ReactNode;
    }

    const getSimpleContent = (content: string | null): SimpleListItemProps[] => {
        let contentProps: SimpleListItemProps[] = [];
        if (content) {
            contentProps = [{ title: content }];
        }
        return contentProps;
    };

    const getDateTimeContent = (): SimpleListItemProps[] => {
        return [
            {
                title: moment(serviceRequest?.sendingDate, DATETIME_API_FORMAT).format(
                    DATETIME_DISPLAY_FORMAT
                ),
                avatar: <CalendarIcon fill={theme['primary-color']} />,
            },
        ];
    };

    const getLocationContent = (): SimpleListItemProps[] => {
        let contentProps: SimpleListItemProps[] = [];
        if (location?.name) {
            contentProps = [
                {
                    title: location?.name,
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<LocationIcon />}
                            imgSrc={location?.mainImageUrl}
                        />
                    ),
                },
            ];
        }
        return contentProps;
    };

    const getCompanyContent = (): SimpleListItemProps[] => {
        let companyContent: SimpleListItemProps[] = [];
        if (serviceRequest && serviceRequest.company) {
            companyContent = [
                {
                    title: serviceRequest.company.name,
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<CompanyIcon />}
                            imgSrc={serviceRequest.company.imageUrl}
                        />
                    ),
                },
            ];
        }
        return companyContent;
    };

    const onManageCompany = (): void => {
        if (serviceRequest && serviceRequest.company) {
            const company: Company | null = serviceRequest.getCompany();
            history.push(serviceRequest && company!.consoleUrl);
        }
    };

    const getUserContent = (): SimpleListItemProps[] => {
        let contact: SimpleListItemProps[] = [];
        if (serviceRequest && serviceRequest.from) {
            contact = [
                {
                    title: serviceRequest.fromName,
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<UserIcon />}
                            imgSrc={serviceRequest.from.imageUrl}
                        />
                    ),
                },
            ];
        }
        return contact;
    };

    const onManageUser = (): void => {
        if (serviceRequest && serviceRequest.from) {
            history.push(serviceRequest && serviceRequest.from.consoleUrl);
        }
    };
    //#endregion

    return (
        <div className="LocationServiceRequestDetails">
            <LocationHeader
                title={t('ServiceRequest.request_details')}
                subTitle={null}
                defaultImg={<ServiceRequestIcon fill={theme['primary-color']} />}
                showStatusBadge={true}
                status={serviceRequest?.opened ? StatusBadgeStatus.Open : StatusBadgeStatus.Closed}
                routes={breadcrumbs}
                loading={loading}
                action={
                    (userStore.isAdmin ||
                        new UserPermissionUtils(userStore).UserCanViewContent([id])) && (
                        <ActionMenu options={actions} type="primary" trigger="click" />
                    )
                }
            />

            <Content>
                <Row gutter={16}>
                    <Col span={12}>
                        <SimpleList
                            title={t('ServiceRequest.request_description')}
                            data={getSimpleContent(serviceRequest?.description || '')}
                        />

                        <SimpleList
                            title={t('ServiceRequest.request_date')}
                            data={getDateTimeContent()}
                        />

                        {!!serviceRequest?.from && (
                            <SimpleList title={t('User.user')} data={getUserContent()}>
                                <Button
                                    type="default"
                                    className="manageButton"
                                    onClick={onManageUser}
                                >
                                    {t('User.user_manage')}
                                </Button>
                            </SimpleList>
                        )}
                    </Col>
                    <Col span={12}>
                        {!!serviceRequest?.from && (
                            <SimpleList title={t('Company.company')} data={getCompanyContent()}>
                                <Button
                                    type="default"
                                    className="manageButton"
                                    onClick={onManageCompany}
                                >
                                    {t('Company.company_manage')}
                                </Button>
                            </SimpleList>
                        )}

                        <SimpleList title={t('Location.location')} data={getLocationContent()} />
                    </Col>
                </Row>
            </Content>
            <CreateUserMessageModal
                user={serviceRequest?.from!}
                title={t('ServiceRequest.service_request')}
                linkedEntity={
                    {
                        entityType: MessageEntityTypeDto.ServiceRequest,
                        entityId: serviceRequest?.id,
                    } as MessageLinkedEntity
                }
                visible={createModalOpen}
                onComplete={onMessageCreationComplete}
                defaultMessageType={UserMessageTypes.GeneralUserMessage}
            />
        </div>
    );
});

export default LocationServiceRequestDetail;
