import React, { FunctionComponent, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { FoodEntree as FoodOrderIcon } from 'Components/icons';
import { Close } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { CreateFoodOrderDestinationSchema } from 'Schemas';
import { useStores, useFormValidation, useService } from 'Hooks';
import 'react-quill/dist/quill.snow.css';
import './order-destination-modal.less';
import { FoodOrderDestinationDto } from 'Api/Features/FoodOrderDestinations/Dtos/FoodOrderDestinationDto';
import { FoodOrderDestinationService } from 'Services/FoodOrderDestinationService';
import { CreateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/CreateFoodOrderDestinationRequestDto';
import { UpdateFoodOrderDestinationRequestDto } from 'Api/Features/FoodOrderDestinations/Dtos/UpdateFoodOrderDestinationRequestDto';

const { Title } = Typography;

const titleGutter: [Gutter, Gutter] = [0, 0];
const formGutter: [Gutter, Gutter] = [40, 0];

interface FoodOrderDestinationModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    merchantId?: string;
    foodOrderDestination?: FoodOrderDestinationDto;
}

const FoodOrderDestinationModal: FunctionComponent<FoodOrderDestinationModalProps> = ({
    visible,
    onComplete,
    merchantId,
    foodOrderDestination,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const foodOrderDestinationService = useService(FoodOrderDestinationService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateFoodOrderDestinationSchema, form);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (foodOrderDestination) {
            form.setFieldsValue({
                name: foodOrderDestination.name,
                email: foodOrderDestination.email,
            });
        }
    }, [foodOrderDestination, form]);

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodOrderIcon />,
                title: t(`Food.order_destination_saved_success`),
                message: t(`Food.order_destination_successfully_saved`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const createFoodOrderDestination = async (values: Store): Promise<void> => {
        const data: CreateFoodOrderDestinationRequestDto = {
            name: values.name,
            email: values.email,
            merchantId: merchantId
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodOrderDestinationService.createFoodOrderDestination(data);
            globalLoadingStore.removeLoading();
            await success()
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editFoodOrderDestination = async (values: Store): Promise<void> => {
        const oldValues = foodOrderDestination as UpdateFoodOrderDestinationRequestDto;
        const data: UpdateFoodOrderDestinationRequestDto = {
            name: values.name || oldValues.name,
            email: values.email || oldValues.email,
            merchantId: merchantId
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await foodOrderDestinationService.updateFoodOrderDestination(data, foodOrderDestination!.id as string);
            globalLoadingStore.removeLoading();
            await success()
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (foodOrderDestination !== undefined) {
            await editFoodOrderDestination(values);
        } else {
            await createFoodOrderDestination(values);
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <FoodOrderIcon />,
                title: t(`leave_confirm_title`),
                message: t(`leave_confirm_message`),
                positiveText: t(
                    `Food.order_destination_leave_confirm_positive${
                        foodOrderDestination !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`leave_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    return (
        <Modal
            visible={visible}
            centered
            title={
                foodOrderDestination !== undefined
                    ? t('Food.order_destinations_edit')
                    : t('Food.order_destinations_create')
            }
            className="FormModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={exit}
            maskClosable={false}
        >
            <div className="FoodOrderDestinationModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={titleGutter}>
                        <Col span={24} className="formSection">
                            <Title level={4}>{t('basic_information')}</Title>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ name: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="email"
                                label={t('User.email')}
                                required={true}
                            >
                                <Input
                                    onChange={(e): void =>
                                        form.setFieldsValue({ email: e.target.value })
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default FoodOrderDestinationModal;
