import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import {
    ColumnType,
    Key,
    SortOrder,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { theme, images } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { TdWithImage } from 'Components/td-with-image';
import { TableFilters } from 'Components/table-filters';
import { Booking } from 'Components/icons';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { GetAmenityCategoriesSortColumnDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { MIN_ITEMS_FOR_REORDER, PAGE_SIZE, TABLE_COL_ORDER_DEFAULT_WIDTH } from 'Models/Constants';
import './index.less';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import { ActionMenuOption, disableActionMenuItem } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import AmenityCategoryModal from './amenity-category-modal';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import ReorderAmenityCategoriesModal from './reorder-amenity-categories-modal';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { AmenityCategories as AmenityCategoriesModel } from 'Models/Amenities/AmenityCategories';

const { listSectionHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const AmenityCategories: FunctionComponent = observer(() => {
    //#region Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const { navigationStore } = useStores();
    const amenityCategoriesService = useService(AmenityCategoryService);
    const filterStoreRef = useRef(new FilterStore());
    const [loading, setLoading] = useState(true);
    const [amenityCategories, setAmenityCategories] = useState<AmenityCategory[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);

    const [actions, setActions] = useState<ActionMenuOption[]>([
        {
            key: 'create',
            title: t('Amenities.amenity_categories_create'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('change_ordering'),
            action: (): void => setReorderModalOpen(true),
        },
    ]);
    navigationStore.setSubmenu(new AmenityCategoriesModel());
    //#endregion

    //#region Table Content
    const getTableColumnsSortHandler = () => {
        return (catA: AmenityCategory, catB: AmenityCategory): number => 0;
    };

    const getTableColumnsSortDirections = (): SortOrder[] => {
        return ['ascend', 'descend', 'ascend']; // # Force to always have ascend or descend states
    };

    const getTableColumnSortOrder = (
        currentSort: SorterResult<AmenityCategory>,
        columnKey: GetAmenityCategoriesSortColumnDto
    ): SortOrder => {
        return columnKey === currentSort.columnKey ? (currentSort.order as SortOrder) : null;
    };

    const getTableColumns = (
        currentSort: SorterResult<AmenityCategory>
    ): ColumnType<AmenityCategory>[] => {
        return [
            {
                key: GetAmenityCategoriesSortColumnDto.Order,
                title: t('Table.column_order'),
                dataIndex: 'order',
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetAmenityCategoriesSortColumnDto.Order
                ),
                width: TABLE_COL_ORDER_DEFAULT_WIDTH,
            },
            {
                key: GetAmenityCategoriesSortColumnDto.Name,
                title: t('Amenities.amenity_category'),
                render: (amenityCategory: AmenityCategory): ReactNode | null => {
                    return (
                        <TdWithImage defaultImg={<Booking />} imgSrc={amenityCategory.imageUrl}>
                            {amenityCategory.name}
                        </TdWithImage>
                    );
                },
                sorter: getTableColumnsSortHandler(),
                sortDirections: getTableColumnsSortDirections(),
                sortOrder: getTableColumnSortOrder(
                    currentSort,
                    GetAmenityCategoriesSortColumnDto.Name
                ),
            },
        ];
    };

    const defaultSortOrder: SorterResult<AmenityCategory> = {
        columnKey: GetAmenityCategoriesSortColumnDto.Order as Key,
        order: 'ascend' as SortOrder,
    };

    const tableToDtoSortOrder = (
        tableSort: SorterResult<AmenityCategory>
    ): [GetAmenityCategoriesSortColumnDto, SortDirectionDto] => {
        const sortColumn: GetAmenityCategoriesSortColumnDto = tableSort.columnKey as GetAmenityCategoriesSortColumnDto;
        const sortDirection: SortDirectionDto =
            tableSort.order === 'descend'
                ? SortDirectionDto.Descending
                : SortDirectionDto.Ascending;

        return [sortColumn, sortDirection];
    };

    const [defaultColumnSort, defaultDirectionSort] = tableToDtoSortOrder(defaultSortOrder);
    const defaultTableColumns = getTableColumns(defaultSortOrder);
    const [columns, setColumns] = useState<ColumnType<AmenityCategory>[]>(defaultTableColumns);

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetAmenityCategoriesSortColumnDto;
            sortDirection: SortDirectionDto;
            searchTerm: string;
            advancedFilters?: AdvancedFilter[];
            locationIds: string[];
        }) => {
            setLoading(true);
            try {
                // call api
                const [items, totalItems] = await amenityCategoriesService.getAmenityCategories({
                    pageSize: params.pagination.pageSize || PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    includeEmpty: true,
                });

                setAmenityCategories(items);

                // disable reorder option
                setActions((value) =>
                    disableActionMenuItem(value, 'reorder', items.length < MIN_ITEMS_FOR_REORDER)
                );

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [amenityCategoriesService]
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<AmenityCategory> | SorterResult<AmenityCategory>[]
    ): void => {
        if (Array.isArray(sorter)) {
            // # We support single column sort only, like the API
            // # This should not happen if columns are properly configured,
            // # but just in case, we keep the first sort only.
            sorter = sorter[0];
        }

        // Update the table sort state
        setColumns(getTableColumns(sorter));

        // Call new sorted data
        const [column, direction] = tableToDtoSortOrder(sorter);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination,
            sortColumn: column,
            sortDirection: direction,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            locationIds: filterStore.currentLocationIds,
        });
    };
    //#endregion

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: defaultColumnSort,
                sortDirection: defaultDirectionSort,
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
                locationIds: filterStore.currentLocationIds,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch, defaultColumnSort, defaultDirectionSort]);
    //#endregion

    //#region Actions
    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                setColumns(defaultTableColumns);
                fetch({
                    pagination: initialPaginationState,
                    sortColumn: defaultColumnSort,
                    sortDirection: defaultDirectionSort,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    locationIds: filterStore.currentLocationIds,
                });
            }
        },
        [fetch, defaultTableColumns, defaultColumnSort, defaultDirectionSort]
    );

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: initialPaginationState,
            sortColumn: defaultColumnSort,
            sortDirection: defaultDirectionSort,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            locationIds: filterStore.currentLocationIds,
        });
        setReorderModalOpen(false);
    };

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'settings',
                nameKey: 'settings',
            },
            {
                path: 'amenity-categories',
                nameKey: 'Amenities.amenity_categories',
            },
        ];
    };

    //#endregion

    return (
        <div className="AmenityCategories">
            <ListSectionHeader
                title={t('Amenities.amenity_categories')}
                subTitle={t('Amenities.amenity_categories_subtitle')}
                defaultImg={<Booking fill={theme['primary-color']} />}
                backgroundImageUrl={listSectionHeader}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
                routes={getBreadcrumb()}
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(amenityCategory: AmenityCategory): string => amenityCategory.id!}
                    dataSource={amenityCategories}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(amenityCategory: AmenityCategory) => ({
                        onClick: (): void => {
                            history.push('/settings/amenity-categories/' + amenityCategory.id);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <AmenityCategoryModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
            {reorderModalOpen && (
                <ReorderAmenityCategoriesModal
                    visible={reorderModalOpen}
                    onComplete={onReorderComplete}
                />
            )}
        </div>
    );
});

export default AmenityCategories;
