// GENERATED FILE - DO NOT MODIFY
import { ExportInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/ExportInvoicesRequestDto';
import { GetCompanyInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetCompanyInvoicesRequestDto';
import { GetInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetInvoicesRequestDto';
import { GetInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetInvoicesResponseDto';
import { GetUserInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetUserInvoicesRequestDto';
import { InvoiceDetailsDto } from 'Api/Features/Invoices/Dtos/InvoiceDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class InvoiceProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getInvoice(invoiceId: string): Promise<InvoiceDetailsDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}",
            { invoiceId: invoiceId },
            null
        );

        const data: InvoiceDetailsDto | null = await this.httpClient.get<InvoiceDetailsDto | null>(uri);
        return data;
    }

    public async getInvoices(request: GetInvoicesRequestDto | null): Promise<GetInvoicesResponseDto | null> {
        const uri = this.buildUri(
            "/invoices",
            null,
            { ...request || undefined }
        );

        const data: GetInvoicesResponseDto | null = await this.httpClient.get<GetInvoicesResponseDto | null>(uri);
        return data;
    }

    public async getUserInvoices(userId: string, request: GetUserInvoicesRequestDto | null): Promise<GetInvoicesResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/invoices",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetInvoicesResponseDto | null = await this.httpClient.get<GetInvoicesResponseDto | null>(uri);
        return data;
    }

    public async getCompanyInvoices(companyId: string, request: GetCompanyInvoicesRequestDto | null): Promise<GetInvoicesResponseDto | null> {
        const uri = this.buildUri(
            "/companies/{companyId}/invoices",
            { companyId: companyId },
            { ...request || undefined }
        );

        const data: GetInvoicesResponseDto | null = await this.httpClient.get<GetInvoicesResponseDto | null>(uri);
        return data;
    }

    public async exportInvoices(request: ExportInvoicesRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/invoices/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async payInvoice(invoiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/payments",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async refundInvoice(invoiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/refunds",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async voidInvoice(invoiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/void",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async closeInvoice(invoiceId: string): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/close",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }
}